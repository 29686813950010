










































































import { Component, Prop, Vue } from "@components/common";
import Event from "@graphql/types/event";
import TeamAction from "@components/events/signup/signup_as_team.vue";
import UserAction from "@components/events/signup/signup_as_user.vue";
import { DateTime } from "luxon";
import { ListTeams } from "@graphql/queries/teams";
import Team from '@/graphql/types/team';
import FormattedTime from "@components/common/formatted_time.vue";
import {EventFlowStageConfiguration} from "@graphql/types/event_flow_stage";

@Component({
  components: {
    TeamAction,
    UserAction,
    FormattedTime,
  },
  apollo: {
    myTeams: {
      query: ListTeams,
      variables() { return {filters: {userIds: [this.currentUserId]}};},
      update: data => data.teams
    }
  }
})
export default class EventAction extends Vue {
  @Prop() private readonly event!: Event;
  @Prop() private readonly eventType!: string;

  private myTeams!: Team[];

  get signUpAction() {
    return this.$route.query.signup === 'confirm' ? 'signup_confirm' : 'signup';
  }

  get eventSignUpOpen() {
    const signUpFlow = this.event.eventFlow?.eventFlowStages?.find(efs => efs.flowType === 'EventStageFlows::Signup::OpenSignup');

    if (signUpFlow) {
      const signUpFlowconfig = signUpFlow.configuration as EventFlowStageConfiguration;
      return DateTime.fromISO(signUpFlowconfig.end_time) > DateTime.local();
    }
    return DateTime.fromISO(this.event.signUpEndTime) > DateTime.local();
  }

  get eventSignUpClosed() {
    if (!this.eventSignUpOpen && DateTime.fromISO(this.event.startTime) > DateTime.local())
      return true;
    return false;
  }

  get eventActive() {
    if (!this.eventFinished && DateTime.fromISO(this.event.startTime) < DateTime.local())
      return true;
    return false;
  }

  get eventFinished() {
    if (this.event.status === 'complete') return true;
    return DateTime.fromISO(this.event.endTime) < DateTime.local();
  }


  get showEventFullButton() {
    return this.isEventFull && !this.eventActive && !this.isParticipant  && !this.eventSignUpClosed && !this.eventFinished;
  }

  // Teams stuff

  get isCaptainOfOneTeam() {
    return this.captainedTeams.length == 1;
  }

  get isCaptainOfMoreTeams() {
    return this.captainedTeams.length > 1;
  }

  get isCaptain() {
    return this.isCaptainOfOneTeam || this.isCaptainOfMoreTeams;
  }

  get captainedTeams() {
    return this.myTeams.filter(this.canInviteToTeam);
  }

  get isInTeam() {
    if (!this.myTeams) return false;
    return this.myTeams.length > 0;
  }

  get isParticipant() {
    if (this.event.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.event.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isParticipantTeamMember() {
    if (this.event.isTeamBased) {
      return this.event.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }

  get isUserBased() {
    return this.event?.isUserBased;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  canInviteToTeam(team) {
    const captainId = team.captain?.id;

    if (!captainId) return false;

    return captainId == this.currentUserId;
  }

  get isEventFull() {
    return this.event.participationCount >= (this.event?.maxPlayers ?? 0);
  }

  showSubmitScoreDialog(...args) {
    this.$emit("showSubmitScoreDialog", ...args);
  }

  checkIn() {
    this.$notify({text: "Checkin in ..."});
  }

  joinAsUser(event) {
    this.$emit("joinAsUser", { event });
  }

  joinAsTeam(event, teamId) {
    this.$emit("joinAsTeam", { event, teamId });
  }

  close(...args) {
    this.$emit("cancel", ...args);
  }

  refreshEvent(...args) {
    this.$emit("updateEvent",...args);
  }
}
