













































































import { Page, Component, Layout, FormLabel, Divider } from "@components/common";
import { errorPath, ErrorMessage } from "@/lib/errors";

@Component({ components: { Layout, FormLabel, Divider }} )
export default class Settings extends Page {
  private formValid = false;
  private currentPassword = "";
  private newPassword = "";
  private repeatNewPassword = "";

  private isPasswordInvalid = false;
  private lastErrors: ErrorMessage[] = [];

  get passwordErrors() {
    return this.lastErrors?.map(error => this.$t(errorPath(error.message)));
  }

  clearErrors() {
    this.lastErrors = [];
  }

  reset() {
    this.currentPassword = this.newPassword = this.repeatNewPassword = "";
    this.clearErrors();
    this.formValid = true;
  }

  matchesFirstPassword(val) {
    return this.newPassword == val || this.$t("profile_settings.passwords_must_match_error").toString();
  }

  notEmpty(val) {
    return !!val && val.length > 0;
  }

  async changePassword() {
    if (!this.formValid)
      return;

    this.$store.dispatch("me/changePassword", {
      oldPassword: this.currentPassword,
      newPassword: this.newPassword
    }).then(result => {
      if (result.success) {
        this.reset();
        this.$notify({
          text: this.$t("profile_settings.password_successfully_updated").toString()
        });

      }
    }).catch(e => { this.lastErrors = e.graphQLErrors; });
  }
}
