



















































































import { Component, Vue, Prop, Watch } from "@components/common";
import CheckInButton from "@components/events/active/check_in_button.vue";
import ScheduleMatch from "@components/events/active/schedule_match.vue";
import Event from "@graphql/types/event";
import EventFlowStage from "@graphql/types/event_flow_stage";
import { FormattedTime } from "@components/common/helpers";

import Match from "@graphql/types/match";

import { DateTime } from "luxon";
import { sortBy, groupBy, max, union } from "underscore";
import { namespace } from "@store";

const MyMatchesStore = namespace("myMatches");
const CASUAL_PLAY_FLOW_TYPE = "TournamentFlows::Qualification::CasualPlay";

@Component({
  components: { CheckInButton, ScheduleMatch, FormattedTime }
})
export default class ActiveEventTeamAction extends Vue {
  @Prop(Object) event!: Event;
  @Prop(String) eventType!: string;

  @MyMatchesStore.Getter("allMatches")
  private myActiveMatches!: Match[];

  /* Match states, schedule_needed, pending_start, checkin_available, checked_in, pending_score, completed, unavailable */

  get isCasualPlayEvent() {
    return (this.event.flowType === CASUAL_PLAY_FLOW_TYPE) ?? false;
  }

  get playoffNoMatchesIconDateTime () {
    return this.event.playoffStartTime;
  }

  get playoffNoMatchesNoticeText() {
    if (this.isFinalStage) return this.$t('match.final_stage_ended');
    return this.$t('match.pending_playoff_matches', {playoffTag: this.playoffTag});
  }

  get participationFinishedNoticeText() {
    return this.$t('match.participation_ended');
  }

  get hasEventMatches() {
    if (this.matchesCount > 0) return true;
    return false;
  }

  get myParticipatingTeam() {
    if (!this.event.participatingTeams) return false;
    return this.event.participatingTeams?.find(a => a.members?.find(member => member.id === this.currentUserId));
  }

  get isParticipantingTeamCaptain() {
    if (!this.event.participatingTeams) return false;
    return this.event.participatingTeams?.some(team => team.captain?.id === this.currentUserId);
  }

  get matchesCount() {
    if (this.event.matches === undefined) {
      return this.event.matchCount ?? 0;
    }
    return this.event.matches.length;
  }

  get hasEventStarted() {
    if (DateTime.fromISO(this.event.startTime) < DateTime.local())
      return true;
    return false;
  }

  get isMatchPendingSchedule() {
    if (this.myMatch === undefined) return false;
    if (this.myMatch.startTimeLocked) return false;
    return !this.myMatch?.isStartTimeConfirmed;
  }

  // Date match defined but not yet started
  get isMatchPendingStart() {
    if (this.myMatch === undefined) return false;
    return !this.hasMatchStarted && !this.isMatchCompleted && (this.myMatch.isStartTimeConfirmed || this.myMatch.startTimeLocked);
  }

  // Match started and not finished yet
  get isMatchActive() {
    return this.hasMatchStarted && !this.isMatchCompleted && !this.isMatchPendingSchedule;
  }

  get hasMatchStarted() {
    if (!this.myMatch)
      return false;

    return DateTime.fromISO(this.myMatch.startTime) < DateTime.local();
  }

  // Match finished
  get isMatchCompleted() {
    return DateTime.fromISO(this.myMatch.endTime) < DateTime.local();
  }

  showSubmitScoreDialog(...args) {
    this.$emit("showSubmitScoreDialog", ...args);
  }

  get isCheckedIn() {
    //TODO
    return true;
  }

  get canParticipate() {
    return !this.isUserFinished;
  }

  get isUserFinished() {
    return this.myParticipation?.eliminated;
  }

  get isQualifications() {
    if (!Event.isLegacyEvent(this.event)) {
      return EventFlowStage.isQualifications(Event.currentEventFlowStage(this.event));
    }

    if (this.event.qualificationEndTime === undefined) {
      return false;
    } else if ((DateTime.fromISO(this.event.qualificationEndTime) > DateTime.local()) && this.hasEventStarted) {
      return true;
    }
    return false;
  }

  get isPendingPlayoffs() {
    return !this.isQualifications && !this.isPlayoffs && !this.hasPlayoffStarted;
  }

  get hasPlayoffStarted() {
    return DateTime.fromISO(this.event.playoffStartTime) < DateTime.local();
  }

  get myMatchStage() {
    if (!this.myMatch) return this.myPreviousMatchMaxStage;
    return this.myMatch?.stage? this.myMatch.stage : 0;
  }

  get isFinalStage() {
    return this.myMatchStage === this.noEventStages;
  }

  get noEventStages() {
    return this.event.lastEliminationStageNumber? this.event.lastEliminationStageNumber : 0;
  }

  get playoffTag () {
    const noStages = this.noEventStages;
    const currentStage = this.myMatchStage;
    const power = noStages - currentStage+1;

    const stage = 2**power;

    switch (stage) {
      case 2:
        return this.$t("match.finale").toString();
      case 4:
        return this.$t("match.semi_finale").toString();
      case 8:
        return this.$t("match.quater_finale").toString();
      default:
        return this.$t("match.round_of", {round: stage, roundMatches: stage/2}).toString();
    }
  }

  get isPlayoffs() {
    if (!Event.isLegacyEvent(this.event)) {
      return EventFlowStage.isPlayoffs(Event.currentEventFlowStage(this.event));
    }

    if ((DateTime.fromISO(this.event.playoffStartTime) < DateTime.local())) {
      return true;
    }
    return false;
  }

  checkIn() {
    this.$notify({text: this.$t("common.not_available_yet").toString()});
  }

  // "id" is here on purpose. Changes shouldn't happen unless it's actually a
  // different match to avoid overwriting the user's input if the data
  // refreshes during entry
  @Watch("mySchedulableMatch.id", {immediate: true})
  setExistingTimes() {
    const match = this.myMatch;
    const time = match?.proposedStartTime ?? match?.startTime;

    if (!match || !time)
      return;

    const startTime = DateTime.fromISO(time);
  }

  get isReschedule() {
    return this.myMatch?.isStartTimeConfirmed;
  }

  get opponent() {
    const match = this.myMatch;
    if (!match)
      return {};

    const opponent = match.participations?.find(p => p.id != this.myParticipatingTeamId);

    if (!opponent)
      return {name: "Nobody", preferredTime: "No"};

    return this.participants.find(p => p.id == opponent.id);
  }

  get myParticipation() {
    return this.event.participations?.find(p => p.id === this.myParticipatingTeamId);
  }

  get participants() {
    return [
      ...this.event.participatingUsers ?? [],
      ...this.event.participatingTeams ?? []
    ];
  }

  get myMatch() {
    if (this.myNeedsConfirmationMatches.length > 0) {
      return this.myNeedsConfirmationMatches[0];
    }
    return this.myUncompletedMatches[0];
  }

  get myPreviousMatchMaxStage() {
    return Number(max(Object.keys(groupBy(this.myCompletedMatches, "stage"))));
  }

  get myNeedsConfirmationMatches() {
    return sortBy(this.myMatches.filter(m => (m.startTimeProposedById !== this.myParticipatingTeamId) && (m.startTimeProposedById !==  null) && !m.isStartTimeConfirmed));
  }

  get myCompletedMatches() {
    return sortBy(this.myMatches.filter(m => !!m.result?.id), "index");
  }

  get myUncompletedMatches() {
    return sortBy(this.myMatches.filter(m => !m.result?.id),"index");
  }

  get myUncompletedMatchesCount() {
    if (this.myUncompletedMatches === undefined) {
      return 0;
    }
    return this.myUncompletedMatches.length;
  }

  get hasUncompletedMatches() {
    return this.myUncompletedMatchesCount === 0 ? false : true;
  }

  get myParticipatingTeamId() {
    if (!this.myParticipatingTeam) return null;
    return this.myParticipatingTeam.id;
  }

  private combineGroupedMatches(matches: Match[]) {
    const toGroupMatches = matches.filter(
      m => m.grouped !== false && !m.groupedMatchIds
    );
    const noGroupedMatches = matches.filter(m => m.grouped === false);

    const groupedMatches = (Object.values(
      groupBy(toGroupMatches, "identifier")
    ) as Match[][]).map(matches => ({
      ...matches[0],
      groupedMatchIds: matches.map(m => m.id)
    }));

    return union(noGroupedMatches, groupedMatches);
  }

  get myMatches() {
    if (!Event.isLegacyEvent(this.event)) return this.myEventMatches;
    return this.combineGroupedMatches((this.event.matches ?? []).filter(match =>
      match.participations?.some(p => p.id === this.myParticipatingTeamId))
    );
  }

  get myEventMatches(): Match[] {
    return this.myActiveMatches.filter(m => (m.eventId == this.event.id && m.eventType === "Event"));
  }

  refreshTournament(...args) {
    this.$emit("refreshTournament", ...args);
  }
}
