import { Vue } from "@components/common";
import Vuex from "vuex";

import { config } from "vuex-module-decorators";
config.rawError = true;

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import AppProperties from "@store/app_properties";
import Authentication from "@store/authentication";
import Chat from "@store/chat";
import Chatrooms from "@store/chatrooms";
import Events from "@store/events";
import Games from "@store/games";
import HallOfFameEntries from "@store/hall_of_fame_entries";
import Matches from "@store/matches";
import Me from "@store/me";
import MyEnrolments from "@/store/my_enrolments";
import MyMatches from "@store/my_matches";
import MyRounds from "@store/my_rounds";
import Navigation from "@store/navigation";
import Notifications from "@store/notifications";
import Progress from "./progress";
import Rounds from "@store/rounds";
import Teams from "@store/teams";
import Tournaments from "@store/tournaments";
import Users from "@store/users";

export const store = new Vuex.Store({
  modules: {
    appProperties: AppProperties,
    authentication: Authentication,
    chat: Chat,
    chatrooms: Chatrooms,
    events: Events,
    games: Games,
    hallOfFameEntries: HallOfFameEntries,
    matches: Matches,
    me: Me,
    myEnrolments: MyEnrolments,
    myMatches: MyMatches,
    myRounds: MyRounds,
    navigation: Navigation,
    notifications: Notifications,
    progress: Progress,
    rounds: Rounds,
    teams: Teams,
    tournaments: Tournaments,
    users: Users,
  },
  plugins: [
    createPersistedState({
      reducer: (data) => ({
        appProperties: data.appProperties,
        authentication: data.authentication,
        events: data.events,
        games: data.games,
        hallOfFameEntries: data.hallOfFameEntries,
        myEnrolments: data.myEnrolments,
        myMatches: data.myMatches,
        myRounds: data.myRounds,
      }),
    }),
  ],
});

export type Store = typeof store;
export { namespace } from "vuex-class";
