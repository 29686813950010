










import { Vue, Component, Prop } from "@components/common";

import Notification from "@graphql/types/notification";

@Component
export default class MatchNotification extends Vue {
  @Prop() private readonly notification!: Notification;
}
