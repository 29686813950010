












































import { Page, Component, Layout } from "@components/common";

import EventCard from "@components/v2/EventCard.vue";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";
import Tournament from '@/graphql/types/tournament';
import { DateTime } from "luxon";
import { chain } from "underscore";
import { namespace } from "@store";

const MyEnrolmentsStore = namespace("myEnrolments");

@Component({
  components: { EventCard, Layout },
})
export default class MyEnrolmentsArchive extends Page {
  private isUpdating = false;


  @MyEnrolmentsStore.Getter("tournaments")
  public myTournaments!: Tournament[];

  @MyEnrolmentsStore.Getter("events")
  public myEvents!: Event[];

  get myEventsAndTournaments() {
    return chain(this.myTournaments)
      .union(this.myEvents)
      .sortBy(event => event.startTime)
      .filter(event => (DateTime.fromISO(event.endTime) < DateTime.local()))
      .reverse()
      .value();
  }

  eventCategoryRoute(event) {
    return new EventCategoryHelpers(event).routeName;
  }

  get hasEnrolments() {
    return this.hasTournaments || this.hasEvents;
  }

  get hasTournaments() {
    return this.myTournaments?.length ?? 0 > 0;
  }

  get hasEvents() {
    return this.myEvents?.length ?? 0 > 0;
  }

  refetchMyEnrolments() {
    this.$store.dispatch("myEnrolments/reload");
  }

}
