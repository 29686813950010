









































































































import { Page, Component, Layout } from "@components/common";
import { DateTime } from "luxon";
import FeedCard from '@components/feed/feed_card.vue';
import { TranslateResult } from 'vue-i18n';
import axios from "axios";

const postsURL = "https://senior-esports.ch/wp-json/wp/v2/app-feed";

@Component({
  components: { Layout, FeedCard },
})
export default class Reminders extends Page {

  private postModal = false;
  private initFetch = true;
  private loading = true;
  private posts: Array<any> = [];
  private queryOptions = {
    // eslint-disable-next-line
    per_page: 20,
    // eslint-disable-next-line
    app_feed_tax: 21,
    page: 1,
    _embed: true,
  }

  private totalPosts = 0
  private activePost = null

  getPosts() {
    this.loading = true;
    axios.get(postsURL, { params: this.queryOptions})
      .then(response => {
        this.totalPosts = response.headers['x-wp-total'];
        if (response.data.length >= 1) {
          this.queryOptions.page += 1;
          response.data.forEach(item => this.posts.push(item));
          this.loading = false;
          this.initFetch = false;
        }
      });
  }

  getFormatedDate(date) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
  }

  get moreAvailable() {
    return this.totalPosts <= this.posts.length ? false : true;
  }

  loadPosts(post) {
    this.activePost = post;
    this.postModal = true;
  }

  sanitize(html) {
    // Remove Wordpress shortcodes from HTML
    // const clean = html.replace(/\[\/?et_pb.*?\]/g, '');
    // Remove empty paragraphs
    const empties = html.replace('<p>&nbsp;</p>','');
    return empties;
  }

  private feed = [1]

  get welcomeCard() {
    return this.$t("feed.card.welcome");
  }

  get placeholderItems() {
    let i;
    const items: TranslateResult[] = [];

    for (i= 1; i <= 10; i++) {
      const translation = this.$t("feed.card.placeholder_"+i);
      if (translation && (translation !== "feed.card.placeholder_"+i))
        items.push(translation);
    }

    return items;
  }

  get hasPlaceholders() {
    return this.placeholderItems.length > 0 || this.welcomeCard;
  }

  mounted() {
    this.getPosts();
  }
}
