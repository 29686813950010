




































import { Component, Vue, Prop } from "@components/common";

@Component
export default class Heading extends Vue {
  @Prop({ default: false, type: Boolean }) readonly noBorder!: boolean;
}
