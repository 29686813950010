import gql from "graphql-tag";

export default gql`
  mutation UpdateMyPlatforms($platforms : [GamePlatformListEntryInput!]!) {
    updateMyPlatforms(platforms: $platforms) {
      success
      platforms {
        platformId
        accountIdentifier
        data
      }
    }
  }
`;
