













































import { Component, Vue, Prop } from "@components/common";
import TableExpander from "@components/v2/TableExpander.vue";
import Heading from "@components/v2/Heading.vue";

@Component({components: {TableExpander, Heading}})
export default class BestPlayers extends Vue {
  @Prop({ default: () => []}) readonly players!: any[];

  private shortList = true;
  private limit = 6;

  get limitedList() {
    return this.shortList ? this.players.slice(0, this.limit) : this.players;
  }

  toggleView() {
    this.shortList = !this.shortList;
  }
}
