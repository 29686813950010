




















































import GroupStage from "@components/tournaments/group_stage.vue";
import Participation from "@graphql/types/participation";
import Tournament from "@graphql/types/tournament";
// import Brackets from "@components/matches/brackets.vue";
import { Component, Vue, Prop } from "@components/common";
import { groupBy } from "underscore";

const GROUP_QUALIFICATION_FLOW_DATA_KEY = "TournamentFlows::Qualification::Groups";

interface Group {
  id: number;
  name: string;
  standings: GroupEntry[];
}

interface GroupEntry {
  name: string;
  id: number;
  p: number;
  w: number;
  d: number;
  l: number;
  pts: number;
  f: number;
  sf: number;
  sa: number;
  diff: number;
}

@Component({  components: {GroupStage}})
export default class TournamentStandings extends Vue {
  @Prop() readonly tournament!: Tournament;
  @Prop() readonly eventPhase!: string;

  private tab = null

  private fields = [
    { field: 'position', label: '#' },
    { field: 'name' },
    { field: 'p' },
    { field: 'w' },
    { field: 'd' },
    { field: 'l' },
    { field: 'pts' },
    { field: 'f' },
    { field: 'sf' },
    { field: 'sa' },
    { field: 'diff', label: '+/-' },
  ]

  get groupedParticipations(): Record<number, Participation[]> {
    const participations = this.tournament?.participations ?? [];

    return groupBy(participations.filter(p => p.group !== null), "group");
  }

  get groups() {
    const participations = this.groupedParticipations;

    return Object.entries(participations).reduce<Group[]>((result, [groupNumber, group]) => (
      [
        ...result,
        {
          id: Number(groupNumber),
          name: `Group ${Number(groupNumber)+1}`,
          standings: group.map(this.groupParticipantEntry.bind(this))
        } as Group
      ]
    ), []);
  }

  get participants() {
    return [
      ...this.tournament.participatingUsers ?? [],
      ...this.tournament.participatingTeams ?? []
    ];
  }

  get isTeamBased() {
    return this.tournament?.participations?.[0].type === "TEAM";
  }

  groupParticipantEntry(participation): GroupEntry {
    const participant = this.participants.find(p => p.id == participation.id);

    if (!participant)
      return {} as GroupEntry;

    const data = participation.data[GROUP_QUALIFICATION_FLOW_DATA_KEY];

    return {
      id: participant.id!,
      name: participant.name!,
      p: data.matches,
      w: data.wins,
      l: data.losses,
      d: data.draws,
      pts: data.points,
      f: data.forfeits,
      sf: data.score_for,
      sa: data.score_against,
      diff: data.difference,
    };
  }

  scrollToMyGroup() {
    if (document.getElementById('mygroup')) {
      this.$smoothScroll({
        scrollTo: document.getElementById('mygroup') as HTMLElement,
        offset: -140,
      });
    }
  }
}
