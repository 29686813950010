













import { Page, Component, Layout } from "@components/common";

@Component({
  components: { Layout },

})
export default class StandingsGames extends Page {

}
