






































import { Component, Page, Prop } from "@components/common";
import UserPlatform from "@graphql/types/user_platform";
import Platform from "@graphql/types/platform";
import Game from "@graphql/types/game";
import { eventFilters, userFilters, featuredFilters, eventStandings } from "@/store/filters";

@Component
export default class Filters extends Page {
  @Prop() placeholder!: string;
  @Prop() notice!: string;
  @Prop() eventType!: string;
  @Prop({ default: false}) myFilter!: boolean;

  public isUpdatingSearch = false;

  private filterModal = false
  private games: Game[] = []
  private platforms: Platform[] = []
  private myGames: Game[] = []
  private myPlatforms: UserPlatform[] = []

  private isMyFilter = this.myFilter

  private filters = (
    this.eventType === 'Event' ? eventFilters :
    this.eventType === 'Featured' ? featuredFilters :
    this.eventType === 'Teams' ? userFilters :
    this.eventType === 'Players' ? userFilters :
    this.eventType === 'Standings' ? eventStandings :
    {}
  );

  mounted() {
    this.games = this.appProperties.games;
    this.platforms = this.appProperties.platforms;
    this.myGames = this.currentUser?.games ?? [];
    this.myPlatforms = this.currentUser?.userPlatforms ?? [];

    if (this.$route.query.gameIds) {
      this.$set(this.filters, 'gameIds', this.$route.query.gameIds);
    }

    if (this.$route.query.platformIds) {
      this.$set(this.filters, 'platformIds', this.$route.query.platformIds);
    }
  }

  get filtersActive () {
    return !!(this.gameIds.length || this.platformIds.length);
  }

  get gameIds() {
    return this.$route.query.gameIds ? this.$route.query.gameIds : [];
  }

  set gameIds(newIds) {
    this.$set(this.filters, 'gameIds', newIds);
    this.$router.push({ query: { ...this.$route.query, gameIds: newIds } })
      .catch(() => { return; });
  }

  get platformIds() {
    return this.$route.query.platformIds ? this.$route.query.platformIds : [];
  }

  set platformIds(newIds) {
    this.$set(this.filters, 'platformIds', newIds);
    this.$router.push({ query: { ...this.$route.query, platformIds: newIds } })
      .catch(() => { return; });
  }

  filterMyPlatforms(values) {
    if (this.isMyFilter) {
      this.platformIds = values.filter(val => !!val.accountIdentifier).map(p => p.platformId);
      this.$emit('refresh', this.filters);
    }
  }

  filterMyGames(values) {
    if (this.isMyFilter) {
      this.gameIds = values.games.map(p => p.gameId);
      this.$emit('refresh', this.filters);
    }
  }

  filter() {
    this.$emit('refresh', this.filters);
    this.filterModal = false;
  }

  resetFilter() {
    this.platformIds = [];
    this.gameIds = [];
    this.isMyFilter = false;
    this.$emit('refresh', this.filters);
    this.filterModal = false;
  }

  markUpdating() {
    this.$emit('mark-updating');
  }

  emitRefresh() {
    this.$emit('refresh', this.filters);
  }
}
