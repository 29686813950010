










import { Page, Component } from "@components/common";

import Notification from "@graphql/types/notification";
import { MATCH_NOTIFICATION_TYPES } from "@components/v2/Notifications.vue";

import NotificationsContent from "@components/v2/Notifications/NotificationsContent.vue";

@Component({
  components: {
    NotificationsContent
  }
})
export default class Notifications extends Page {
  private loading = false;
  private notifications: Notification[] = [];

  async loadNotifications() {
    this.notifications = await this.$store.dispatch("notifications/search", {
      filters: {types: MATCH_NOTIFICATION_TYPES},
    });
  }

  loadData() {
    this.loading = true;

    Promise.all([
      this.loadNotifications(),
    ]).finally(() => this.loading = false);
  }

  mounted() {
    this.loadData();
  }

}
