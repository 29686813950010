




















































import { Component, Vue, Prop } from "@components/common";

import Tournament from "@graphql/types/tournament";
import Platform from "@graphql/types/platform";
import Team from "@graphql/types/team";

@Component
export default class TournamentCardDetail extends Vue {
  @Prop(Object) readonly tournament!: Tournament;

  private myTeams!: Team[];
  private platforms: Platform[] = [];
  private selectedTeamId = null;

  get showTeamsDropdown() {
    return this.isTeamBased && this.myTeams?.length > 1;
  }

  get checkinTime() {
    if (this?.tournament.checkin !== undefined) {
      return Math.round(this.tournament.checkin / 60);
    }
      return undefined;
  }

  get uniquePlatformsIcons() {
    const platforms = this.tournament?.platforms;

    if (!platforms)
      return [];

    return Array.from(
      new Set(platforms.map(x => x.icon))
    );
  }

  get participantCount() {
    const tournament = this.tournament;

    if (!tournament)
      return 0;

    return Math.max(
      tournament?.participatingUsers?.length ?? 0,
      tournament?.participatingTeams?.length ?? 0
    );
  }

  get tournamentFull() {
    const tournament = this.tournament;

    if (!tournament || !tournament.maxPlayers)
      return false;

    return this.participantCount >= tournament.maxPlayers;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get isUserBased() {
    return this.tournament?.mode === "1v1";
  }

  get coverImage() {
    return this.tournament?.coverImage?.url ?? '';
  }

  isTeamAlreadyParticipating(team) {
    return this?.tournament?.participatingTeams?.some(p => p.id == team.id);
  }
}
