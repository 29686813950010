


















import { Component, Vue, Prop } from "@components/common";
import { GetTeamMatchStatistics } from "@graphql/queries/team_match_stats";
import UserMatchStats from "@graphql/types/user_match_stats";

@Component({
  apollo: {
    matchStats: {
      query: GetTeamMatchStatistics,
      variables() { return {teamId: parseInt(this.teamId)};},
    }
  }
})

export default class MatchStatisticsCard extends Vue {
  @Prop() readonly teamId!: number;


  private matchStats?: UserMatchStats;

  get stats() {
    return { won: this.matchStats?.wins, played: this.matchStats?.playedMatches};
  }
}
