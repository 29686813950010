import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";
import gql from "graphql-tag";
import createStore from "@store/generic_store";
import eventBus from "@/event_bus";

import Match, { MatchFields } from "@graphql/types/match";

import SubmitMatchResult from "@graphql/mutations/submit_match_result";
import SubmitMatchResults from "@graphql/mutations/submit_match_results";
import AdminSubmitMatchResult from "@graphql/mutations/admin/submit_match_result";
import AdminSetMatchOptions from "@graphql/mutations/admin/set_match_options";
import ConfirmMatchResult from "@graphql/mutations/confirm_match_result";
import CheckinToMatch from "@graphql/mutations/checkin_to_match";
import CheckinToMatches from "@graphql/mutations/checkin_to_matches";
import ProposeMatchStartTime from "@graphql/mutations/propose_match_start_time";
import ProposeMatchStartTimes from "@graphql/mutations/propose_match_start_times";
import ConfirmMatchStartTime from "@graphql/mutations/confirm_match_start_time";
import ConfirmMatchStartTimes from "@graphql/mutations/confirm_match_start_times";

export default class Matches extends createStore({
  name: "match",
  pluralName: "matches",
  recordType: Match,
}) {
  @Action({ rawError: true })
  async submitResult({ matchId, winnerId = null, scores, draw = null, data }) {
    const {
      data: { submitMatchResult: result },
    } = await apollo.mutate({
      mutation: SubmitMatchResult,
      variables: { matchId, scores, data },
    });

    if (result.success) this.context.dispatch("updateCache", result.match);

    return result;
  }

  @Action({ rawError: true })
  async submitResults({ results }) {
    const {
      data: { submitMatchResults: result },
    } = await apollo.mutate({
      mutation: SubmitMatchResults,
      variables: { results },
    });

    if (result.success) {
      result.matches.forEach((match) =>
        this.context.dispatch("updateCache", match)
      );
    }

    return result;
  }

  @Action({ rawError: true })
  async confirmResult({ matchId }) {
    const {
      data: { confirmMatchResult: result },
    } = await apollo.mutate({
      mutation: ConfirmMatchResult,
      variables: { matchId },
    });

    if (result.success) this.context.dispatch("updateCache", result.match);

    return result;
  }

  @Action({ rawError: true })
  async setStreamingLinks({ matchIds, streamingLinks }) {
    const {
      data: { setMatchStreamingLinks: result },
    } = await apollo.mutate({
      mutation: gql`
        mutation SetMatchStreamingLinks(
          $matchIds: [ID!]!
          $streamingLinks: JSON!
        ) {
          setMatchStreamingLinks(
            matchIds: $matchIds
            streamingLinks: $streamingLinks
          ) {
            success
            matches {
              ...MatchFields
            }
          }
        }
        ${MatchFields}
      `,
      variables: { matchIds, streamingLinks },
    });

    if (result.success)
      result.matches.forEach((match) =>
        this.context.dispatch("updateCache", match)
      );

    return result;
  }

  @Action({ rawError: true })
  async checkIn({ matchId, teamId = null, asTeam = false }) {
    const {
      data: { checkinToMatch: result },
    } = await apollo.mutate({
      mutation: CheckinToMatch,
      variables: { matchId, teamId, asTeam },
    });

    if (result.success) this.context.dispatch("updateCache", result.match);

    return result;
  }

  @Action({ rawError: true })
  async checkIns({ matchIds, teamId = null, asTeam = false }) {
    const {
      data: { checkinToMatches: result },
    } = await apollo.mutate({
      mutation: CheckinToMatches,
      variables: { matchIds, teamId, asTeam },
    });

    if (result.success) {
      result.matches.forEach((match) =>
        this.context.dispatch("updateCache", match)
      );
    }

    return result;
  }

  @Action({ rawError: true })
  async proposeNewTime({ matchId, time }) {
    const {
      data: { proposeMatchStartTime: result },
    } = await apollo.mutate({
      mutation: ProposeMatchStartTime,
      variables: { matchId, startTime: time },
    });

    if (result.success) this.context.dispatch("updateCache", result.match);

    return result;
  }

  @Action({ rawError: true })
  async proposeNewTimes({ matchIds, time }) {
    const {
      data: { proposeMatchStartTimes: result },
    } = await apollo.mutate({
      mutation: ProposeMatchStartTimes,
      variables: { matchIds, startTime: time },
    });

    if (result.success) {
      result.matches.forEach((match) =>
        this.context.dispatch("updateCache", match)
      );
    }

    return result;
  }

  @Action({ rawError: true })
  async confirmStartTime({ matchId, time }) {
    const {
      data: { confirmMatchStartTime: result },
    } = await apollo.mutate({
      mutation: ConfirmMatchStartTime,
      variables: { matchId, startTime: time },
    });

    if (result.success) this.context.dispatch("updateCache", result.match);

    return result;
  }

  @Action({ rawError: true })
  async confirmStartTimes({ matchIds, time }) {
    const {
      data: { confirmMatchStartTimes: result },
    } = await apollo.mutate({
      mutation: ConfirmMatchStartTimes,
      variables: { matchIds, startTime: time },
    });

    if (result.success) {
      result.matches.forEach((match) =>
        this.context.dispatch("updateCache", match)
      );
    }

    return result;
  }

  @Action({ rawError: true })
  async submitAdminResult({
    matchId,
    scores,
    data,
    initiatorId,
    resetMatch,
    resetMatchParams,
    reason,
  }) {
    const {
      data: {
        admin: { submitMatchResult: result },
      },
    } = await apollo.mutate({
      mutation: AdminSubmitMatchResult,
      variables: {
        matchId,
        scores,
        data,
        initiatorId,
        resetMatch,
        resetMatchParams,
        reason,
      },
    });

    if (result.success) {
      this.context.dispatch("updateCache", {
        ...result.match,
        result: result.matchResult,
      });
    }

    return result;
  }

  @Action({ rawError: true })
  async setAdminMatchOptions({
    ids,
    startTime,
    participation1Id,
    participation2Id,
    initiatorId,
    reason,
  }) {
    const {
      data: {
        admin: { setMatchOptions: result },
      },
    } = await apollo.mutate({
      mutation: AdminSetMatchOptions,
      variables: {
        ids,
        startTime,
        participation1Id,
        participation2Id,
        initiatorId,
        reason,
      },
    });

    if (result.success) this.context.dispatch("updateCache", result.match);

    return result;
  }
}
