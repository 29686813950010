



















import { Component, Vue, Prop } from "@components/common";

@Component
export default class Dialog extends Vue {
  @Prop() private readonly invitation;
  private show = false;

  confirm(...args) {
    this.show = false;
    this.$emit("confirm", ...args);
  }

  cancel(...args) {
    this.show = false;
    this.$emit("cancel", ...args);
  }
}
