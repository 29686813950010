import gql from "graphql-tag";
import Base from "@graphql/types/base";
import File, { FileFields } from "@graphql/types/file";
import { TeamFields } from "@graphql/types/team";
import StreamingAccount, {
  StreamingAccountFields
} from "@graphql/types/streaming_account";
import UserPlatform from "@graphql/types/user_platform";
import Game from "@graphql/types/game";
import Team from "@graphql/types/team";
import { PlatformFields } from "@graphql/types/platform";

export const MeFields = gql`
  fragment MeFields on Me {
    id
    uuid
    fullName
    firstName
    lastName
    nickname
    name
    preferredGameTime

    email
    birthDate
    isPremium
    premiumUntil

    onboardingStep
    onboardingCompleted

    avatar {
      ...FileFields
    }
    coverImage {
      ...FileFields
    }

    discordNickname
    profileImage
    showNickname

    teams {
      ...TeamFields
    }

    games {
      id
      tag
      name
      avatar {
        ...FileFields
      }
    }
    userPlatforms {
      accountIdentifier
      platform {
        ...PlatformFields
      }
    }
    streamingAccounts {
      ...StreamingAccountFields
    }

    notificationSettings {
      receiveChatNotifications
    }
  }
  ${FileFields}
  ${TeamFields}
  ${PlatformFields}
  ${StreamingAccountFields}
`;

interface NotificationSettings {
  receiveChatNotifications: boolean;
}

export default class Me extends Base<Me> {
  uuid?: string;
  fullName?: string;
  preferredGameTime?: string;
  firstName?: string;
  lastName?: string;
  nickname?: string;
  name?: string;
  email?: string;
  birthDate?: string;
  isPremium?: string;
  premiumUntil?: string;
  avatar?: File;
  coverImage?: File;
  onboardingStep?: number;
  onboardingCompleted?: boolean;

  discordNickname?: string;
  profileImage?: string;
  showNickname?: boolean;

  streamingAccounts?: StreamingAccount[];
  userPlatforms?: UserPlatform[];

  games?: Game[];
  teams?: Team[];

  notificationSettings?: NotificationSettings;
}
