








































import { Component, Vue, Prop, Divider } from "@components/common";
import { FormattedTime } from "@components/common/helpers";
import { ListTeams } from "@graphql/queries/teams";

import Team from "@graphql/types/team";

@Component({
  components: { FormattedTime, Divider },
})

export default class TeamCard extends Vue {
  @Prop() readonly team!: Team;
  @Prop(Boolean) readonly allowJoin!: boolean
  @Prop({default: false}) readonly details!: boolean;

  private myTeams!: Team[];

  get membersCount() {
    if(this.team.members === undefined) {
      return 0;
    } else {
      return this.team.members.length;
    }
  }

}
