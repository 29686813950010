import gql from "graphql-tag";

export default gql`
  mutation RegisterUser(
    $email : String!,
    $password : String!,
    $attributes : UserCreateInput!
  ) {
    createUser(email: $email, password: $password, attributes: $attributes) {
      success
    }
  }
`;
