
































import { Component, Prop, Vue } from "@components/common";

@Component
export default class Skeleton extends Vue {
  @Prop({default: 6 }) readonly listItems
}
