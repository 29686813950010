














import { Page, Component, Layout } from "@components/common";

import GamesForm from "@components/me/games_form.vue";

@Component({
  components: { Layout, GamesForm }
})

export default class MyGames extends Page {
  gamesSaved() {
     this.$notify({text: this.$t("profile_settings.saved.my_games").toString()});
     this.$router.push({ name: 'Profile Settings'});
  }
}
