






















































































































































































import { Page, Component, Layout } from "@components/common";

import FeedCard from '@components/feed/feed_card.vue';
import MyPendingMatches from "@components/feed/pendingmatches.vue";
import MyActiveMatches from "@components/feed/active_matches.vue";
import NewsCard from "@components/v2/NewsCard.vue";
import axios from "axios";
import { sortBy, union } from "underscore";

export const postsURL = "https://senior-esports.ch/wp-json/wp/v2/app-feed";
export const newsURL = "https://www.senior-esports.com/wp-json/wp/v2/posts";

@Component({
  components: {
    Layout,
    FeedCard,
    MyPendingMatches,
    MyActiveMatches,
    NewsCard
  },
})
export default class FeedAllPage extends Page {

  private postModal = false;
  private initFetch = true;
  private loading = true;
  private posts: Array<any> = [];
  private news: Array<any> = [];
  private queryOptions = {
    per_page: 20,
    app_feed_tax: 19,
    page: 1,
    _embed: true,
  }

  private newsQueryOptions = {
    per_page: 20,
    categories: 22,
    page: 1,
    _embed: true,
  }

  private totalPosts = 0
  private totalNewsPosts = 0
  private activePost = null

  created() {
    this.$store.commit("progress/startLoading");
  }

  get allPosts() {
    return sortBy(union(this.posts, this.news), "date").reverse();
  }

  getPosts() {
    this.loading = true;
    axios.get(postsURL, { params: this.queryOptions})
      .then(response => {
        this.totalPosts = response.headers['x-wp-total'];
        if (response.data.length >= 1) {
          this.queryOptions.page += 1;
          response.data.forEach(item => this.posts.push(item));
          this.loading = false;
          this.initFetch = false;
          this.$store.commit("progress/finishLoading");
        }
      });
  }

  getNews() {
    this.loading = true;
    axios.get(newsURL, { params: this.newsQueryOptions})
      .then(response => {
        this.totalNewsPosts = response.headers['x-wp-total'];
        if (response.data.length >= 1) {
          this.queryOptions.page += 1;
          response.data.forEach(item => this.news.push(item));
          this.loading = false;
          this.initFetch = false;
        }
      });
  }

  get hasCategoryFilter() {
    return !!this.$route.query?.category;
  }

  get category() {
    return this.$route.query.category;
  }

  loadFilteredPosts() {
    this.loading = true;

    axios.get(newsURL, {params: {...this.newsQueryOptions, categories: this.category}})
      .then(response => {
        this.totalPosts = response.headers['x-wp-total'];
        if (response.data.length >= 1) {
          this.queryOptions.page += 1;
          response.data.forEach(item => this.posts.push(item));
          this.loading = false;
          this.initFetch = false;
          this.$store.commit("progress/finishLoading");
        }
      });
  }


  get moreAvailable() {
    return this.totalPosts <= this.posts.length ? false : true;
  }

  loadPost(post) {
    this.activePost = post;
    this.postModal = true;
  }

  sanitize(html) {
    return html.replace('<p>&nbsp;</p>','');
  }

  loadData() {
    if (this.hasCategoryFilter)
      this.loadFilteredPosts();
    else {
      this.getPosts();
      this.getNews();
    }
  }

  mounted() {
    this.loadData();
  }
}
