import gql from "graphql-tag";
import Base from "@graphql/types/base";
import Participation, {
  ParticipationFields,
} from "@graphql/types/participation";

import { selectable as s } from "@store/generic_store";

export const BaseDivisionFields = gql`
  fragment BaseDivisionFields on Division {
    id
    eventId
    position
    level
  }
`;

export const DivisionWithParticipationsFields = gql`
  fragment DivisionWithParticipationsFields on Division {
    ...BaseDivisionFields
    participations {
      ...ParticipationFields
    }
  }
  ${BaseDivisionFields}
  ${ParticipationFields}
`;

export default class Division extends Base<Division> {
  @s() id?: number;
  @s() eventId?: number;
  @s() position?: number;
  @s() level?: number;
  @s() name?: string; // computed
  @s() participations?: Participation[];
}
