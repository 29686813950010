








































































import { Component, Vue, Prop } from "@components/common";

import { namespace } from "@/store";
const AuthenticationState = namespace("authentication");

@Component
export default class BRStandingsTable extends Vue {
  @Prop() readonly items;
  @Prop() readonly headers;
  @Prop() readonly isTeam!: boolean;
  @Prop({type: Boolean}) readonly compact!: boolean;

  private tempUserId = null;

  getParticipantLink (id) {
    const query = {r: this.$route.fullPath};

    return this.isTeam ?
      {name: 'Team', params: { teamId: id }, query} :
      {name: 'Players', params: { userId: id }, query};
  }

  @AuthenticationState.State
  private teamRoles;

  isMe(item) {
    if (this.isTeam) {
      return this.teamRoles.some(role => role.teamId == item.id);
    } else {
      return this.currentUserId == item.id;
    }
  }

  getParticipantPoints(item, key) {
    const participationId = item.participationId;

    const points = item[key].find(x => x.participationId === participationId)?.points ?? 0;
    return points;
  }

  scrollToMyGroup() {
    if (document.getElementById('mygroup')) {
      this.$smoothScroll({
        scrollTo: document.getElementById('mygroup') as HTMLElement
      });
    }
  }
}
