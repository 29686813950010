





import { Component, Prop, Vue } from "@components/common";
import Game from "@graphql/types/game";

@Component
export default class GameLink extends Vue {
  @Prop({
    default: () => ({name: ""})
  })
  readonly game!: Game
}
