





















































import { Component, Vue, Prop } from "@components/common";

@Component({})
export default class DivisionSelector extends Vue {
  @Prop() private readonly list;
  @Prop({ default: () => []}) private readonly groups;
  @Prop() private readonly selectedDivision;
  @Prop({default: false}) private readonly disabled!: boolean;

  get selectedDivisionName() {
    return this.selectedDivision?.name ?? "";
  }

  selectItem(val) {
    this.$emit("input", val);
  }
}
