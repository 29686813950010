import { Vue, Component } from "@components/common";
import { namespace } from "vuex-class";
import Me from "@graphql/types/me";

const AuthenticationStore = namespace("authentication");
import Event from "@graphql/types/event";

@Component
export default class AuthenticationMixin extends Vue {
  @AuthenticationStore.State("user")
  public currentUser?: Me;

  @AuthenticationStore.Getter
  public isLoggedIn!: boolean;

  get currentUserId(): number | null {
    return this.currentUser?.id ?? null;
  }

  isEventAdmin(event: Event): boolean {
    if (!this.isLoggedIn) return false;

    const userId = this.currentUserId;

    if (!userId) return false;

    return event.administratorUserIds?.includes(userId) ?? false;
  }
}
