import gql from "graphql-tag";

import { LeagueFields, DetailedLeagueFields } from "@graphql/types/league";
import { MatchBaseFields } from "@graphql/types/match";
import { MatchResultFields } from "@graphql/types/match_result";
import { MatchCheckinFields } from "@graphql/types/checkin";

export const GetLeague = gql`
  query GetLeague($id: ID!) {
    league(id: $id) {
      ...LeagueFields
    }
  }
  ${LeagueFields}
`;

export const GetLeagueWithDetails = gql`
  query GetLeagueWithDetails($id: ID!) {
    league(id: $id) {
      ...DetailedLeagueFields
    }
  }
  ${DetailedLeagueFields}
`;

export const GetLeagueWithMatches = gql`
  query GetLeagueWithMatches($id: ID!) {
    league(id: $id) {
      ...DetailedLeagueFields
      matches {
        ...MatchBaseFields
        result {
          ...MatchResultFields
        }
        checkins {
          ...MatchCheckinFields
        }
      }
    }
  }
  ${MatchBaseFields}
  ${DetailedLeagueFields}
  ${MatchResultFields}
  ${MatchCheckinFields}
`;
