import gql from "graphql-tag";
import { MatchDetailFields, MatchFields } from "@graphql/types/match";
import { ParticipationFields } from "@graphql/types/participation";
import { MatchResultFields } from "@graphql/types/match_result";

export const ListMatchesWithParticipants = gql`
  query ListMatch($filters: MatchesFilterInput!) {
    matches(filters: $filters) {
      ...MatchFields
      participations {
        id
        name
        type
      }
    }
  }
  ${MatchFields}
`;

export const ListMyMatches = gql`
  query ListMyMatches($filters: MatchesFilterInput!) {
    myMatches(filters: $filters) {
      ...MatchFields
      participations {
        id
        name
        type
      }
    }
  }
  ${MatchFields}
`;

export const ListMyActiveMatches = gql`
  query myActiveMatches {
    myMatches(filters: { status: ACTIVE }) {
      ...MatchDetailFields
      participations {
        id
        name
        type
      }
    }
  }
  ${MatchDetailFields}
`;

export const ListMyPendingMatches = gql`
  query myPendingMatches {
    myMatches(filters: { status: UNSCHEDULED }) {
      ...MatchDetailFields
      participations {
        id
        name
        type
      }
    }
  }
  ${MatchDetailFields}
`;

export const ListMyPendingConfirmationMatches = gql`
  query myPenfingConfirmationMatches {
    myMatches(filters: { status: PENDING_MY_CONFIRMATION }) {
      ...MatchDetailFields
      participations {
        id
        name
        type
      }
    }
  }
  ${MatchDetailFields}
`;

export const GetDivisionsMatches = gql`
  query getDivisionsMatches($filters: MatchesFilterInput!) {
    matches(filters: $filters) {
      ...MatchFields
      result {
          ...MatchResultFields
      }
    }
  }
  ${MatchResultFields}
  ${MatchFields}
`;

export const GetMatches = gql`
  query getMatches($filters: MatchesFilterInput!) {
    matches(filters: $filters) {
      ...MatchFields
      result {
          ...MatchResultFields
      }
    }
  }
  ${MatchResultFields}
  ${MatchFields}
`;

export const GetMatchesWithResult = gql`
  query getMatchesWithResults($filters: MatchesFilterInput!) {
    matches(filters: $filters) {
      ...MatchFields
      participations {
        ...ParticipationFields
        participant {
          id
          name
          avatar {
            url
          }
        }
      }
      participation1 {
        participant {
          id
          name
          avatar {
            url
          }
        }
      }
      participation2 {
        participant {
          id
          name
          avatar {
            url
          }
        }
      }
      result {
        ...MatchResultFields
      }
    }
  }
  ${MatchFields}
  ${ParticipationFields}
  ${MatchResultFields}
`;
