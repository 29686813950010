import gql from "graphql-tag";
import { MatchFields } from "@graphql/types/match";

export default gql`
  mutation AdminSetMatchOptions(
    $ids: [ID!]!
    $startTime: DateTime!
    $participation1Id: ID!
    $participation2Id: ID!
    $initiatorId: ID!
    $reason: String!
  ) {
    admin {
      setMatchOptions(
        ids: $ids
        startTime: $startTime
        participation1Id: $participation1Id
        participation2Id: $participation2Id
        initiatorId: $initiatorId
        reason: $reason
      ) {
        success
        matches {
          ...MatchFields
        }
      }
    }
  }
  ${MatchFields}
`;
