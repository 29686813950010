






import { Component, Vue } from "@components/common";

@Component
export default class CardBody extends Vue {

}
