import { render, staticRenderFns } from "./event.vue?vue&type=template&id=01fe3476&scoped=true&"
import script from "./event.vue?vue&type=script&lang=ts&"
export * from "./event.vue?vue&type=script&lang=ts&"
import style0 from "./event.vue?vue&type=style&index=0&id=01fe3476&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fe3476",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VContainer,VSheet,VSpacer,VTab,VTabItem,VTabs,VTabsItems})
