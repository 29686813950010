





































import { GuestPage, Component, Layout } from "@components/common";
import GameForm from "@components/me/games_form.vue";

@Component({
  components: { Layout, GameForm }
})
export default class Register extends GuestPage {
  private isRegistrationComplete = false;
  private activeStep = 2;

  nextStep() {
    this.updateOnboardingStep();
    this.$router.push("/register/3");
  }

  async updateOnboardingStep() {
    const result = await this.$store.dispatch("me/update", {attributes: {onboardingStep: 3}}); // next onboarding step is pushed
    this.trackAnalytics();
  }

  private trackAnalytics() {
    this.$gtag.event('onboarding', {
      'event_category': 'onboarding_steps',
      'event_label': 'Step 2',
    });
    this.$gtag.event('onboarding', {
      'event_category': 'onboarding_form',
      'event_label': 'User games',
    });
  }
}
