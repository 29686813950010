import gql from "graphql-tag";
import { MatchFields } from "@graphql/types/match";
import { MatchCheckinFields } from "@graphql/types/checkin";

export default gql`
  mutation CheckinToMatch($matchId: ID!, $asTeam: Boolean, $teamId: ID) {
    checkinToMatch(matchId: $matchId, asTeam: $asTeam, teamId: $teamId) {
      success
      match {
        ...MatchFields

        checkins {
          ...MatchCheckinFields
        }
      }
    }
  }
  ${MatchFields}
  ${MatchCheckinFields}
`;
