
















































































import Talk from "talkjs";
import User from "@graphql/types/user";
import { Component, Vue, Watch } from "@components/common";
import { FindUser } from "@graphql/queries/user";
import { Conversation } from "@store/chat";
import Chatroom from "@graphql/types/chatroom";

@Component
export default class Chatbox extends Vue {
  private me: Talk.User | null = null;
  private talkSession: Talk.Session | null = null;

  private recipientName = '';
  private recipientAvatar = '';
  private recipientId = '';
  private showChat = false;
  private chatbox: Talk.Chatbox | null = null;

  private currentChat: Conversation | null = null

  private allowJoin = false;
  private chatroom: Chatroom | null = null;

  private loading = false;


  // Triggers after login
  @Watch("currentUser.id")
  async reinitializeChat(): Promise<void> {
    await this.$nextTick;
    this.initializeChat();
  }

  async joinChatroom() {
    const chatroom = this.chatroom;
    const id = chatroom?.talkjsConversationId;

    if (!chatroom)
      return;

    if (!id)
      return;

    this.loading = true;

    this.$store.dispatch("chatrooms/join", chatroom)
      .then(() => {
        this.openChatroom(chatroom);
      })
      .finally(() => this.loading = false);
  }

  openChatroom(chatroom) {
    this.resetChat(false);
    this.clearChatContainer();

    this.recipientName = chatroom.name;

    this.$nextTick(() => this.openGroupChatbox({ id: chatroom.talkjsConversationId }));
  }

  mounted() {
    Talk.ready.then(this.initializeChat);

    this.$eventBus.$on("chat:open:1on1", this.openChat);
    this.$eventBus.$on("chat:open:1on1id", this.openChatById);
    this.$eventBus.$on("chat:open:group", this.openGroupChat);
    this.$eventBus.$on("chat:open:readonly", this.openReadonly);
    this.$eventBus.$on("chat:open:support", this.openSupportChat);
  }

  unmounted(): void {
    this.$eventBus.$off("chat:open:1on1", this.openChat);
    this.$eventBus.$on("chat:open:1on1id", this.openChatById);
    this.$eventBus.$off("chat:open:group", this.openGroupChat);
    this.$eventBus.$off("chat:open:readonly", this.openReadonly);
    this.$eventBus.$off("chat:open:support", this.openSupportChat);
  }

  closeChat() {
    this.resetChat();
    this.showChat = false;
  }

  resetChat(close = true) {
    this.showChat = !close;
    this.allowJoin = false;

    this.currentChat = null;
    this.recipientName = "";
    this.recipientAvatar = "";

    this.chatbox?.destroy();
  }

  async openSupportChat(): Promise<void> {
    const uuid = this.appProperties.settings.supportUserUuid;

    if (!uuid) {
      this.$notify({type: "error", text: "Something went wrong. :("});
      return;
    }

    this.openChat(uuid);
  }

  clearChatContainer(): void {
    const container = this.$refs.chatContainer as HTMLElement;

    if (!container)
      return;

    container.innerHTML = "";
  }

  async openGroupChat(chat: Conversation): Promise<void> {
    this.resetChat();

    this.currentChat = chat;
    this.showChat = true;

    this.recipientName = chat.custom?.data?.name ?? "";
    this.recipientAvatar = chat.custom?.data?.avatar ?? "";

    this.clearChatContainer();

    this.$nextTick(() =>
      this.openGroupChatbox(chat)
    );
  }

  async openReadonly(chatroom: Chatroom): Promise<void> {
    const appId = process.env.VUE_APP_TALKJS_APP_ID as string;

    const chatroomUrl = `https://talkjs.com/embed/${appId}/${chatroom.talkjsConversationId}?auth_token=${chatroom.authToken}`;

    this.showChat = true;
    this.allowJoin = true;
    this.chatroom = chatroom;

    this.recipientName = chatroom?.name ?? "";

    this.$nextTick(() => {
      const el = document.createElement("iframe");
      el.setAttribute("src", chatroomUrl);
      el.setAttribute("style", "width: 100%; height: 100%; border: 0;");

      const container = this.$refs.chatContainer as HTMLElement;
      this.clearChatContainer();
      container.appendChild(el);
    });
  }

  get canJoin(): boolean {
    return this.allowJoin;
  }

  async openChat(uuid: string): Promise<void> {
    const user = await this.fetchUser({uuid});

    if (user?.uuid === this.currentUser?.uuid) return;

    this.showChat = true;
    this.recipientName = String(user?.name);
    this.recipientAvatar = String(user?.avatar?.url);
    this.recipientId = String(user?.id);
    this.clearChatContainer();

    this.$nextTick(() => {
      if (!user) {
        this.$notify({
          type: "error",
          text: this.$t("common.something_went_wrong").toString()}
        );
        return;
      }
      this.openDirectChatbox(user);
    });
  }

  async openChatById(id: string): Promise<void> {
    const user = await this.fetchUser({id});

    if (user?.uuid === this.currentUser?.uuid) return;

    this.showChat = true;
    this.recipientName = String(user?.name);
    this.recipientAvatar = String(user?.avatar?.url);
    this.recipientId = String(user?.id);
    this.clearChatContainer();

    this.$nextTick(() => {
      if (!user) {
        this.$notify({
          type: "error",
          text: this.$t("common.something_went_wrong").toString()}
        );
        return;
      }
      this.openDirectChatbox(user);
    });
  }

  async fetchUser({id, uuid}: {id?: string, uuid?: string}): Promise<User | null> {
    const { data: { user } } = await this.$apollo.query({
      query: FindUser,
      variables: { id, uuid }
    });

    if (user)
      return new User(user);

    return null;
  }

  initializeChat(): void {
    this.me = null;
    this.talkSession = null;

    const uuid = this.currentUser?.uuid;
    const name = this.currentUser?.name;

    if (!uuid || !name)
      return;

    const me = this.me = this.talkUser({uuid, name});

    this.talkSession = new Talk.Session({
      appId: process.env.VUE_APP_TALKJS_APP_ID as string,
      me: me
    });

    this.talkSession.setDesktopNotificationEnabled(true, { alertOnFailure: false });
    this.talkSession.unreads.on("change", unreads => {
      this.$store.dispatch("chat/setUnreadConversations", { unreads });
    });

    // For testing theme
    //this.talkSession.syncThemeForLocalDev("/talkjs-theme.css");
  }

  openDirectChatbox(user: User, data = {}): void {
    const session = this.talkSession;
    const me = this.me;

    if (!session || !me)
      return;

    const talkUser = this.talkUser(user);

    const conversation = session.getOrCreateConversation(
      Talk.oneOnOneId(me, talkUser)
    );

    conversation.setAttributes({
      ...data,
      welcomeMessages: [(this.$t('chat.welcome_message', {user : talkUser.name})).toString()],
      custom: this.talkConversationCustom(me, talkUser)
    });

    conversation.setParticipant(me);
    conversation.setParticipant(talkUser);

    const chatbox = session.createChatbox(conversation, {
      showChatHeader: false
    });

    chatbox.mount(this.$refs.chatContainer as HTMLElement);
    this.chatbox = chatbox;
    this.showChat = true;

    setTimeout(() => {
      if (this.isLoggedIn)
        this.$store.dispatch("chat/refreshMyConversations");
    }, 120_000);
  }

  openGroupChatbox({ id }: { id: string }): void {
    const session = this.talkSession;

    if (!session)
      return;

    const conversation = session.getOrCreateConversation(id);

    const chatbox = session.createChatbox(conversation, {
      showChatHeader: false
    });

    chatbox.mount(this.$refs.chatContainer as HTMLElement);
    this.chatbox = chatbox;
  }

  talkConversationCustom(me: Talk.User, other: Talk.User): { data: string } {
    return {
      data: JSON.stringify({
        initiatorId: me["uuid"] ?? me["id"],
        participants: [me, other]
      })
    };
  }

  talkUser(user: Partial<User>): Talk.User {
    return new Talk.User({
      id: this.talkUserId(user),
      name: user.name,
      role: "player",
      custom: {
        uuid: user.uuid,
        name: user.name,
        avatar: String(user.avatar?.url)
      }
    } as any);
    // This is explicitly cast to any because it seems like there's a bug
    // either with Talk or a recent version of TS incorrectly detecting the
    // constructor as taking only the ID parameter.
  }

  talkUserId(user: User): string {
    if (!user.uuid)
      throw new Error("Can't do anything with a user without a UUID.");

    return user.uuid;
  }

  isCustomChatroom(conversation) {
    if (!conversation)
      return false;

    return conversation.custom.data.type == "custom";
  }

  openUser(id: string, chat: Conversation): void {
    // This can currently happen with support user chat
    if (!chat)
      return;

    if (chat.custom?.data.type === "group") {
      const eventId = chat.custom.data?.event_id?.toString();

      this.closeChat();

      if (eventId) {
        this.$router.push({name: 'Event', params: {id: eventId}});
      }
    } else if (this.isCustomChatroom(chat)) {
      return;
    } else {
      this.closeChat();
      this.$router.push({name: 'Players', params: {userId: id}});
    }
  }
}
