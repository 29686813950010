







import { Component, Prop, Vue } from "@components/common";

@Component
export default class Card extends Vue {
  @Prop(String) title!: string
}
