import gql from "graphql-tag";
import Base from "@graphql/types/base";
import Participation, {
  ParticipationFields,
} from "@graphql/types/participation";
import MatchResult, { MatchResultFields } from "@graphql/types/match_result";
import Checkin, { MatchCheckinFields } from "@graphql/types/checkin";

export const MatchBaseFields = gql`
  fragment MatchBaseFields on Match {
    id
    bracket
    divisionId
    endTime
    eventFlowStageId
    eventId
    eventType
    group
    groupId
    grouped
    identifier
    index
    skipCheckin
    isStartTimeConfirmed
    originalEndTime
    originalStartTime
    participationsAssigned
    playoffsGroupId
    proposedStartTime
    stage
    startTime
    startTimeLocked
    startTimeProposedById
    startTimeProposedAt
    startTimeConfirmedAt
    structure
    checkin
    streamingLinks
  }
`;

export const MatchFields = gql`
  fragment MatchFields on Match {
    ...MatchBaseFields

    eventGroup {
      position
    }

    participations {
      ...ParticipationFields
    }
  }
  ${MatchBaseFields}
  ${ParticipationFields}
`;

export const MatchDetailFields = gql`
  fragment MatchDetailFields on Match {
    ...MatchFields

    result {
      ...MatchResultFields
    }
    checkins {
      ...MatchCheckinFields
    }
  }
  ${MatchFields}
  ${MatchResultFields}
  ${MatchCheckinFields}
`;

import { selectable as s } from "@store/generic_store";

export default class Match extends Base<Match> {
  __typename?: string;
  cupId?: number;
  @s() id?: number;
  @s() divisionId?: number;
  @s() grouped?: boolean;
  @s() identifier?: string;
  @s() eventId?: number;
  @s() index?: number;
  @s() eventType?: string;
  @s() startTime?: string;
  @s() endTime?: string;
  @s() startTimeLocked?: boolean;
  @s() skipCheckin?: boolean;
  stage?: number;
  result?: MatchResult;
  bracket?: string;
  @s() group?: number;
  @s() groupId?: number;
  @s() proposedStartTime?: string;
  @s() startTimeProposedById?: number;
  @s() startTimeProposedAt?: string;
  @s() isStartTimeConfirmed?: boolean;
  @s() startTimeConfirmedAt?: boolean;
  @s() structure?: string; // group, playoff, division
  @s() playoffsGroupId?: number;
  @s() checkin?: number;

  @s() eventFlowStageId?: number;
  groupedMatchIds?: number[];
  groupedResults?: MatchResult[];

  participations?: Participation[];
  participation1?: Participation;
  participation2?: Participation;
  checkins?: Checkin[];

  @s() originalStartTime?: string;
  @s() originalEndTime?: string;
  @s() participationsAssigned?: boolean;

  @s() streamingLinks?: Record<string, string>;
  @s() timeProposedWithinConfirmationPeriod?: boolean;

  eventGroup?: { position: number };

  static getTitle(match: Match) {
    if (!match?.participations) return "Match";

    return match.participations.map((p) => p.id).join(" - ");
  }

  constructor({
    checkins = [] as Checkin[],
    participations = [] as Participation[],
    result = {} as MatchResult,
    ...rest
  } = {}) {
    super(rest);

    this.participations = participations.map(
      (participation) => new Participation(participation)
    );
    this.checkins = checkins.map((checkin) => new Checkin(checkin));
    this.result = new MatchResult(result);
  }
}
