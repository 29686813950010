










import { Component, GuestPage, Layout } from "@components/common";

@Component({
  components: { Layout },
})
export default class Terms extends GuestPage {

}
