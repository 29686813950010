import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";
import { DateTime } from "luxon";
import createStore from "@store/generic_store";
import Game from "@graphql/types/game";
import gql from "graphql-tag";

import { selectable as s } from "@store/generic_store";

export class HallOfFameEntry {
  @s() public id: number;
  @s() public userId?: number;
  @s() public teamId?: number;
  @s() public gameId: number;

  @s() public entryType: string;
  @s() public interval: string;
  @s() public day: string;
  @s() public position: number;
  @s() public value: number;

  @s() public data: Record<string, any>;

  constructor({
    id,
    userId,
    teamId,
    gameId,
    entryType,
    interval,
    day,
    position,
    value,
    data,
  }) {
    this.id = id;
    this.userId = userId;
    this.teamId = teamId;
    this.gameId = gameId;
    this.entryType = entryType;
    this.interval = interval;
    this.day = day;
    this.position = position;
    this.value = value;
    this.data = data;
  }
}

export default class HallOfFameEntries extends createStore({
  name: "hallOfFameEntry",
  pluralName: "hallOfFameEntries",
  recordType: HallOfFameEntry,
}) {
  @Action({ rawError: true })
  async forGame({ gameId, day, type, limit = 10 }) {
    const { data } = await apollo.query({
      query: gql`
        query HallOfFameEntriesForGame($gameId : ID!, $day : Date!, $participantType : String!) {
          hallOfFame(gameId: $gameId, day: $day, participantType: $participantType) {
            ${this.recordSelectables.join(" ")}
          }
        }
      `,
      variables: { gameId, day, participantType: type },
    });

    const records = data["hallOfFame"];
    const ids = records.map((record) => record.id);

    this.context.commit("commitRecords", {
      records,
      selection: this.recordSelectables,
    });

    return ids.map((id) => this.records[id].record);
  }
}
