





















import { Component, Vue, Prop } from "@components/common";

import Event from "@/graphql/types/event";
import Participation from "@graphql/types/participation";
import PlayDay from "@/graphql/types/play_day";
import Round from "@/graphql/types/br_round";
import Team from "@/graphql/types/team";

import { DateTime } from "luxon";

import {
  WaitingStage,
  ResultStage,
  OverviewStage,
  CheckinStage
} from "@components/v2/ActivityCards/RoundStages";

//import { DateTime } from "luxon";
import RoundHelpers, { RoundStage } from "@/lib/helpers/round_card_helpers";

@Component({ components: {
    WaitingStage,
    CheckinStage, // Matchkey inside here
    ResultStage,
    OverviewStage
  }})
export default class RoundCard extends Vue {
  @Prop() private round!: Round;

  private isLoading = false;
  private event: Event | null = null;
  private myTeams: Team[] = [];

  private forcedStage: RoundStage | null = null;

  private isExpanded = false;

  private timer: ReturnType<typeof setInterval> | null = null;
  private ticker = 0;

  tick() {
    this.ticker += 1;
  }

  expand() {
    this.isExpanded = !this.isExpanded;
  }

  get stageComponentName(): string {
    return this.currentStage;
  }

  get isCheckedIn() {
    return this.roundHelpers.isCheckedIn;
  }

  get currentStage(): RoundStage {
    if (!this.isLoggedIn)
      return RoundStage.Error;

    const round = this.round;

    if (!round)
      return RoundStage.Error;

    const startTime = DateTime.fromISO(this.round.startTime);
    const endTime = DateTime.fromISO(this.round.endTime);
    const checkinEndTime = startTime.plus({seconds: round.checkin});
    const now = DateTime.local();

    if (startTime > now)
      return RoundStage.Waiting;
    else if (now < checkinEndTime)
      return RoundStage.Checkin;
    else if (endTime > now && this.isCheckedIn && !this.roundHelpers.hasSubmittedOwnResult)
      return RoundStage.Result;
    else
      return RoundStage.Overview;
  }

  get canGameSkipRoundKey() {
    // TODO:
    return false;
  }

  get hasResult() {
    // TODO
    return false;
  }

  get roundHelpers() {
    return new RoundHelpers(this.round, {
      me: this.currentUser,
      stage: null,
      event: this.event,
      myTeams: this.myTeams,
      playDay: this.playDay,
    });
  }

  mounted() {
    this.loadData();

    this.timer = setInterval(this.tick, 10_000);
  }

  unmounted() {
    const timer = this.timer;
    if (timer)
      clearInterval(timer);
  }

  updateRound(round: Round) {
    this.round = round;
  }

  async loadData() {
    this.isLoading = true;

    Promise
    .all([
      this.loadEvent(),
      this.loadMyTeams(),
    ])
    .finally(() => this.isLoading = false);
  }

  get playDay() {
    return this.event?.playDays?.find(pd => pd.id == this.round.playDayId);
  }

  async loadMyTeams() {
    if (this.event?.mode == "1v1")
      return this.myTeams = [];

    console.log("Current", this.currentUserId);
    this.myTeams = await this.$store.dispatch("teams/search", {filters: {captainId: this.currentUserId}});
  }

  async loadEvent() {
    this.event = await this.$store.dispatch("events/find", {
      id: this.round.eventId,
      extraSelection: [
        {playDays: PlayDay},
        {participations: Participation}
      ]
    });
  }

  forceStage(stage: RoundStage) {
    this.forcedStage = stage;
  }
}
