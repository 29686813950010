import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import Round from "@graphql/types/br_round";
import {
  ListMyActiveRounds,
  ListMyPendingRounds,
} from "@graphql/queries/rounds";
import { createApollo } from "@lib/graphql";
import { store } from "@store";
import { union } from "underscore";

const apollo = createApollo();

const INVALIDATION_NOTIFICATION_NAME_PARTS = [
  "round_reminder_short",
  "round_checkin_reminder",
  "invalidate_my_rounds",
];

@Module({ namespaced: true })
export default class MyRounds extends VuexModule {
  private loaded = false;
  private myRounds: Round[] = [];
  private myPendingRounds: Round[] = [];

  constructor(stuff) {
    super(stuff);

    setInterval(() => {
      store.dispatch("myRounds/reload");
    }, 131_111); // different than myMatches/reload so ti is not happening simuntaniously using prime numbers to avoid concurency

    /*  For time being all websocket based refreshes are suspended, should not query BE !!!
    // ALERT !!! - this causes for all current users to refresh at once ->> CRASH APP

    ---------------------!!!!!!!!!!!!!!--------------------------------

    eventBus.$on("notification:received", notification => {
      const invalidation = INVALIDATION_NOTIFICATION_NAME_PARTS.some(
        part => notification.notificationType.indexOf(part) != -1
      );

      console.log("Invalidating: ", invalidation);
      if (invalidation) {
        store.commit("myRounds/clear");
        store.dispatch("myRounds/reload");
      }
    });

    */
  }

  get rounds() {
    return this.myRounds.map((round) => new Round(round));
  }

  get pendingRounds() {
    return this.myPendingRounds.map((round) => new Round(round));
  }

  get allRounds() {
    return union(this.pendingRounds, this.rounds);
  }

  get roundsCount() {
    return this.allRounds.length;
  }

  get pendingRoundsCount() {
    return this.myPendingRounds.length;
  }

  get activeRoundsCount() {
    return this.rounds.length;
  }

  @Action({ rawError: true })
  async reload() {
    this.context.dispatch("reloadMyActiveRounds");
    this.context.dispatch("reloadMyPendingRounds");
  }

  @Action
  async reloadMyActiveRounds() {
    const {
      data: { myRounds: result },
    } = await apollo.query({
      query: ListMyActiveRounds,
      fetchPolicy: "no-cache",
    });

    this.context.commit("setMyActiveRounds", result);
  }

  @Action
  async reloadMyPendingRounds() {
    const {
      data: { myRounds: result },
    } = await apollo.query({
      query: ListMyPendingRounds,
      fetchPolicy: "no-cache",
    });

    this.context.commit("setMyPendingRounds", result);
  }

  @Mutation
  setMyActiveRounds(myRounds) {
    this.myRounds = myRounds;
    this.loaded = true;
  }

  @Mutation
  setMyPendingRounds(myRounds) {
    this.myPendingRounds = myRounds;
    this.loaded = true;
  }

  @Mutation
  clear() {
    this.myRounds = [];
    this.myPendingRounds = [];
    this.loaded = false;
  }
}
