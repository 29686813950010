




















































import { Component, Vue, Prop } from "@components/common";

import League from "@graphql/types/league";
import Platform from "@graphql/types/platform";
import Team from "@graphql/types/team";

@Component
export default class LeagueCardDetail extends Vue {
  @Prop(Object) readonly league!: League;

  private myTeams!: Team[];
  private platforms: Platform[] = [];
  private selectedTeamId = null;

  get showTeamsDropdown() {
    return this.isTeamBased && this.myTeams?.length > 1;
  }

  get checkinTime() {
    if (this?.league.checkin !== undefined) {
      return Math.round(this.league.checkin / 60);
    }
      return undefined;
  }

  get uniquePlatformsIcons() {
    const platforms = this.league?.platforms;

    if (!platforms)
      return [];

    return Array.from(
      new Set(platforms.map(x => x.icon))
    );
  }

  get participantCount() {
    const league = this.league;

    if (!league)
      return 0;

    return Math.max(
      league?.participatingUsers?.length ?? 0,
      league?.participatingTeams?.length ?? 0
    );
  }

  get leagueFull() {
    const league = this.league;

    if (!league || !league.maxPlayers)
      return false;

    return this.participantCount >= league.maxPlayers;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get isUserBased() {
    return this.league?.mode === "1v1";
  }

  get coverImage() {
    return this.league?.coverImage?.url ?? '';
  }

  isTeamAlreadyParticipating(team) {
    return this?.league?.participatingTeams?.some(p => p.id == team.id);
  }
}
