





















import { Component, Vue, Prop, Divider } from "@components/common";
import StreamingAccount from "@graphql/types/streaming_account";

@Component({
  components: { Divider },
})
export default class StreamingAccountCard extends Vue {
  @Prop() readonly streaming_accout!: StreamingAccount;

  accountIcon(tag) {
    return require(`../../assets/profile/img-streaming-${tag}.svg`);
  }
}
