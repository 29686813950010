import gql from "graphql-tag";
import Base from "@graphql/types/base";
import User, { UserFields } from "./user";

import { selectable as s } from "@store/generic_store";

export const MatchCheckinFields = gql`
  fragment MatchCheckinFields on MatchCheckin {
    id
    createdAt
    matchId
    userId
    participationId
  }
`;

export default class MatchCheckin extends Base<MatchCheckin> {
  @s() createdAt?: string;
  @s() matchId?: string;
  @s() userId?: number;
  @s() participationId?: number;
}
