import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";

import SubmitRoundResult from "@graphql/mutations/submit_round_result";
import CheckinToRound from "@graphql/mutations/checkin_to_round";

import Round from "@graphql/types/br_round";

import createStore from "@store/generic_store";

export default class Rounds extends createStore({
  name: "round",
  recordType: Round,
}) {
  @Action({ rawError: true })
  async submitResult({ roundId, rank, kills, data }) {
    const {
      data: { submitRoundResult: result },
    } = await apollo.mutate({
      mutation: SubmitRoundResult,
      variables: { roundId, rank, kills, data },
    });

    if (result.success) this.context.dispatch("updateCache", result.round);

    return result;
  }

  @Action({ rawError: true })
  async checkIn({ roundId, teamId = null, asTeam = false }) {
    const {
      data: { checkinToRound: result },
    } = await apollo.mutate({
      mutation: CheckinToRound,
      variables: { roundId, teamId, asTeam },
    });

    if (result.success) this.context.dispatch("updateCache", result.round);

    return result;
  }
}
