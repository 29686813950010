















































import { Component, Vue, Prop, Divider } from "@components/common";
import Round from "@graphql/types/br_round";
import FormattedTime from "@components/common/formatted_time.vue";
import BRCheckInButton from "@components/events/active/BR_check_in_button.vue";
import { DateTime } from "luxon";
import Event from "@graphql/types/event";
import { namespace } from "@/store";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";

const MyEnrolmentsStore = namespace("myEnrolments");

@Component({
  components: {
    Divider,
    FormattedTime,
    BRCheckInButton,
  }
})

export default class MyMatchesRoundCard extends Vue {
  @Prop() readonly round!: Round;
  @Prop() readonly event!: Event;

  @MyEnrolmentsStore.Getter("tournaments")
  public myTournaments!: Event[];

  @MyEnrolmentsStore.Getter("events")
  public myEvents!: Event[];

  get justButton() {
    return true;
  }

  eventRouteName(event) {
    return new EventCategoryHelpers(event).routeName;
  }

  get eventName() {
    return this.event?.name;
  }

  get eventGameName() {
    return this.event?.game?.name;
  }

  get translationVariables () {
    const variables = {
      // current round
      roundStartTime: this.round ? DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundStartDate: this.round ? DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.DATE_MED) : '',
      roundEndTime: this.round ? DateTime.fromISO(this.round.endTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundEndDate: this.round ? DateTime.fromISO(this.round.endTime).toLocaleString(DateTime.DATE_MED) : '',
      roundCheckInTime: this.round ? DateTime.fromISO(this.checkInEndTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundCheckInDate: this.round ? DateTime.fromISO(this.checkInEndTime).toLocaleString(DateTime.DATE_MED) : '',
      roundNumber: this.round ? this.round.roundNumber : '',
      roundHostKey: this.round.hostKey,

      // Discord
      gameDiscordUrl: this.event?.game?.discordChannelUrl,
      gameDiscordChannel: this.event?.game?.discordChannelName
    };
    return variables;
  }

  get checkInEndTime() {
    return (DateTime.fromISO(this.round.startTime).plus({seconds: this.event?.checkin}));
  }


  get hasRoundStarted() {
    return DateTime.fromISO(this.round.startTime) < DateTime.local();
  }

  // Match started and not finished yet
  get isRoundActive() {
    return this.hasRoundStarted && !this.isRoundCompleted;
  }

  // Match finished
  get isRoundCompleted() {
    return DateTime.fromISO(this.round.endTime) < DateTime.local();
  }

  get eventPlatforms() {
    return this.event?.platforms?.map(function(p){
        return p.name;
    }).join(", ");
  }


  showSubmitScoreDialog(...args) {
    this.$emit("showSubmitScoreDialog", ...args);
  }

  checkIn() {
    this.$notify({text: this.$t("common.not_available_yet").toString()});
  }

  refreshEvent() {
    this.$emit("refreshEvent");
  }
}
