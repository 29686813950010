
























































import { GuestPage, Component, Layout, FormLabel } from "@components/common";

@Component({  components: { Layout, FormLabel }})
export default class Register extends GuestPage {

  complete() {
    this.$router.push({name: "Feed"});
  }

  private isRegistrationComplete = false;
  private activeStep = 4;

  private trackAnalytics() {
    this.$gtag.event('onboarding', {
      'event_category': 'onboarding_steps',
      'event_label': 'Step 4',
    });
    this.$gtag.event('onboarding', {
      'event_category': 'onboarding_form',
      'event_label': 'Join discord',
    });
  }
}
