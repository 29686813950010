
























































































































































































import { Component, Vue, Prop, Divider, Watch } from "@components/common";

import ActivityCard from "@components/v2/ActivityCards/ActivityCard.vue";
import ChatButton from "@components/chat/chat_button.vue";
import DiscordNameCard from "@components/players/discord_name_card.vue";
import EventCard from "@components/v2/EventCard.vue";
import GameAccountCard from "@components/players/game_account_card.vue";
import GameCard from "@components/v2/GameCard.vue";
import HScroller from "@components/v2/HScroller.vue";
import MatchStatisticsCard from "@components/players/match_statistics_card.vue";
import PlayerCard from "@components/v2/PlayerCard.vue";
import PreferredGameTime from "@components/games/prefered_game_time.vue";
import StreamingAccountCard from "@components/players/streaming_account_card.vue";

import Checkin from "@graphql/types/checkin";
import Event from "@graphql/types/event";
import Match from "@graphql/types/match";
import MatchResult from "@graphql/types/match_result";
import Participation from "@graphql/types/participation";
import User from "@graphql/types/user";
import Heading from "@components/v2/Heading.vue";

import MatchHelpers from "@/lib/helpers/match_helpers";

@Component({
  components: {
    ActivityCard,
    ChatButton,
    DiscordNameCard,
    Divider,
    EventCard,
    GameAccountCard,
    GameCard,
    HScroller,
    MatchStatisticsCard,
    PlayerCard,
    PreferredGameTime,
    StreamingAccountCard,
    Heading
  },
})

export default class PlayerCardDetails extends Vue {
  @Prop() readonly player!: User;

  private events: Event[] = [];
  private pastEvents: Event[] = [];
  private myMatches: Match[] = [];

  mounted() {
    this.loadEvents();
    this.loadPastEvents();
    this.loadMyMatches();

    this.$eventBus.$on("store:update", this.updateMatch);
  }

  unmounted() {
    this.$eventBus.$off("store:update", this.updateMatch);
  }

  updateMatch({ name, record: match }) {
    if (name !== "match")
      return;

    const index = this.myMatches.findIndex(m => m.id == match.id);

    if (index != -1)
      this.$set(this.myMatches, index, match);
  }

  @Watch("player.id")
  async loadEvents() {
    const player = this.player;

    if (!player?.id)
      return;

    this.events = await this.$store.dispatch("events/search", {
      filters: {userIds: [this.player.id]},
      limit: 15
    });
  }

  @Watch("player.id")
  async loadPastEvents() {
    const player = this.player;

    if (!player?.id)
      return;

    this.pastEvents = await this.$store.dispatch("events/search", {
      filters: {userIds: [this.player.id], state: "finished"},
      limit: 15
    });
  }

  @Watch("isMe")
  async loadMyMatches() {
    if (!this.isMe)
      return;

    this.myMatches = MatchHelpers.combineGroupedMatches(
      await this.$store.dispatch("matches/search", {
        filters: {userIds: [Number(this.currentUserId)], status: "NOT_ENDED"},
        limit: 999,
        extraSelection: [{
          participations: Participation,
          result: MatchResult,
          checkins: Checkin
        }],
        noCache: true
      })
    );
  }

  get hasTeams() {
    return !!this.player?.teams?.length;
  }

  get teamsCount () {
    return this.hasTeams ? this.player.teams?.length : 0;
  }

  get streamingAccountsCount () {
    return this.hasStreamingAccounts ? this.player.streamingAccounts?.length : 0;
  }

  get gamingAccountsCount () {
    return this.player.platforms?.length ?? 0;
  }

  get hasDiscordName() {
    return !!this.player?.discordNickname;
  }

  get hasMatchStatistics() {
    return true;
  }

  get hasGamingAccounts() {
    return (this.player?.userPlatforms?.length ?? 0) > 0;
  }

  get hasStreamingAccounts() {
    return (this.player?.streamingAccounts?.length ?? 0) > 0;
  }

  get hasGames() {
    return this.gamesCount > 0;
  }

  get gamesCount() {
    return this.player?.games?.length ?? 0;
  }

  get playerCoverImage() {
    return this.player?.coverImage?.url ?? '';
  }

  get playerAvatar() {
    return this.player?.avatar?.url ?? '';
  }

  get playerName() {
    return this.player?.name ?? '';
  }

  get playerId() {
    return this.player?.id;
  }

  get playerDiscrodNickname() {
    return this.player?.discordNickname ?? '';
  }

  get playerPreferredGameTime() {
    return this.player?.preferredGameTime ?? '';
  }

  get filteredPlatforms() {
    return this.player?.userPlatforms?.filter( p => p.accountIdentifier != '');
  }

  get playerUuid() {
    return this.player?.uuid;
  }

  get isMe() {
    const me = this.currentUser;
    const player = this.player;

    if (!me || !player)
      return false;

    return me.id == player.id;
  }
}
