

















































import { Vue, Component} from "@components/common";
import CookieConsent from '@components/common/cookie_consent.vue';
import { namespace } from "@store";

const ChatStore = namespace("chat");
const MyMatchesStore = namespace("myMatches");
const MyRoundsStore = namespace("myRounds");

@Component({ components: {CookieConsent}})
export default class Footer extends Vue {
  @ChatStore.State
  public unreadConversationCount!: number;

  @ChatStore.Getter
  public hasUnreadConversations!: boolean;

  @MyMatchesStore.Getter("matchesCount")
  public matchesCount!: number;

  @MyRoundsStore.Getter("roundsCount")
  public roundsCount!: number;

  //@MyRoundsStore.Getter("rounds")
  //public myRounds!: Round[];

  private links = [
    {
      id: 1,
      label: "footer.feed",
      icon: "$icn-feed",
      activeIcon: "$icn-feed-selected",
      url: "/feed",
      size: "20",
      badge: 0,
    },
    {
      id: 2,
      label: "footer.browse",
      icon: "$icn-browse",
      activeIcon: "$icn-browse-selected",
      url: "/browse",
      size: "20",
      badge: 0,
    },
    /*
    {
      id: 3,
      label: "footer.matches",
      icon: "$icn-matches",
      activeIcon: "$icn-matches-selected",
      url: "/matches",
      size: "20",
      badge: 0,
    },
    */
    /*
    {
      id: 4,
      label: "footer.standings",
      icon: "$icn-standings",
      activeIcon: "$icn-standings-selected",
      url: "/standings",
      size: "20",
      badge: 0,
    },
    */
    {
      id: 4,
      label: "footer.community",
      icon: "$icn-inbox",
      activeIcon: "$icn-inbox-active",
      url: "/chat",
      guestUrl: "/register",
      size: "19",
      badge: this.unreadConversationCount,
    },
    {
      id: 5,
      label: "",
      guestLabel: "register.sign_up",
      icon: "",
      guestIcon: "mdi-account-plus-outline",
      activeIcon: "mdi-account-plus-outline",
      url: "",
      guestUrl: "/register",
      size: "22",
      guestOnly: true,
    },
    {
      id: 6,
      label: "footer.profile",
      guestLabel: "footer.login",
      icon: "$icn-profile",
      guestIcon: "mdi-login-variant",
      activeIcon: "$icn-profile-selected",
      url: "/profile",
      size: "20",
      badge: 0,
    },
  ]

  get linksWithBadges() {
    return this.links.map(l => ({
      ...l,
      badge: (l.id === 3) ? this.roundsAndMatchesCount : 0
    }));
  }

  get roundsAndMatchesCount() {
    return this.roundsCount + this.matchesCount;
  }
}
