






































import { Component, Vue, Watch } from "@components/common";
import { NewNotification } from "@graphql/subscriptions/notifications";
import Notification from "@graphql/types/notification";
import Chatbox from "@components/chat/chatbox.vue";

@Component({
  apollo: {
    $subscribe: {
      newNotification: {
        query: NewNotification,
        result({ data }) {
          if (!data?.newNotification)
            return;

          this.$eventBus.$emit(
            "notification:received",
            new Notification(data.newNotification)
          );

          // Push notifications are currently disabled for users
          /*
          const title = this.$t(
            `notifications.${data.newNotification.notificationType}.banner.title`,
            data.newNotification.data
          ).toString();

          const text =  this.$t(
            `notifications.${data.newNotification.notificationType}.banner.text`,
            data.newNotification.data
          ).toString();

          this.$notify({ title, text });
           */
        }
      }
    }
  }, components: {Chatbox},
})
export default class App extends Vue {
  get routeClass() {
    const route = this.$route.path;
    return route.replace(/\//gi, '-').substring(1);
  }

  private updateEvent = null;
  private snackbar = false;
  private vertical = true;

  created() {
    document.addEventListener("service-worker-updated", this.updateAvailable, {once: true});
  }

  mounted() {
    if (this.isLoggedIn)
      this.$store.dispatch("authentication/reauthenticate");

    this.$eventBus.$on("auth:force-logout", () => {
      if (this.isLoggedIn) {
        this.$router.push("/logout");
      }
    });

    this.$eventBus.$on("store:update", ({ store, record }) => {
      console.log(`Received ${store} update`, record);
      this.$store.dispatch(`${store}/updateRecord`, record);
    });
  }

  reload() {
    location.reload();
    this.snackbar = false;
  }

  updateAvailable() {
    this.snackbar = true;
  }

  @Watch('$store.state.progress.loading')
  updateProgress(newVal: number, oldVal: number) {
    if (newVal === 0) return this.$Progress.finish();
    if (oldVal === 0) return this.$Progress.start();
    this.$Progress.set(1.8 / Math.max(oldVal, newVal));
  }
}
