import Event from "@graphql/types/event";

export default class EventCategoryHelpers {
  private isLegacyEvent = false;

  constructor(private event: Event) {
    this.isLegacyEvent = Event.isLegacyEvent(event);
  }

  get translationKey() {
    if (this.isLegacyEvent) {
      return this.event.__typename?.toLowerCase() ?? "event";
    }

    return this.event.category?.toLowerCase() ?? "event";
  }

  get categoryIconName() {
    return `$icn-${this.translationKey}`;
  }

  get routeName() {
    if (this.isLegacyEvent) {
      return (
        this.translationKey.charAt(0).toUpperCase() +
        this.translationKey.slice(1)
      );
    }

    return "Event";
  }

  icon(name) {
    if (!name) return `$icn-${this.translationKey}`;

    return `$icn-${this.translationKey}-${name}`;
  }
}
