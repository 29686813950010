import { render, staticRenderFns } from "./myenrolments_archive.vue?vue&type=template&id=25d51d19&scoped=true&"
import script from "./myenrolments_archive.vue?vue&type=script&lang=ts&"
export * from "./myenrolments_archive.vue?vue&type=script&lang=ts&"
import style0 from "./myenrolments_archive.vue?vue&type=style&index=0&id=25d51d19&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d51d19",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VContainer,VProgressCircular,VSheet})
