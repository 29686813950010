























































import MyMatchesMatchCard from '@pages/matches/my_matches_match_card.vue';
import MatchCard from "@components/v2/ActivityCards/MatchCard.vue";
import MyMatchesRoundCard from '@pages/matches/my_matches_round_card.vue';
import { Page, Component, Layout } from "@components/common";
import Match from '@/graphql/types/match';
import Round from '@/graphql/types/match';
import Event from '@graphql/types/event';
import { sortBy, union,  uniq } from "underscore";
import { namespace } from "@store";

const MyMatchesStore = namespace("myMatches");
const MyRoundsStore = namespace("myRounds");
const MyEnrolmentsStore = namespace("myEnrolments");

@Component({
  components: {
    Layout,
    MyMatchesMatchCard,
    MyMatchesRoundCard,
    MatchCard
  }
})

export default class MyMatches extends Page {

  @MyEnrolmentsStore.Getter("tournaments")
  public myTournaments!: Event[];

  @MyEnrolmentsStore.Getter("events")
  public myEvents!: Event[];

  @MyMatchesStore.Getter("pendingMatches")
  public myMatches!: Match[];

  @MyRoundsStore.Getter("pendingRounds")
  public myRounds!: Round[];

  private isUpdating = false;

  isRound(match) {
    return match.__typename === "Round" || match.Typename === "Round";
  }

  isMatch(match) {
    return (match.__typename === "Match" || match.Typename === "Match");
  }

  get roundsAndMatchesEvents() {

    const events = {};
    const eventIds =
    uniq(this.roundsAndMatches.map(m => (
          {
            identifier: this.eventIdentifier(m.eventId, m.eventType)
          }
        )
      ),
    id => id.identifier);

    eventIds.forEach(e => {
        events[e.identifier] = this.getEventByIdentifier(e.identifier);
    });

    return events;
  }

  private matchEvent(identifier) {
    return this.roundsAndMatchesEvents[identifier];
  }

  private eventIdentifier(eventId, eventType) {
    return `${eventType}.${eventId}`;
  }

  private getEventByIdentifier(identifier) {
    const tmp = identifier.split(".");

    const eventType = tmp[0];
    const eventId = tmp[1];

    switch(eventType) {
      case "Tournament": return this.myTournaments.find(l => l.id == eventId);
      case "Event": return this.myEvents.find(l => l.id ===eventId);
      default: return new Event;
    }

  }

  get roundsAndMatches() {
    return union(this.myMatches, this.myRounds);
  }

  get hasRoundsAndMatches() {
    return this.hasRounds || this.hasMatches;
  }

  get sortedRoundsAndMatches() {
    return sortBy(sortBy(this.roundsAndMatches, "index"), function(o) {return o.startTime;});
  }

  get hasMatches() {
    return this.myMatches?.length ?? 0 > 0;
  }

  get sortedMatches() {
    return sortBy(this.myMatches, function(o) { return o.startTime; });
  }

  get hasRounds() {
    return this.myRounds?.length ?? 0 > 0;
  }

  get sortedRounds() {
    return sortBy(this.myRounds, function(o) { return o.startTime; });
  }

  refreshPendingMatches() {
    this.$store.dispatch("myMatches/reload");
  }

  refreshPendingRounds() {
    this.$store.dispatch("myRounds/reload");
  }

}
