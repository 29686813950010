











import { Component, Page, Layout } from "@components/common";
import PlayerCardDetails from "@components/players/player_detail.vue";
import {FindUserDetails} from '@graphql/queries/user';

@Component({
  components: { PlayerCardDetails, Layout },
  apollo: {
    user: {
      query: FindUserDetails,
      variables() {
        return {
          id: this.$route.params.userId
        };
      }
    }
  }
})

export default class Player extends Page {
  get activeTab() {
    return this.$route.query.tab || 'overview';
  }

  set activeTab(tab) {
    const newQuery = {...this.$route.query, tab};

    if (this.$route.query == newQuery)
      return;

    this.$router.replace({query: newQuery});
  }
}
