import gql from "graphql-tag";
import Game, { GameFields } from "@graphql/types/game";
import Platform, { PlatformFields } from "@graphql/types/platform";

export const GetAppProperties = gql`
  query GetAppProperties {
    games(limit: 999) {
      ...GameFields
    }
    platforms {
      ...PlatformFields
    }

    settings {
      supportUserId
      supportUserUuid
    }
  }
  ${PlatformFields}
  ${GameFields}
`;

export interface AppProperties {
  games: Game[];
  platforms: Platform[];
  settings: { supportUserId?: number; supportUserUuid?: string };
}
