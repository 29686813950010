import gql from "graphql-tag";
import { DetailedEventFields } from "@graphql/types/event";

export default gql`
  mutation JoinEvent(
    $id: ID!
    $asUser: Boolean!
    $asTeam: Boolean
    $teamId: ID
  ) {
    joinEvent(id: $id, asUser: $asUser, asTeam: $asTeam, teamId: $teamId) {
      success
      event {
        ...DetailedEventFields
      }
    }
  }
  ${DetailedEventFields}
`;
