import gql from "graphql-tag";

import { FileFields } from "@graphql/types/file";

export default gql`
mutation UpdateMyCoverImage($coverImage : Upload!) {
  updateMyCoverImage(coverImage: $coverImage) {
    success
    coverImage {
      ...FileFields
    }
  }
}
${FileFields}
`;
