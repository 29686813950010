





















































































import { Vue, GuestPage, Component, Card, MainHeader, Layout, FormLabel } from "@components/common";
import { notEmpty } from "@lib/validations";
import Me from '@graphql/types/me';

@Component({components: { Card, MainHeader, Layout, FormLabel }})
export default class Register extends GuestPage {
  private form: Me = {
    firstName: '',
    lastName: '',
    nickname: '',
    discordNickname: '',
    onboardingStep: 0
  };

  mounted() {
    if (this.currentUser) {
      this.form.firstName = this.currentUser.firstName;
      this.form.lastName = this.currentUser.lastName;
      this.form.nickname = this.currentUser.nickname;
      this.form.discordNickname = this.currentUser.discordNickname;
      this.form.onboardingStep = 2; // next onboarding step is pushed
    }
  }

  private activeStep = 1;
  private isFormValid = false;
  private isRegistrationComplete = false;
  private notEmpty = notEmpty

  async submit() {
    if (!this.userForm.validate()) {
      this.$notify({text: this.$t("errors.registration.input_required").toString(), type: "error"});
      return;
    }

    const result = await this.$store.dispatch("me/update", {attributes: this.form});


    if (result.success) {
      this.isRegistrationComplete = true;
      this.$router.push("/register/2");
      this.trackAnalytics();
    }
  }

  private trackAnalytics() {
      this.$gtag.event('onboarding', {
        'event_category': 'onboarding_steps',
        'event_label': 'Step 1',
      });
      this.$gtag.event('onboarding', {
        'event_category': 'onboarding_form',
        'event_label': 'Basic user info',
      });
  }

  get userForm() {
    return this.$refs.userForm as Vue & { validate: () => boolean };
  }
}
