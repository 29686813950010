import gql from "graphql-tag";
import { NotificationFields } from "@graphql/types/notification";

export const NewNotification = gql`
  subscription newNotification {
    newNotification {
      ...NotificationFields
    }
  }
  ${NotificationFields}
`;
