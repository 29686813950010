



















































































import Match from "@/graphql/types/match";
import Team from "@/graphql/types/team";
import Event from "@/graphql/types/event";

import { Component, Vue, Prop, FormLabel } from "@components/common";
import MatchHelpers, { MatchStage } from "@lib/helpers/match_helpers";
import { DateTime, Interval } from "luxon";
import SubmitMatchScore from "@components/events/active/submit_match_score.vue";
import SubmitMatchScores from "@components/events/active/submit_match_scores.vue";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  MatchCardBasics,
  MatchKey,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";

@Component({
  components: {
    ActionBtn,
    CardBody,
    CardHeader,
    DateTime,
    DateTimeInfo,
    Expander,
    FormLabel,
    MatchCardBasics,
    MatchKey,
    Meta,
    MetaLine,
    SubmitMatchScore,
    SubmitMatchScores,
    UserAvatar,
  }
})
export default class ResultStage extends Vue {
  @Prop() private match!: Match;
  @Prop() private event!: Event;
  @Prop() private myTeams!: Team[];
  @Prop() private show!: boolean;

  private loading = false;
  private checkedIn = false;
  private showSubmitDialog = false;

  submitMatchScore() {
    this.loading = true;
    this.showSubmitDialog = true;
  }

  get matchHelpers() {
    return new MatchHelpers(this.match, [], {
      me: this.currentUser,
      matchStage: MatchStage.Result,
      event: this.event,
      myTeams: this.myTeams
    });
  }

  // @TODO: Is this obsolete?
  refreshTournament(...args) {
    this.$emit("refreshTournament", ...args);
  }

  get formattedLatestStartDatetime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_MED);
  }

  get isCheckedIn() {
    return !!this.match?.checkins?.find(c => c.userId == this.currentUserId);
  }

  get secondsUntilEndTime() {
    return Interval.fromDateTimes(
      DateTime.local(),
      DateTime.fromISO(this.match.endTime),
    ).length("seconds");
  }

  get formattedEndTime() {
    return DateTime.fromISO(this.match.endTime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get priority() {
    if (this.secondsUntilEndTime < 600)
      return 3;

    return 2;
  }

  get expanded() {
    return this.show || this.priority > 2;
  }

  get checkinOpen() {
    return true;
  }
}
