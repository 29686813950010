import gql from "graphql-tag";
import {MatchStatisticFields} from "@graphql/types/user_match_stats";


export const GetUserMatchStatistics = gql`
    query MatchStats($userId: Int!) {
        matchStats(userId: $userId) {
            ...MatchStatisticFields
        }
    }
    ${MatchStatisticFields}
`;