import gql from "graphql-tag";
import { MatchFields } from "@graphql/types/match";

export default gql`
  mutation ProposeMatchStartTime($matchId: ID!, $startTime: DateTime!) {
    proposeMatchStartTime(matchId: $matchId, startTime: $startTime) {
      success
      match {
        ...MatchFields
      }
    }
  }
  ${MatchFields}
`;
