



















































import { Page, Component, Layout } from "@components/common";
import TeamCard from "@components/teams/card.vue";
import Filters from "@components/events/filters.vue";
import { teamFilters } from "@/store/filters";
import scrollMonitor from 'scrollmonitor';

import Team from "@graphql/types/team";

@Component({
  components: { TeamCard, Layout, Filters },
})
export default class Teams extends Page {
  private isUpdating = true;
  private isUpdatingSearch = true;
  private activeSearch = false;
  private loadingMore = false;
  private page = 1;
  private hasMore = true;
  private teams: Team[] = []

  public filters = teamFilters;

  markUpdating() {
    this.activeSearch = true;
    this.isUpdatingSearch = true;
  }

  refresh() {
    this.$apollo.queries.teams.refetch();
    this.hasMore = true;
    this.page = 1;
  }

  async loadMoreTeams() {
    this.loadingMore = true;

    const teams = await this.getTeams(++this.page);

    teams.forEach(u =>
      !this.teams.find(u_ => u_.id == u.id) && this.teams.push(u)
    );

    this.loadingMore = false;
    this.isUpdating = false;
    this.isUpdatingSearch = false;
  }

  async loadTeams() {
    this.teams = await this.getTeams();

    this.loadingMore = false;
    this.isUpdating = false;
    this.isUpdatingSearch = false;
  }

  async getTeams(page = 1): Promise<Team[]> {
    const limit = 25;

    const teams = await this.$store.dispatch("teams/search", {
      filters: this.filters,
      extraSelection: "avatar { url }",
      page,
      limit
    });

    this.hasMore = teams.length >= limit;

    return teams;
  }

  initializeInfiniteScroll() {
    const el = document.getElementById('sensor');
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      console.log("extra");
      if (!(this.isUpdating || this.loadingMore) && this.hasMore) {
      console.log("extra in");
        this.loadMoreTeams().finally(() => this.loadingMore = false);
      }
    });
  }


  mounted() {
    this.loadTeams()
      .then(this.initializeInfiniteScroll);
  }
}
