














import { Component, Vue, FormLabel } from "@components/common";

@Component({components: {FormLabel}})
export default class HScroller extends Vue {

  get hasDefaultSlot () {
    return !!this.$slots.default;
  }
}
