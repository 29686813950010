import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";

import AcceptTeamInvitation from "@graphql/mutations/accept_team_invitation";
import UpdateMe from "@graphql/mutations/update_me";
import UpdateMyGames from "@graphql/mutations/update_my_games";
import UpdateMyPassword from "@graphql/mutations/update_my_password";
import UpdateMyPlatforms from "@graphql/mutations/update_my_platforms";
import UpdateMyStreamingAccounts from "@graphql/mutations/update_my_streaming_accounts";
import UpdateMyAvatar from "@graphql/mutations/update_my_avatar";
import UpdateMyCoverImage from "@graphql/mutations/update_my_cover_image";

@Module({ namespaced: true })
export default class Me extends VuexModule {
  @Action({ rawError: true })
  async update({ attributes }) {
    const {
      data: { updateMe: result }
    } = await apollo.mutate({
      mutation: UpdateMe,
      variables: { attributes }
    });

    await this.context.dispatch("authentication/reauthenticate", null, {
      root: true
    });

    return result;
  }

  @Action({ rawError: true })
  async acceptInvitation({ invitationId }) {
    const {
      data: { acceptTeamInvitation: result }
    } = await apollo.mutate({
      mutation: AcceptTeamInvitation,
      variables: { invitationId }
    });

    await this.context.dispatch("authentication/reauthenticate", null, {
      root: true
    });

    return result;
  }

  @Action({ rawError: true })
  async changePassword({ oldPassword, newPassword }) {
    const {
      data: { updateMyPassword: result }
    } = await apollo.mutate({
      mutation: UpdateMyPassword,
      variables: { oldPassword, newPassword }
    });

    return result;
  }

  @Action({ rawError: true })
  async setPlatforms({ platforms }) {
    const {
      data: { updateMyPlatforms: result }
    } = await apollo.mutate({
      mutation: UpdateMyPlatforms,
      variables: { platforms }
    });

    return result;
  }

  @Action({ rawError: true })
  async setGames({ games }) {
    const {
      data: { updateMyGames: result }
    } = await apollo.mutate({
      mutation: UpdateMyGames,
      variables: { games }
    });
    return result;
  }

  @Action({ rawError: true })
  async setAvatar({ avatar }) {
    const {
      data: { updateMyAvatar: result }
    } = await apollo.mutate({
      mutation: UpdateMyAvatar,
      variables: { avatar }
    });

    return result;
  }

  @Action({ rawError: true })
  async setCoverImage({ coverImage }) {
    const {
      data: { updateMyCoverImage: result }
    } = await apollo.mutate({
      mutation: UpdateMyCoverImage,
      variables: { coverImage }
    });

    return result;
  }

  @Action({ rawError: true })
  async setStreamingAccounts({ streamingAccounts }) {
    const {
      data: { updateMyStreamingAccounts: result }
    } = await apollo.mutate({
      mutation: UpdateMyStreamingAccounts,
      variables: { streamingAccounts }
    });

    return result;
  }
}
