import gql from "graphql-tag";

import { BRRoundFields } from "@graphql/types/br_round";

export default gql`
  mutation CheckinToRound($roundId: ID!) {
    checkinToRound(roundId: $roundId) {
      success
      round {
        ...BRRoundFields
      }
    }
  }
  ${BRRoundFields}
`;
