import gql from "graphql-tag";

import { FileFields } from "@graphql/types/file";

export default gql`
mutation UploadTeamAvatar($teamId : ID!, $avatar : Upload!) {
  uploadTeamAvatar(teamId: $teamId, avatar: $avatar) {
    success
    avatar {
      ...FileFields
    }
  }
}
${FileFields}
`;
