





























































import { Component, Vue, Prop, Watch } from "@components/common";
import Event from "@graphql/types/event";
import { DateTime } from "luxon";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";

interface LeaveEventParams {
  id?: number;
  teamId?: number;
  asTeam?: boolean;
}

@Component
export default class JoinAsUserAction extends Vue {
  @Prop(Object) event!: Event;
  @Prop(String) eventType!: string;
  @Prop(String) action!: string;


  @Watch("event.id")
  get helpers() {
    return new EventCategoryHelpers(this.event);
  }

  get isUserBased() {
    return this.event.isUserBased;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get computedAction() {
    if (this.isSignedUp && (this.action === 'signup' || this.action === 'signup_closed' || this.action === 'signup_confirm'))
      return 'active';
    return this.action;
  }

  // This is for the new flows, if enum for open sign_up is SIGNUP_OPEN so default to that if non set
  get signUpMode() {
    const currentFlowStage = Event.currentEventFlowStage(this.event);

    return currentFlowStage ? currentFlowStage.subtype : 'SIGNUP_OPEN';
  }

  get myParticipatingTeam() {
    const teams = this.event.participatingTeams;

    if (!teams || teams.length == 0)
      return null;

    return teams.find(team =>
      team.members?.find(member => member.id === this.currentUserId)
    );
  }

  get isParticipatingTeamCaptain() {
    return this.myParticipatingTeam?.captain?.id === this.currentUserId;
  }

  get canLeaveEvent() {
    if (this.hasEventStarted)
      return false;

    return this.isParticipant && (this.event.isUserBased || this.isParticipatingTeamCaptain);
  }

  get hasEventStarted() {
    const event = this.event;

    if (!event) return false;

    return DateTime.fromISO(event.startTime) < DateTime.local();
  }

  get isSignedUp() {
    return this.isParticipant === true;
  }


  async leaveEvent() {
    if (!await this.confirmLeave())
      return;

    let params: LeaveEventParams = {id: this.event.id};
    if (this.isTeamBased)
      params = { ...params, teamId: this.myParticipatingTeam?.id, asTeam: true};

    // TODO: Remove legacy tournament support
    const action = Event.isLegacyEvent(this.event) ? "tournaments/leave" : "events/leave";
    const result = await this.$store.dispatch(action, params);

    if (result.success) {
      this.$notify({
        type: "success",
        text: this.$t(`event.leave_success`, {name: this.event.name}).toString()
      });

      this.$emit("update", {event: new Event(result.event)});
    }
  }

  confirmLeave() {
    return this.$confirm(
      this.$t(`event.confirm_leave`).toString()
    );
  }
// TODO - add exceptions for round based games
  get signupButtonTextKey() {
    if (!Event.isLegacyEvent(this.event)) {
      return `event.action.${this.action}`;
    } else {
      return `event.${this.eventType}.${this.action}`;
    }
  }

  get signoutButtonTextKey() {
    if (!Event.isLegacyEvent(this.event)) {
      return `event.leave`;
    } else {
      return `event.${this.eventType}.leave`;
    }
  }

  get signupButtonTextHintKey() {
    if (!Event.isLegacyEvent(this.event)) {
      return `event.action.${this.computedAction}_tooltip_signedin`;
    } else {
      return `event.${this.eventType}.${this.computedAction}_tooltip_signedin`;
    }
  }

  get signupClosedButtonTextHintKey() {
    if (!Event.isLegacyEvent(this.event)) {
      return `event.action.${this.computedAction}_signup_closed_tooltip`;
    } else {
      return `event.${this.eventType}.${this.computedAction}_signup_closed_tooltip`;
    }
  }

  get fullButtonTextHintKey() {
    if (!Event.isLegacyEvent(this.event)) {
      return `event.action.full_tooltip`;
    } else {
      return `event.${this.eventType}.full_tooltip`;
    }
  }

  joinEvent() {
    this.$emit("joinEvent", this.event);
  }

  checkIn() {
    this.$notify({text: this.$t("common.not_available_yet").toString()});
  }

  get isEventFull() {
    return this.event.participationCount >= (this.event?.maxPlayers ?? 0);
  }

  get isParticipant() {
    if (this.event.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.event.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isParticipantTeamMember() {
    if (this.event.isTeamBased) {
      return this.event.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }
}
