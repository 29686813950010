


































































































































import { Component, Vue, Prop, Divider, Watch } from "@components/common";
import Match from "@graphql/types/match";
import Event from "@graphql/types/event";
import Participation from "@graphql/types/participation";
import CardDetailHeader from "@components/cups/card_detail_header.vue";
import { GetMatchesWithResult } from "@graphql/queries/matches";

@Component({
  components: { CardDetailHeader, Divider },
  apollo: {
    matches: {
      skip() { return !this.matchIds; },
      query: GetMatchesWithResult,
      variables() { return { filters: {ids: this.matchIds }};},
      result() { this.loading = false; },
      error() { this.loading = false; },
      pollInterval: 11111
    }
  }
})
export default class SubmitMatchScores extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly matchIds!: number[];
  @Prop() readonly show!: boolean;

  private matches: Match[] = [];
  private loading = true;

  private fstPartRes = [];
  private secPartRes = [];

  private overtime: Record<number, boolean> = {};

  get gameId() {
    return this.event.gameId || -1;
  }

  get allowsOvertime() {
    return this.game?.allowsOvertime ?? false;
  }

  get gameName() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }

  get game() {
    return this.gameById(this.gameId);
  }
  get opponentName() {
    return this.opponent?.name ?? "";
  }

  private matchById(matchId): Match {
    return this.matches?.find(m => m.id == matchId) || new Match;
  }

  private matchFistParticipant(matchId): Participation {
    return this.matchById(matchId).participation1?.participant;
  }

  private matchSecondParticipant(matchId): Participation {
    return this.matchById(matchId).participation2?.participant;
  }

  private firstParticipantResult(matchId) {
    return Number(this.fstPartRes[matchId]);
  }

  private secondParticipantResult(matchId) {
    return Number(this.secPartRes[matchId]);
  }

  get opponent() {
    if (this.currentUserId == this.firstParticipant?.id)
      return this.secondParticipant;
    else
      return this.firstParticipant;
  }

  @Watch("matchId")
  reloadMatch() {
    this.$apollo.queries.match.refetch();
  }

  get firstParticipant() {
    return this.matches?.[0].participations?.[0]?.participant;
  }

  get secondParticipant() {
    return this.matches?.[0].participations?.[1].participant;
  }
/*
  get winnerId() {
    if (this.firstParticipantResult > this.secondParticipantResult)
      return this.firstParticipant.id;
    else if (this.secondParticipantResult > this.firstParticipantResult)
      return this.secondParticipant.id;
    else
      return null;
  }

  private scores(matchId) {
    return [
      {
        participantId: this.firstParticipant.id,
        score: this.firstParticipantResult
      },
      {
        participantId: this.secondParticipant.id,
        score: this.secondParticipantResult
      },
    ];
  }

  get isDraw() {
    return this.firstParticipantResult == this.secondParticipantResult;
  }
*/

  private scores(matchId) {
    return [
      {
        participantId: this.matchFistParticipant(matchId).id,
        score: this.firstParticipantResult(matchId)
      },
      {
        participantId: this.matchSecondParticipant(matchId).id,
        score: this.secondParticipantResult(matchId)
      },
    ];
  }

  private matchOvertime(matchId) {
    return this.overtime[matchId];
  }

  get results() {
    const results = this.matches?.map(m=>(
      {
        matchId: m.id,
        scores: this.scores(m.id),
        data: { overtime: this.matchOvertime(m.id) }
      }
    ));

    return results;
  }

  async submitResults() {

    const result = await this.$store.dispatch("matches/submitResults", {
      results: this.results,
    });

    if (result.success) {
      this.$gtag.event('match_submit_score', {
        'event_category': 'match_action',
        'event_label': 'Submit match score'
      });
      //this.match = { ...this.match, result: result.matchResult};
      this.$notify({text: this.$t("match.result_card.result_submitted").toString()});
      this.close();
    }
  }

  close() {
    this.$emit("close");
    this.$emit("refreshTournament");
  }

  canBeEqual(match) {
    if (this.overtime[match.id]) return true;
    else {
      return this.fstPartRes[match.id] === this.secPartRes[match.id] ? false : true;
    }
  }
}
