import gql from "graphql-tag";
import { AuthenticationResultFields } from "@graphql/queries/authenticate";

export default gql`
  mutation ActivateUser($token : String!, $password : String) {
    activateUser(token: $token, password: $password) {
      ...AuthenticationResultFields
    }
  }
  ${AuthenticationResultFields}
`;
