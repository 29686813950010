import gql from "graphql-tag";
import { BRRoundFields } from "@graphql/types/br_round";

export const ListMyRounds = gql`
  query MyRounds($filters: RoundsFilterInput!) {
    myRounds(filters: $filters) {
      ...BRRoundFields
    }
  }
  ${BRRoundFields}
`;

export const ListMyActiveRounds = gql`
  query myActiveRounds {
    myRounds(filters: { status: "active" }) {
      ...BRRoundFields
    }
  }
  ${BRRoundFields}
`;

export const ListMyPendingRounds = gql`
  query myPendingRounds {
    myRounds(filters: { status: "pending" }) {
      ...BRRoundFields
    }
  }
  ${BRRoundFields}
`;
