
































































import { Component, Vue } from "@components/common";

@Component({})
export default class IconGameTime extends Vue {

}
