






































































































































































































import { Component, Prop, Vue } from "@components/common";
import { DateTime } from "luxon";

import Countdown from "@components/events/countdown.vue";
import Event from "@graphql/types/event";
import IconWaiting from "@components/cups/icon_waiting.vue";
import Match from "@graphql/types/match";
import iconGameTime from "@components/cups/icon_game_time.vue";
import FormattedTime from "@components/common/formatted_time.vue";
import SubmitMatchScore from "@components/events/active/submit_match_score.vue";
import SubmitMatchScores from "@components/events/active/submit_match_scores.vue";

@Component({
  components: {
    IconWaiting,
    iconGameTime,
    Countdown,
    FormattedTime,
    SubmitMatchScore,
    SubmitMatchScores
  }
})

export default class CheckInButton extends Vue {
  @Prop() event!: Event;
  @Prop() match!: Match;
  @Prop(String) eventType!: string;
  @Prop(String) action!: string;
  @Prop({default:false}) justButton!: boolean;

  private show = false;
  private showSubmitScore = false;

  private submitScoreDialog = {
    show: false,
    matchId: null
  };


  get myParticipatingTeamId() {
    if (!this.myParticipatingTeam) return null;
    return this.myParticipatingTeam.id;
  }

  get myParticipatingTeam() {
    if (this.isTeamBased) {
      const myParticipatingTeams = this.event.participatingTeams?.filter(team => team.members?.find(member => member.id === this.currentUserId));

      if (myParticipatingTeams?.length === 1) return myParticipatingTeams[0];

      return myParticipatingTeams?.find(t => t.captain?.id == this.currentUserId);
    }

    return null;
  }

  get isParticipatingTeamCaptain() {
    if (!this.isTeamBased) return true;
    return this.myParticipatingTeam?.captain?.id === this.currentUserId;
  }

  openSubmitResultDialog(match) {
    this.submitScoreDialog.matchId = match.id;
    this.submitScoreDialog.show = true;
  }

  closeSubmitScoreDialog() {
    this.submitScoreDialog.show = false;
    this.submitScoreDialog.matchId = null;
  }

  get formattedMatchStartTime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get computedAction() {
    if (!this.isUserCheckedIn) return 'check_in';
    else {
      if (!this.opponentCheckedIn) return 'checked_in';
      return 'submit_score';
    }
  }

  get matchStarttime() {
    if (this?.match?.startTime) {
      return new Date(this.match.startTime.toString()).toLocaleString();
    } else {
      return "";
    }
  }

  get matchStarted() {
    const match = this.match;
    if (!match)
      return false;

    return (DateTime.fromISO(match.startTime) <= DateTime.local() && this.opponentCheckedIn);
  }

  get matchEndTime() {
    const match = this.match;
    if (!match)
      return false;

    return new Date(DateTime.fromISO(match.endTime)).toLocaleString();
  }

  get canSubmitScore() {
    return this.isParticipant && this.matchStarted;
  }

  get isParticipant() {
    if (this.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.event.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isParticipantTeamMember() {
    if (this.isTeamBased) {
      return this.event.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }

  get isResultAlreadySubmitted() {
    return !!this.match?.result?.id;
  }

  get participations() {
    return this.match?.participations ?? [];
  }

  get myOpponentParticipation() {
    return this.participations.find(p => p.id != this.currentParticipantId);
  }

  get currentParticipantId() {
    if (!this.isTeamBased) return this.currentUserId;
    return this.myParticipatingTeamId;
  }

  get myOpponent() {
    const opPart = this.myOpponentParticipation;

    if (!opPart?.id)
      return null;

    const participants = [
      ...this.event.participatingUsers ?? [],
      ...this.event.participatingTeams ?? []
    ];

    return participants.find(p => p.id == opPart.id);
  }

  get myOpponentName() {
    return this.myOpponent?.name ?? "";
  }

  get cupHasMatches() {
    if (this.event.matches !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  get isAutoWin() {
    return !this.myOpponent?.id;
  }

  get isUserCheckedIn() {
    return (this.match?.checkins ?? []).some(checkin =>
      checkin?.userId == this.currentUserId
    );
  }

  get opponentCheckedIn() {
    return !this.isAutoWin &&
      (this.match?.checkins ?? []).some(c => c.participationId === this.myOpponentParticipation?.participationId);
  }

  get opponentFailedToCheckIn() {
    return this.matchStarted && !this.opponentCheckedIn;
  }

  get isTeamBased() {
    return this.event.mode === "1v1" ? false : true;
  }

  showDialog() {
    this.show = true;
  }

  closeMatchStartDialog() {
    this.show = false;
  }

  showSubmitScoreDialog() {
    //this.showSubmitScore = true
    this.$emit("showSubmitScoreDialog", this.match);
  }

  refreshTournament(...args) {
    this.$emit("refreshTournament", ...args);
  }

  async checkIn() {
    let variables = {};

    let mutation = "matches/checkIn";
    variables = { matchId: this.match?.id};

    if (this.match.grouped) {
      mutation = "matches/checkIns";
      variables = { matchIds: this.match.groupedMatchIds };
    }

    const result = await this.$store.dispatch(mutation, {
      ...variables,
    });

    if (result.success) {
      this.showDialog();
      this.refreshTournament();
      this.trackAnalytics();
    }
  }

  async checkInAsTeam() {
    const result = await this.$store.dispatch(
      "matches/checkIn",
      { matchId: this.match?.id, asTeam: true, teamId: this.myParticipatingTeamId}
    );

    if (result.success) {
      this.showDialog();
      this.refreshTournament();
      this.trackAnalytics();
    }
  }

  private trackAnalytics() {
      this.$gtag.event('match_check_in', {
        'event_category': 'match_action',
        'event_label': 'Check in to match',
        'value': { userId: this.currentUserId, eventId: this.event.id, eventName: this.event.name, eventType: this.eventType }
      });
  }
}
