

























































import { Component, Vue, Watch } from "@components/common";

import Notification from "@graphql/types/notification";

import NotificationsContent from "./Notifications/NotificationsContent.vue";

// This is a temprorary quick-fix to allow a hacky way of displaying notifications
// until we develop the full notifications system.
export const MATCH_NOTIFICATION_TYPES = [
  "MatchStartTimeConfirmed",
  "MatchStartTimeProposed",
  "MatchStartingSoon",
  "MatchTimeConfirmed",
  "TeamAdvancedTo3rdPlaceMatch",
  "TeamMatchReminderLong",
  "TeamMatchReminderShort",
  "TeamMatchTimeProposed",
  "TeamSubmitScoreReminder",
  "UserAdvancedTo3rdPlaceMatch",
  "UserMatchForfeitedNoAction",
  "UserMatchForfeitedNoActionOther",
  "UserMatchLostForfeitNoschedule",
  "UserMatchLostNocheckin",
  "UserMatchReminderLong",
  "UserMatchReminderShort",
  "UserMatchSchedulingReminderRecipient",
  "UserMatchSchedulingReminderScheduler",
  "UserMatchTimeProposed",
  "UserMatchWonForfeitNoschedule",
  "UserMatchWonNocheckin",
  "UserRoundSubmitScoreReminder",
  "UserSubmitScoreReminder",
];


@Component({components: {
  NotificationsContent,
}})
export default class NotificationsInbox extends Vue {
  private notifications: Notification[] = [];
  private modal = false


  get unreadNotifications() {
    return this.notifications.filter(n => !n.isRead);
  }

  get hasUnreadNotifications() {
    return this.unreadNotificationCount > 0;
  }

  get unreadNotificationCount() {
    return this.unreadNotifications.length;
  }

  async loadNotifications() {
    this.notifications = await this.$store.dispatch("notifications/search", {
      filters: {types: MATCH_NOTIFICATION_TYPES},
    });
  }

  async markNotificationsRead() {
    console.log("markNotificationsread");
    await this.$store.dispatch("notifications/markAllRead");
  }

  mounted() {
    this.loadNotifications();
  }

  @Watch("modal")
  intercept() {
    if (!this.isLoggedIn) {
      this.$router.push({name: 'Join'});
      this.modal = false;
      return;
    }

    if (this.modal)
      this.loadNotifications().then(this.markNotificationsRead);
  }
}
