






























































































































import { Vue, GuestPage, Component, Card, MainHeader, Layout, FormLabel, Divider } from "@components/common";
import { DateTime } from "luxon";
import { emailRules, passwordRules } from "@lib/validations";

const DATE_FORMAT = "yyyy-MM-dd";
const INITIAL_OFFSET = {years: 30};

const firstAvailableRegistrationDate = DateTime.local().minus(INITIAL_OFFSET);

@Component({components: { Card, MainHeader, Layout, FormLabel, Divider }})
export default class Register extends GuestPage {
  private email = "";
  private password = "";
  private birthDate = firstAvailableRegistrationDate.toFormat(DATE_FORMAT);
  private terms = false

  private isFormValid = false;
  private isRegistrationComplete = false;

  private emailRules = emailRules;
  private passwordRules = passwordRules;

  ageRule(dateStr) {
    const date = DateTime.fromFormat(dateStr, DATE_FORMAT);
    return date < firstAvailableRegistrationDate ||
      this.$t("register.not_old_enough").toString();
  }

  isChecked(val) {
    return !!val;
  }

  async submit() {
    if (!this.form.validate())
      return;

    await this.$store.dispatch("users/register", {
      email: this.email,
      password: this.password,
      attributes: {
        birthDate: this.birthDate,
        origin: this.$route.query.origin ?? this.$route.query.utm_origin
      }
    });

    this.isRegistrationComplete = true;
  }

  get form() {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  get paramsEmail() {
    return this.$route.query.email ? this.$route.query.email.toString() : '';
  }

  get paramsBirthday() {
    const birthDate = this.$route.query.date_of_birth;

    return birthDate? (DateTime.fromFormat(birthDate, "dd-MM-yyyy").toFormat(DATE_FORMAT)) : '';
  }

  get hasParams() {
    return !!this.paramsEmail || !!this.paramsBirthday;
  }

  created() {
    if (this.paramsEmail) this.email = this.paramsEmail;
    if (this.paramsBirthday) this.birthDate = this.paramsBirthday;
  }
}
