import { Vue, Component } from "@components/common";
import { namespace } from "vuex-class";
import { AppProperties as AppPropertiesInterface } from "@graphql/queries/app_properties";
import Game from "@graphql/types/game";
import Platform from "@graphql/types/platform";

const AppPropertiesStore = namespace("appProperties");

@Component
export default class AppPropertiesMixin extends Vue {

  @AppPropertiesStore.Getter("appProperties")
  public appProperties!: AppPropertiesInterface;

  public gameById(id: number) {
    return this.appProperties.games.find(g => g.id == id) ?? null;
  }

  public platformById(id: number) {
    return this.appProperties.platforms.find(g => g.id == id) ?? null;
  }

  public gamesByIds(ids: number[]) {
    return ids.map(id => this.appProperties.games.find(object => object.id == id)).filter(Boolean) as Game[];
  }

  public platformsByIds(ids: number[]) {
    return ids.map(id => this.appProperties.platforms.find(p => p.id == id)).filter(Boolean) as Platform[];
  }

}
