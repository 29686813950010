






import { Component, Vue, Prop } from "@components/common";

@Component
export default class CardHeader extends Vue {
  @Prop() public value?: boolean;
}
