import Vue from "vue";

interface Filters {
  name: string;
  featured?: boolean;
  state?: string;
  gameIds?: string[];
  platformIds?: string[];
}

export const userFilters: Filters = Vue.observable({
  name: "",
  gameIds: [],
  platformIds: [],
});

export const teamFilters: Filters = Vue.observable({
  ids: [],
  name: "",
  userIds: [],
  captainId: null,
  gameIds: [],
  platformIds: [],
});

export const eventFilters: Filters = Vue.observable({
  name: "",
  state: "notstarted",
  gameIds: [],
  platformIds: [],
});

export const featuredFilters: Filters = Vue.observable({
  name: "",
  featured: true,
  state: "notfinished",
  gameIds: [],
  platformIds: [],
});

export const eventStandings: Filters = Vue.observable({
  name: "",
  state: "finished",
  gameIds: [],
  platformIds: [],
});

export const eventLive: Filters = Vue.observable({
  name: "",
  state: "running",
  gameIds: [],
  platformIds: [],
});
