import { Vue } from "@components/common";
import { store } from "@store";
import VueRouter, { RouteConfig } from "vue-router";

import Activate from "@pages/activate.vue";
import AllNotifications from "@pages/feed/all.vue";
import Browse from "@pages/browse.vue";
import ChangePassword from "@pages/profile/ChangePassword.vue";
import Chat from "@pages/chat.vue";
import CreateNewTeam from "@pages/profile/TeamManagement/CreateNewTeam.vue";
import EditProfile from "@pages/profile/EditProfile.vue";
import EditTeam from "@pages/profile/TeamManagement/EditTeam.vue";
import Event from "@pages/event.vue";
import Events from "@pages/browse/events.vue";
import EventsListing from "@pages/events.vue";
import Faq from "@pages/profile/faq.vue";
import Featured from "@pages/browse/featured.vue";
import Feed from "@pages/feed.vue";
import Game from "@pages/game.vue";
import GameAccounts from "@pages/profile/GameAccounts.vue";
import Games from "@pages/browse/games.vue";
import GamesPage from "@pages/games.vue";
import Invitations from "@pages/invitations.vue";
import Join from "@pages/join.vue";
import League from "@pages/league.vue";
import Login from "@pages/login.vue";
import Logout from "@pages/logout.vue";
import Match from "@pages/match.vue";
import Matches from "@pages/matches.vue";
import MyEnrolments from "@pages/matches/myenrolments.vue";
import MyEnrolmentsArchive from "@pages/matches/myenrolments_archive.vue";
import MyGames from "@pages/profile/MyGames.vue";
import MyMatches from "@pages/matches/mymatches.vue";
import News from "@pages/feed/news.vue";
import NotificationSettings from "@pages/profile/NotificationSettings.vue";
import Notifications from "@pages/feed/notifications.vue";
import PasswordForgot from "@pages/password_forgot.vue";
import PasswordReset from "@pages/password_reset.vue";
import Pending from "@pages/matches/pending.vue";
import Player from "@pages/player.vue";
import PlayersPage from "@pages/players.vue";
import Profile from "@pages/profile.vue";
import ProfileSettings from "@pages/profile/Settings.vue";
import Register from "@pages/register.vue";
import RegisterStep1 from "@pages/register/step_1.vue";
import RegisterStep2 from "@pages/register/step_2.vue";
import RegisterStep3 from "@pages/register/step_3.vue";
import RegisterStep4 from "@pages/register/step_4.vue";
import Reminders from "@pages/feed/reminders.vue";
import Rules from "@pages/feed/rules.vue";
import Standings from "@pages/standings.vue";
import StandingsBestOfWeek from "@pages/standings/bestofweek.vue";
import StandingsFinished from "@pages/standings/finished.vue";
import StandingsGames from "@pages/standings/games.vue";
import StandingsLive from "@pages/standings/live.vue";
import StandingsPlayers from "@pages/standings/players.vue";
import StandingsTeams from "@pages/standings/teams.vue";
import StreamingAccounts from "@pages/profile/StreamingAccounts.vue";
import Team from "@pages/team.vue";
import TeamManagement from "@pages/profile/TeamManagement.vue";
import Teams from "@pages/browse/teams.vue";
import TeamsPage from "@pages/teams.vue";
import Terms from "@pages/terms.vue";
import Tournament from "@pages/tournament/tournament.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: "/feed",
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    meta: { auth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signin",
    name: "Join",
    component: Join,
    meta: { comeBack: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/activate",
    name: "Activate",
    component: Activate,
  },
  {
    path: "/forgot-password",
    name: "Password forgot",
    component: PasswordForgot,
  },
  {
    path: "/reset-password",
    name: "Password reset",
    component: PasswordReset,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/1",
    name: "step_1",
    component: RegisterStep1,
  },
  {
    path: "/register/2",
    name: "step_2",
    component: RegisterStep2,
  },
  {
    path: "/register/3",
    name: "step_3",
    component: RegisterStep3,
  },
  {
    path: "/register/4",
    name: "step_4",
    component: RegisterStep4,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { auth: true },
    component: Profile,
  },
  {
    path: "/profile/settings",
    name: "Profile Settings",
    meta: { auth: true },
    component: ProfileSettings,
  },
  {
    path: "/profile/settings/mygames",
    name: "My Games",
    meta: { auth: true },
    component: MyGames,
  },
  {
    path: "/profile/settings/edit",
    name: "Edit Profile",
    meta: { auth: true },
    component: EditProfile,
  },
  {
    path: "/profile/settings/change-password",
    name: "Change Password",
    meta: { auth: true },
    component: ChangePassword,
  },
  {
    path: "/profile/settings/team",
    name: "Team Management",
    meta: { auth: true },
    component: TeamManagement,
  },
  {
    path: "/profile/settings/create-new-team",
    name: "Create New Team",
    meta: { auth: true },
    component: CreateNewTeam,
  },
  {
    path: "/profile/settings/teams/:teamId",
    name: "Edit Team",
    meta: { auth: true },
    component: EditTeam,
    props: true,
  },
  {
    path: "/profile/settings/game-accounts",
    name: "Game Accounts",
    meta: { auth: true },
    component: GameAccounts,
  },
  {
    path: "/profile/settings/streaming-accounts",
    name: "Streaming Accounts",
    meta: { auth: true },
    component: StreamingAccounts,
  },
  {
    path: "/profile/settings/notifications",
    name: "Notification Settings",
    meta: { auth: true },
    component: NotificationSettings,
  },
  {
    path: "/teams/:teamId",
    name: "Team",
    meta: { comeBack: true },
    component: Team,
  },

  {
    path: "/browse",
    name: "Browse",
    component: Browse,
    children: [
      {
        path: "/browse/teams",
        name: "browse.tab.teams",
        meta: { comeBack: true, saveScrollPosition: true },
        component: Teams,
      },
      {
        path: "/browse/featured",
        name: "browse.tab.featured",
        meta: { comeBack: true, saveScrollPosition: true },
        component: Featured,
      },
    ],
  },
  {
    path: "/players",
    name: "players-index",
    meta: { comeBack: true, saveScrollPosition: true },
    component: PlayersPage,
  },
  {
    path: "/teams",
    name: "teams-index",
    meta: { comeBack: true, saveScrollPosition: true },
    component: TeamsPage,
  },
  {
    path: "/invitations",
    name: "Invitations",
    component: Invitations,
  },
  {
    path: "/browse/tournaments/:id",
    alias: "/standings/tournaments/:id",
    name: "Tournament",
    meta: { comeBack: true, saveScrollPosition: true },
    component: Tournament,
    props(route) {
      return route.query || {};
    },
  },
  {
    path: "/browse/leagues/:id",
    alias: "/standings/leagues/:id",
    name: "League",
    meta: { comeBack: true, saveScrollPosition: true },
    component: League,
    props(route) {
      return route.query || {};
    },
  },
  {
    path: "/browse/events/:id",
    alias: "/standings/events/:id",
    name: "Event",
    meta: { comeBack: true, saveScrollPosition: true },
    component: Event,
    props(route) {
      return route.query || {};
    },
  },
  {
    path: "/browse/players/:userId",
    name: "Players",
    component: Player,
    meta: { comeBack: true },
  },
  {
    path: "/browse/games/:id",
    name: "Game",
    component: Game,
    props: true,
  },
  {
    path: "/match/:matchId",
    name: "Match",
    component: Match,
  },
  {
    path: "/feed",
    name: "Feed",
    component: Feed,
    redirect: "/feed/all",
    children: [
      {
        path: "/feed/all",
        name: "feed.tab.all",
        component: AllNotifications,
      },
      {
        path: "/feed/news",
        name: "feed.tab.news",
        component: News,
      },
      {
        path: "/feed/reminders",
        name: "feed.tab.reminders",
        component: Reminders,
      },
      {
        path: "/feed/rules",
        name: "feed.tab.rules",
        component: Rules,
      },
      {
        path: "/feed/notifications",
        name: "feed.tab.notifications",
        component: Notifications,
      },
    ],
  },
  {
    path: "/matches",
    name: "My Matches",
    component: Matches,
    redirect: "/matches/mymatches",
    children: [
      {
        path: "/matches/mymatches",
        name: "matches.tab.mymatches",
        meta: { comeBack: true, auth: true },
        component: MyMatches,
      },
      {
        path: "/matches/myenrolments",
        name: "matches.tab.myenrolments",
        meta: { comeBack: true, auth: true },
        component: MyEnrolments,
      },
      {
        path: "/matches/pending",
        name: "matches.tab.pending",
        meta: { comeBack: true, auth: true },
        component: Pending,
      },
      {
        path: "/matches/archive",
        name: "matches.tab.archive",
        meta: { comeBack: true, auth: true },
        component: MyEnrolmentsArchive,
      },
    ],
  },
  {
    path: "/standings",
    name: "Standings",
    redirect: "/standings/live",
    component: Standings,
    meta: { comeBack: true },
    children: [
      {
        path: "/standings/bestofweek",
        name: "standings.tab.bestofweek",
        component: StandingsBestOfWeek,
      },
      {
        path: "/standings/live",
        name: "standings.tab.live",
        meta: { comeBack: true },
        component: StandingsLive,
      },
      {
        path: "/standings/games",
        name: "standings.tab.games",
        component: StandingsGames,
      },
      {
        path: "/standings/players",
        name: "standings.tab.players",
        component: StandingsPlayers,
      },
      {
        path: "/standings/teams",
        name: "standings.tab.teams",
        component: StandingsTeams,
      },
      {
        path: "/standings/finished",
        name: "standings.tab.finished",
        meta: { comeBack: true },
        component: StandingsFinished,
      },
      {
        path: "/standings/leagues",
        redirect: "/standings/finished",
      },
      {
        path: "/standings/tournaments",
        redirect: "/standings/finished",
      },
    ],
  },
  {
    path: "/privacy",
    beforeEnter() {
      window.open("https://www.senior-esports.ch/privacy-policy/", "_blank");
    },
  },
  {
    path: "/profile/settings/support",
    beforeEnter() {
      window.open("https://discord.gg/eku3kYR", "_blank");
    },
  },
  {
    path: "/discord",
    beforeEnter() {
      window.open("https://discord.gg/bZvuBcd", "_blank");
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    meta: { comeBack: true },
    component: Faq,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/browse/events",
    redirect: "/browse/featured",
  },
  {
    path: "/games",
    name: "games-index",
    component: GamesPage,
  },
  {
    path: "/events",
    name: "events-index",
    component: EventsListing,
    props: true,
  },
  {
    path: "/events/:mainFilter",
    name: "events-index",
    component: EventsListing,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      const previousTopPosition = store.state.navigation.previousTopPosition;
      const position = { selector: "" };
      if (to.hash) {
        position.selector = to.hash;
        if (document.querySelector(to.hash)) {
          return position;
        }
      } else if (to.meta?.saveScrollPosition) {
        return { x: 0, y: previousTopPosition };
      } else {
        return { x: 0, y: 0 };
      }
    }
  },
});

router.beforeEach(function (to, from, next) {
  // TODO: These should be defined in a config, or at least in a constant outside
  // this function.

  const fromMeta = from.meta;
  if (fromMeta) {
    if (fromMeta.comeBack) {
      if (fromMeta.hash) return;
      //Remove IDs from the path
      const backUrl = from.path.replace(/\d+$/, "");
      store.commit("navigation/setBackPage", {
        name: from.name,
        path: backUrl,
      });
    } else {
      store.commit("navigation/setBackPage", { name: "", path: "" });
    }

    if (fromMeta.saveScrollPosition) {
      store.commit(
        "navigation/setPreviousTopPosition",
        document.documentElement.scrollTop
      );
    }
  }

  const toMeta = to.meta;
  if (toMeta) {
    if (toMeta.auth && !router.app.isLoggedIn) {
      const nextUrl = to.path.replace(/\d+$/, "");
      store.commit("navigation/setNextPage", { name: to.name, path: nextUrl });
      next({ name: "Join" });
    } else next();
  }
});

export default router;
