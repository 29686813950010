import { render, staticRenderFns } from "./event_card.vue?vue&type=template&id=33158943&scoped=true&"
import script from "./event_card.vue?vue&type=script&lang=ts&"
export * from "./event_card.vue?vue&type=script&lang=ts&"
import style0 from "./event_card.vue?vue&type=style&index=0&id=33158943&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33158943",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCol,VIcon,VImg,VList,VListItem,VMenu,VRow,VSheet})
