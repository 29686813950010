



















































































































































import { Page, Watch, Component, Layout, Divider } from "@components/common";
import TeamModel from "@graphql/types/team";
import { ListTeamsWithCaptainId } from "@graphql/queries/teams";
import TeamMembersManagementForm from "@components/teams/member_management_form.vue";

@Component({
  components: {
    Layout,
    Divider,
    TeamMembersManagementForm
  },
  apollo: {
    teams: {
      query: ListTeamsWithCaptainId,
      error(error) {
        this.error = JSON.stringify(error.message);
        this.showError = true;
      },
      variables() { return {filters: {userIds: [this.currentUserId]}}; },
      result() { this.isUpdating = false; }
    }
  }
})

export default class TeamManagement extends Page {
  private isUpdating = true;
  private error = null
  private showError = false

  private teams: TeamModel[] = []
  private filters = {
    name: "",
  }

  @Watch("filters.name")
  markUpdating() {
    this.isUpdating = true;
  }

  async refresh() {
    this.$apollo.queries.teams.refetch();
  }

  canEditTeam(team) {
    return team.captain?.id == this.currentUserId;
  }

  get teamCaptain() {
    return this.teams.filter(team => team.captain?.id === this.currentUserId);
  }

  get teamMember() {
    return this.teams.filter(team => team.captain?.id !== this.currentUserId);
  }
}
