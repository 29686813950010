











































import { Component, GuestPage, Layout, FormLabel } from "@components/common";
import { notEmpty } from "@lib/validations";

@Component({
  components: { Layout, FormLabel }
})
export default class PasswordReset extends GuestPage {
  private isFormValid = false;
  private error = false;

  private form = {
    token: "",
    password: ""
  };
  private secondPassword = "";
  private notEmpty = notEmpty;

  matchesFirstPassword(val) {
    return this.form.password == val ||
      this.$t("password_reset.passwords_must_match").toString();
  }

  mounted() {
    this.form.token = this.$route.query.token as string;
  }

  get errorMessage() {
    return this.error ? this.$t("password_reset.not_found") : "";
  }

  async submit() {
    if (!this.isFormValid)
      return;

    const result = await this.$store.dispatch("users/resetPassword", this.form);

    if (result.success) {
      this.$notify({ text: this.$t("password_reset.success").toString() });
      this.$router.push("/login");
    }
  }
}
