import gql from "graphql-tag";
import Base from "@graphql/types/base";

export const FileFields = gql`
  fragment FileFields on FileWithUrl {
    url
  }
`;

export default class File extends Base<File> {
  url?: string;
}
