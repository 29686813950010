

















































































































import { Component, Vue, Prop } from "@components/common";
import { Datetime } from "vue-datetime";
import { DateTime, Interval } from "luxon";

import MatchHelpers, { MatchStage } from "@lib/helpers/match_helpers";
import Event from "@/graphql/types/event";

import Match from "@/graphql/types/match";
import Team from "@/graphql/types/team";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  MatchCardBasics,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";

import 'vue-datetime/dist/vue-datetime.css';

const SCHEDULE_PRIORITY_2_OFFSET = 72 * 3600;
const SCHEDULE_PRIORITY_3_OFFSET = 24 * 3600;

@Component({
  components: {
    ActionBtn,
    CardBody,
    CardHeader,
    DateTimeInfo,
    Datetime,
    Expander,
    MatchCardBasics,
    Meta,
    MetaLine,
    UserAvatar,
  }
})
export default class ScheduleStage extends Vue {
  @Prop() private match!: Match;
  @Prop() private event!: Event;
  @Prop() private myTeams!: Team[];
  @Prop() private show!: boolean;

  // Common starting string for translations
  private component = 'match_card.schedule_stage'
  private loading = false;
  private startTime = "";

  openChat() {
    const otherUser = this.matchHelpers.otherParticipation(this.currentUserId as number);

    if (!otherUser)
      return;

    this.$eventBus.$emit("chat:open:1on1id", otherUser.id);
  }

  proposeTime() {
    this.loading = true;

    const match = this.match;
    let mutation = "matches/proposeNewTime";
    let variables: Record<string, any> = { matchId: this.match.id };

    if (match.grouped && match.groupedMatchIds) {
      mutation = "matches/proposeNewTimes";
      variables = { matchIds: match.groupedMatchIds };
    }

    this.$store.dispatch(mutation, {
      ...variables,
      time: this.startTime
    })
      .finally(() => this.loading = false);
  }

  get opponentParticipation() {
    return this.matchHelpers.opponentParticipation;
  }

  get matchHelpers() {
    return new MatchHelpers(this.match, [], {
      me: this.currentUser,
      matchStage: MatchStage.Schedule,
      event: this.event,
      myTeams: this.myTeams
    });
  }

  get remainingTime() {
    this.ticker;

    let startTime = this.matchHelpers.latestSchedulableTime;

    if (this.isTimeProposedToMe) {
      startTime = DateTime.fromISO(this.match.startTimeProposedAt).plus({seconds: this.timeProposalGracePeriod});
    }

    return Interval.fromDateTimes(
      DateTime.local(),
      DateTime.fromISO(startTime)
    ).length("seconds");
  }

  get remainingTimeText() {
    return this.matchHelpers.intervalText(this.remainingTime, {short: true});
  }

  get timeProposalGracePeriod() {
    return this.matchHelpers.timeProposalConfirmationPeriod;
  }

  get priority() {
    if (this.isTimeProposedByMe)
      return this.remainingTime < 24 * 3600 ? 2 : 1;
    else if (this.isTimeProposedToMe)
      return this.remainingTime < 6 * 3600 ? 3 : 2;

    if (this.remainingTime < SCHEDULE_PRIORITY_3_OFFSET)
      return 3;
    else if (this.remainingTime < SCHEDULE_PRIORITY_2_OFFSET)
      return 2;
    else
      return 1;
  }

  get icon() {
    if (this.isTimeProposedToMe)
      return "mdi-clock";
    else if (this.isTimeProposedByMe)
      return this.priority == 2 ? "mdi-clock-time-eight-outline" : "$icn-browse";
    else
      return this.priority == 3 ? "mdi-clock-time-eight-outline" : "$icn-browse";
  }

  get isTimeProposedToMe() {
    return this.matchHelpers.isStartTimeProposed && !this.matchHelpers.isTimeProposedByMe;
  }

  get isTimeProposedByMe() {
    return this.matchHelpers.isStartTimeProposed && this.matchHelpers.isTimeProposedByMe;
  }

  get expanded() {
    return this.show || this.priority > 2;
  }

  get minDatetime() {
    const originalStartTime = DateTime.fromISO(this.match.originalStartTime);
    const now = DateTime.local();

    return (now > originalStartTime ? now : originalStartTime).toISO();
  }

  get maxDatetime() {
    return DateTime.fromISO(this.match.originalEndTime).toISO();
  }

  get formattedLatestStartDatetime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_MED);
  }

  get isScheduled() {
    return !!this.match.startTimeProposedById;
  }

  private timer: ReturnType<typeof setInterval> | null = null;
  private ticker = 0;

  mounted() {
    this.timer = setInterval(this.tick, 1000);
  }

  unmounted() {
    const timer = this.timer;
    if (timer)
      clearInterval(timer);
  }

  tick() {
    this.ticker += 1;
  }
}
