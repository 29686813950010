






























































































































































































































import Event from "@/graphql/types/event";
import Match from "@/graphql/types/match";
import Team from "@/graphql/types/team";
import { Component, Vue, Prop, FormLabel, Divider } from "@components/common";
import MatchHelpers, { MatchStage } from "@lib/helpers/match_helpers";
import { DateTime } from "luxon";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  MatchCardBasics,
  MatchKey,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";

@Component({
  components: {
    ActionBtn,
    CardBody,
    CardHeader,
    DateTime,
    DateTimeInfo,
    Expander,
    FormLabel,
    MatchCardBasics,
    MatchKey,
    Meta,
    MetaLine,
    UserAvatar,
    Divider
  }
})
export default class OverviewStage extends Vue {
  @Prop() private match!: Match;
  @Prop() private event!: Event;
  @Prop() private myTeams!: Team[];
  @Prop() private show!: boolean;

  private loading = false;
  private checkedIn = false;

  challengeResult() {
    this.$eventBus.$emit("chat:open:support");
  }

  get allAvailableData() {
    return Object.entries({
      ...this.match,
      // ...this.event
    });
  }

  get resultSubmitter() {
    const submitterId = this?.match?.result?.submitterId;

    if (!submitterId)
      return null;

    return this.match?.participations?.find(p => p.id == submitterId);
  }

  get resultText() {
    return this.matchHelpers.resultText;
  }

  get startTimeProposer() {
    return this.match?.participations?.find(p => p.id == this.match.startTimeProposedById);
  }

  get startTimeProposee() {
    const proposer = this.startTimeProposer;

    if (!proposer)
      return null;

    return this.matchHelpers.otherParticipation(proposer.id as number);
  }

  formattedTime(time: string | null) {
    if (!time)
      return this.$t("common.never");

    return DateTime.fromISO(time).toLocaleString(DateTime.DATETIME_MED);
  }

  participationCheckin(id) {
    return this.match?.checkins?.find(c => c.participationId == id);
  }


  get hasWinner() {
    const match = this.match;

    return match && match.result && match.result.winnerId;
  }

  get winner() {
    const match = this.match;
    const result = match?.result;

    if (!match || !result || !result.winnerId)
      return null;

    return match.participations?.find(p => p.participationId == result.winnerId);
  }

  get hasSystemNote() {
    return this.match?.result?.data?.["__system_note"]?.length > 0;
  }

  get resultSystemNote() {
    return this.match?.result?.data?.["__system_note"];
  }

  get systemDecisionDescription() {
    const key = this.resultSystemNote
      .replaceAll(/_+/g, "_")
      .replaceAll("-", "_");

    return this.matchHelpers.t(`system_note.${key}`);
  }

  get matchHelpers() {
    return new MatchHelpers(this.match, [], {
      me: this.currentUser,
      matchStage: MatchStage.Overview,
      event: this.event,
      myTeams: this.$props.myTeams
    });
  }

  get myParticipationName() {
    return this.matchHelpers.myParticipation?.name ?? "";
  }

  get opponentParticipationName() {
    return this.opponentParticipation?.name ?? "";
  }

  get startTimeProposerName() {
    return this.startTimeProposer?.name ?? "";
  }

  get startTimeProposeeName() {
    return this.startTimeProposee?.name ?? "";
  }

  get opponentParticipation() {
    return this.matchHelpers.opponentParticipation;
  }

  get formattedLatestStartDatetime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_MED);
  }

  get isCheckedIn() {
    return !!this.match?.checkins?.find(c => c.userId == this.currentUserId);
  }

  get priority() {
    const result = this.match?.result;
    const myParticipation = this.matchHelpers.myParticipation;

    if (!result || !myParticipation)
      return 1;

    if (result.draw)
      return 2;
    else if (result.winnerId == myParticipation.participationId)
      return 4;
    else if (result.winnerId && result.winnerId != myParticipation.participationId)
      return 3;

    return 1;
  }

  get expanded() {
    return this.show;
  }
}
