























import { Component, Mixins, Prop } from "@components/common";
import Match from "@graphql/types/match";
import MatchHelpersMixin from "@/mixins/match_helpers";

@Component
export default class ResultCard extends Mixins(MatchHelpersMixin) {
  @Prop(Object) private readonly match!: Match;

  async confirmResult() {
    if (await this.getUserConfirmation())
      this.$emit("confirmResult");
  }

  async getUserConfirmation() {
    const result = await this.$confirm(
      this.$t("match.result_card.confirmation_dialogue", {
        winnerName: this.winner.name,
        winningScore: this.winner.score,
        losingScore: this.loser.score
      }).toString(),
      {title: "Confirm result?"}
    );

    return result;
  }
}
