import gql from "graphql-tag";

import { FileFields } from "@graphql/types/file";

export default gql`
mutation UpdateMyAvatar($avatar : Upload!) {
  updateMyAvatar(avatar: $avatar) {
    success
    avatar {
      ...FileFields
    }
  }
}
${FileFields}
`;
