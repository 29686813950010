

















import { Component, Vue, Prop } from "@components/common";
import { GetUserMatchStatistics } from "@graphql/queries/user_match_stats";
import UserMatchStats from "@graphql/types/user_match_stats";

@Component({
  apollo: {
    matchStats: {
      query: GetUserMatchStatistics,
      variables() { return {userId: parseInt(this.userId)};},
    }
  }
})

export default class MatchStatisticsCard extends Vue {
  @Prop() readonly userId!: number;

  private matchStats?: UserMatchStats;

  get stats() {
    return { won: this.matchStats?.wins, played: this.matchStats?.playedMatches};
  }
}
