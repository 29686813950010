import gql from "graphql-tag";
import Base from "@graphql/types/base";

export const PlatformFields = gql`
  fragment PlatformFields on GamePlatform {
    id
    tag
    name
    icon
  }
`;

export default class Platform extends Base<Platform> {
  static readonly module = "platforms";

  declare id?: number
  tag?: string
  name?: string
  icon?: string
}
