



































































import { Vue, Component, Prop } from "@components/common";

import gql from "graphql-tag";
import { union } from "underscore";

import Event from "@graphql/types/event";
import { Participant as ConversationParticipant, Conversation } from "@store/chat";
import User from "@graphql/types/user";

interface Participant extends ConversationParticipant {
  uuid: string;
}

const REFRESH_PERIOD = 15;


@Component({
  apollo: {
    adminUsers: {
      query: gql`
        query GetAdminUsers($ids : [ID!]!) {
          users(filters: { ids: $ids }) {
            id
            uuid
            nickname
          }
        }
      `,
      variables() { return {ids: this.event.administratorUserIds}; },
      update: ({ users }) => users.map(u => ({...u, isAdmin: true}))
    }
  }
})
export default class EventChatAdmin extends Vue {
  @Prop() readonly event!: Event;

  private adminUsers!: User[];
  private messages = [];
  private conversation: Conversation | null = null;

  private timer: ReturnType<typeof setInterval> | null = null;

  mounted() {
    this.loadConversation();
    this.loadMessages();

    this.timer = setInterval(this.timerTick, 1000);
  }

  unmounted() {
    const timer = this.timer;

    if (timer)
      clearInterval(timer);
  }

  private timerValue = 0;
  timerTick() {
    this.timerValue += 1;

    if (this.timerValue >= REFRESH_PERIOD) {
      this.loadMessages();
    }
  }

  get refreshTimerPercentage() {
    return this.timerValue / REFRESH_PERIOD * 100;
  }

  formattedUUID(uuid) {
    // NO idea how TalkJS manages so many different formats of UUIDs in just
    // a few endpoints, but this should normalise all of them without parsing, lol
    return uuid
      .toLowerCase()
      .replaceAll("-", "")
      .replaceAll("_", "");
  }

  findUser(uuid) {
    if (uuid.toLowerCase() == "sytem")
      return {nickname: "System", uuid: uuid};

    const user = this.allUsers.find(u => this.formattedUUID(u.uuid) == this.formattedUUID(uuid));

    return user ?? {nickname: uuid.substring(0, 6), uuid: uuid};
  }

  get allUsers() {
    return union(this.event.participatingUsers, this.adminUsers);
  }

  get participants(): Participant[] {
    const participants = this.conversation?.participants;
    if (!participants)
      return [];

    return Object.entries(participants).map(pair => ({ uuid: pair[0], ...(pair[1]) as Record<string, any> } as Participant));
  }

  hasWritePermission(user) {
    const uuid = this.formattedUUID(user.uuid);
    const participant = this.participants.find(p => this.formattedUUID(p.uuid) == this.formattedUUID(uuid));

    return participant?.access == "ReadWrite";
  }

  async toggleUserWritePermission(user) {
    const uuid = this.formattedUUID(user.uuid);
    const participant = this.participants.find(p => this.formattedUUID(p.uuid) == uuid);
    const newAccess = participant?.access == "Read" ? "ReadWrite" : "Read";

    await this.$store.dispatch("chat/setConversationAccess", {
      eventId: this.event.id,
      userId: user.id,
      access: newAccess
    });

    this.loadConversation();
  }

  async deleteMessage(messageId) {
    if (await this.$confirm("Do you want to delete this messsage?")) {
      await this.$store.dispatch("chat/deleteConversationMessage", { eventId: this.event.id, messageId });
      this.loadMessages();
    }
  }

  async loadConversation() {
    this.conversation = await this.$store.dispatch("chat/conversation", {eventId: this.event.id});
  }

  async loadMessages() {
    const messages = await this.$store.dispatch("chat/conversationMessages", {eventId: this.event.id});
    this.messages = messages.map(message => ({...message, user: this.findUser(message.senderId)}));

    this.timerValue = 0;
  }
}
