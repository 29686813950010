









































































import { Component, Prop, Vue } from "@components/common";

@Component
export default class ErrorPopup extends Vue {
  @Prop() event!: Event;
  @Prop(String) private readonly title;
  @Prop(String) private readonly notice;
  @Prop(Boolean) private readonly wrongAge;
  @Prop(Boolean) private show;

  private modal = false

  get showDialog() {
    return this.show;
  }

  set showDialog(value) {
    this.modal = value;
    if (!value) this.$emit("close-confirmation");
  }

  close(...args) {
    this.$emit("close-confirmation", ...args);
  }
}
