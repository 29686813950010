import Me from "@graphql/types/me";
import Team from "@graphql/types/team";
import {
  AUTHENTICATE as AUTHENTICATE_QUERY,
  REAUTHENTICATE as REAUTHENTICATE_QUERY,
} from "@graphql/queries/authenticate";

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { createApollo } from "@lib/graphql";
import { onLogin, onLogout } from "@lib/graphql";
import Game from "@graphql/types/game";
import UserPlatform from "@graphql/types/user_platform";

const apollo = createApollo();

class TeamRolePair {
  constructor(public teamId: number, public role: string) {}
}

@Module({ namespaced: true })
export default class Authentication extends VuexModule {
  public user: Me | null = null;
  public token: string | null = null;
  public isPremiumUser = false;
  public teamRoles: TeamRolePair[] = [];

  get isLoggedIn() {
    return (this.token?.length ?? 0) > 0;
  }

  @Mutation
  setGames(games: Game[]) {
    if (this.user !== null && games) this.user.games = games;
  }

  @Mutation
  setPlatforms(userPlatforms: UserPlatform[]) {
    if (this.user !== null && userPlatforms)
      this.user.userPlatforms = userPlatforms;
  }

  @Action({ rawError: true })
  async authenticate({ email, password }) {
    const {
      data: { authenticate: result },
    } = await apollo.query({
      query: AUTHENTICATE_QUERY,
      variables: { email, password },
      fetchPolicy: "no-cache",
    });

    if (!result?.token) return;

    this.context.dispatch("applyAuth", result);
  }

  @Action({ rawError: true })
  async reauthenticate() {
    const {
      data: { reauthenticate: result },
    } = await apollo.query({
      query: REAUTHENTICATE_QUERY,
      fetchPolicy: "no-cache",
    });

    if (!result?.token) return;

    this.context.dispatch("applyAuth", result);
  }

  @Action({ rawError: true })
  async logout() {
    this.context.commit("removeAuth");
    this.context.commit("chat/clear", null, { root: true });
    this.context.commit("myMatches/clear", null, { root: true });
    this.context.commit("myRounds/clear", null, { root: true });
  }

  @Action({ rawError: true })
  applyAuth(auth) {
    this.context.commit("setAuth", auth);

    onLogin(apollo, auth.token);

    this.context.dispatch("myMatches/reload", null, { root: true });
    this.context.dispatch("myRounds/reload", null, { root: true });
    this.context.dispatch("myEnrolments/reload", null, { root: true });
    this.context.dispatch("appProperties/reload", null, { root: true });
  }

  @Mutation
  setAuth(auth) {
    this.user = new Me(auth.user);
    this.token = auth.token;
    this.isPremiumUser = auth.isPremiumUser;
    this.teamRoles = auth.teamRoles;
  }

  @Mutation
  removeAuth() {
    this.user = null;
    this.token = null;

    onLogout(apollo);
  }

  get canInviteToTeam(): ({ id }: Team) => boolean {
    return ({ id }: Team) => {
      return (
        this.teamRoles.find((role) => role.teamId == id)?.role == "captain"
      );
    };
  }
}
