




























































































































import { Component, Vue, Prop, Watch } from "@components/common";
import Event from "@graphql/types/event";
import { ListTeamsWithCaptainId } from "@graphql/queries/teams";
import Team from "@graphql/types/team";
import { FormattedTime } from "@components/common/helpers";
import { DateTime } from "luxon";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";

interface LeaveEventParams {
  id?: number;
  teamId?: number;
  asTeam?: boolean;
}

@Component({
  components: { FormattedTime },
  apollo: {
    myTeams: {
      query: ListTeamsWithCaptainId,
      variables() { return {filters: {userIds: [this.currentUserId]}};},
      update: ({ teams }) => teams.map(t => new Team(t)),
      result() { this.$nextTick(this.preselectFirstTeam); }
    }
  }
})

export default class JoinAsTeamAction extends Vue {
  @Prop(Object) private readonly event!: Event;
  @Prop(String) private readonly eventType!: string;
  @Prop(String) private readonly action!: string;

  private selectedTeamId_?: number = 0;
  private myTeams: Team[] = [];
  private isDisabled = true;
  private showDialog = false;


  @Watch("event.id")
  get helpers() {
    return new EventCategoryHelpers(this.event);
  }

  get myParticipatingTeam() {
    const teams = this.event.participatingTeams;

    if (!teams || teams.length == 0)
      return null;

    return teams.find(team =>
      team.members?.find(member => member.id === this.currentUserId)
    );
  }

  get isParticipatingTeamCaptain() {
    return this.myParticipatingTeam?.captain?.id === this.currentUserId;
  }

  get canLeaveEvent() {
    if (this.hasEventStarted)
      return false;

    return this.isParticipant && (this.event.isUserBased || this.isParticipatingTeamCaptain);
  }

  async leaveEvent() {
    if (!await this.confirmLeave())
      return;

    let params: LeaveEventParams = {id: this.event.id};
    if (this.isTeamBased)
      params = { ...params, teamId: this.myParticipatingTeam?.id, asTeam: true};

    // TODO: Remove legacy tournament support
    const action = Event.isLegacyEvent(this.event) ? "tournaments/leave" : "events/leave";
    const result = await this.$store.dispatch(action, params);

    if (result.success) {
      this.$notify({
        type: "success",
        text: this.$t(`event.leave_success`, {name: this.event.name}).toString()
      });

      this.$emit("update", result);
    }
  }

  confirmLeave() {
    return this.$confirm(
      this.$t(`event.confirm_leave`).toString()
    );
  }
  get hasEventStarted() {
    const event = this.event;

    if (!event) return false;

    return DateTime.fromISO(event.startTime) < DateTime.local();
  }
  get computedAction() {
    if (this.isSignedUp && (this.action === 'signup' || this.action === 'signup_closed' || this.action === 'signup_confirm'))
      return 'active';
    return this.action;
  }

  // This is for the new flows, if enum for open sign_up is SIGNUP_OPEN so default to that if non set
  get signUpMode() {
    const currentFlowStage = Event.currentEventFlowStage(this.event);

    return currentFlowStage ? currentFlowStage.subtype : 'SIGNUP_OPEN';
  }

  get isSignedUp() {
    return this.isParticipant === true;
  }

  get isCaptainOfOneTeam() {
    return this.captainedTeams.length == 1;
  }

  get isCaptainOfMoreTeams() {
    return this.captainedTeams.length > 1;
  }

  get isParticipantingTeamCaptain() {
    if (!this.event.participatingTeams) return false;
    return this.event.participatingTeams?.some(team => team.captain?.id === this.currentUserId);
  }

  get isCaptain() {
    return this.isCaptainOfOneTeam || this.isCaptainOfMoreTeams;
  }

  get captainedTeams() {
    return this.myTeams.filter(this.canInviteToTeam);
  }

  get isInTeam() {
    return this.myTeams.length > 0;
  }

  get selectedTeamId() {
    if (this.isCaptainOfOneTeam)
      return this.captainedTeams[0]?.id;

    return this.selectedTeamId_;
  }

  set selectedTeamId(val) {
    this.selectedTeamId_ = val;
  }

  get selectedTeam() {
    return this.myTeams.find(team => team.id == this.selectedTeamId);
  }

  isTeamAlreadyParticipating(team) {
    return this.event?.participatingTeams?.some(t => t.id == team.id);
  }

  canInviteToTeam(team) {
    const captainId = team.captain?.id;

    if (!captainId) return false;

    return captainId == this.currentUserId;
  }

  get isParticipant() {
    if (this.event.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.event.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isEventFull() {
    return this.event.participationCount >= (this.event?.maxPlayers ?? 0);
  }

  preselectFirstTeam() {
    this.selectedTeamId = this.captainedTeams.find(team =>
      !this.isTeamAlreadyParticipating(team)
    )?.id;
  }

  get signoutButtonTextKey() {
    return this.translationTag('event', 'leave', '');
  }

  translationTag(prefix: string, sufix: string, action: string) {
    return Event.isLegacyEvent(this.event) ? `${prefix}.${this.eventType}.${action}${sufix}` : `${prefix}.${action}${sufix}`;
  }

  get signupButtonTextKey() {
    if (!this.isInTeam) {
      this.isDisabled = true;
      return this.translationTag('event', '_notTeamMember', this.action);
    }
    else if (this.isCaptainOfOneTeam) {
      this.isDisabled = false;
      return this.translationTag('event', '_Captain', this.action);
    }
    else if (this.isCaptainOfMoreTeams) {
      this.isDisabled = false;
      return this.translationTag('event', '_CaptainMultiple', this.action);
    }
    else if (!this.isCaptain) {
      this.isDisabled = true;
      return this.translationTag('event', '_notCaptain', this.action);
    }
    else
      return `common.empty`;
  }

  get signupButtonTextHintKey() {

    if (!this.isInTeam) {
      this.isDisabled = true;
      return this.translationTag('event', '_tooltip_notTeamMember', this.computedAction);
    }
    else if (this.isCaptainOfOneTeam) {
      this.isDisabled = false;
      return this.translationTag('event', '_tooltip_Captain', this.computedAction);
    }
    else if (this.isCaptainOfMoreTeams) {
      this.isDisabled = false;
      return this.translationTag('event', '_tooltip_CaptainMultiple', this.computedAction);
    }
    else if (!this.isCaptain) {
      this.isDisabled = true;
      return this.translationTag('event', '_tooltip_notCaptain', this.computedAction);
    }
    else
      return `common.empty`;
  }

  get signupClosedButtonTextHintKey() {

    if (!this.isInTeam) {
      this.isDisabled = true;
      return this.translationTag('event', '_tooltip_notTeamMember', this.computedAction);
    }
    else if (this.isCaptainOfOneTeam) {
      this.isDisabled = false;
      return this.translationTag('event', '_tooltip_Captain', this.computedAction);
    }
    else if (this.isCaptainOfMoreTeams) {
      this.isDisabled = false;
      return this.translationTag('event', '_tooltip_CaptainMultiple', this.computedAction);
    }
    else if (!this.isCaptain) {
      this.isDisabled = true;
      return this.translationTag('event', '_tooltip_notCaptain', this.computedAction);
    }
    else
      return `common.empty`;
  }

    get fullButtonTextHintKey() {
      if (!this.isInTeam)
        return `event.full_tooltip_notTeamMember`;
      else if (this.isCaptainOfOneTeam)
        return `event..full_tooltip_Captain`;
      else if (this.isCaptainOfMoreTeams)
        return `event.full_tooltip_CaptainMultiple`;
      else if (!this.isCaptain)
        return `event.full_tooltip_notCaptain`;
      else
        return `common.empty`;
  }

  get isParticipantTeamMember() {
    if (this.event.isTeamBased) {
      return this.event.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }

  get isUserBased() {
    return this.event?.isUserBased;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  signupAsTeam() {
    if (this.isCaptainOfOneTeam) {
      this.joinEvent(this.captainedTeams[0].id);
    } else {
      if (this.isCaptain) {
        this.openDialog();
      } else {
        this.$notify({
          type: "error",
          duration: 3000,
          title: this.$t('event.team_action.not_a_captain').toString()
        });
      }
    }
  }

  joinEvent(teamId) {
    this.$emit("joinEvent", this.event, teamId);
    this.showDialog = false;
  }

  checkIn() {
    this.$notify({title: this.$t("common.not_available_yet").toString()});
  }

  openDialog() {
    this.showDialog = true;
  }
}
