








































































































import { Component, Vue, Prop } from "@components/common";
import { Datetime } from "vue-datetime";

import { sortBy } from "underscore";

import RoundHelpers, { RoundStage } from "@lib/helpers/round_card_helpers";

import Event from "@/graphql/types/event";
import PlayDay from "@/graphql/types/play_day";
import Round from "@/graphql/types/br_round";
import Team from "@/graphql/types/team";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  RoundCardBasics,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";
import GetMatchKey from "@components/events/active/get_match_key.vue";

import 'vue-datetime/dist/vue-datetime.css';


@Component({
  components: {
    ActionBtn,
    CardBody,
    CardHeader,
    DateTimeInfo,
    Datetime,
    Expander,
    GetMatchKey,
    Meta,
    MetaLine,
    RoundCardBasics,
    UserAvatar,
  }
})
export default class OverviewStage extends Vue {
  @Prop() private readonly round!: Round;

  @Prop() private readonly event!: Event;
  @Prop() private readonly myTeams!: Team[];
  @Prop() private readonly playDay!: PlayDay;

  @Prop() private readonly show!: boolean;

  // Common starting string for translations
  private loading = false;

  get roundName() {
    return this.roundHelpers.roundName;
  }

  get hasSubmittedOwnResult() {
    return this.roundHelpers.hasSubmittedOwnResult;
  }

  get myResult() {
    return this.roundHelpers.myResult;
  }

  get roundHelpers() {
    return new RoundHelpers(this.round, {
      me: this.currentUser,
      stage: RoundStage.Overview,
      event: this.event,
      myTeams: this.myTeams,
      playDay: this.playDay,
    });
  }

  get results() {
    return sortBy(this.round.results, "points")
      .reverse()
      .map(result => ({...result, participation: this.participationById(result.participationId)}));
  }

  get previewResults() {
    return this.results.slice(0, 3);
  }

  participationById(id) {
    return this.event?.participations?.find(p => p.participationId == id);
  }

  get priority() {
    return 1;
  }

  get icon() {
    return "mdi-controller-variant";
  }

  get expanded() {
    return this.show || this.priority > 2;
  }

  async loadData() {
    this.loading = true;

    Promise.all([
      // lol
    ]).finally(() => {
      this.loading = false;
    });
  }

  private timer: ReturnType<typeof setInterval> | null = null;
  private ticker = 0;

  mounted() {
    this.timer = setInterval(this.tick, 1000);

    this.loadData();
  }

  unmounted() {
    const timer = this.timer;
    if (timer)
      clearInterval(timer);
  }

  tick() {
    this.ticker += 1;
  }
}
