





























import { Component, Vue, FormLabel } from "@components/common";

@Component({components: {FormLabel}})
export default class BrowseEvents extends Vue {

private eventTypes: Array<{
  label: string;
  icon: string;
  link: string;
}> = [
  {
    label: "Leagues",
    icon: "$icn-league",
    link: "/events/leagues"
  },
  {
    label: "Tournaments",
    icon: "$icn-tournament",
    link: "/events/tournaments"
  },
  {
    label: "Cups",
    icon: "$icn-cup",
    link: "/events/cups"
  },

  {
    label: "Players",
    icon: "$icn-player",
    link: "/players"
  },

  {
    label: "Active Events",
    icon: "$icn-streaming-accounts",
    link: "/events/active"
  },
  {
    label: "Past Events",
    icon: "$icn-games",
    link: "/events/past"
  }
];

}
