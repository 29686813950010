



















import { Component, Vue, Prop } from "@components/common";

@Component
export default class ActionBtn extends Vue {
  @Prop({ default: '' }) readonly icon!: string;
  @Prop({ type: Boolean }) primary!: boolean;
  @Prop({ type: Boolean }) secondary?: boolean;
  @Prop({ default: false }) disabled!: boolean;
}
