import Event from "@graphql/types/event";
export default class EventHelpers {
  constructor(private event: Event) {}

  get shouldShowStandings() {
    return this.hasGroups || this.hasDivisions;
  }

  get hasPlayoffs() {
    return (
      (this.event?.playoffsGroups?.length ?? 0) > 0 ||
      this.hasFlowStageCategory("playoffs")
    );
  }

  get hasBattleRoyale() {
    return this.hasFlowStageCategory("battleroyale");
  }

  get hasRounds() {
    return (this.event?.playDays?.length ?? 0) > 0 || this.hasBattleRoyale;
  }

  get hasQualifications() {
    return this.hasFlowStageCategory("qualification");
  }

  get hasGroups() {
    // In a perfect situation, we'd only be checking the flow stages
    return (
      (this.event?.groups?.length ?? 0) > 0 ||
      this.hasFlowStageCategory("groups")
    );
  }

  get hasDivisions() {
    return (
      (this.event?.divisions?.length ?? 0) > 0 ||
      this.hasFlowStageCategory("divisions")
    );
  }

  hasFlowStageCategory(part) {
    return this.event.eventFlow?.eventFlowStages?.some((efs) => {
      const [_, category, type] = efs?.flowType?.split("::") ?? [];

      return category.toLowerCase().includes(part);
    });
  }

  hasFlowStage(name) {
    return this.event.eventFlow?.eventFlowStages?.some(
      (efs) => efs.flowType === name
    );
  }
}
