import gql from "graphql-tag";
import { TeamFields } from "@graphql/types/team";
import { UserFields } from "@graphql/types/user";

export const FindTeamWithDetails = gql`
  query FindTeamWithDetails($id : ID!) {
    team(id: $id) {
      ...TeamFields
      captain {
        ...UserFields
      }
      members {
        ...UserFields
      }
    }
  }
  ${TeamFields}
  ${UserFields}
`;

export const FindTeam = gql`
  query FindTeam($id : ID!) {
    team(id: $id) {
      ...TeamFields
    }
  } 
  ${TeamFields}
`;

export default FindTeam;
