





























































import { Page, Component, Layout } from "@components/common";

import Event from "@graphql/types/event";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";
import Filters from "@components/events/filters.vue";
import EventCard from "@components/events/event_card.vue";
import { ListFilteredEvents } from "@graphql/queries/events";
import { eventLive } from "@/store/filters";
import { chain } from "underscore";

@Component({
  components: { EventCard, Layout, Filters },
  apollo: {
    liveEvents: {
      query: ListFilteredEvents,
      variables() { return {tournamentFilters: this.filters, eventFilters: this.filters}; },
      result() { this.isUpdating = false; this.isUpdatingSearch = false;},
      update: data => data
    },
  }
})
export default class LiveStandings extends Page {
  private isUpdating = true;
  private isUpdatingSearch = true;
  private activeSearch = false;
  private liveEvents = { tournaments: [], events: [] }

  public filters = eventLive;

  eventCategoryRoute(event: Event) {
    return new EventCategoryHelpers(event).routeName;
  }

  markUpdating() {
    this.activeSearch = true;
    this.isUpdatingSearch = true;
  }

  get events() {
    return chain(this.liveEvents.tournaments)
      .union(this.liveEvents.events)
      .sortBy(event => event.startTime)
      .value();
  }

  get hasAnyEvents() {
    if (this.activeSearch) return true;
    return this.events.length > 0 ? true : false;
  }

  async refresh() {
    this.$apollo.queries.liveEvents.refresh();
  }
}
