















import { Page, Component, Layout, FormLabel, Divider } from "@components/common";
import TeamForm from "@components/teams/form.vue";
import Team from "@graphql/types/team";

@Component({ components: { Layout, FormLabel, Divider, TeamForm }} )
export default class CreateNewTeam extends Page {
  private createdTeam = new Team;
}
