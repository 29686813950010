import { DetailedEventFields } from "@/graphql/types/event";
import gql from "graphql-tag";
import { EventFields } from "@graphql/types/event";
import { MatchBaseFields } from "@graphql/types/match";
import { MatchResultFields } from "@graphql/types/match_result";
import { MatchCheckinFields } from "@graphql/types/checkin";


export const GetEvent = gql`
  query GetEvent($id: ID!) {
    event(id: $id) {
      ...EventFields
    }
  }
  ${EventFields}
`;

export const GetEventWithDetails = gql`
  query GetEventWithDetails($id: ID!) {
    event(id: $id) {
      ...DetailedEventFields
    }
  }
  ${DetailedEventFields}
`;

export const GetEventWithMatches = gql`
  query GetEventWithMatches($id: ID!) {
    event(id: $id) {
      ...DetailedEventFields
      matches {
        ...MatchBaseFields
        result {
          ...MatchResultFields
        }
        checkins {
          ...MatchCheckinFields
        }
      }
    }
  }
  ${MatchBaseFields}
  ${DetailedEventFields}
  ${MatchResultFields}
  ${MatchCheckinFields}
`;
