













































































import { Component, Vue, Prop, Divider } from "@components/common";
import { FormattedTime } from "@components/common/helpers";

import User from "@graphql/types/user";

@Component({
  components: { FormattedTime, Divider },
})

export default class PlayerCard extends Vue {
  @Prop() readonly player!: User;
}

