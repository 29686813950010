import "reflect-metadata";
import gql from "graphql-tag";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";
import { store } from "@/store";
import eventBus from "@/event_bus";
import { isArray } from "underscore";

export const SELECTABLES_KEY = Symbol("_gqlSelectables");

export function getSelectables(target) {
  const selectables = Reflect.getMetadata(SELECTABLES_KEY, target);

  if (typeof selectables === "undefined" && !!target.prototype) {
    return getSelectables(target.prototype);
  }

  return selectables;
}

// TODO: Broken for more than one level of nesting
// Do we even want to allow that? Queries get complex and slow.
export function getFullSelection(...selections) {
  return selections
    .flatMap((selection) => {
      if (typeof selection === "string") return selection;

      if (isArray(selection)) return selection.map((s) => getFullSelection(s));

      if (typeof selection === "object") {
        return Object.entries(selection).map(([key, val]) => {
          const selectables = getSelectables(val);
          if (selectables) return `${key} { ${selectables.join(" ")} }`;
          else return getFullSelection(val);
        });
      }
    })
    .filter(Boolean);
}

export function selectable(selection?: any) {
  return (target: any | any, name: PropertyKey): any => {
    const selectables: string[] = getSelectables(target);

    const fullSelection = selection
      ? getFullSelection(selection)
      : name.toString();

    if (selectables) {
      selectables.push(fullSelection);
    } else {
      Reflect.defineMetadata(SELECTABLES_KEY, [fullSelection], target);
    }
  };
}

export default function createStore({
  name,
  pluralName = undefined,
  recordType,
}: {
  name: string;
  pluralName?: string;
  recordType: any;
}) {
  type RecordType = typeof recordType;

  class WrappedRecord {
    constructor(
      public record: RecordType,
      public selections: string[],
      public loadedAt: Date
    ) {}

    get age() {
      return new Date().getTime() - this.loadedAt.getTime();
    }
  }

  function hasAllSelections(
    wrappedRecord: WrappedRecord,
    requiredSelections: string[]
  ) {
    return requiredSelections.every((s) =>
      wrappedRecord.selections.includes(s)
    );
  }

  const recordName = name;
  const pluralRecordName = pluralName ?? `${name}s`;
  const capitalizedRecordName =
    recordName.charAt(0).toUpperCase() + recordName.slice(1);

  const capitalizedPluralRecordName =
    pluralRecordName.charAt(0).toUpperCase() + pluralRecordName.slice(1);

  @Module({ namespaced: true })
  class GenericStore extends VuexModule {
    protected records: Record<number, WrappedRecord> = {};

    protected readonly recordName = recordName;
    protected readonly pluralRecordName = pluralRecordName;
    protected readonly capitalizedRecordName = capitalizedRecordName;
    protected readonly capitalizedPluralRecordName =
      capitalizedPluralRecordName;

    constructor(params) {
      super(params);
    }

    @Action({ rawError: true })
    async find({
      id,
      selection = getSelectables(recordType),
      extraSelection = [],
      maxAge = 3600,
    }) {
      const fullSelection = getFullSelection(selection, extraSelection);

      const cachedRecord = this.records[id];
      if (
        cachedRecord &&
        cachedRecord.age <= maxAge * 1000 &&
        hasAllSelections(cachedRecord, fullSelection)
      ) {
        console.log("Returning from store's cache");
        return cachedRecord.record;
      }

      const { data } = await apollo.query({
        query: gql`
          query GenericFind_${capitalizedRecordName}($id : ID!) {
            ${recordName}(id: $id) {
              ${fullSelection.join("\n")}
            }
          }`,
        variables: { id },
      });

      this.context.commit("commitRecords", {
        records: [data[recordName]],
        selection: fullSelection,
      });

      return this.records[data[recordName].id].record;
    }

    @Action({ rawError: true })
    updateCache(record: RecordType) {
      eventBus.$emit(`store:update`, {
        name: recordName,
        store: pluralRecordName,
        record: record,
      });
    }

    @Action({ rawError: true })
    updateRecord(record: RecordType) {
      console.log(`Updating store for ${recordName} with:`, record);

      this.context.commit("commitRecords", {
        records: [record],
        selection: getSelectables(recordType),
      });
    }

    @Action({ rawError: true })
    async fetch({
      id,
      selection = getSelectables(recordType),
      extraSelection = [],
    }) {
      const fullSelection = getFullSelection(selection, extraSelection);

      const { data } = await apollo.query({
        fetchPolicy: "no-cache",
        query: gql`
          query GenericFetch_${capitalizedRecordName}($id : ID!) {
            ${recordName}(id: $id) {
              ${fullSelection.join("\n")}
            }
          }`,
        variables: { id },
      });

      this.context.commit("commitRecords", {
        records: [data[recordName]],
        selection: fullSelection,
      });

      return this.records[data[recordName].id].record;
    }

    @Action({ rawError: true })
    async search({
      filters,
      selection = getSelectables(recordType),
      extraSelection = [],
      page = 1,
      limit = 50,
      noCache = false,
    }) {
      const fullSelection = getFullSelection(selection, extraSelection);

      const { data } = await apollo.query({
        query: gql`
          query GenericSearch_${recordName}(
            $filters : ${capitalizedPluralRecordName}FilterInput
            $page : Int
            $limit : Int
          ) {
            ${pluralRecordName}(filters: $filters, page : $page, limit : $limit) {
              ${fullSelection.join("\n")}
            }
          }`,
        variables: { filters, page, limit },
        fetchPolicy: noCache ? "no-cache" : undefined,
      });

      const records = data[pluralRecordName];
      const ids = records.map((r) => r.id);

      this.context.commit("commitRecords", {
        records: data[pluralRecordName],
        selection: fullSelection,
      });

      return ids.map((id) => this.records[id].record);
    }

    @Mutation
    commitRecords({ records, selection }) {
      records.forEach((r) => {
        this.records[r.id] = new WrappedRecord(
          new recordType(r),
          selection,
          new Date()
        );
      });
    }

    get recordSelectables() {
      return getSelectables(recordType);
    }
  }

  return GenericStore;
}
