






































import { Component, Vue, Prop } from "@components/common";

@Component
export default class FormLabel extends Vue {
  @Prop({ default: ''}) readonly tooltip!: string
}
