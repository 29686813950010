import { Vue } from "@components/common";
import App from "./App.vue";

import Notifications from "vue-notification";
import VueDebounce from "vue-debounce";
import VueGtag from "vue-gtag";
import HotJar from "vue-hotjar";
import Appsignal from "@appsignal/javascript";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueProgressBar from "vue-progressbar";
import { store } from "@store";
import { errorHandler } from "@appsignal/vue";
import router from "./router";

const isProduction = process.env.NODE_ENV == "production";

const appsignal = new Appsignal({
  key: process.env.VUE_APP_APPSIGNAL_API_KEY
});

const progressBarOptions = {
  color: "#6240e6",
  failedColor: "#d61132",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

Vue.config.devtools = process.env.VUE_APP_ALLOW_DEVTOOLS == "true";
if (isProduction) Vue.config.errorHandler = errorHandler(appsignal, Vue);

Vue.use(Notifications);
Vue.use(VueDebounce, {
  defaultTime: "500ms"
});

Vue.use(HotJar, {
  id: process.env.VUE_APP_HOTJAR_CODE,
  isProduction: isProduction
});

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_CODE }
});

Vue.use(VueSmoothScroll, {
  duration: 600,
  updateHistory: false
});

Vue.use(VueProgressBar, progressBarOptions);

import "./registerServiceWorker";
import { createProvider } from "@lib/graphql";
import { EventBusMixin } from "@/event_bus";
import "./lib/luxon.ts";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";

import AuthenticationMixin from "@/mixins/authentication";
import AppPropertiesMixin from "@/mixins/app_properties";

import "./styles/style.scss";

Vue.config.productionTip = false;

Vue.mixin(AuthenticationMixin);
Vue.mixin(EventBusMixin);
Vue.mixin(AppPropertiesMixin);

new Vue({
  router,
  apolloProvider: createProvider(),
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
