








































































































import { Component, Vue, Prop } from "@components/common";
import Event from "@graphql/types/event";
import MatchCard from "@components/matches/match_card.vue";
import { sortBy, groupBy, union } from "underscore";
import { DateTime } from "luxon";
import Match from "@graphql/types/match";
import { namespace } from "@/store";
import { GetMatches } from "@graphql/queries/matches";

const AuthenticationState = namespace("authentication");

@Component({
  components: {MatchCard},
  apollo: {
    matches: {
      query: GetMatches,
      variables() { return { filters: { eventId: Number(this.event.id), eventType: "Event" }}; },
      result() { this.loading = false; },
    },
  }
})
export default class GroupMatches extends Vue {
  @Prop() readonly event!: Event;

  private matches: Match[] = [];
  private tab = null
  private filterMyMatches = true;

  @AuthenticationState.State
  private teamRoles;

  get teamIds () {
    return (this.teamRoles ?? []).map(id => id.teamId);
  }

  get participants() {
    if (this.event.isUserBased)
      return this.event.participatingUsers;
    else
      return this.event.participatingTeams;
  }

  private noDates(dates) {
    return Object.keys(dates).length;
  }

  get matchDates() {
    const formatedMatches = this.scheduledMatches.map((m) => ({
      ...m,
      groupDate: DateTime.fromISO(m.startTime).toLocaleString(DateTime.DATE_MED)
    }));

    const groupByDate = groupBy(sortBy(formatedMatches, 'startTime'), "groupDate");

    return groupByDate;
  }

  get pastMatchesDates() {
    const formatedMatches = this.pastMatches.map((m) => ({
      ...m,
      groupDate: DateTime.fromISO(m.startTime).toLocaleString(DateTime.DATE_MED)
    }));

    const groupByDate = groupBy(sortBy(formatedMatches, 'startTime').reverse(), "groupDate");
    return groupByDate;
  }

  get scheduledMatches() {
    return sortBy(this.filteredMatches?.filter(m => m.isStartTimeConfirmed && !m.result));
  }

  get hasPendingMatches() {
    return this.pendingMatches.length > 0 ? true : false;
  }

  get pendingMatches() {
    return this.filteredMatches?.filter(m=> !m.isStartTimeConfirmed && !m.result);
  }

  get pastMatches() {
    return this.filteredMatches?.filter(m=> m.result);
  }

  // just group matches need to be here
  get filteredMatches() {
    if (this.filterMyMatches) return sortBy(this.myMatches, "group");
    return sortBy(this.allMatches,"group");
  }

  private combineGroupedMatches(matches: Match[]) {
    const toGroupMatches = matches.filter(
      m => m.grouped === true && !m.groupedMatchIds
    );
    const noGroupedMatches = matches.filter(m => m.grouped !== true);

    const groupedMatches = (Object.values(
      groupBy(toGroupMatches, "identifier")
    ) as Match[][]).map(matches => ({
      ...matches[0],
      groupedMatchIds: matches.map(m => m.id),
      groupedResults: matches.map(m => m.result)
    }));

    return union(noGroupedMatches, groupedMatches);
  }

  get myMatches() {
    return (this.allMatches ?? []).filter(match =>
      match.participations?.some(p => p.id == this.currentUserId || this.teamIds.includes(p.id ?? 0))
    );
  }

  get allMatches() {
    return this.combineGroupedMatches(this.groupMatches);
  }

  get groupMatches() {
    return this.matches?.filter(m=> m.bracket === "group");
  }


  toggleMyMatchesFilter() {
    this.filterMyMatches = !this.filterMyMatches;
  }
}
