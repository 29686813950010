



























import { Vue, Component, Prop } from "@components/common";
import Event from "@graphql/types/event";
import Chatroom from "@graphql/types/chatroom";
import Talk from "talkjs";

@Component
export default class EventChat extends Vue {
  @Prop() readonly event!: Event;
  @Prop({default: false}) isParticipant!: boolean;

  private me:          Talk.User | null = null;
  private talkSession: Talk.Session | null = null;
  private chatbox:     Talk.Chatbox | null = null;
  private chatroom:    Chatroom | null = null;

  get canJoin() {
    const chatroom = this.chatroom;

    if (!chatroom)
      return false;

    if (this.isParticipant)
      return !chatroom.isParticipant;

    return false;
  }

  mounted() {
    this.initializeChat();
    this.loadChatroom().then(this.openChat);
  }

  openChat() {
    const chatroom = this.chatroom;

    if (!chatroom)
      return;

    if (chatroom.isParticipant)
      this.openChatbox();
    else
      this.openReadonlyChatbox();
  }

  async joinChatroom() {
    const result = await this.$store.dispatch("chatrooms/joinEvent", {eventId: this.event.id});

    if (result.success) {
      const chatroom = this.chatroom;
      if (chatroom) chatroom.isParticipant = true;

      this.openChatbox();
    }
  }

  async initializeChat() {
    this.me = null;
    this.talkSession = null;

    const uuid = this.currentUser?.uuid;
    const name = this.currentUser?.name;

    if (!uuid || !name)
      return;

    const me = this.me = new Talk.User({id: uuid, name: name});

    this.talkSession = new Talk.Session({
      appId: process.env.VUE_APP_TALKJS_APP_ID as string,
      me: me
    });

    this.talkSession.setDesktopNotificationEnabled(true, { alertOnFailure: false });

    //this.$nextTick(this.openChatbox);

    await this.$nextTick();
  }

  openReadonlyChatbox() {
    const appId = process.env.VUE_APP_TALKJS_APP_ID as string;
    const chatroom = this.chatroom;

    if (!chatroom)
      return;

    const chatroomUrl = `https://talkjs.com/embed/${appId}/${chatroom.talkjsConversationId}?auth_token=${chatroom.authToken}`;

    const el = document.createElement("iframe");
    el.setAttribute("src", chatroomUrl);
    el.setAttribute("style", "width: 100%; height: 100%; border: 0;");

    const container = this.$refs.chatContainer as HTMLElement;

    this.clearChatContainer();
    container.appendChild(el);
  }

  clearChatContainer(): void {
    const container = this.$refs.chatContainer as HTMLElement;

    if (!container)
      return;

    container.innerHTML = "";
  }

  async loadChatroom() {
    this.chatroom = await this.$store.dispatch("chatrooms/find", {
      id: this.event.chatroomId
    });
  }

  openChatbox() {
    const { talkSession: session, me, event, chatroom } = this;

    if (!session || !me || !event?.conversationId || !chatroom)
      return;

    const conversation = session.getOrCreateConversation(chatroom.talkjsConversationId as string);
    const chatbox = session.createChatbox(conversation, {
      showChatHeader: false,
    });

    this.clearChatContainer();

    this.chatbox = chatbox;
    this.chatbox.mount(this.$refs.chatContainer as HTMLElement);
  }
}
