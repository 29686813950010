import gql from "graphql-tag";

import { ConversationFields } from "@graphql/types/conversation";

export const ListMyConversations = gql`
  query ListMyConversations {
    myConversations {
      ...ConversationFields
    }
  }
  ${ConversationFields}
`;
