





































































import { Component, Vue, Prop } from "@components/common";

@Component
export default class PodiumBadge extends Vue {
  @Prop({ default: 'winner'} ) readonly place!: string;
  @Prop({ default: '1'} ) readonly symbol!: string;
  @Prop({ default: null} ) readonly avatar!: string;
  @Prop({ default: null} ) readonly to!: string;
}
