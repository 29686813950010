











import { Component, Page } from "@components/common";
import TeamInvitation from "@graphql/types/team_invitation";
import { ListMyReceivedInvitations } from "@graphql/queries/team_invitations";
import AcceptInvitationButton from "./invitations/dialog.vue";

@Component({
  components: { AcceptInvitationButton },
  apollo: {
    invitations: {
      query: ListMyReceivedInvitations,
      update: data => data.myReceivedInvitations
    },
  }
})
export default class Invitations extends Page {
  private invitations: TeamInvitation[] = [];

  get hasAnyInvitations() {
    return this.invitations?.length > 0;
  }

  async confirm(invitation) {
    const result = await this.$store.dispatch(
      "users/acceptTeamInvitation",
      {invitationId: invitation.id}
    );

    if (result.success)
      this.$notify({text: `You have joined <b>${invitation.team.name}</b>.`});
    else
      this.$notify({text: "Something went wrong.", type: "error"});
  }

  async acceptInvitation(invitation) {
    const result = await this.$store.dispatch("me/acceptInvitation", {invitationId: invitation.id});

    if (result.success) {
      this.$notify({text: "Succesfully joined team."});
      this.$apollo.queries.invitations.refresh();
    }
  }
}
