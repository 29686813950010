

















































































































































































































































































import { Component, Prop, Vue, Divider, FormLabel, Watch } from "@components/common";
import { DateTime } from "luxon";
import { Datetime } from 'vue-datetime';
import { sortBy} from "underscore";
import { FormattedTime } from "@components/common/helpers";
import ChatButton from "@components/chat/chat_button.vue";
import Match from "@graphql/types/match";
import User from "@graphql/types/user";
import Team from "@graphql/types/team";
import Event from "@graphql/types/event";
import Division from "@graphql/types/division";

import CardDetailHeader from "@components/tournaments/card_detail_header.vue";

import 'vue-datetime/dist/vue-datetime.css';

@Component({
  components: {
    CardDetailHeader,
    Divider,
    FormLabel,
    FormattedTime,
    ChatButton,
    Datetime
  }
})
export default class ScheduleMatch extends Vue {
  @Prop(Object) readonly event!: Event;
  @Prop(Object) readonly myMatch!: Match;
  @Prop() readonly isPendingStart!: boolean;
  @Prop({default:false}) justButton!: boolean;
  private showDialog = false;

  private startTime = ''

  // "id" is here on purpose. Changes shouldn't happen unless it's actually a
  // different match to avoid overwriting the user's input if the data
  // refreshes during entry
  @Watch("myMatch.id", {immediate: true})
  setExistingTimes() {
    this.startTime = '';
  }

  async scheduleMatch() {
    const match = this.myMatch;
    // const time = this.formattedEnteredDatetime;
    const time = this.startTime;

    if (!time)
      return;

    let mutation = "matches/proposeNewTime";
    let variables = {};

    variables = { matchId: match.id };
    if (match.grouped) {
      mutation = "matches/proposeNewTimes";
      variables = { matchIds: match.groupedMatchIds };
    }

    const result = await this.$store.dispatch(mutation, {
      ...variables,
      time
    });

    if (result.success) {
      this.trackAnalytics("schedule");
      this.refreshEvent();
      const key = this.isConfirmed ?
        "match.match_rescheduled" :
        "match.match_proposed";

      this.$notify({text: this.$t(key, {opponentName: this.opponent?.name}).toString()});
      this.showDialog = false;
      this.$store.dispatch("myMatches/reload");
    }
  }

  async confirmMatchStartTime() {
    const match = this.myMatch;
    const time = this.myMatch.proposedStartTime;

    let mutation = "matches/confirmStartTime";
    let variables = {};

    variables = { matchId: match.id };
    if (match.grouped) {
      mutation = "matches/confirmStartTimes";
      variables = { matchIds: match.groupedMatchIds };
    }

    const result = await this.$store.dispatch(mutation, {
      ...variables,
      time
    });

    if (result.success) {
      this.trackAnalytics("confirm");
      this.refreshEvent();
      const key = "match.match_start_time_confirmed";
      this.$notify({text: this.$t(key, {opponentName: this.opponent?.name}).toString()});
      this.showDialog = false;
      this.$store.dispatch("myMatches/reload");
    }
  }

  private trackAnalytics(type) {
    const TrackingInfo = {label:"", action: ""};

    if (type === 'confirm') {
      this.$gtag.event('match_accept_time', {
        'event_category': 'match_action',
        'event_label': 'Accept match time',
        'value': { userId: this.currentUserId, matchId: this.myMatch.id}
      });
    } else {
      if (this.isPendingSchedule) {
        TrackingInfo.label = "Propose match time";
        TrackingInfo.action = "match_propose_time";
      }

      if (this.isPendingMyConfirmation) {
        TrackingInfo.label = "Reject match time";
        TrackingInfo.action = "match_reject_time";
      }

      if (this.isPendingStart) {
        TrackingInfo.label = "Reschedule match time";
        TrackingInfo.action = "match_reschedule_time";
      }

      this.$gtag.event(TrackingInfo.action, {
        'event_category': 'match_action',
        'event_label': TrackingInfo.label,
        'value': { userId: this.currentUserId, matchId: this.myMatch.id}
      });
    }
  }

  get opponentAvatar() {
    return this.opponent?.avatar?.url ? this.opponent?.avatar?.url : '';
  }

  get gameId() {
    return this.event.gameId || -1;
  }

  get gameName() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }

  get game() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }

  get matchAgainstSubText() {
    if (this.isGroupMatch) return this.$t('match.group_match_against', {grouptag: this.groupTag, matchtag: this.matchTag, opponent: this.opponentName});
    if (this.isDivisionMatch) return this.$t('match.divisions.match_against', {divisionNumber: this.divisionNumber, divisionLevel: this.divisionLevel, divisionLevelName: this.divisionlevelName, opponent: this.opponentName});
    return this.$t('match.playoffs.match_against', {playoffTag: this.playoffTag, opponent: this.opponentName});
  }

  get scheduleMatchButtonText() {
    if (this.justButton) return this.$t("match.schedule_match_my_matches");
    if (this.isGroupMatch)
      return this.$t('match.schedule_group_match', {grouptag: this.groupTag, matchtag: this.matchTag});
    if (this.isPlayoffMatch)
      return this.$t('match.schedule_playoff_match', {playofftag: this.playoffTag});
    if (this.isDivisionMatch)
      return this.$t('match.schedule_division_match');
    return this.$t("match.schedule_match_my_matches");
  }

  get isMatchTimeLocked() {
    return !!this.myMatch.startTimeLocked;
  }

  get matchDivision() {
    return this.isDivisionMatch ? this.event.divisions?.find(d => d.id == this.myMatch.divisionId) : new Division;
  }

  get divisionLevel() {
    return this.matchDivision?.level!== undefined? this.matchDivision?.level + 1 : null;
  }

  get divisionNumber() {
    return this.matchDivision?.position !== undefined? this.matchDivision?.position + 1 : null;
  }

  get divisionlevelName() {
    return this.$t(`league.division.name.level_${this.divisionLevel}`, {divisionNumber: this.divisionNumber, gameName: this.gameName}).toString();
  }

  get pendingOpponentConfirmationTooltip() {
    if (this.isGroupMatch)
      return this.$t(`match.schedule_waiting_opponent_confirmation_tooltip`, {opponentName: this.opponentName, matchTag: this.matchTag, groupTag: this.groupTag, proposedTime: this.formattedProposedStartTime}).toString();
    if (this.isDivisionMatch)
      return this.$t(`match.divisions.schedule_waiting_opponent_confirmation_tooltip`, {opponentName: this.opponentName, divisionLevel: this.divisionLevel, divisionNumber: this.divisionNumber, divisionLevelName: this.divisionlevelName, proposedTime: this.formattedProposedStartTime}).toString();
    if (this.isPlayoffMatch)
      return this.$t('match.playoffs.schedule_waiting_opponent_confirmation_tooltip', {matchstarttime: this.formattedProposedStartTime, playofftag: this.playoffTag, opponentName: this.opponentName}).toString();
    return "";
  }

  get pendingMyConfirmationTooltip() {
    if (this.isGroupMatch)
      return this.$t(`match.schedule_waiting_my_confirmation_tooltip`, {playername: this.opponentName, matchTag: this.matchTag, groupTag: this.groupTag, proposedTime: this.formattedProposedStartTime});
    if (this.isPlayoffMatch)
      return this.$t('match.playoffs.schedule_waiting_my_confirmation_tooltip', {gameName: this.gameName, proposedTime: this.formattedProposedStartTime, playofftag: this.playoffTag, playername: this.opponentName});
    if (this.isDivisionMatch)
      return this.$t('match.divisions.schedule_waiting_my_confirmation_tooltip', {gameName: this.gameName, proposedTime: this.formattedProposedStartTime, divisionLevel: this.divisionLevel, divisionNumber: this.divisionNumber, divisionLevelName: this.divisionlevelName, opponentName: this.opponentName});
    return "";
  }

  get pendingMatchButtonText() {
    if (this.isGroupMatch)
      return this.$t("match.group_match_check_in_available_in",{matchstarttime: this.formattedMatchStartTime, grouptag: this.groupTag, matchtag: this.matchTag});
    if (this.isPlayoffMatch)
      return this.$t('match.playoffs.match_check_in_available_in', {matchstarttime: this.formattedMatchStartTime, playofftag: this.playoffTag, opponentName: this.opponentName});
    if (this.isDivisionMatch)
      return this.$t('match.divisions.match_check_in_available_in', {matchstarttime: this.formattedMatchStartTime, divisionLevel: this.divisionLevel, divisionNumber: this.divisionNumber, divisionLevelName: this.divisionlevelName, opponentName: this.opponentName});
    return "";
  }

  get pendingMatchSubText() {
    if (this.isGroupMatch)
      return this.$t("match.group_match_start_at",{matchstarttime: this.formattedMatchStartTime, grouptag: this.groupTag, matchtag: this.matchTag});
    if (this.isPlayoffMatch)
      return this.$t('match.playoffs.match_start_at_sub_text', {matchstarttime: this.formattedMatchStartTime, playofftag: this.playoffTag, opponentName: this.opponentName});
    if (this.isDivisionMatch)
      return this.$t('match.divisions.match_start_at_sub_text', {matchstarttime: this.formattedMatchStartTime, divisionLevel: this.divisionLevel, divisionNumber: this.divisionNumber, divisionLevelName: this.divisionlevelName, opponentName: this.opponentName});
    return "";
  }

  get scheduleMatchNoticeText() {
    if (this.isPlayoffMatch)
      return this.$t('match.schedule_playoff_match_notice', {playofftag: this.playoffTag}).toString();
    if (this.isDivisionMatch)
      return this.$t('match.divisions.schedule_division_match_notice', {matchstarttime: this.formattedMatchStartTime, divisionLevel: this.divisionLevel, divisionNumber: this.divisionNumber, divisionLevelName: this.divisionlevelName, opponentName: this.opponentName});
    if (this.isGroupMatch)
      return this.$t('match.schedule_group_match_notice', {grouptag: this.groupTag, matchtag: this.matchTag}).toString();
    else
      return this.$t('match.schedule_match_notice', {opponentName: this.opponentName});
  }

  get playoffTag () {
    const noStages = this.event.lastEliminationStageNumber? this.event.lastEliminationStageNumber : 0;
    const currentStage = this.myMatch.stage? this.myMatch.stage : 0;
    const power = noStages - currentStage+1;

    const stage = 2**power;

    switch (stage) {
      case 2:
        return this.$t("match.finale").toString();
      case 4:
        return this.$t("match.semi_finale").toString();
      case 8:
        return this.$t("match.quater_finale").toString();
      default:
        return this.$t("match.round_of", {round: stage, roundMatches: stage/2}).toString();
    }
  }

  get groupTag() {
    if (this.myMatch.group === undefined) return "N/A";
    return this.myMatch.group + 1;
  }

  get matchTag() {
    if (this.myMatch.index === undefined) return "N/A";
    return this.myMatch.index + 1;
  }

  get opponentName() {
    return this.opponent?.name;
  }

  get opponentPreferedTime() {
    return this.opponent?.preferredGameTime;
  }

  get isScheduleDateMissed() {
    return !this.isConfirmed && this.myMatch.proposedStartTime === null && !this.isProposedByMe && !this.isPendingStart;
  }

  get matchMaxDuration() {
    return this.event.matchDuration ? this.event.matchDuration : 0;
  }
  get isGroupMatch() {
    return (this.myMatch.bracket === 'group' && !this.isDivisionMatch)? true : false;
  }

  get isPlayoffMatch() {
    return !this.isGroupMatch && !this.isDivisionMatch && !this.isPlayoffsGroupMatch;
  }

  get isDivisionMatch() {
    return !!this.myMatch.divisionId;
  }

  get isPlayoffsGroupMatch() {
    return !!this.myMatch.playoffsGroupId;
  }

  get isQualifications() {
    if (this.isPlayoffs) return false;
    if (this.event.qualificationEndTime === undefined) {
      return false;
    } else if ((DateTime.fromISO(this.event.qualificationEndTime) > DateTime.local())) {
      return true;
    }
    return false;
  }

  get isPlayoffs() {
    if ((DateTime.fromISO(this.event.playoffStartTime) < DateTime.local()) && this.myMatch.bracket !== 'group') {
      return true;
    }
    return false;
  }

  get roundEndTime() {
    if (this.isPlayoffs) return this.currentStage?.endTime;
    return this.isQualifications ? this.event.qualificationEndTime : this.event.endTime;
  }

  get scheduleMaxTime() {
    return DateTime.fromISO(this.roundEndTime).minus({seconds: this.matchMaxDuration}).toISO();
  }

  get scheduleMinTime() {
    return DateTime.local().plus({seconds: this.matchMaxDuration}).toISO();
  }

  get currentStage() {
    if (!this.isPlayoffs) return null;
    return this.event.stages?.find(o => o.stageNumber === this.myMatch.stage);
  }

  get isPendingSchedule() {
    return !this.isConfirmed && this.myMatch.proposedStartTime === null && !this.isProposedByMe && !this.isPendingStart && this.hasParticipantsAssigned;
  }

  get hasParticipantsAssigned() {
    return this.myMatch.participationsAssigned;
  }

  get isProposedByMe() {
    return this.currentUserId?.toString() === this.myMatch.startTimeProposedById?.toString();
  }

  get isPendingOpponentConfirmation() {
    return this.isProposedByMe && !this.isConfirmed && !this.isPendingStart;
  }

  get isPendingMyConfirmation() {
    return !this.isProposedByMe && !this.isPendingSchedule && !this.isConfirmed && !this.isPendingStart && this.hasParticipantsAssigned;
  }

  get isConfirmed() {
    return this.myMatch?.isStartTimeConfirmed;
  }

  get hasStarted() {
    return DateTime.fromISO(this.myMatch.startTime) < DateTime.local();
  }

  get canReschedule() {
    return this.isConfirmed && !this.hasStarted;
  }

  get formattedProposedStartTime() {
    return DateTime.fromISO(this.myMatch.proposedStartTime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get matchIndex() {
    if (this.myMatch?.index === undefined) return 0;
    return this.myMatch?.index + 1;
  }

  get formattedMatchStartTime() {
    return DateTime.fromISO(this.myMatch.startTime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get isUserBased() {
    return this.event?.mode === "1v1";
  }

  get currentParticipantId() {
    if (this.isUserBased) return this.currentUserId;
    return this.myParticipatingTeam?.id;
  }

  get myParticipatingTeam() {
    if (this.isTeamBased) {
      const myParticipatingTeams = this.event.participatingTeams?.filter(team => team.members?.find(member => member.id === this.currentUserId));

      if (myParticipatingTeams?.length === 1) return myParticipatingTeams[0];

      return myParticipatingTeams?.find(t => t.captain?.id == this.currentUserId);
    }

    return null;
  }

  get isParticipatingTeamCaptain() {
    if (this.isUserBased) return true;
    return this.myParticipatingTeam?.captain?.id === this.currentUserId;
  }

  get opponent() {
    const match = this.myMatch;
    if (!match)
      return {};

    const opponent = match.participations?.find(p => p.id != this.currentParticipantId);

    if (!opponent)
      return {name: "Nobody", preferredTime: "No", nickname: "Nobody", preferredGameTime: "No", avatar: { url: ''}};

    return this.participants.find(p => p.id == opponent.id);
  }

  get opponentUUID() {
    if(this.isUserBased) {
      const o = this.opponent as User;
      return o.uuid;
    } else {
      const t = this.opponent as Team;
      return t.captain?.uuid;
    }
  }

  get participants() {
    return [
      ...this.event.participatingUsers ?? [],
      ...this.event.participatingTeams ?? []
    ];
  }

  get currentMatchNumber() {
    const match = this.myMatch;
    const id = match?.id;

    if (id) {
      let count = 0;
      this.myCompletedMatches.forEach(m => {
        if (m.id && (m.id < id))
          count += 1;
      });

      return count + 1;
    } else
      return 1;

  }

  get myCompletedMatches() {
    return sortBy(this.myMatches.filter(m => !!m.result?.id),"index");
  }

  get myUncompletedMatches() {
    return sortBy(this.myMatches.filter(m => !m.result?.id),"index");
  }

  get myMatches() {
    return (this.event.matches ?? []).filter(match =>
      match.participations?.some(p => p.id == this.currentUserId)
    );
  }

  get minDatetime() {
    return this.scheduleMinTime;

    // This is not necessarily useful, as originalStartTime is often set to endTime - matchDuration
    //const now = DateTime.local() ;
    //let startTime = DateTime.fromISO(this.myMatch.originalStartTime);

    //if (now > startTime)
    //  startTime = now;

    //return startTime.toISO();
  }

  get maxDatetime() {
    return DateTime.fromISO(this.roundEndTime).minus({seconds: this.matchMaxDuration}).toISO();
  }

  openDialog() {
    this.showDialog = true;
  }

  refreshEvent() {
    this.$emit("refreshEvent");
  }
}
