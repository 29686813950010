
export function notEmpty(val) {
  return !!val && val.length > 0;
}

export function email(email) {
  const [addressee, domain] = email.split("@");

  // Does it make sense to verify this further?
  if (addressee.length < 1)
    return false;

  if (!domain)
    return false;

  if (!domain.includes("."))
    return false;

  if (domain.substr(-1) == ".")
    return false;

  return true;
}

export const emailRules = [
  notEmpty,
  email
];

export const passwordRules = [
  notEmpty,
  password => password.length >= 6
];

