


























































































































































































































































































































import { Component, Vue, Prop } from "@components/common";

import { union, isArray } from "underscore";
import { DateTime } from "luxon";

import Chatroom from "@graphql/types/chatroom";
import Event from "@graphql/types/event";
import User from "@graphql/types/user";
import ChatCard from "@components/chat/chat_card.vue";
import FormattedTime from "@components/common/formatted_time.vue";
import Heading from "@components/v2/Heading.vue";
import PlayerCard from "@components/v2/PlayerCard.vue";
import CommunityCard from "@components/v2/CommunityCard.vue";
import PodiumBadge from "@/components/events/common/podium_badge.vue";
import Podium from "@components/events/common/podium.vue";
import HScroller from "@components/v2/HScroller.vue";

const STAGES_ROLE_ORDER = [
  "signup",
  "qualification",
  "playoffs",
  "battle_royale"
];

@Component({
  components: {
    ChatCard,
    FormattedTime,
    Heading,
    PlayerCard,
    PodiumBadge,
    CommunityCard,
    Podium,
    HScroller
  }
})
export default class EventInformationTab extends Vue {
  @Prop() event!: Event;

  private loading = false;
  private administrators: User[] = [];
  private chatrooms: Chatroom[] = [];

  get information() {
    return this.event.information;
  }

  get eventPlatforms() {
    const event = this.event;
    const platformIds = event?.platformIds;

    if (!event || !platformIds)
      return [];

    return platformIds.map(id =>
      this.platformById(id)
    ).filter(Boolean);
  }

  get prizeLabels() {
    return [
      {place: "winner", symbol: "1"},
      {place: "second", symbol: "2"},
      {place: "third",  symbol: "3"},
      {place: "fourth", symbol: "4"},
      {place: "fifth",  symbol: "5"},
    ];
  }

  mounted() {
    this.loadData();
  }

  isSameDay(left, right) {
    const day1 = DateTime.fromISO(left);
    const day2 = DateTime.fromISO(right);

    return day1.year == day2.year && day1.ordinal == day2.ordinal;
  }

  async loadData() {
    this.loading = true;

    Promise.all([
      this.loadAdministrators(),
      this.loadChatrooms(),
    ]).finally(() => this.loading = false);
  }

  async loadAdministrators() {
    const event = this.event;

    if (!event)
      return;

    const adminIds = union(
      event.administratorUserIds ?? [],
      event.information?.shownEventAdminIds ?? []
    );

    if (adminIds.length == 0) {
      this.administrators = [];
      return;
    }

    this.administrators = await this.$store.dispatch("users/search", {
      filters: {ids: adminIds},
      extraSelection: "avatar { url }"
    });
  }

  async loadChatrooms() {
    const event = this.event;

    if (!event || !event.information?.showChatrooms)
      return;

    const chatroomIds = event.information.shownChatroomIds;

    this.chatrooms = await this.$store.dispatch("chatrooms/search", {filters: {ids: chatroomIds}});
  }

  stageByRole(role) {
    role = role.toUpperCase();
    const eventFlow = this.event?.eventFlow;

    if (!eventFlow)
      return null;

    return eventFlow?.eventFlowStages?.find(stage => stage.role == role);
  }

  get relevantFlowStages() {
    return STAGES_ROLE_ORDER
      .map(role => this.stageByRole(role))
      .filter(Boolean);
  }

  get signupStage() {
    return this.stageByRole("signup");
  }

  get qualificationStage() {
    return this.stageByRole("qualification");
  }

  isStageActive(stage) {
    return this.event?.eventFlow?.currentEventFlowStageId == stage.id;
  }

  isArray = isArray;

  // Podium implementation

  get hasFinalStandings() {
    return (this.event?.standings?.length ?? 0) > 0;
  }

  get topLevelStandings() {
    const playoffsGroups = this.event?.playoffsGroups;

    if (!playoffsGroups)
      return null;

    const topPlayoffs = playoffsGroups.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))[0];

    return this.event.standings?.filter(s => s.playoffsGroupId === topPlayoffs.id);
  }

  get participants() {
    return union(this.event.participatingTeams, this.event.participatingUsers);
  }

  private findParticipantById(id) {
    return this.participants.find(p => p.id == id);
  }

  private userPlace(place: number) {
      const emptyPlayer = {id: place, name: '', avatar: {url:''}};
      const player = this.topLevelStandings.find(ps => ps.position === place.toString());

      const playerId = player ? player.participantId : undefined;

      return playerId ? this.findParticipantById(playerId) : emptyPlayer;
  }
}
