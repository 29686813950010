

































































































































import { Page, Component, Layout, Divider } from "@components/common";
import User from "@graphql/types/user";
import { GetMyInfo } from "@graphql/queries/me";
import userPlaceholder from "@/assets/user-placeholder.svg";

@Component({
  components: { Layout, Divider },
  apollo: {
    user: {
      query: GetMyInfo,
      variables() { return {id: this.userId}; },
      update: data => data.me
    },
  }
})

export default class Settings extends Page {
  private user?: User = new User();

  private accountSettings = [
    { text: 'profile_settings.edit_profile', icon: '$icn-player',  url: '/profile/settings/edit' },
    { text: 'profile_settings.change_password', icon: '$icn-change-pass',  url: '/profile/settings/change-password' },
    { text: 'profile_settings.team_management', icon: '$icn-team-management',  url: '/profile/settings/team' },
    { text: 'profile_settings.my_games', icon: '$icn-games',  url: '/profile/settings/mygames' },
    { text: 'profile_settings.game_accounts', icon: '$icn-game-accounts',  url: '/profile/settings/game-accounts' },
    { text: 'profile_settings.streaming_accounts', icon: '$icn-streaming-accounts',  url: '/profile/settings/streaming-accounts' },
    { text: 'profile_settings.notification_settings', icon: '$icn-notification-settings',  url: '/profile/settings/notifications' },
  ]

  private support = [
    { text: 'profile_settings.faq', icon: '$icn-faq', url: '/faq' },
    { text: 'profile_settings.support', icon: '$icn-support',  url: '/profile/settings/support' },
  ]

  private legal = [
//   TODO { text: 'profile_settings.toc', icon: '$icn-terms', url: '/terms'},
    { text: 'profile_settings.pp', icon: '$icn-privacy', url: '/privacy'}
  ]

  private item = 1

  get avatarUrl() {
    return this.user?.avatar?.url ?? "";
  }

  imagePlaceholder(e) {
    e.target.src = userPlaceholder;
  }
}
