















    import Participation from "@graphql/types/participation";
    import Event from "@graphql/types/event";
    import PromotedPlayers from "@components/events/common/promoted_players.vue";
    import { Component, Vue, Prop } from "@components/common";
    import { ESFPlayoffsLeagueDivisionsPlayoffsConfiguration } from "@graphql/types/event_flow_stage";


    import { namespace } from "@/store";
    const AuthenticationState = namespace("authentication");
    const ESFPlayoffsLeagueDivisionsPlayoffs = "EventStageFlows::Playoffs::LeagueDivisionsPlayoffs";

    @Component({
    components: {PromotedPlayers}
    })
    export default class PlayoffsGroupStandings extends Vue {
    @Prop() readonly event!: Event;
    @Prop() readonly selectedPlayoff: any;
    @Prop() readonly isSimplePlayoffs!: boolean;

    private tab = null
    private participantPlaceholder = {
      id: -1,
      name: "?",
      avatar: ""
    }

    get playoffConfig(): ESFPlayoffsLeagueDivisionsPlayoffsConfiguration {
      return this.event.eventFlow?.eventFlowStages?.find(efs => efs.flowType === ESFPlayoffsLeagueDivisionsPlayoffs)?.configuration as ESFPlayoffsLeagueDivisionsPlayoffsConfiguration;
    }

    get selectedPlayoffConfig() {
      return !this.isSimplePlayoffs ? this.playoffConfig.playoffs?.find(p => p.tag == this.selectedPlayoff.tag) : undefined;
    }

    get playoffStandings() {
      return this.isSimplePlayoffs ? this.event.standings : this.event.standings?.filter(s => s.playoffsGroupId === this.selectedPlayoff.id);
    }

    private userPlace(place: number) {
        const emptyPlayer = {id: place, name: '', avatar: {url:''}};
        const player = this.playoffStandings.find(ps => ps.position === place.toString());

        const playerId = player ? player.participantId : undefined;

        if (playerId) {
          return this.findParticipantById(playerId) || emptyPlayer;
        } else {
          return emptyPlayer;
        }
    }

    private findParticipantById(id) {
      return this.event.isUserBased ? this.event.participatingUsers?.find( u => u.id === id) : this.event.participatingTeams?.find( t => t.id === id);
    }

    get noPromoting(): number {
      return this.isSimplePlayoffs ? 0: this.selectedPlayoffConfig?.promotion_count || 0;
    }

    get promotingPlayers() {
      const players: any = [];

      for (let i = 1; i <= Number(this.noPromoting); i++) {
        players.push(this.userPlace(i));
      }

      return players;
    }

    get isTeamBased() {
      return this.event.mode === 'teams';
    }

    get participants() {
      const participants: any = [];
      let i;
      for (i = 0; i < this.noPromoting; i++) {
        participants.push(this.participantPlaceholder);
      }

      return participants;
    }

}
