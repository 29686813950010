











import { Page, Component, Layout } from "@components/common";

// TODO: Me is already loaded at startup?
import { GetMyInfo } from "@graphql/queries/me";
import User from "@graphql/types/user";
import PlayerCardDetails from "@components/players/player_detail.vue";

@Component({
  components: {
    Layout,
    PlayerCardDetails
},
  apollo: {
    user: {
      query: GetMyInfo,
      variables() { return {id: this.currentUserId}; },
      update: data => data.me
    },
  }
})
export default class Profile extends Page {
  private user?: User = new User();
}
