








































































































































import { Page, Component, Layout, FormLabel } from "@components/common";
import { DateTime } from "luxon";

import BrowseEvents from "@components/v2/BrowseEvents.vue";
import EventCard from "@components/v2/EventCard.vue";
import EventsFilterInput from "@components/events/events_filter_input.vue";
import GameCard from "@components/v2/GameCard.vue";
import HScroller from "@components/v2/HScroller.vue";
import MatchCard from "@components/v2/ActivityCards/MatchCard.vue";
import PlayerCard from "@components/v2/PlayerCard.vue";

import Event from "@/graphql/types/event";
import Game from "@/graphql/types/game";
import Team from "@/graphql/types/team";
import User from "@/graphql/types/user";

import { namespace } from "@store";
const MyEnrolmentsStore = namespace("myEnrolments");

@Component({
  components: {
    BrowseEvents,
    EventCard,
    EventsFilterInput,
    FormLabel,
    GameCard,
    HScroller,
    Layout,
    MatchCard,
    PlayerCard,
  }
})
export default class Browse extends Page {
  @MyEnrolmentsStore.Getter("events")
  private myEvents!: Event[];

  private activeEvents:   Event[] = [];
  private pastEvents:     Event[] = [];
  private popularEvents:  Event[] = [];
  private upcomingEvents: Event[] = [];
  private popularGames:   {gameId: number, game: Game, position: number, playerCount: number }[] = [];
  private topPlayers:     User[] = [];
  private topTeams:       Team[] = [];

  private loading = false;
  private filters = {};

  loadData() {
    this.loading = true;

    Promise.all([
      this.loadUpcomingEvents(),
      this.loadPopularEvents(),
      this.loadPastEvents(),
      this.loadActiveEvents(),
      this.loadPopularGames(),
      this.loadHallOfFame(),
      this.loadTeamsHallOfFame(),
    ]).then(() => {
      this.loading = false;
    });
  }

  mounted() {
    this.loadData();
  }

  get myActiveEvents() {
    return this.myEvents.filter(event => DateTime.fromISO(event.endTime) > DateTime.local());
  }

  async loadHallOfFame() {
    this.topPlayers = await this.$store.dispatch("users/searchBest", {
      extraSelection: [
        "avatar { url }",
        "games { id avatar { url } }"
      ],
      order: "active",
      limit: 10
    });
  }

  async loadTeamsHallOfFame() {
    this.topTeams = await this.$store.dispatch("teams/searchBest", {
      extraSelection: [
        "avatar { url }",
      ],
      order: "active",
      limit: 10
    });
  }

  async loadUpcomingEvents(): Promise<void> {
    this.upcomingEvents = await this.$store.dispatch("events/search", {
      filters: {...this.filters, state: "notstarted"},
      limit: 10,
      order: "start_time ASC"
    });
  }

  async loadPastEvents(): Promise<void> {
    this.pastEvents = await this.$store.dispatch("events/search", {
      filters: {...this.filters, state: "finished"},
      order: "__special:past_interesting",
      limit: 10
    });
  }

  async loadActiveEvents(): Promise<void> {
    this.activeEvents = await this.$store.dispatch("events/search", {
      filters: {...this.filters, state: "running"},
      limit: 10,
    });
  }

  async loadPopularEvents(): Promise<void> {
    // Not quite sure what popular is here. I imagine events from whatever we decide are popular games
    this.popularEvents = await this.$store.dispatch("events/search", {...this.filters, gameIds: this.popularGameIds});
  }

  async loadPopularGames() {
    this.popularGames = await this.$store.dispatch("games/popular");
  }

  get popularGameIds() {
    return this.popularGames.map(pg => pg.gameId);
  }
}
