



















import { Vue, Component, Prop } from "@components/common";

import Checkin from "@graphql/types/checkin";
import Match from "@graphql/types/match";
import MatchResult from "@graphql/types/match_result";
import Notification from "@graphql/types/notification";
import Participation from "@graphql/types/participation";

import ActivityCard from "@components/v2/ActivityCards/ActivityCard.vue";

@Component({
  components: {
    ActivityCard,
  },
})
export default class MatchNotification extends Vue {
  @Prop() private readonly notification!: Notification;

  private loading = false;
  private match: Match | null = null;

  get matchId() {
    return this.notification?.data?.match_id ?? null;
  }

  async loadMatch() {
    this.loading = true;

    await this.$store.dispatch("matches/find", {
      id: this.matchId,
      extraSelection: {
        participations: Participation,
        result: MatchResult,
        checkins: Checkin
      }
    })
      .then(match => this.match = match)
      .finally(() => this.loading = false);
  }

  mounted() {
    this.loadMatch();
  }
}
