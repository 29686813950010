

















import { Component, Vue, Prop } from "@components/common";
import User from "@graphql/types/user";

@Component
export default class ChatButton extends Vue {
  @Prop() uuid!: string;
  @Prop() player!: User;

  get uuId() {
    return this?.uuid;
  }

  get nickname() {
    return this.player?.nickname ?? "";
  }

  async openChat() {
    this.$eventBus.$emit("chat:open:1on1", this.uuId);
  }
}
