
























































































































import { Component, Vue, Prop, Divider, Watch } from "@components/common";
import Match from "@graphql/types/match";
import Event from "@graphql/types/event";
import CardDetailHeader from "@components/cups/card_detail_header.vue";
import { GetMatchWithResult } from "@graphql/queries/match";

@Component({
  components: { CardDetailHeader, Divider },
  apollo: {
    match: {
      skip() { return !this.matchId; },
      query: GetMatchWithResult,
      variables() { return {id: this.matchId}; },
      pollInterval: 10000
    }
  }
})
export default class SubmitMatchScore extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly matchId!: number;
  @Prop() readonly show!: boolean;
  @Prop({default: ""}) readonly matchName!: string;


  private match?: Match;

  private fstPartRes = "0";
  private secPartRes = "0";

  private overtime = false;

  get gameId() {
    return this.event.gameId || -1;
  }

  get gameName() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }

  get game() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }
  get opponentName() {
    return this.opponent?.name ?? "";
  }

  get firstParticipantResult() {
    return Number(this.fstPartRes);
  }

  get secondParticipantResult() {
    return Number(this.secPartRes);
  }

  get firstParticipantAvatar() {
    return this.firstParticipant.avatar?.url ?? "";
  }

  get secondParticipantAvatar() {
    return this.secondParticipant.avatar?.url ?? "";
  }

  get opponent() {
    if (this.currentUserId == this.firstParticipant?.id)
      return this.secondParticipant;
    else
      return this.firstParticipant;
  }

  @Watch("matchId")
  reloadMatch() {
    this.$apollo.queries.match.refetch();
  }

  get firstParticipant() {
    return this.match?.participations?.[0]?.participant;
  }

  get secondParticipant() {
    return this.match?.participations?.[1].participant;
  }

  get winnerId() {
    if (this.firstParticipantResult > this.secondParticipantResult)
      return this.firstParticipant.id;
    else if (this.secondParticipantResult > this.firstParticipantResult)
      return this.secondParticipant.id;
    else
      return null;
  }

  get hasResult() {
    return !!this.match?.result;
  }

  get scores() {
    return [
      {
        participantId: this.firstParticipant.id,
        score: this.firstParticipantResult
      },
      {
        participantId: this.secondParticipant.id,
        score: this.secondParticipantResult
      },
    ];
  }

  async submitResult() {
    const result = await this.$store.dispatch("matches/submitResult", {
      matchId: this.match?.id,
      scores: this.scores,
      data: { overtime: this.overtime }
    });

    if (result.success) {
      this.$gtag.event('match_submit_score', {
        'event_category': 'match_action',
        'event_label': 'Submit match score',
        'value': { userId: this.currentUserId, matchId: this.match?.id}
      });
      this.match = { ...this.match, result: result.matchResult};
      this.$notify({text: this.$t("match.result_card.result_submitted").toString()});
      this.close();
    }
  }

  async confirmResult() {
    const matchId = this.match?.id;

    if (!matchId)
      return;

    const result = await this.$store.dispatch("matches/confirmResult", { matchId });

    if (result.success) {
      this.match = { ...this.match, result: result.matchResult };
      this.$notify({text: this.$t("match.result_card.result_confirmed").toString()});
    }
  }

  close() {
    this.$emit("close");
    this.$emit("refreshTournament");
  }

  canBeEqual() {
    if (this.overtime) return true;
    else {
      return this.fstPartRes === this.secPartRes ? false : true;
    }
  }
}
