








































































































































































































import { Vue, Page, Component, Layout, Divider, FormLabel } from "@components/common";
import { GetMyInfo } from "@graphql/queries/me";
import { notEmpty } from "@lib/validations";
import Me from "@graphql/types/me";

@Component({
  components: { Layout, Divider, FormLabel },
  apollo: {
    me: {
      query: GetMyInfo,
      result({ data: { me }}) { this.fillForm(me); }
    }
  }
})
export default class Settings extends Page {
  private me = new Me();
  private notEmpty = notEmpty;
  private isFormValid = false;

  private form = {
    firstName: "",
    lastName: "",
    nickname: "",
    showNickname: false,
    discordNickname: "",
    birthDate: "",
    preferredGameTime: "",
  };

  get avatarUrl() {
    const url = this.me?.avatar?.url;
    if (url && url?.length > 0)
      return url;

    return undefined;
  }

  get coverImageUrl() {
    const url = this.me?.coverImage?.url;
    if (url && url?.length > 0)
      return url;

    return undefined;
  }

  async uploadAvatar({ target: { files: [file] }}) {
    if (file.size > 5000000) {
      this.$notify({title: this.$t("errors.teams.image_to_big").toString(), type: 'error'});
      return;
    }
    try {
      const result = await this.$store.dispatch("me/setAvatar", {avatar: file});
      if (result.success) {
        this.updateData({avatar: result.avatar});
        this.$notify({text: this.$t("common.saved").toString()});
      }
    } catch (error) {
      this.$notify({title: this.$t("errors.teams.image_to_big").toString(), type: 'error'});
    }
  }

  async uploadCoverImage({ target: { files: [file] }}) {
    if (file.size > 5000000) {
      this.$notify({title: this.$t("errors.teams.image_to_big").toString(), type: 'error'});
      return;
    }
    try {
      const result = await this.$store.dispatch("me/setCoverImage", {coverImage: file});
      if (result.success) {
        this.updateData({coverImage: result.coverImage});
        this.$notify({text: this.$t("common.saved").toString()});
      }
    } catch (error) {
      this.$notify({title: this.$t("errors.teams.image_to_big").toString(), type: 'error'});
    }
  }

  async saveChanges() {
    if (!this.userForm.validate()) {
      this.$notify({text: this.$t("errors.registration.input_required").toString(), type: "error"});
      return;
    }

    const result = await this.$store.dispatch("me/update", {attributes: this.form});

    if (result.success) {
      this.updateData(result.me);
      this.$notify({text: this.$t("profile_settings.saved.profile").toString()});
      this.$router.push({ name: 'Profile Settings'});
    }
  }

  private updateData(me) {
    this.me = new Me(me);
    this.fillForm(this.me);
  }

  private fillForm(me) {
    Object.keys(this.form).forEach(formKey => {
      if (me[formKey])
        this.form[formKey] = me[formKey];
    });
  }

  get userForm() {
    return this.$refs.userForm as Vue & { validate: () => boolean };
  }
}
