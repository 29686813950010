






















































import { Component, Vue, Prop } from "@components/common";

import { namespace } from "@/store";
const AuthenticationState = namespace("authentication");

@Component
export default class GroupStage extends Vue {
  @Prop() readonly items;
  @Prop() readonly headers;
  @Prop() readonly groupSize;
  @Prop() readonly isTeam!: boolean;

  get standings() {
    const standings = this.items.standings;
    const emptySeats = Number(this.groupSize - standings.length);
    const noOpponent = {
      name: this.$t('match.group_match_no_opponent'),
      id: null,
      p: 0,
      w: 0,
      l: this.gamesPerParticipant,
      d: 0,
      pts: 0,
      f: 0,
      sf: 0,
      sa: this.gamesPerParticipant,
      diff: -this.gamesPerParticipant,
      position: null
    };

    function noOpponents(slots) {
      const arr = [...Array(slots)];
      return arr.map(() => { return noOpponent; });
    }

    standings.sort((left, right) => {
      if (left.pts > right.pts) return -1;
      if (right.pts > left.pts) return 1;

      if (left.diff > right.diff) return -1;
      if (right.diff > left.diff) return 1;

      if (left.sf > right.sf) return -1;
      if (right.sf > left.sf) return 1;

      return 0;
    });

    const sorted = [...standings, ...noOpponents(emptySeats)];

    sorted.forEach((val, i) => {
      val.position = i + 1;
    });

    return sorted;
  }

  getParticiapntLink (id) {
    return this.isTeam ?
      {name: 'Team', params: { teamId: id }}
      : {name: 'Players', params: { userId: id }};
  }

  get gamesPerParticipant() {
    return this.groupSize - 1;
  }

  get group() {
    return {
      ...this.items,
      standings: this.standings
    };
  }

  @AuthenticationState.State
  private teamRoles;

  isMe(item) {
    const teamRoles = this.teamRoles;
    const currentUserId = this.currentUserId;

    if (this.isTeam) {
      return teamRoles.some(role => role.teamId == item.id);
    } else {
      return currentUserId == item.id;
    }
  }
}
