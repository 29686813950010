















































import { Page, Component, Layout } from "@components/common";
import { chain } from "underscore";

import Event from "@graphql/types/event";
import EventCard from "@components/events/event_card.vue";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";
import Filters from "@components/events/filters.vue";
import { ListEventCards } from "@graphql/queries/events";
import { eventStandings } from "@/store/filters";

@Component({
  components: { EventCard, Layout, Filters },
  apollo: {
    finishedEvents: {
      query: ListEventCards,
      variables() { return {filters: this.filters, tournamentFilters: this.filters}; },
      result() { this.isUpdating = false; this.isUpdatingSearch = false;},
      update: data => data
    },
  }
})

export default class StandingsFinished extends Page  {
  private isUpdating = true;
  private isUpdatingSearch = true;
  private activeSearch = false;
  private finishedEvents: {events: []; tournaments: []} = {events: [], tournaments: []};

  public filters = eventStandings;

  get events() {
    return chain(this.finishedEvents.tournaments)
      .union(this.finishedEvents.events)
      .sortBy(event => event.endTime)
      .reverse()
      .value();
  }

  eventRouteName(event) {
    return new EventCategoryHelpers(event).routeName;
  }

  markUpdating() {
    this.activeSearch = true;
    this.isUpdatingSearch = true;
  }

  refresh() {
    this.$apollo.queries.finishedEvents.refresh();
  }
}
