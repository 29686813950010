









































































import { Component, Vue } from "@components/common";

@Component({})
export default class IconWaiting extends Vue {

}
