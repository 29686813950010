import gql from "graphql-tag";
import { MatchResultFields } from "@graphql/types/match_result";

export default gql`
  mutation SubmitMatchResults (
    $results: [MatchResultInput!]!
  ) {
    submitMatchResults (
      results: $results
    ) {
      success
    }
  }
`;
