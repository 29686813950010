





















import { Component, Vue, FormLabel } from "@components/common";
import { namespace } from "@/store";

import Game from "@graphql/types/game";
import Platform from "@graphql/types/platform";

import { isArray } from "underscore";

const AppPropertiesStore = namespace("appProperties");

@Component({components: {FormLabel}})
export default class EventsFilterInput extends Vue {
  @AppPropertiesStore.Getter("games")
  private availableGames!: Game[];

  @AppPropertiesStore.Getter("platforms")
  private availablePlatforms!: Platform[];

  private selectedFilters: string[] = [];

  updateFilters() {
    this.$emit("updateFilters", this.filters);
  }

  get filterItems() {
    return [
      {header: "States"},
      ...this.statesItems,
      {header: "Games"},
      ...this.gamesItems,
      {header: "Platforms"},
      ...this.platformsItems,
    ];
  }

  get filters() {
    let filters = {};

    this.selectedFilters.forEach(filter => {
      const [key, value] = filter.split(":");

      const prevFilter = filters[key];

      if (isArray(prevFilter))
        prevFilter.push(value);
      else if (prevFilter)
        filters[key] = [prevFilter, value];
      else
        filters[key] = value;
    });

    return filters;
  }

  filterValueText(type, value) {
    switch (type) {
      case "state":
        return this.$t(`event.filter.states.${value}`);
      case "gameIds":
        return this.games[value].name;
      case "platformIds":
        return this.platforms[value].name;
    }
  }

  get games() {
    return this.availableGames.reduce((result, game) => ({...result, [game.id as number]: game}), {});
  }

  get platforms() {
    return this.availablePlatforms.reduce((result, platform) => ({...result, [platform.id as number]: platform}), {});
  }

  get statesItems() {
    return ["notstarted", "active", "notfinished"].map(state => ({
      text: this.$t(`event.filter.states.${state}`).toString(),
      value: `state:${state}`
    }));
  }

  get gamesItems() {
    return this.availableGames.flatMap(g => [
      {text: g.name, value: `gameIds:${g.id}`},
    ]);
  }

  get platformsItems() {
    return this.availablePlatforms.flatMap(p => [
      {text: p.name, value: `platformIds:${p.id}`},
    ]);
  }
}
