

































import { GuestPage, Prop, Component, Card, MainHeader, Layout, FormLabel } from "@components/common";
import Game from "@graphql/types/game";

@Component({
  components: { Card, MainHeader, Layout, FormLabel }
})

export default class GamesForm extends GuestPage {
  @Prop(String) buttonLabel!: string
  private form = {};

  private loading = false;

  private isFormValid = false;

  get games(): Game[] {
    return this.appProperties.games;
  }

  get myGames(): Game[] {
    return this.currentUser?.games ?? [];
  }

  mounted() {
    this.initForm(this.games);
    this.fillFormValues(this.myGames);
  }

  selectAll() {
    Object.keys(this.form).forEach(key => this.form[key] = true);
  }

  initForm(values) {
    values.forEach(val => {
      if (this.form[val.id])
        return;

      this.$set(this.form, val.id, false);
    });
  }

  fillFormValues(games) {
    games.forEach(game => {
      this.$set(this.form, game.id, true);
    });
  }

  async submit() {
    if (this.gamesInput.length < 1) {
      this.$notify({text: this.$t("errors.registration.select_one").toString(), type: "error"});
      return;
    }

    try {
      this.loading = true;
      const result = await this.$store.dispatch("me/setGames", {
        games: this.gamesInput
      });

      if (result.success) {
        this.$emit("saved");
        this.$store.commit("authentication/setGames", this.selectedGames(this.gamesInput));
      }
    }
    finally {
      this.loading = false;
    }
  }

  selectedGames(gamesInput) {
    return this.games.filter(g => this.gamesInput.some(gi => gi.gameId === g.id));
  }

  get gamesInput() {
    // TODO: Platforms?
    return Object.keys(this.form)
      .filter(key => this.form[key])
      .map(key => (
        {gameId: this.games.find(g => g?.id?.toString() == key)?.id, gamePlatformId: null})
      );
  }
}
