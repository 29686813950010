import { Vue, Component } from "@components/common";
import { namespace } from "vuex-class";
import Me from "@graphql/types/me";

@Component
export default class ListensForOwnMatchUpdates extends Vue {
  updateMatch(match) {
    console.log(`Updating match ${match.id} in ${this.constructor.name}.`);

    const matches = this["matches"];
    const localMatch = this["match"];

    if (matches) {
      const index = matches.map(m => m.id).indexOf(match.id);

      if (index !== -1)
        matches[index] = match;
    } else if (localMatch && localMatch.id == match.id) {
      this["match"] = match;
    }
  }

  updateMatchResult({matchId, result}) {
    console.log(`Updating match ${matchId} result in ${this.constructor.name}`);

    const matches = this["matches"];
    const match = this["match"];

    if (matches) {
      const index = matches.map(m => m.id).indexOf(matchId);

      if (index != -1)
        matches[index] = { ...matches[index], result: result};
    } else if (match && match.id == matchId) {
      this.$set(this, "match", {...match, result: result});
    }
  }

  mounted() {
    this.$eventBus.$on("match:update", this.updateMatch);
    this.$eventBus.$on("match:update-result", this.updateMatchResult);
  }

  unmounted() {
    this.$eventBus.$off("match:update", this.updateMatch);
    this.$eventBus.$off("match:update-result", this.updateMatchResult);
  }
}
