import { Component, Mixins, Page } from "@components/common";
import Match from "@graphql/types/match";

@Component
export default class MatchHelpersMixin extends Mixins(Page) {
  // HACK: I don't know a better way to re-use this.amatch across multiple components
  // with different definitions (prop, private, readonly in this case).
  // This is jsut to fix typing issues, it works either way.
  get _match() {
    return this["match"];
  }

  get matchTitle() {
    return Match.getTitle(this._match);
  }

  get firstParticipant() {
    return this.getParticipant(0);
  }

  get secondParticipant() {
    return this.getParticipant(1);
  }

  get canSubmitResult() {
    return this.isEitherParticipant && !this.hasResult;
  }

  get canConfirmResult() {
    return (
      this.isEitherParticipant &&
      this.hasResult &&
      !this.hasAlreadySubmittedResult &&
      !this.isResultConfirmed
    );
  }

  get hasAlreadySubmittedResult() {
    return this?._match?.result?.submitterId == this?.currentUser?.id;
  }

  get isResultConfirmed() {
    return !!this._match?.result?.confirmerId;
  }

  get isEitherParticipant() {
    if (!this?._match?.participations) return false;

    return this._match.participations.some((p) => p.id == this.currentUserId);
  }

  get winner() {
    const first = this.getParticipant(0);
    const second = this.getParticipant(1);

    if (first.score > second.score) return first;
    else if (second.score > first.score) return second;
    else return null;
  }

  get loser() {
    const first = this.getParticipant(0);
    const second = this.getParticipant(1);

    if (first.score < second.score) return first;
    else if (second.score < first.score) return second;
    else return null;
  }

  get hasResult() {
    return !!this._match?.result;
  }

  private isWinner(participant) {
    return this?.winner?.id == participant.id;
  }

  private getParticipant(idx) {
    const participant = this._match.participations?.[idx];

    if (!participant?.id) return -1;

    const result = this._match.result;
    const score = result?.scores?.[participant.id] ?? -1;

    return {
      ...participant,
      score,
    };
  }
}
