import gql from "graphql-tag";
import Base from "@graphql/types/base";

export const MatchStatisticFields = gql`
  fragment MatchStatisticFields on UserMatchStats {
    draws
    losses
    wins
    participatingMatches
    playedMatches
  }
`;

export default class UserMatchStats extends Base<UserMatchStats> {
  draws?: number;
  losses?: number;
  wins?: number;
  participatingMatches?: number;
  playedMatches?: number;
}
