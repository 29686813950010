import gql from "graphql-tag";
import Base from "@graphql/types/base";

export const StageOverrideFields = gql`
    fragment StageOverrideFields on StageOverride {
        startTime
        endTime
        nameIdentifier
        stageNumber
    }
`;


export class StageOverride extends Base<StageOverride> {
    startTime?: string;
    endTime?: string;
    nameIdentifier?: string;
    stageNumber?: number;
}

export default StageOverride;
