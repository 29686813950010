import gql from "graphql-tag";
import { ListTournamentFields } from "@graphql/types/tournament";
import {
  EventFields,
  ListEventFields,
  MyEventsFields
} from "@graphql/types/event";
import { GameFields } from "@graphql/types/game";
import { PlatformFields } from "@graphql/types/platform";
import { TeamFields } from "../types/team";

export const ListFilteredEvents = gql`
  query ListFilteredEvents(
    $tournamentFilters: TournamentsFilterInput
    $eventFilters: EventsFilterInput
  ) {
    tournaments(filters: $tournamentFilters) {
      ...ListTournamentFields
    }

    events(filters: $eventFilters) {
      ...ListEventFields
    }
  }
  ${ListEventFields}
  ${ListTournamentFields}
`;

export const ListMyEventsCards = gql`
  query ListMyEventsCards($filters: EventsFilterInput) {
    myEvents(filters: $filters) {
      ...MyEventsFields
    }
  }
  ${MyEventsFields}
`;

export const ListEvents = gql`
  query ListEvents($filters: EventsFilterInput) {
    events(filters: $filters) {
      ...EventFields
    }
  }
  ${EventFields}
`;

export const ListEventCards = gql`
  query ListEvent(
    $filters: EventsFilterInput
    $tournamentFilters: TournamentsFilterInput
  ) {
    events(filters: $filters) {
      ...ListEventFields
    }

    tournaments(filters: $tournamentFilters) {
      ...ListTournamentFields
    }
  }
  ${ListEventFields}
  ${ListTournamentFields}
`;

export const ListEventsWithGame = gql`
  query ListEventsWithGame($filters: EventsFilterInput) {
    events(filters: $filters) {
      ...EventFields
      game {
        ...GameFields
      }
    }
  }
  ${EventFields}
  ${GameFields}
`;

export const ListEventsWithGameAndTeamParticipation = gql`
  query ListEventsWithGameAndTeamParticipation($filters: EventsFilterInput) {
    events(filters: $filters) {
      ...LeagueFields
      game {
        ...GameFields
      }
      participatingTeams {
        ...TeamFields
      }
      platforms {
        ...PlatformFields
      }
    }
  }
  ${EventFields}
  ${PlatformFields}
  ${GameFields}
  ${TeamFields}
`;

export const ListFilteredEventIds = gql`
  query ListFilteredEventIds(
    $tournamentFilters: TournamentsFilterInput
    $eventFilters: EventsFilterInput
  ) {
    tournaments(filters: $tournamentFilters) {
      id
    }

    events(filters: $eventFilters) {
      id
    }
  }
`;
