














import { Component, Vue, Prop } from "@components/common";

@Component
export default class MetaLine extends Vue {
  @Prop({ default: "" }) public icon!: string;
}
