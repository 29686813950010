































import { Component, Vue, Prop, Watch } from "@components/common";
import { DateTime } from "luxon";

export interface Week {
  id: number;
  name: string;
  number: string;
  startTime: Date;
  endTime: Date;
}

@Component
export default class WeekSelector extends Vue {
  @Prop() private readonly weeks!: Week[];
  @Prop() private readonly defaultWeek!: Week;

  private selection: Week | any = {}

  mounted(): void {
    this.defaultWeek ?
      this.selected = this.defaultWeek :
      this.selected = this.weeks[0];
  }

  formatDate(date: Date): string {
    return DateTime.fromISO(Number(date)).toLocaleString(DateTime.local());
  }

  @Watch('selected', {immediate: true})
  initialPostision(value): void {
    if (Object.keys(this.selected).length > 0) {
      const parent = this.$refs.selector;
      const key = this.weeks.findIndex(week => week.id === value.id);
      const child = this.$refs[`week_${key}`]?.[0]?.parentElement;
      this.scrollTo(parent, child, key);
    }
  }

  scrollTo(parent, child, key): void {
    const margin = 13; // set child margin + border, if any
    const offset = 85; // set offset from the left
    parent.scrollLeft = ((child.offsetWidth + margin) * key) - offset;
  }

  get selected() {
    return this.selection;
  }

  set selected(val) {
    this.selection = val;
    this.$emit('input', val);
  }
}
