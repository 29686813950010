import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";
import createStore from "@store/generic_store";
import Event from "@graphql/types/event";

import JoinEvent from "@graphql/mutations/join_event";
import LeaveEvent from "@graphql/mutations/leave_event";

import { getSelectables } from "@store/generic_store";
import gql from "graphql-tag";

export default class Events extends createStore({
  name: "event",
  recordType: Event,
}) {
  @Action({ rawError: true })
  async join({ id, asUser, asTeam = false, teamId = null }) {
    const {
      data: { joinEvent: result },
    } = await apollo.mutate({
      mutation: JoinEvent,
      variables: { id, asUser, asTeam, teamId },
    });

    return result;
  }

  @Action({ rawError: true })
  async search({
    filters,
    order = null,
    category = null,
    selection = getSelectables(Event),
    extraSelection = [],
    page = 1,
    limit = 50,
  }) {
    const fullSelection = Array.from(
      new Set([...selection, ...extraSelection])
    );
    const name = this.recordName;
    const pluralName = this.capitalizedPluralRecordName;

    const { data } = await apollo.query({
      query: gql`
        query CustomEventSearch_${name}(
          $filters : ${pluralName}FilterInput,
          $order : String,
          $category : EventCategory
          $page : Int,
          $limit : Int
        ) {
          ${this.pluralRecordName}(
            filters: $filters,
            order: $order,
            category: $category,
            page: $page,
            limit: $limit
          ) {
            ${fullSelection.join("\n")}
          }
        }`,
      variables: { filters, category, page, limit, order },
    });

    const records = data[this.pluralRecordName];
    const ids = records.map((r) => r.id);

    this.context.commit("commitRecords", {
      records: data[this.pluralRecordName],
      selection: fullSelection,
    });

    return ids.map((id) => this.records[id].record);
  }

  @Action({ rawError: true })
  async leave({ id, asTeam = false, teamId = null }) {
    const {
      data: { leaveEvent: result },
    } = await apollo.mutate({
      mutation: LeaveEvent,
      variables: { id, asTeam, teamId },
    });

    return result;
  }
}
