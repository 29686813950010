import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";
import gql from "graphql-tag";
import { ListMyConversations } from "@graphql/queries/conversations";
import {
  GetConversation,
  GetConversationMessages
} from "@graphql/queries/conversation";

import ConversationMessage from "@graphql/types/conversation_message";

import Talk from "talkjs";

export interface SenderCustom {
  id: number;
  name: string;
}

export interface Sender {
  custom: SenderCustom;
}

interface ConversationCustomData {
  initiatorId: string;
  participants: Talk.User[];
  type: string;
  avatar?: string;
  event_id?: number;
  name?: string;
}

export class ConversationCustom {
  public jsonData: string;

  constructor(json: string) {
    this.jsonData = json;
  }

  get data(): ConversationCustomData {
    return JSON.parse(this.jsonData) as ConversationCustomData;
  }

  toString() {
    return this.jsonData;
  }
}

export class ConversationLastMessage {
  private _text?: string;
  public createdAt: string;

  constructor(lastMessage) {
    this._text = lastMessage?.text ?? lastMessage?.body;
    this.createdAt = lastMessage?.createdAt;
  }

  get text() {
    return this._text;
  }
}

export interface Participant {
  access: "Read" | "ReadWrite";
  notify: boolean;
}

export class Conversation {
  public id: string;
  public subject: string;
  public custom: ConversationCustom;
  public lastMessage: ConversationLastMessage;
  public createdAt: string;
  public isRead: boolean;
  public participants: Record<string, Participant>;

  constructor({
    id,
    subject,
    custom,
    lastMessage,
    participants,
    createdAt,
    isRead = true
  }) {
    this.id = id;
    this.subject = subject;
    this.custom = new ConversationCustom(custom.data);
    this.lastMessage = new ConversationLastMessage(lastMessage);
    this.createdAt = createdAt;
    this.isRead = isRead;
    this.participants = participants;
  }
}

export interface Unread {
  conversation: Conversation;
  sender: Sender;
}

@Module({ namespaced: true })
export default class Chat extends VuexModule {
  public unreadConversationCount = 0;
  public unreadConversations: Conversation[] = [];
  private myConversations: Conversation[] = [];

  @Action({ rawError: true })
  setUnreadConversations({ unreads }) {
    this.context.commit("setUnreads", {
      unreads: unreads.map(
        unread =>
          new Conversation({
            ...unread.lastMessage.conversation,
            lastMessage: unread.lastMessage
          })
      )
    });
    this.context.commit("setUnreadConversationCount", {
      count: unreads.length
    });

    this.context.commit("syncReadStatuses");
  }

  @Action({ rawError: true })
  async conversation({ eventId }) {
    const { data } = await apollo.query({
      query: GetConversation,
      fetchPolicy: "no-cache",
      variables: { eventId }
    });

    return new Conversation(data.conversation);
  }

  @Action({ rawError: true })
  async conversationMessages({ eventId }) {
    const { data } = await apollo.query({
      query: GetConversationMessages,
      fetchPolicy: "no-cache",
      variables: { eventId }
    });

    return data.conversationMessages.map(m => new ConversationMessage(m));
  }

  @Action({ rawError: true })
  async deleteConversationMessage({ eventId, messageId }) {
    const {
      data: { succes: result }
    } = await apollo.mutate({
      mutation: gql`
        mutation DeleteConversationMessage($eventId: ID!, $messageId: ID!) {
          deleteConversationMessage(eventId: $eventId, messageId: $messageId) {
            success
          }
        }
      `,
      variables: { eventId, messageId }
    });

    return result;
  }

  @Action({ rawError: true })
  async setConversationAccess({ eventId, userId, access }) {
    const {
      data: { succes: result }
    } = await apollo.mutate({
      mutation: gql`
        mutation SetConversationAccess(
          $eventId: ID!
          $userId: ID!
          $access: String!
        ) {
          setConversationAccess(
            eventId: $eventId
            userId: $userId
            access: $access
          ) {
            success
          }
        }
      `,
      variables: { eventId, userId, access }
    });

    return result;
  }

  @Mutation
  public setUnreadConversationCount({ count }: { count: number }) {
    this.unreadConversationCount = count;
  }

  @Mutation
  public setUnreads({ unreads }) {
    this.unreadConversations = unreads;
  }

  @Mutation
  syncReadStatuses() {
    this.myConversations.forEach(conversation => (conversation.isRead = true));

    this.unreadConversations.forEach(unread => {
      const index = this.myConversations.findIndex(c => c.id == unread.id);

      const conversation = new Conversation({
        ...unread,
        isRead: false,
        custom: { data: unread.custom.toString() }
      });

      if (index == -1) {
        this.myConversations.unshift(conversation);
        return;
      } else {
        this.myConversations[index] = conversation;
      }
    });
  }

  @Mutation
  clear() {
    this.unreadConversationCount = 0;
    this.unreadConversations = [];
    this.myConversations = [];
  }

  @Action({ rawError: true })
  async refreshMyConversations() {
    const { data } = await apollo.query({
      query: ListMyConversations,
      fetchPolicy: "no-cache"
    });

    this.context.commit("setMyConversations", {
      conversations: data.myConversations
    });

    this.context.commit("syncReadStatuses");
  }

  @Mutation
  setMyConversations({ conversations }) {
    this.myConversations = conversations.map(c => new Conversation(c));
  }

  get hasUnreadConversations() {
    return this.unreadConversationCount > 0;
  }
}
