











































































































import { Component, Vue, Prop } from "@components/common";
import Match from "@graphql/types/match";
import Event from "@graphql/types/event";
import { DateTime } from "luxon";
import MatchSettings from "@/components/matches/match_settings.vue";
import ListensForOwnMatchUpdates from "@/mixins/listens_for_own_match_updates";

import { namespace } from "@/store";
import { ESFQualificationLeagueDivisionsConfiguration } from "@graphql/types/event_flow_stage";

const DIVISION_GROUP_QUALIFICATION_FLOW_DATA_KEY = "EventStageFlows::Qualification::LeagueDivisions";
const AuthenticationStore = namespace("authentication");

@Component({
  mixins: [ListensForOwnMatchUpdates],
  components: { MatchSettings }
})
export default class MatchCard extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly match!: Match;
  @Prop() readonly participants;

  // This is used on match-result update, because `this.match`'s reactivity is b0rk
  private newMatch: Match | null = null;

  get teamLineup() {
    return false;
  }

  get noOpponentScore() {
    let i;
    const scores: number[] = [];

    for (i = 0; i < this.gamesPerMatchGroup; i++) {
      scores[i] = 0;
    }

    return scores.join(' | ');
  }

  get divisionFlowConfiguration() {
      return this.event.eventFlow?.eventFlowStages?.find(efs => efs.flowType === DIVISION_GROUP_QUALIFICATION_FLOW_DATA_KEY)?.configuration as ESFQualificationLeagueDivisionsConfiguration;
  }

  get gamesPerMatchGroup() {
    switch (this.divisionFlowConfiguration?.match_type) {
      case 'bo1': return 1;
      case 'bo3': return 3;
      case 'bo5': return 5;
      case 'bo7': return 7;
      case 'home_away': return 2;
      default: return 1;
    }
  }

  get editResultMarkers() {
    if (this.divisionFlowConfiguration?.match_type == "home_away")
      return ["H:", "A:"];
    else
      return Object.keys([...Array(15)]).map(x => Number(x) + 1 + ':');
  }

  get matchStartTime () {
    if (this.match.isStartTimeConfirmed || this.match.result) return ' - '+ DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.TIME_SIMPLE);
    return '';
  }

  get matchStage() {
    return this.match?.stage? this.match.stage : 0;
  }

  get isFinalStage() {
    return this.matchStage === this.noEventStages;
  }

  get noEventStages() {
    return this.event.lastEliminationStageNumber? this.event.lastEliminationStageNumber : 0;
  }

  get playoffTag () {
    const noStages = this.noEventStages;
    const currentStage = this.matchStage;
    const power = noStages - currentStage+1;

    const stage = 2**power;

    switch (stage) {
      case 2:
        return this.$t("match.finale").toString();
      case 4:
        return this.$t("match.semi_finale").toString();
      case 8:
        return this.$t("match.quater_finale").toString();
      default:
        return this.$t("match.round_of", {round: stage, roundMatches: stage/2}).toString();
    }
  }

  get isDivisionMatch() {
    return !!this.match.divisionId;
  }

  get matchName() {
    if (this.isDivisionMatch) return this.$t("match.divisions.match_name", {weekNumber: this.divisionMatchWeekNumber});
    if (this.match.bracket === "group") return this.$t("match.group_match_name", {grouptag: this.groupTag, matchtag: this.matchTag});
    if (this.match.bracket === 'loser' && this.event.flowType === "TournamentFlows::Elimination::SingleEliminationWith3rdPlace" ) return this.$t(`matches.brackets.3rd_place_match`);
    return this.playoffTag;
  }

  get divisionMatchWeekNumber() {
    return this.match.index!== undefined? this.match.index + 1 : '';
  }

  get groupTag() {
    if (this.match.group === undefined) return "N/A";
    return this.match.group + 1;
  }

  get matchTag() {
    if (this.match.index === undefined) return "N/A";
    return this.match.index + 1;
  }

  get matchIndex() {
    if (this.match?.index === undefined) return 0;
    return this.match?.index + 1;
  }

  @AuthenticationStore.State
  private teamRoles;

  get teamIds() {
    return (this.teamRoles ?? []).map(id => id.teamId);
  }

  get isParticipantOneMe() {
    const participant = this.participantOne;
    if (!participant)
      return false;

    return this.currentUserId === participant.id || this.teamIds.includes(participant.id);
  }

  get isParticipantTwoMe() {
    const participant = this.participantTwo;
    if (!participant)
      return false;

    return this.currentUserId === participant.id || this.teamIds.includes(participant.id);
  }

  get participantOneScore() {
    const match = this.newMatch ?? this.match;

    if (!match)
      return "?";

    if ( match.grouped ) {
      return match?.groupedResults
        ?.map(gr => gr?.scores?.[this.participantOne.id] ?? "?")
        ?.join("  |  ");
    }

    return this.match?.result?.scores?.[this.participantOne.id] ?? "?";
  }

  get participantTwoScore() {
    const match = this.newMatch ?? this.match;

    if (!match)
      return "?";

    if ( match.grouped ) {
      return match?.groupedResults
        ?.map(gr => gr?.scores?.[this.participantTwo.id] ?? "?")
        ?.join("  |  ");
    }

    return this.match?.result?.scores?.[this.participantTwo.id] ?? "?";
  }


  get isTeamBased() {
    return this.match?.participations?.[0].type === "TEAM";
  }

  get participantOne() {
    const opponentId = this.match?.participations?.[0]?.id;

    return this.participants.find(p => p.id == opponentId);
  }

  get participantTwoAvatar() {
    return this.participantTwo?.avatar?.url ?? "";
  }

  get participantOneAvatar() {
    return this.participantOne?.avatar?.url ?? "";
  }

  get participantTwo() {
    const opponentId = this.match?.participations?.[1]?.id;

    if (!opponentId)
      return null;

    return this.participants.find(p => p.id == opponentId);
  }

  get isAutoWin() {
    return !this.participantTwo;
  }

  get winner() {
    const winnerParticipationId = this?.match?.result?.winnerId;

    const winnerParticipation = this.match?.participations?.find(p =>
      p.participationId === winnerParticipationId
    );

    if (!winnerParticipation)
      return null;

    return this.participants.find(p => p.id == winnerParticipation.id);
  }

  get isParticipantOneWon() {
    return this.participantOne?.id === this.winner?.id;
  }

  get isParticipantTwoWon() {
    return this.participantTwo?.id === this.winner?.id;
  }

  get winnerAvatarUrl() {
    return this.winner?.avatar?.url ?? "";
  }

  get groupedMatchIds() {
    if (this.match.grouped === true)
      return this.match.groupedMatchIds;
    else
      return [this.match.id];
  }

  submitResult() {
    this.$emit("openSubmitResult", this.match);
  }

  getParticiapntLink (id) {
    return this.isTeamBased ?
      {name: 'Team', params: { teamId: id }}
      : {name: 'Players', params: { userId: id }};
  }
}
