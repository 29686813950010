
































import { Component, Vue, Prop } from "@components/common";
import { DateTime } from "luxon";
import Match from "@graphql/types/match";
import Event from "@graphql/types/event";

@Component
export default class MatchCardMeta extends Vue {
  @Prop({ default: 1 }) private priority?: number;
  @Prop({ default: '' }) private timeLeft?: string;
  @Prop({default: "mdi-clock-time-eight-outline"}) private icon!: string;
  @Prop() private readonly match!: Match;
  @Prop() private readonly event!: Event;

  // TODO: These are all copied from MatchCardBasics and should exist in a unified location
  get playPeriod() {
    const match = this.match;
    const index = match.index ?? -1; // -1 is okay, arr[-1] is always undefined
    const config = this.eventFlowStage?.configuration?.["play_periods"]?.[index] ?? {};

    return {
      startTime: config?.start_time ?? match.originalStartTime,
      endTime: config?.end_time ?? match.originalEndTime
    };
  }

  get scheduleLineTime() {
    return this.isStartTimeConfirmed ? this.startTime : this.formattedLatestStartDatetime;
  }

  // So much copypaste
  get hasConfiguredPlayPeriod() {
    const match = this.match;

    // This actually can't happen.
    if (!match?.index)
      return false;

    return !!this.eventFlowStage?.configuration?.["playPeriods"]?.[match.index];
  }

  get isPlayPeriodSameYear() {
    return DateTime.fromISO(this.playPeriod.startTime).year === DateTime.fromISO(this.playPeriod.endTime).year;
  }

  get playPeriodStart() {
    if (this.isPlayPeriodSameYear) {
      let parts = DateTime.fromISO(this.playPeriod.startTime).toLocaleParts(DateTime.DATE_SHORT);
      const index = parts.findIndex(part => part.type == "year");

      return parts.slice(0, index - 1).map(p => p.value).join("");
    } else {
      return DateTime.fromISO(this.playPeriod.endTime).toLocaleString(DateTime.DATE_SHORT);
    }
  }

  get playPeriodEnd() {
    return DateTime.fromISO(this.playPeriod.endTime).toLocaleString(DateTime.DATE_SHORT);
  }

  get eventFlowStage() {
    return this.event?.eventFlowStages?.find(s => s.id == this.match.eventFlowStageId);
  }

  get startTime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_MED);
  }

  get isStartTimeConfirmed() {
    return this.match.isStartTimeConfirmed;
  }

  get formattedLatestStartDatetime() {
    const latestStartTime = DateTime.fromISO(this.match.originalEndTime).minus({seconds: this.event.matchDuration});

    return DateTime.fromISO(latestStartTime).toLocaleString(DateTime.DATETIME_MED);
  }
}
