import gql from "graphql-tag";

export default gql`
    mutation LeaveTournament(
        $tournamentId: ID!
        $asTeam: Boolean
        $teamId: ID
    ) {
        leaveTournament(tournamentId: $tournamentId, asTeam: $asTeam, teamId: $teamId) {
            success
        }
    }
`;