import gql from "graphql-tag";
import Base from "@graphql/types/base";

import { selectable as s } from "@store/generic_store";

export const NotificationFields = gql`
  fragment NotificationFields on Notification {
    id
    notificationType
    data
    userId
    isRead
  }
`;

export default class Notification extends Base<Notification> {
  @s() id?: number;
  @s() notificationType?: string;
  @s() data?: Record<string, any>;
  @s() userId?: number;
  @s() isRead?: boolean;
}
