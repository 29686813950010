




































import { Component, Vue, Prop } from "@components/common";

import DivisionsPlayoffs from "@components/events/playoffs/divisions_playoffs.vue";
import GroupPlayoffs from "@components/events/playoffs/group_playoffs.vue";
import Heading from "@components/v2/Heading.vue";
import SimplePlayoffs from "@components/events/playoffs/simple_playoffs.vue";

import Event from "@graphql/types/event";

@Component({
  components: {
    DivisionsPlayoffs,
    GroupPlayoffs,
    Heading,
    SimplePlayoffs,
  }
})
export default class EventplayoffsTab extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly playoffs!: any[];

  private panels: number[] = [];

  expandOwnGroup(): void {
    this.panels = [0];

    const myParticipation = this?.event?.participations?.find(p => p.id == this.currentUserId);

    if (!myParticipation)
      return;

    const index = this.playoffsGroups.findIndex(g => g.id == myParticipation.playoffsGroupId);

    if (index != -1)
      this.panels = [index];
  }

  get playoffsGroups() {
    return this.event.playoffsGroups ?? [];
  }

  get isPlayoffsGroupMatches() {
    return this.event.eventFlow?.eventFlowStages?.some(efs => efs.flowType === "EventStageFlows::Playoffs::LeagueDivisionsPlayoffs");
  }

  get hasSimplePlayoffsMatches() {
    return this.event.eventFlow?.eventFlowStages?.some(efs => efs.flowType === "EventStageFlows::Playoffs::SimplePlayoffs");
  }

  get hasGroupPlayoffs() {
    return this.event.eventFlow?.eventFlowStages?.some(efs => efs.flowType === "EventStageFlows::Playoffs::GroupPlayoffs") || false;
  }

  mounted() {
    this.$nextTick(() => this.expandOwnGroup());
  }
}
