















import { Component, Vue, Prop } from "@components/common";
import Event from "@graphql/types/event";
import Match from "@graphql/types/match";
import Brackets from "@components/matches/brackets.vue";
import MatchResult from "@graphql/types/match_result";

import { GetMatches } from "@graphql/queries/matches";

import { groupBy } from "underscore";

import { namespace } from "@/store";

const AuthenticationState = namespace("authentication");
const GROUP_PLAYOFFS_FLOW_STAGE = "EventStageFlows::Playoffs::GroupPlayoffs";

interface GroupedMatch extends Match {
  groupedMatchIds: number[];
  groupedResults: MatchResult[];
}

@Component({
  components: { Brackets },
  apollo: {
    matches: {
      query: GetMatches,
      variables() { return { filters: { eventFlowStageId: this.eventFlowStageId }}; },
      result() { this.loading = false; },
    },
  }
})

export default class GroupPlayoffs extends Vue {
  @Prop() readonly event!: Event;

  private matches: Match[] = [];
  private tab = null
  private filterMyMatches = true;
  private loading = true;

  @AuthenticationState.State
  private teamRoles;

  get eventFlowStageId(): number {
    return Number(this.eventFlowStage?.id);
  }

  get groupedMatches() {
    const groupedMatches = (Object.values(groupBy(this.matches, "identifier")) as Match[][]).map(
      matches => ({
        ...matches[0],
        groupedMatchIds: matches.map(m => m.id),
        groupedResults: matches.map(m => m.result)
      } as GroupedMatch)
    );

    return groupedMatches;
  }


  get eventFlowStage() {
    return this.event.eventFlow?.eventFlowStages?.find(efs => efs.flowType === GROUP_PLAYOFFS_FLOW_STAGE);
  }

  get participants() {
    if (this.event.isUserBased)
      return this.event.participatingUsers;
    else
      return this.event.participatingTeams;
  }

  private noDates(dates) {
    return Object.keys(dates).length;
  }

  // just group matches need to be here

  get teamIds () {
    return (this.teamRoles ?? []).map(id => id.teamId);
  }

  get myMatches() {
    return (this.matches ?? []).filter(match =>
      match.participations?.some(p => p.id == this.currentUserId || this.teamIds.includes(p.id ?? 0))
    );
  }

  get hasMatches() {
    return !!this.myMatches.length;
  }
}
