import gql from "graphql-tag";
import { DetailedEventFields } from "@graphql/types/event";

export default gql`
    mutation LeaveEvent(
        $id: ID!
        $asTeam: Boolean
        $teamId: ID
    ) {
        leaveEvent(id: $id, asTeam: $asTeam, teamId: $teamId) {
            success
            event {
              ...DetailedEventFields
            }
        }
    }
    ${DetailedEventFields}
`;
