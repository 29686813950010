import gql from "graphql-tag";
import { UserFields } from "@graphql/types/user";

export const ListUsers = gql`
  query ListUsers($filters: UsersFilterInput) {
    users(filters: $filters) {
      ...UserFields
    }
  }
  ${UserFields}
`;

export const ListUsersWithTeamIds = gql`
  query ListUsersWithTeamIds($filters: UsersFilterInput) {
    users(filters: $filters) {
      ...UserFields
      teamIds
    }
  }
  ${UserFields}
`;
