import gql from "graphql-tag";

import { ConversationFields } from "@graphql/types/conversation";
import { ConversationMessageFields } from "@graphql/types/conversation_message";

export const GetConversation = gql`
  query GetConversation($eventId: ID!) {
    conversation(eventId: $eventId) {
      ...ConversationFields
    }
  }
  ${ConversationFields}
`;

export const GetConversationMessages = gql`
  query GetConversationMessages($eventId: ID!) {
    conversationMessages(eventId: $eventId) {
      ...ConversationMessageFields
    }
  }
  ${ConversationMessageFields}
`;
