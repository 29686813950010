import gql from "graphql-tag";
import Base from "@graphql/types/base";

import { selectable as s } from "@store/generic_store";

export const MatchResultFields = gql`
  fragment MatchResultFields on MatchResult {
    id
    draw
    winnerId
    submitterId
    confirmerId
    scoring
    data
    scores
    createdAt
  }
`;

export default class MatchResult extends Base<MatchResult> {
  @s() id?: number;
  @s() draw?: boolean;
  @s() winnerId?: number;
  @s() submitterId?: number;
  @s() confirmerId?: number;
  @s() scoring?: number;
  @s() data?: Record<string, any>;
  @s() scores?: Record<number, number>;
  @s() createdAt?: string;
}
