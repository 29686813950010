







































































































































































































































































import { Component, Vue, Prop, Divider, Watch } from "@components/common";
import Match from "@graphql/types/match";
import Event from "@graphql/types/event";
import CardDetailHeader from "@components/cups/card_detail_header.vue";
import { GetMatchWithResult } from "@graphql/queries/match";
import MatchResult from "@graphql/types/match_result";

import { notEmpty } from "@lib/validations";

interface VueForm {
  validate();
}

@Component({
  components: { CardDetailHeader, Divider },
  apollo: {
    match: {
      skip() { return !this.matchId; },
      query: GetMatchWithResult,
      variables() { return {id: this.matchId}; },
      pollInterval: 600_000
    }
  }
})
export default class EditMatchScore extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly matchId!: number;
  @Prop() readonly show!: boolean;

  private match?: Match;
  private firstNewScore = null;
  private secondNewScore = null;
  private initiatorId = null;
  private editReason = ""
  private overtime = false;
  private draw = false;

  private resetMatch = false;
  private resetStatus = true;
  private resetResult = true;
  private resetTimes = true;
  private resetScheduling = true;


  @Watch("resetResult")
  setResetStatusFlag(val) {
    if (val === true)
      this.resetStatus = true;
  }

  @Watch("resetStatus")
  setResetStatusFlagSelf(val) {
    if (val === false && this.resetResult === true)
      this.$nextTick(() => this.resetStatus = true);
  }

  get overtimeCheckbox() {
    return this.match?.result?.data?.['overtime'] ?? false;
  }

  set overtimeCheckbox(val) {
    const match = this.match;

    if (match) {
      if (!match.result) {
        match.result = new MatchResult({data: { overtime: val}});
      }
    }
  }

  // TODO: Reasons only as values - translatable text
  private reasons = [
    {value: "app-not-working", text: "App lief nicht"},
    {value: "opponent-did-not-respond", text: "Gegner hat nicht auf Einladung reagiert"},
    {value: "noshow", text: "No Show am Match"},
    {value: "not-checked-in", text: "Nicht eingecheckt"},
    {value: "forgot-result", text: "Resultat vergessen einzugeben"},
  ]

  get currentFirstScore() {
    return this.match?.result?.scores?.[this.firstParticipant.id];
  }

  get currentSecondScore() {
    return this.match?.result?.scores?.[this.secondParticipant.id];
  }

  get gameId() {
    return this.event.gameId ?? -1;
  }

  get gameName() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }

  get game() {
    return this.gameById(this.gameId);
  }

  get opponentName() {
    return this.opponent?.name ?? "";
  }

  get firstParticipantResult() {
    return Number(this.firstNewScore);
  }

  get secondParticipantResult() {
    return Number(this.secondNewScore);
  }

  get firstParticipantAvatar() {
    return this.firstParticipant.avatar?.url ?? "";
  }

  get secondParticipantAvatar() {
    return this.secondParticipant.avatar?.url ?? "";
  }

  get opponent() {
    if (this.currentUserId == this.firstParticipant?.id)
      return this.secondParticipant;
    else
      return this.firstParticipant;
  }

  @Watch("matchId")
  reloadMatch() {
    this.$apollo.queries.match.refetch();
  }

  get firstParticipation() {
    return this.match?.participations?.[0];
  }

  get secondParticipation() {
    return this.match?.participations?.[1] ?? {};
  }

  get firstParticipant() {
    return this.match?.participations?.[0]?.participant;
  }

  get secondParticipant() {
    return this.match?.participations?.[1]?.participant ?? {};
  }

  get winnerId() {
    if (this.firstParticipantResult > this.secondParticipantResult)
      return this.firstParticipant.id;
    else if (this.secondParticipantResult > this.firstParticipantResult)
      return this.secondParticipant.id;
    else
      return null;
  }

  get hasResult() {
    return !!this.match?.result;
  }

  get scores() {
    return [
      {
        participantId: this.firstParticipant.id,
        score: this.firstParticipantResult
      },
      {
        participantId: this.secondParticipant.id,
        score: this.secondParticipantResult
      },
    ];
  }

  get isDraw() {
    return this.firstParticipantResult == this.secondParticipantResult;
  }

  async submitResult() {
    const form = this.$refs.form as unknown as VueForm;

    if (!form || !form.validate())
      return;

    const result = await this.$store.dispatch("matches/submitAdminResult", {
      matchId: this.match?.id,
      scores: this.scores,
      reason: this.editReason,
      initiatorId: this.initiatorId,
      resetMatch: this.resetMatch,
      resetMatchParams: {
        resetStatus: this.resetStatus,
        resetResult: this.resetResult,
        resetTimes: this.resetTimes,
        resetScheduling: this.resetScheduling,
      },
      data: { overtime: this.isOvertime, draw: this.isManualDraw }
    });

    if (result.success) {
      this.$gtag.event('admin_match_submit_score', {
        'event_category': 'match_action',
        'event_label': 'Override or submit match score (admin)',
        'value': { userId: this.currentUserId, matchId: this.match?.id, eventId: this.event.id }
      });
      this.match = { ...this.match, result: result.matchResult};

      this.$eventBus.$emit("match:update-result", {
        matchId: this.match.id,
        result: this.match.result
      });

      this.$notify({text: this.$t("match.result_card.result_submitted").toString()});
      this.close();
    }
  }

  get isManualDraw() {
    const game = this.game;

    if (!game)
      return false;

    return game.allowsManualDraw && this.draw;
  }

  get isOvertime() {
    const game = this.game;

    if (!game)
      return false;

    return game.allowsOvertime && this.overtime;
  }

  close() {
    this.$emit("close");
    this.$emit("refreshTournament");
  }

  canOnlyDrawIfDivisions() {
    return this.match?.structure == "DIVISION" ||
      !(this.firstParticipantResult == this.secondParticipantResult);
  }

  get eitherParticipantInitiator() {
    return !!this.initiatorId;
  }

  notEmpty = notEmpty;
}
