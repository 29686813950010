















import { Component, Vue, Prop } from "@components/common";
import Event from "@graphql/types/event";
import Match from "@graphql/types/match";
import Brackets from "@components/matches/brackets.vue";
import MatchResult from "@graphql/types/match_result";

import { GetDivisionsMatches } from "@graphql/queries/matches";

import { groupBy } from "underscore";

import { namespace } from "@/store";

const AuthenticationState = namespace("authentication");
const DIVISION_PLAYOFFS_FLOW_STAGE = "EventStageFlows::Playoffs::LeagueDivisionsPlayoffs";

interface GroupedMatch extends Match {
  groupedMatchIds: number[];
  groupedResults: MatchResult[];
}

@Component({
  components: { Brackets },
  apollo: {
    matches: {
      query: GetDivisionsMatches,
      variables() { return { filters: { eventFlowStageId: this.eventFlowStageId }}; },
      result() { this.loading = false; },
    },
  }
})

export default class DivisionsPlayoffs extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly selectedPlayoff: any;

  private matches: Match[] = [];
  private tab = null
  private filterMyMatches = true;
  private loading = true;

  @AuthenticationState.State
  private teamRoles;

  get eventFlowStageId(): number {
    return Number(this.eventFlowStage?.id);
  }


  get groupedMatches() {
    const groupedMatches = (Object.values(groupBy(this.selectedPlayoffs?.matches, "identifier")) as Match[][]).map(
      matches => ({
        ...matches[0],
        groupedMatchIds: matches.map(m => m.id),
        groupedResults: matches.map(m => m.result)
      } as GroupedMatch)
    );

    return groupedMatches;
  }

  get selectedPlayoffs() {
    return this.playoffGroups?.find(pg => pg.id === this.selectedPlayoffTag);
  }

  get eventFlowStage() {
    return this.event.eventFlow?.eventFlowStages?.find(efs => efs.flowType === DIVISION_PLAYOFFS_FLOW_STAGE);
  }

  get playoffGroups() {

    return this.event.playoffsGroups?.map(pp => (
      {
        id: pp.id,
        tag: pp.tag,
        matches: this.playoffGroupMatchesById(pp.id)
      }
    ));
  }


  private playoffGroupMatchesById(playoffsGroupId) {
    return this.matches.filter(m => m.playoffsGroupId == playoffsGroupId);
  }

  get selectedPlayoffTag() {
    return this.selectedPlayoff.id;
  }

  get participants() {
    if (this.event.isUserBased)
      return this.event.participatingUsers;
    else
      return this.event.participatingTeams;
  }

  private noDates(dates) {
    return Object.keys(dates).length;
  }

  // just group matches need to be here

  get teamIds () {
    return (this.teamRoles ?? []).map(id => id.teamId);
  }

  get myMatches() {
    return (this.matches ?? []).filter(match =>
      match.participations?.some(p => p.id == this.currentUserId || this.teamIds.includes(p.id ?? 0))
    );
  }

  get hasMatches() {
    return !!this.myMatches.length;
  }

  updateMatch(match) {
    console.log(`Updating match ${match.id} in DivisionsMatches.`);

    const matches = this.matches;

    if (!matches || matches.length == 0)
      return;

    const index = matches.map(m => m.id).indexOf(match.id);

    if (index !== -1)
      matches[index] = match;
  }

  updateMatchResult({matchId, result}) {
    console.log(`Updating match ${matchId} result in DivisionsMatches.`);

    const matches = this.matches;

    if (!matches || matches.length == 0)
      return;

    const index = matches.map(m => m.id).indexOf(matchId);

    if (index != -1)
      this.matches[index] = { ...this.matches[index], result: result};
  }

  mounted() {
    this.$eventBus.$on("match:update", this.updateMatch);
    this.$eventBus.$on("match:update-result", this.updateMatchResult);
  }

  unmounted() {
    this.$eventBus.$off("match:update", this.updateMatch);
    this.$eventBus.$off("match:update-result", this.updateMatchResult);
  }
}
