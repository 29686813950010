





















import { Prop, Component, Vue } from "@components/common";
import { DateTime } from "luxon";

@Component
export default class NewsCard extends Vue {
  @Prop() readonly coverImageUrl!: string;
  @Prop() readonly title!: string;
  @Prop() readonly date!: string;
  @Prop() readonly caption!: string;

  getFormatedDate(date) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
  }
}
