













import { Component, Vue, Prop } from "@components/common";

enum Priority {
  Low = 1,
  Medium,
  High,
  Green
}

@Component
export default class Expander extends Vue {
  @Prop() public value?: boolean;
  @Prop({default: Priority.Low}) priority!: Priority;

  private readonly priorityClasses = {
    [Priority.Low]:    "priority-normal",
    [Priority.Medium]: "priority-high",
    [Priority.High]:   "priority-urgent",
    [Priority.Green]:  "priority-green"
  };
}
