








import { Component, Page, Layout } from "@components/common";
import { namespace } from "@store";

const MyMatchesStore = namespace("myMatches");
const MyRoundsStore = namespace("myRounds");

@Component({ components: { Layout }})
export default class Matches extends Page {

  @MyMatchesStore.Getter
  public pendingMatchesCount!: number;

  @MyMatchesStore.Getter
  public activeMatchesCount!: number;

  @MyRoundsStore.Getter
  public pendingRoundsCount!: number;

  @MyRoundsStore.Getter
  public activeRoundsCount!: number;

  get tabs() {
    return  [
      'matches.tab.myenrolments',
      ['matches.tab.mymatches', { badge: this.activeMatchesCount + this.activeMatchesCount }],
      ['matches.tab.pending', { badge: this.pendingMatchesCount + this.activeRoundsCount }],
      'matches.tab.archive',
    ];
  }
}
