import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";
import gql from "graphql-tag";
import createStore, { getFullSelection } from "@store/generic_store";

import Team from "@graphql/types/team";

import UploadAvatar from "@graphql/mutations/upload_team_avatar";
import UploadCoverImage from "@graphql/mutations/upload_team_avatar";
import CreateTeam from "@graphql/mutations/create_team";
import UpdateTeam from "@graphql/mutations/update_team";
import AddTeamMember from "@graphql/mutations/add_team_member";
import RemoveTeamMember from "@graphql/mutations/remove_team_member";

export default class Teams extends createStore({
  name: "team",
  recordType: Team,
}) {
  @Action({ rawError: true })
  async uploadAvatar({ teamId, avatar }) {
    const {
      data: { uploadTeamAvatar: result },
    } = await apollo.mutate({
      mutation: UploadAvatar,
      variables: { teamId, avatar },
      context: { hasUpload: true },
    });

    return result;
  }

  @Action({ rawError: true })
  async uploadCoverImage({ teamId, coverImage }) {
    const {
      data: { uploadCoverImage: result },
    } = await apollo.mutate({
      mutation: UploadCoverImage,
      variables: { teamId, coverImage },
      context: { hasUpload: true },
    });

    return result;
  }

  @Action({ rawError: true })
  async create({ name, avatar = null, coverImage = null }) {
    const {
      data: { createTeam: result },
    } = await apollo.mutate({
      mutation: CreateTeam,
      variables: { attributes: { name, avatar, coverImage } },
    });

    return result;
  }

  @Action({ rawError: true })
  async update({ id, attributes }) {
    const {
      data: { updateTeam: result },
    } = await apollo.mutate({
      mutation: UpdateTeam,
      variables: { id, attributes },
    });

    return result;
  }

  @Action({ rawError: true })
  async addMember({ teamId, userId }) {
    const {
      data: { addTeamMember: result },
    } = await apollo.mutate({
      mutation: AddTeamMember,
      variables: { teamId, userId },
    });

    return result;
  }

  @Action({ rawError: true })
  async removeMember({ teamId, userId }) {
    const {
      data: { removeTeamMember: result },
    } = await apollo.mutate({
      mutation: RemoveTeamMember,
      variables: { teamId, userId },
    });

    return result;
  }

  @Action({ rawError: true })
  async searchBest({
    filters,
    selection = this.recordSelectables,
    extraSelection = [],
    page = 1,
    limit = 50,
    noCache = false,
    order = "active",
  }) {
    const fullSelection = getFullSelection(selection, extraSelection);

    const { data } = await apollo.query({
      query: gql`
        query SearchBestTeams(
          $filters : ${this.capitalizedPluralRecordName}FilterInput
          $page : Int
          $limit : Int
          $order : String
        ) {
          ${
            this.pluralRecordName
          }(filters: $filters, page : $page, limit : $limit, order: $order) {
            ${fullSelection.join("\n")}
          }
        }`,
      variables: { filters, page, limit, order },
      fetchPolicy: noCache ? "no-cache" : undefined,
    });

    const records = data[this.pluralRecordName];
    const ids = records.map((r) => r.id);

    this.context.commit("commitRecords", {
      records: data[this.pluralRecordName],
      selection: fullSelection,
    });

    return ids.map((id) => this.records[id].record);
  }
}
