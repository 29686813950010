















import MyMatchesMatchCard from '@pages/matches/my_matches_match_card.vue';
import FeedMatchCard from "@components/feed/feed_match_card.vue";
import { Page, Component, Layout } from "@components/common";
import Match from '@/graphql/types/match';
import Event from "@graphql/types/event";
import { sortBy, uniq } from "underscore";
import { namespace } from "@store";
import { DateTime } from "luxon";

const MyMatchesStore = namespace("myMatches");
const MyEnrolmentsStore = namespace("myEnrolments");

@Component({
    components: {
        Layout,
        MyMatchesMatchCard,
        FeedMatchCard
    },
})

export default class MyActiveMatches extends Page {

    @MyEnrolmentsStore.Getter("tournaments")
    public myTournaments!: Event[];

    @MyEnrolmentsStore.Getter("events")
    public myEvents!: Event[];

    @MyMatchesStore.Getter("matches")
    public myMatches!: Match[];

    get myActiveMatches() {
        return this.myMatches;
    }

    get matchStartTimes() {
        return this.myMatches.map( m => ( {
            startTime: DateTime.fromISO(m.startTime),
            endTime: DateTime.fromISO(m.endTime),
            now: DateTime.local(),
            isActive: DateTime.fromISO(m.startTime) < DateTime.local(),
            hasEnded: DateTime.fromISO(m.endTime) < DateTime.local()
        }));
    }

    get myCaptainedActiveMatches() {
        if (this.myCaptainedTeams === undefined ) return false;
        return this.myActiveMatches.filter(m => m.participations?.some(p => this.myCaptainedTeams?.some(t => t.id == p.id)));
    }

    get myCaptainedTeams() {
        if (! this.currentUser?.teams) return undefined;
        return this.currentUser?.teams?.filter(t => t.captain?.id === this.currentUserId);
    }

    isEventUserBased(event) {
        return event.mode === "1v1";
    }


    get roundsAndMatchesEvents() {
        const events = {};
        const eventIds =
        uniq(this.myActiveMatches.map(m => (
            {
                identifier: this.eventIdentifier(m.eventId, m.eventType)
            }
            )
        ),
        id => id.identifier);

        eventIds.forEach(e => {
            events[e.identifier] = this.getEventByIdentifier(e.identifier);
        });

        return events;
    }

    private matchEvent(identifier) {
        return this.roundsAndMatchesEvents[identifier];
    }

    private eventIdentifier(eventId, eventType) {
        return `${eventType}.${eventId}`;
    }

    private getEventByIdentifier(identifier) {
        const tmp = identifier.split(".");

        const eventType = tmp[0];
        const eventId = tmp[1];

        switch(eventType) {
        case "Event" : return this.myEvents.find(l => l.id == eventId);
        case "Tournament": return this.myTournaments.find(l => l.id == eventId);
        default: return new Event;
        }

    }


    get hasMatches() {
        if (this.sortedMatches === undefined) return false;
        if (this.sortedMatches.length > 0) return true;
        return false;
    }

    get sortedMatches() {
        return sortBy(this.myActiveMatches, function(o) { return o.startTime; });
    }

    refreshPendingConfiormationMatches() {
        this.$store.dispatch("myMatches/reloadMyPendingMatches");
    }

    }
    