















import MyMatchesMatchCard from '@pages/matches/my_matches_match_card.vue';
import FeedMatchCard from "@components/feed/feed_match_card.vue";
import { Page, Component, Layout } from "@components/common";
import Match from '@/graphql/types/match';
import Event from "@graphql/types/event";
import { sortBy, uniq, union } from "underscore";
import { namespace } from "@store";

const MyMatchesStore = namespace("myMatches");
const MyEnrolmentsStore = namespace("myEnrolments");

@Component({
    components: {
        Layout,
        MyMatchesMatchCard,
        FeedMatchCard
    },
})

export default class MyPendingMatches extends Page {

    @MyEnrolmentsStore.Getter("tournaments")
    public myTournaments!: Event[];

    @MyEnrolmentsStore.Getter("events")
    public myEvents!: Event[];

    @MyMatchesStore.Getter("pendingMatches")
    public myMatches!: Match[];

    get myPendingConfirmationMatches() {
        return this.myMatches.filter(m => (
            (m.startTimeLocked !== true) &&
            (m.isStartTimeConfirmed !== true) &&
            (m.startTimeProposedById != this.currentUser?.id) &&
            (m.proposedStartTime !== null)
            )
        );
    }

    get myCaptainedPendingMatches() {
        if (this.myCaptainedTeams === undefined ) return false;
        return this.myPendingConfirmationMatches.filter(m => m.participations?.some(p => this.myCaptainedTeams?.some(t => t.id == p.id)));
    }

    get myPendingConfirmation1v1Matches() {
        return this.myPendingConfirmationMatches.filter(m => {return (this.isEventUserBased(this.matchEvent(this.eventIdentifier(m.eventId, m.eventType))));});
    }

    get myPendingMatches() {
        return union(this.myCaptainedPendingMatches, this.myPendingConfirmation1v1Matches);
    }

    get myCaptainedTeams() {
        if (! this.currentUser?.teams) return undefined;
        return this.currentUser?.teams?.filter(t => t.captain?.id === this.currentUserId);
    }

    isEventUserBased(event) {
        return event.mode === "1v1";
    }


    get roundsAndMatchesEvents() {
        const events = {};
        const eventIds =
        uniq(this.myPendingConfirmationMatches.map(m => (
            {
                identifier: this.eventIdentifier(m.eventId, m.eventType)
            }
            )
        ),
        id => id.identifier);

        eventIds.forEach(e => {
            events[e.identifier] = this.getEventByIdentifier(e.identifier);
        });

        return events;
    }

    private matchEvent(identifier) {
        return this.roundsAndMatchesEvents[identifier];
    }

    private eventIdentifier(eventId, eventType) {
        return `${eventType}.${eventId}`;
    }

    private getEventByIdentifier(identifier) {
        const tmp = identifier.split(".");

        const eventType = tmp[0];
        const eventId = tmp[1];

        switch(eventType) {
        case "Event" : return this.myEvents.find(l => l.id == eventId);
        case "Tournament": return this.myTournaments.find(l => l.id == eventId);
        default: return new Event;
        }

    }


    get hasMatches() {
        if (this.sortedMatches === undefined) return false;
        if (this.sortedMatches.length > 0) return true;
        return false;
    }

    get sortedMatches() {
        return sortBy(this.myPendingMatches, function(o) { return o.startTime; });
    }

    refreshPendingConfiormationMatches() {
        this.$store.dispatch("myMatches/reloadMyPendingMatches");
    }

    }
    