import gql from "graphql-tag";
import Base from "@graphql/types/base";

export const ConversationMessageFields = gql`
  fragment ConversationMessageFields on ConversationMessage {
    id
    senderId
    conversationId
    text
    createdAt
    custom
    origin
    type
  }
`;

export default class ConversationMessage {
  id?: string;
  senderId?: string;
  conversationId?: string;
  text?: string;
  createdAt?: number;
  custom?: Record<string, string>;
  origin?: string;
  type?: string;

  constructor(props: Partial<ConversationMessage>) {
    Object.assign(this, props);
  }
}
