





import { Component, Prop, Vue } from "@components/common";
import User from "@graphql/types/user";

@Component
export default class UserLink extends Vue {
  @Prop({
    default: () => ({name: ""})
  })
  readonly user!: User
}
