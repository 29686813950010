import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";
import createStore from "@store/generic_store";
import User from "@graphql/types/user";

import AcceptTeamInvitation from "@graphql/mutations/accept_team_invitation";
import ActivateUser from "@graphql/mutations/activate_user";
import CREATE_USER_MUTATION from "@graphql/mutations/create_user";
import InviteTeamMember from "@graphql/mutations/invite_team_member";
import RequestPasswordReset from "@graphql/mutations/request_password_reset";
import ResetUserPassword from "@graphql/mutations/reset_user_password";

import gql from "graphql-tag";
import { getFullSelection } from "@/store/generic_store";

@Module({ namespaced: true })
export default class Users extends createStore({
  recordType: User,
  name: "user",
}) {
  @Action({ rawError: true })
  async register({ email, password, attributes }) {
    const { data: createUser } = await apollo.mutate({
      mutation: CREATE_USER_MUTATION,
      variables: { email, password, attributes },
    });

    return createUser;
  }

  @Action({ rawError: true })
  async inviteToTeam({ teamId, userId }) {
    const {
      data: { sendTeamInvitation: result },
    } = await apollo.mutate({
      mutation: InviteTeamMember,
      variables: { teamId, userId },
    });

    return result;
  }

  @Action({ rawError: true })
  async acceptTeamInvitation({ invitationId }) {
    const {
      data: { acceptTeamInvitation: result },
    } = await apollo.mutate({
      mutation: AcceptTeamInvitation,
      variables: { invitationId },
    });

    return result;
  }

  @Action({ rawError: true })
  async activate({ token, password }) {
    const {
      data: { activateUser: result },
    } = await apollo.mutate({
      mutation: ActivateUser,
      variables: { token, password },
    });

    this.context.dispatch("authentication/applyAuth", result, { root: true });

    return result;
  }

  @Action({ rawError: true })
  async requestPasswordReset({ email }) {
    const {
      data: { requestPasswordReset: result },
    } = await apollo.mutate({
      mutation: RequestPasswordReset,
      variables: { email },
    });

    return result;
  }

  @Action({ rawError: true })
  async resetPassword({ token, password }) {
    const {
      data: { resetUserPassword: result },
    } = await apollo.mutate({
      mutation: ResetUserPassword,
      variables: { token, password },
    });

    return result;
  }

  @Action({ rawError: true })
  async searchBest({
    filters,
    selection = this.recordSelectables,
    extraSelection = [],
    page = 1,
    limit = 50,
    noCache = false,
    order = "active",
  }) {
    const fullSelection = getFullSelection(selection, extraSelection);

    const { data } = await apollo.query({
      query: gql`
        query SearchBestUsers(
          $filters : ${this.capitalizedPluralRecordName}FilterInput
          $page : Int
          $limit : Int
          $order : String
        ) {
          ${
            this.pluralRecordName
          }(filters: $filters, page : $page, limit : $limit, order: $order) {
            ${fullSelection.join("\n")}
          }
        }`,
      variables: { filters, page, limit, order },
      fetchPolicy: noCache ? "no-cache" : undefined,
    });

    const records = data[this.pluralRecordName];
    const ids = records.map((r) => r.id);

    this.context.commit("commitRecords", {
      records: data[this.pluralRecordName],
      selection: fullSelection,
    });

    return ids.map((id) => this.records[id].record);
  }
}
