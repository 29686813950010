




































































import { Component, Vue, Prop } from "@components/common";
import { Datetime } from "vue-datetime";
import { DateTime, Interval } from "luxon";

import RoundHelpers, { RoundStage } from "@lib/helpers/round_card_helpers";

import Event from "@/graphql/types/event";
import PlayDay from "@/graphql/types/play_day";
import Round from "@/graphql/types/br_round";
import Team from "@/graphql/types/team";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  RoundCardBasics,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";

import BRSubmitRoundScore from "@components/events/active/BR_submit_round_score.vue";
import GetMatchKey from "@components/events/active/get_match_key.vue";

import 'vue-datetime/dist/vue-datetime.css';

const ROUND_PRIORITY_2_OFFSET = 72 * 3600;
const ROUND_PRIORITY_3_OFFSET = 24 * 3600;

@Component({
  components: {
    ActionBtn,
    BRSubmitRoundScore,
    CardBody,
    CardHeader,
    DateTimeInfo,
    Datetime,
    Expander,
    GetMatchKey,
    Meta,
    MetaLine,
    RoundCardBasics,
    UserAvatar,
  }
})
export default class ResultStage extends Vue {
  @Prop() private readonly round!: Round;

  @Prop() private readonly event!: Event;
  @Prop() private readonly myTeams!: Team[];
  @Prop() private readonly playDay!: PlayDay;

  @Prop() private readonly show!: boolean;

  private submitScoreDialog = false;

  closeSubmitScoreDialog() {
    this.submitScoreDialog = false;
  }

  // Common starting string for translations
  private loading = false;

  get roundName() {
    return this.roundHelpers.roundName;
  }

  get roundHelpers() {
    return new RoundHelpers(this.round, {
      me: this.currentUser,
      stage: RoundStage.Result,
      event: this.event,
      myTeams: this.myTeams,
      playDay: this.playDay,
    });
  }

  get hasOwnResult() {
    return !!this.round.results?.some(r => r.participationId == this.roundHelpers.myParticipation?.participationId);
  }

  get remainingTime() {
    this.ticker;

    return Interval.fromDateTimes(
      DateTime.local(),
      DateTime.fromISO(this.round.startTime)
    ).length("seconds");
  }

  get priority() {
    if (this.remainingTime < ROUND_PRIORITY_3_OFFSET)
      return 3;
    else if (this.remainingTime < ROUND_PRIORITY_2_OFFSET)
      return 2;
    else
      return 1;
  }

  get icon() {
    return "clock";
  }

  get expanded() {
    return this.show || this.priority > 2;
  }

  async loadData() {
    this.loading = true;

    Promise.all([
      // lol
    ]).finally(() => {
      this.loading = false;
    });
  }

  // Reusing old components ...
  get translationVariables () {
    const round = this.round;

    if (!round)
      return {};

    return {
      roundStartTime: this.formattedTime(round.startTime),
      roundStartDate: this.formattedTime(round.startTime, "DATE_MED"),
      roundEndTime: this.formattedTime(round.endTime),
      roundEndDate: this.formattedTime(round.endTime, "DATE_MED"),
      roundNumber: round.roundNumber,
      round: round.roundNumber,
      roundHostKey: round.hostKey,
    };
  }

  formattedTime(time, format = "TIME_SIMPLE") {
    return DateTime.fromISO(time).toLocaleString(DateTime[format]);
  }

  private timer: ReturnType<typeof setInterval> | null = null;
  private ticker = 0;

  mounted() {
    this.timer = setInterval(this.tick, 1000);

    this.loadData();
  }

  unmounted() {
    const timer = this.timer;
    if (timer)
      clearInterval(timer);
  }

  tick() {
    this.ticker += 1;
  }
}
