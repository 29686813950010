

















import { Page, Component, Prop, Layout, FormLabel, Divider } from "@components/common";
import { FindTeamWithDetails } from "@graphql/queries/team";
import TeamForm from "@components/teams/form.vue";
import Team from "@graphql/types/team";

@Component({
  components: { Layout, FormLabel, Divider, TeamForm },
  apollo: {
    team: {
      query: FindTeamWithDetails,
      variables() { return { id: this.teamId };}
    }
  }
})
export default class EditTeam extends Page {
  @Prop() teamId!: number;
  private team!: Team;

  updateTeam(team) {
    this.team = team;
  }
}

