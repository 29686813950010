import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import Tournament from "@graphql/types/tournament";
import Event from "@graphql/types/event";
import eventBus from "@/event_bus";
import { ListMyEventsCards } from "@graphql/queries/events";
import { ListMyTournamentsCards } from "@graphql/queries/tournaments";
import { createApollo } from "@lib/graphql";
import { store } from "@store";
import { union } from "underscore";

const apollo = createApollo();

const INVALIDATION_NOTIFICATION_NAME_PARTS = [
  "user_event_left",
  "team_event_left",
  "joined_event_as_team",
  "joined_event_as_user",
  "joined_battle_royale_event_as_user",
];

@Module({ namespaced: true })
export default class MyEnrolments extends VuexModule {
  private loaded = false;
  private myTournaments: Tournament[] = [];
  private myEvents: Event[] = [];

  constructor(stuff) {
    super(stuff);

    setInterval(() => {
      store.dispatch("myEnrolments/reload");
    }, 600_000); // 10 min

    /*  For time being all websocket based refreshes are suspended, should not query BE !!!
    // ALERT !!! - this causes for all current users to refresh at once ->> CRASH APP

    ---------------------!!!!!!!!!!!!!!--------------------------------

    eventBus.$on("notification:received", notification => {
      const invalidation = INVALIDATION_NOTIFICATION_NAME_PARTS.some(
        part => notification.notificationType.indexOf(part) != -1
      );

      console.log("Invalidating: ", invalidation);
      if (invalidation) {
        store.commit("myEnrolments/clear");
        store.dispatch("myEnrolments/reload");
      }
    }); */
  }

  get tournaments() {
    return this.myTournaments.map((t) => new Tournament(t));
  }

  get cups() {
    return this.myEvents
      .map((c) => new Event(c))
      .filter((e) => e.category === "CUP");
  }

  get events() {
    return this.myEvents.map((c) => new Event(c));
  }

  get count() {
    return union(this.events, this.tournaments).length;
  }

  @Action({ rawError: true })
  async reload() {
    this.context.dispatch("reloadMyTournaments");
    this.context.dispatch("reloadMyEvents");
  }

  @Action
  async reloadMyTournaments() {
    const {
      data: { myTournaments: result },
    } = await apollo.query({
      query: ListMyTournamentsCards,
      fetchPolicy: "no-cache",
    });

    this.context.commit("setMyTournaments", result);
  }

  @Action
  async reloadMyEvents() {
    const {
      data: { myEvents: result },
    } = await apollo.query({
      query: ListMyEventsCards,
      fetchPolicy: "no-cache",
    });

    this.context.commit("setMyEvents", result);
  }

  @Mutation
  addTournament(tournament) {
    if (!this.myTournaments.some((t) => t.id === tournament.id)) {
      this.myTournaments.push(tournament);
    }
  }

  @Mutation
  removeTournament(tournament) {
    const myTournament = this.myTournaments.find((c) => c.id === tournament.id);
    if (myTournament) {
      const index = this.myTournaments.indexOf(myTournament);
      this.myTournaments.splice(index);
    }
  }

  @Mutation
  addEvent(event) {
    if (!this.myEvents.some((l) => l.id === event.id)) {
      this.myEvents.push(event);
    }
  }

  @Mutation
  removeEvent(event) {
    const myEvent = this.myEvents.find((c) => c.id === event.id);
    if (myEvent) {
      const index = this.myEvents.indexOf(myEvent);
      this.myEvents.splice(index);
    }
  }

  @Mutation
  setMyTournaments(tournaments) {
    this.myTournaments = tournaments;
    this.loaded = true;
  }

  @Mutation
  setMyEvents(events) {
    this.myEvents = events;
    this.loaded = true;
  }

  @Mutation
  clear() {
    this.myEvents = [];
    this.myTournaments = [];
    this.loaded = false;
  }
}
