





























































import { GuestPage, Component, MainHeader, Layout, FormLabel } from "@components/common";

@Component({components: { MainHeader, Layout, FormLabel }})
export default class Login extends GuestPage {
  private email = ""
  private password = ""

  private valid = false;

  notEmpty(val: string) {
    return !!val && val.length > 0;
  }

  async submit() {
    if (!this.valid)
      return;

    await this.$store.dispatch("authentication/authenticate", {
      email: this.email,
      password: this.password
    });

    if (this.currentUser?.onboardingCompleted) {
      this.$router.push("/feed");
    } else {
      switch (this.currentUser?.onboardingStep) {
        case 0:
          this.$router.push("/register/1");
          break;
        default:
          this.$router.push("register/" + this.currentUser?.onboardingStep);
          break;
      }
    }
  }
}
