import Event from "@graphql/types/event";
import Match from "@graphql/types/match";
import Participation from "@graphql/types/participation";
import Team from "@graphql/types/team";
import User from "@graphql/types/user";

import { DateTime } from "luxon";
import { groupBy } from "underscore";

import i18n from "@/i18n";

type Participant = User | Team;

export enum MatchStage {
  Schedule = "ScheduleStage",
  Reschedule = "RescheduleStage",
  Checkin = "CheckinStage",
  Result = "ResultStage",
  Overview = "OverviewStage",
  Error = "ErrorStage",
}

const PLACEHOLDER_MATCH_DURATION = 3600;

export default class MatchCardHelpers {
  public me?: User;
  public matchStage?: MatchStage;
  public event?: Event;
  public myTeams?: Team[];

  constructor(
    public match: Match,
    public participants: Participant[],
    { matchStage, me, event, myTeams }: Partial<MatchCardHelpers> = {}
  ) {
    this.me = me;
    this.matchStage = matchStage;
    this.event = event;
    this.myTeams = myTeams;
  }

  formattedDateTime(datetime) {
    return DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get translationData() {
    const match = this.match;

    return {
      weekNumber: (match.index ?? 0) + 1,
      startTime: this.formattedDateTime(match.startTime),
      endTime: this.formattedDateTime(match.endTime),
      latestSchedulableTime: this.formattedDateTime(this.latestSchedulableTime),
      proposedStartTime: this.formattedDateTime(match.proposedStartTime),
    };
  }

  get timeProposalConfirmationPeriod() {
    const match = this.match;
    const event = this.event;

    if (!event || !event.eventFlowStages)
      throw new Error(
        "Cannot determine confirmation period without event and eventFlowStages"
      );

    return (
      event.eventFlowStages.find((s) => s.id == match.eventFlowStageId)
        ?.configuration?.["time_proposal_confirmation_period"] ?? 3600
    );
  }

  get _playPeriod() {
    const match = this.match;

    if (!match.index) return;

    const eventFlowStage = this.event?.eventFlowStages?.find(
      (stage) => stage.id == match.eventFlowStageId
    );

    return eventFlowStage?.configuration?.["playPeriods"]?.[match.index];
  }

  get playPeriod() {
    const match = this.match;
    const config = this._playPeriod ?? {};

    return {
      startTime: config?.startTime ?? match.originalStartTime,
      endTime: config?.endTime ?? match.originalEndTime,
    };
  }

  get hasConfiguredPlayPeriod() {
    return !!this._playPeriod;
  }

  get resultText() {
    const result = this.match?.result;
    const me = this.myParticipation;
    const opponent = this.opponentParticipation;

    if (!result?.id || !me?.id || !opponent?.id) return null;

    return [result?.scores?.[me.id], result.scores?.[opponent.id]].join(":");
  }

  get matchName() {
    const key = this.hasConfiguredPlayPeriod
      ? "activity_card.week_name"
      : "activity_card.match_name";

    return i18n.t(key, {
      weekNumber: (this.match.index ?? 0) + 1,
      week: (this.match.index ?? 0) + 1,
      playPeriodStart: this.playPeriodStart,
      playPeriodEnd: this.playPeriodEnd,
    });
  }

  get playPeriodStart() {
    if (this.isPlayPeriodSameYear) {
      const parts = DateTime.fromISO(this.playPeriod.startTime).toLocaleParts(
        DateTime.DATE_SHORT
      );
      const index = parts.findIndex((part) => part.type == "year");

      return parts
        .slice(0, index - 1)
        .map((p) => p.value)
        .join("");
    } else {
      return DateTime.fromISO(this.playPeriod.endTime).toLocaleString(
        DateTime.DATE_SHORT
      );
    }
  }

  get playPeriodEnd() {
    return DateTime.fromISO(this.playPeriod.endTime).toLocaleString(
      DateTime.DATE_SHORT
    );
  }

  get isPlayPeriodSameYear() {
    return (
      DateTime.fromISO(this.playPeriod.startTime).year ===
      DateTime.fromISO(this.playPeriod.endTime).year
    );
  }

  get isFreilos() {
    return this.match.participationsAssigned && !this.match.participations?.[1];
  }

  get matchCardStage() {
    const match = this.match;

    let startTime = DateTime.fromISO(match.startTime);
    const endTime = DateTime.fromISO(match.endTime);
    const currentTime = DateTime.local();

    const matchDuration = this.matchDuration;

    // Until start time is confirmed, we can consider all the matches not started
    // until the last possible moment. This simplifies our logic a lot later.
    if (!this.isStartTimeConfirmed)
      startTime = endTime.minus({ seconds: matchDuration });

    if (this.hasResult) {
      // Freilos matches start with a result, admins can also sometimes submit
      // results before schedule.
      return MatchStage.Overview;
    } else if (startTime > currentTime) {
      if (this.isStartTimeConfirmed) return MatchStage.Reschedule;
      else if (this.isStartTimeProposed && !this.isTimeProposedByMe)
        return MatchStage.Reschedule;
      else return MatchStage.Schedule;
    } else if (
      startTime < currentTime &&
      currentTime < this.checkinEndTime &&
      !this.bothParticipationsCheckedIn
    ) {
      return MatchStage.Checkin;
    } else if (currentTime < endTime && !this.hasResult)
      return MatchStage.Result;
    // TODO
    else return MatchStage.Overview;
  }

  get bothParticipationsCheckedIn() {
    return this.match?.checkins?.length == 2;
  }

  get hasResult() {
    return !!this.match?.result?.id;
  }

  get latestSchedulableTime() {
    return DateTime.fromISO(this.match.originalEndTime).minus({
      seconds: this.matchDuration,
    });
  }

  get matchDuration() {
    return this.event?.matchDuration ?? PLACEHOLDER_MATCH_DURATION;
  }

  intervalText(sec, ...args) {
    return MatchCardHelpers.intervalText(sec, ...args);
  }

  static intervalText(
    seconds,
    { short = false, maxLevels = 2, separator = " " } = {}
  ) {
    if (seconds != seconds || seconds < -120)
      return i18n.t("common.countdown.in_the_past");
    else if (seconds <= 0) return i18n.t("common.countdown.right_now");

    const parts = [
      { key: "weeks", duration: 60 * 60 * 24 * 7 },
      { key: "days", duration: 60 * 60 * 24 },
      { key: "hours", duration: 60 * 60 },
      { key: "minutes", duration: 60 },
      { key: "seconds", duration: 1 },
    ];

    const results: string[] = [];
    const baseKey = short ? "common.countdown.short" : "common.countdown";

    parts.forEach((part) => {
      const partCount = Math.floor(seconds / part.duration);

      if ((partCount > 0 || results.length > 0) && results.length < maxLevels) {
        results.push(
          i18n.t(`${baseKey}.${part.key}`, {
            count: partCount,
          }) as string
        );
      }

      seconds %= part.duration;
    });

    return results.join(separator);
  }

  get checkinEndTime(): DateTime {
    const checkin = this.match?.checkin ?? this.event?.checkin ?? 0;

    return DateTime.fromISO(this.match.startTime).plus({ seconds: checkin });
  }

  get myParticipation() {
    const me = this.me;

    if (!me) return;

    return this.match.participations?.find((p) => p.id == this.myParticipantId);
  }

  get myRelevantTeam() {
    const { event, myTeams } = this;
    if (!event || !myTeams)
      throw new Error(
        "Cannot determine relevant team without event and teams info"
      );

    return myTeams.find((t) => event.participations?.find((p) => p.id == t.id));
  }

  get isTeamBased() {
    const event = this.event;

    if (!event) throw new Error("Can't do without event.");

    return event.mode == "teams";
  }

  get myParticipantId() {
    if (this.isTeamBased) {
      return this.myRelevantTeam?.id;
    } else return this.me?.id;
  }

  // Generic priority?
  priority(secondsLeft) {
    if (secondsLeft < 3600 * 2) return 3;
    else if (secondsLeft < 86400) return 2;
    else return 1;
  }

  get translationSubkey() {
    const stage = this.matchStage;
    if (!stage) return "error";

    return stage
      .split(/(?=[A-Z])/)
      .join("_")
      .toLowerCase();
  }

  t(key, opts = {}) {
    return i18n.t(`match_card.${this.translationSubkey}.${key}`, {
      ...this.translationData,
      ...opts,
    });
  }

  // ts for t-simple
  ts(key, opts = {}) {
    return i18n.t(`match_card.${this.translationSubkey}.${key}`, {
      ...opts,
    });
  }

  get isStartTimeConfirmed() {
    return this.isStartTimeProposed && this.match.isStartTimeConfirmed;
  }

  get isStartTimeProposed() {
    return !!this.match.startTimeProposedById;
  }

  get isTimeProposedByMe() {
    const me = this.me;
    if (!me) throw new Error("this.me is not set, can't do.");

    return this.match.startTimeProposedById == me.id;
  }

  get firstParticipation(): Participation {
    return this.match.participations![0];
  }

  get firstParticipant(): Participant | undefined {
    const firstParticipation = this.firstParticipation;

    return this.participants.find((p) => p.id == firstParticipation.id);
  }

  get secondParticipation(): Participation {
    return this.match.participations![1];
  }

  get secondParticipant(): Participant | undefined {
    const secondParticipation = this.secondParticipation;

    return this.participants.find((p) => p.id == secondParticipation.id);
  }

  otherParticipation(participantId: number): Participation | undefined {
    return this.match.participations?.find((p) => p.id != participantId);
  }

  otherParticipant(participantId: number): Participant | undefined {
    return this.participants.find((p) => p.id != participantId);
  }

  participantAvatar(participant: Participant): string {
    return participant?.avatar?.url ?? "";
  }

  get opponentParticipation() {
    const me = this.myParticipation;
    if (!me?.id) return undefined;

    return this.otherParticipation(me.id);
  }

  static combineGroupedMatches(matches: Match[]) {
    const ungroupedMatches = matches.filter((m) => !m.grouped);
    const groupedMatches = matches.filter((m) => m.grouped);

    return ungroupedMatches.concat(
      Object.entries(groupBy(groupedMatches, "identifier")).map(
        ([key, value]) =>
          new Match({
            ...(value as Match[])[0],
            groupedMatchIds: (value as Match[]).map((m) => m.id),
          } as Match)
      )
    );
  }
}
