



















import { Component, Vue, Prop } from "@components/common";
import Round from "@graphql/types/br_round";
import Event from "@graphql/types/event";
import { DateTime } from "luxon";

@Component
export default class RoundCard extends Vue {
  @Prop() readonly round!: Round;
  @Prop() readonly event!: Event;

  get eventName() {
    return this.event?.name;
  }

  get formattedRoundStartTime() {
    return DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.TIME_SIMPLE);
  }

  get formattedRoundStartDate() {
    return DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.DATE_MED);
  }

  get formattedRoundEndTime() {
    return DateTime.fromISO(this.round.endTime).toLocaleString(DateTime.TIME_SIMPLE);
  }

  get formattedRoundEndDate() {
    return DateTime.fromISO(this.round.endTime).toLocaleString(DateTime.DATE_MED);
  }

  get playDayNumber() {
    return this.event?.playDays?.find(p => p.id == this.round?.playDayId)?.dayNumber;
  }

  get roundNumber() {
    return this.round.roundNumber;
  }
}
