import gql from "graphql-tag";
import { PlatformFields } from "@graphql/types/platform";

export const ListPlatforms = gql`
  query ListPlatforms {
    platforms {
      ...PlatformFields
    }
  }
  ${PlatformFields}
`;
