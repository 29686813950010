











































import { Component, Vue, Prop } from "@components/common";

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

@Component
export default class GetMatchKey extends Vue {
  @Prop({default: ''}) discordLink!: string
  @Prop({default: ''}) key!: string
  @Prop({default: 10}) showFor!: number

  private keyDisplayed = false
  private timer;

  action() {
    if (this.key) {
      clearInterval(this.timer);
      this.showCode();
    } else if  (this.discordLink) {
      window.open(this.discordLink, '_blank');
    } else {
      clearInterval(this.timer);
      this.showCode();
    }
  }

  showCode() {
    this.keyDisplayed = true;
    this.timer = setInterval(() => {
      this.keyDisplayed = false;
      }, this.showFor * 1000
    );
  }

  copyKey() {
    this.$copyText(this.matchKey).then(() => {
      this.$notify({
        text: this.$t("match.match_key_copied").toString()
      });
      }, () => {
      this.$notify({
        type: "error",
        title: this.$t('match.cannot_copy').toString()
      });
    });
    this.keyDisplayed = false;
  }

  get matchKey() {
    return this.key;
  }
}
