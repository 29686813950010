




















































































































import { Page, Component, Layout, FormLabel, Divider } from "@components/common";

@Component({ components: { Layout, FormLabel, Divider }} )
export default class Notifications extends Page {
  private availableHours = ['1', '2', '3', '4']
  private selectedHours = '2'

  private pauseAllNotifications = false

  private pushNotifications = {
    matchReminder: {
      id: 'match_reminder',
      value: true
    },
    matchRequests: {
      id: 'match_requests',
      value: true
    },
    teamNotifications: {
      id: 'team_notifications',
      value: true
    },
    communityNews: {
      id: 'community_news',
      value: true
    }
  }

  private emailNotifications = {
    matchReminder: {
      id: 'match_reminder',
      value: true
    },
    matchRequests: {
      id: 'match_requests',
      value: true
    },
    teamNotifications: {
      id: 'team_notifications',
      value: true
    },
    communityNews: {
      id: 'community_news',
      value: true
    },
  }

  private form = {
    notificationSettings: {}
  };

  mounted() {
    this.initializeNotificationSettingsForm();
  }

  initializeNotificationSettingsForm() {
    this.form.notificationSettings = {
      receiveChatNotifications: this.currentUser?.notificationSettings?.receiveChatNotifications ?? false
    };
  }

  async saveNotificationSettings() {
    this.$store.dispatch("me/update", {attributes: this.form})
      .then(() => {
        this.$notify({text: this.$t('common.saved').toString(), type: "success"});
      })
      .catch(() => this.$notify({text: this.$t('common.error').toString(), type: "error"}));
  }

  option(item) {
    const singular = this.$t('notification_settings.before_match_starts',
        { hour: this.$t('notification_settings.hour')}
      );
    const plural = this.$t('notification_settings.before_match_starts',
        { hour: this.$t('notification_settings.hours')}
      );
    return Number(item) == 1 ? `${item} ${singular}` : `${item} ${plural}`;
  }
}
