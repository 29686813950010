








import { Component, Page, Layout} from "@components/common";
@Component({
  components: { Layout }
})
export default class Feed extends Page {
  private tabs = [
    'feed.tab.all',
    'feed.tab.news',
    'feed.tab.reminders',
    'feed.tab.rules'
  ]
}
