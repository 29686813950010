















































import { Component, Vue, Divider } from "@components/common";
@Component({
  components: { Divider },
})
export default class FeedCard extends Vue {

  hasSlot (name = 'default') {
    return !!this.$slots[ name ] || !!this.$scopedSlots[ name ];
  }
}
