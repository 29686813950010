























































import { GuestPage, Component, Layout, FormLabel } from "@components/common";
import Platform from "@graphql/types/platform";
import { ListMyPlatforms } from "@graphql/queries/me";
import { ListPlatforms } from "@graphql/queries/platforms"; // don't remove to read from appProperties, appProperties are available only to logged in users

interface PlatformEntry {
  platformId: number;
  accountIdentifier: string;
  data: JSON;
}

@Component({
  components: { Layout, FormLabel },
  apollo: {
    platforms: {
      query: ListPlatforms,
      update: ({ platforms }) => platforms.map(p => new Platform(p))
    },
    myPlatforms: {
      query: ListMyPlatforms,
      result({ data: { myPlatforms } }) { this.fillExistingValues(myPlatforms); }
    },
  },
})
export default class Register extends GuestPage {
  private myPlatforms: PlatformEntry[] = []
  private platforms: Platform[] = [];
  private isFormValid = false;
  private isRegistrationComplete = false;
  private activeStep = 3;
  private form = {};

  fillExistingValues(values) {
    values.forEach(val => {
      this.$set(this.form, val.platformId, val.accountIdentifier);
    });
  }

  async savePlatforms() {
    const platforms = Object.keys(this.form).map(key =>
      ({platformId: key, accountIdentifier: this.form[key], data: {}})
    );

    const result = await this.$store.dispatch("me/setPlatforms", { platforms });

    if (result.success) {
      await this.$store.dispatch("me/update", {attributes: {onboardingStep: 4, onboardingCompleted: true}}); // next onboarding step is pushed

      this.$router.push("/register/4");

    }
  }

  private trackAnalytics() {
    this.$gtag.event('onboarding', {
      'event_category': 'onboarding_steps',
      'event_label': 'Step 3',
    });
    this.$gtag.event('onboarding', {
      'event_category': 'onboarding_form',
      'event_label': 'User gaming platforms',
    });
  }

}
