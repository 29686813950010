import { Vue } from "@components/common";
import Vuetify from "vuetify";
import VuetifyConfirm from "vuetify-confirm";

import changePass from "@/assets/icons/icn-change-pass.vue";
import teamManagement from "@/assets/icons/icn-team-management.vue";
import games from "@/assets/icons/icn-games.vue";
import gameAccounts from "@/assets/icons/icn-game-accounts.vue";
import streamingAccounts from "@/assets/icons/icn-streaming-accounts.vue";
import faq from "@/assets/icons/icn-faq.vue";
import support from "@/assets/icons/icn-support.vue";
import terms from "@/assets/icons/icn-terms.vue";
import privacy from "@/assets/icons/icn-privacy-policy.vue";
import chevron from "@/assets/icons/icn-chevron.vue";
import question from "@/assets/icons/icn-question.vue";
import matches from "@/assets/icons/icn-matches.vue";
import matchesSelected from "@/assets/icons/icn-matches-selected.vue";
import home from "@/assets/icons/icn-home.vue";
import standings from "@/assets/icons/icn-standings.vue";
import standingsSelected from "@/assets/icons/icn-standings-selected.vue";
import search from "@/assets/icons/icn-search.vue";
import eventTime from "@/assets/icons/icn-event-time.vue";
import eventPlayers from "@/assets/icons/icn-event-players.vue";
import eventTeams from "@/assets/icons/icn-event-teams.vue";
import matchesPlayed from "@/assets/icons/icn-matches-played.vue";
import eventPrize from "@/assets/icons/icn-event-prize.vue";
import filter from "@/assets/icons/icn-filter.vue";
import xboxlive from "@/assets/icons/icn-xboxlive.vue";
import xbox from "@/assets/icons/icn-xbox.vue";
import playstation from "@/assets/icons/icn-playstation.vue";
import psn from "@/assets/icons/icn-psn.vue";
import pc from "@/assets/icons/icn-pc.vue";
import riot from "@/assets/icons/icn-riot.vue";
import multiplatform from "@/assets/icons/icn-multiplatform.vue";
import feed from "@/assets/icons/icn-feed.vue";
import feedSelected from "@/assets/icons/icn-feed-selected.vue";
import browse from "@/assets/icons/icn-browse.vue";
import browseSelected from "@/assets/icons/icn-browse-selected.vue";
import player from "@/assets/icons/icn-player.vue";
import addMember from "@/assets/icons/icn-add-member.vue";
import addNewMembers from "@/assets/icons/icn-add-new-members.vue";
import leaveEvent from "@/assets/icons/icn-leave-event.vue";
import profile from "@/assets/icons/icn-profile.vue";
import profileSelected from "@/assets/icons/icn-profile-selected.vue";
import editTeam from "@/assets/icons/icn-edit-team.vue";
import jumpToGroup from "@/assets/icons/icn-jump-to-group.vue";
import showMyMatches from "@/assets/icons/icn-show-my-matches.vue";
import sendMessage from "@/assets/icons/icn-send-message.vue";
import reschedule from "@/assets/icons/icn-reschedule.vue";
import accept from "@/assets/icons/icn-accept.vue";
import decline from "@/assets/icons/icn-decline.vue";
import place1 from "@/assets/icons/icn-place-1.vue";
import place2 from "@/assets/icons/icn-place-2.vue";
import place3 from "@/assets/icons/icn-place-3.vue";
import close from "@/assets/icons/icn-close.vue";
import matchesWonBold from "@/assets/icons/icn-matches-won-bold.vue";
import matchesPlayedBold from "@/assets/icons/icn-matches-played-bold.vue";
import inbox from "@/assets/icons/icn-inbox.vue";
import inboxActive from "@/assets/icons/icn-inbox-selected.vue";
import getCode from "@/assets/icons/icn-get-code.vue";
import nintendoSwitch from "@/assets/icons/icn-nintendo-switch.vue";
import flagDe from "@/assets/icons/icn-flag-de.vue";
import flagEn from "@/assets/icons/icn-flag-en.vue";
import flagDev from "@/assets/icons/icn-flag-dev.vue";
import cup from "@/assets/icons/icn-cup.vue";
import tournament from "@/assets/icons/icn-tournament.vue";
import league from "@/assets/icons/icn-league.vue";
import changeMatchSettings from "@/assets/icons/icn-change-match-settings.vue";
import editMatchScore from "@/assets/icons/icn-edit-match-score.vue";
import time from "@/assets/icons/icn-time.vue";
import notifications from "@/assets/icons/icn-notifications.vue";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#623bff",
        secondary: "#b0bec5",
        warn: "#f5c131",
        accent: "#ebeff7",
        marine: "#3DAFFF",
        icon: "#ADB8CC",
        bg: "#ebeff7",
        error: "#b71c1c",
        success: "#43D198",
        activeIcon: "#260047"
      }
    }
  },
  default: {
    props: {
      outlined: true,
      singleLine: true
    }
  },
  icons: {
    values: {
      "icn-change-pass": { component: changePass },
      "icn-team-management": { component: teamManagement },
      "icn-games": { component: games },
      "icn-game-accounts": { component: gameAccounts },
      "icn-streaming-accounts": { component: streamingAccounts },
      "icn-notification-settings": { component: notifications },
      "icn-faq": { component: faq },
      "icn-support": { component: support },
      "icn-terms": { component: terms },
      "icn-privacy": { component: privacy },
      "icn-chevron": { component: chevron },
      "icn-question": { component: question },
      "icn-home": { component: home },
      "icn-search": { component: search },
      "icn-event-time": { component: eventTime },
      "icn-event-players": { component: eventPlayers },
      "icn-event-teams": { component: eventTeams },
      "icn-event-prize": { component: eventPrize },
      "icn-filter": { component: filter },
      "icn-xboxlive": { component: xboxlive },
      "icn-xbox": { component: xbox },
      "icn-playstation": { component: playstation },
      "icn-psn": { component: psn },
      "icn-matches-played": { component: matchesPlayed },
      "icn-pc": { component: pc },
      "icn-multiplatform": { component: multiplatform },
      "icn-feed": { component: feed },
      "icn-feed-selected": { component: feedSelected },
      "icn-browse": { component: browse },
      "icn-browse-selected": { component: browseSelected },
      "icn-matches": { component: matches },
      "icn-matches-selected": { component: matchesSelected },
      "icn-standings": { component: standings },
      "icn-standings-selected": { component: standingsSelected },
      "icn-profile": { component: profile },
      "icn-profile-selected": { component: profileSelected },
      "icn-player": { component: player },
      "icn-add-member": { component: addMember },
      "icn-add-new-members": { component: addNewMembers },
      "icn-leave-event": { component: leaveEvent },
      "icn-edit-team": { component: editTeam },
      "icn-jump-to-group": { component: jumpToGroup },
      "icn-show-my-matches": { component: showMyMatches },
      "icn-accept": { component: accept },
      "icn-decline": { component: decline },
      "icn-send-message": { component: sendMessage },
      "icn-reschedule": { component: reschedule },
      "icn-place-1": { component: place1 },
      "icn-place-2": { component: place2 },
      "icn-place-3": { component: place3 },
      "icn-close": { component: close },
      "icn-matches-won-bold": { component: matchesWonBold },
      "icn-matches-played-bold": { component: matchesPlayedBold },
      "icn-inbox": { component: inbox },
      "icn-inbox-active": { component: inboxActive },
      "icn-get-code": { component: getCode },
      "icn-nintendo-switch": { component: nintendoSwitch },
      "icn-riot": { component: riot },
      "icn-flag-en": { component: flagEn },
      "icn-flag-de": { component: flagDe },
      "icn-flag-dev": { component: flagDev },
      "icn-tournament": { component: tournament },
      "icn-cup": { component: cup },
      "icn-league": { component: league },
      "icn-change-match-settings": { component: changeMatchSettings },
      "icn-edit-match-score": { component: editMatchScore },
      "icn-time": { component: time },
      "icn-notifications": { component: notifications }
    }
  }
});

Vue.use(VuetifyConfirm, { vuetify });

export default vuetify;
