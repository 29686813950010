import gql from "graphql-tag";

import { TournamentFields, DetailedTournamentFields } from "@graphql/types/tournament";
import { MatchFields } from "@graphql/types/match";
import { MatchResultFields } from "@graphql/types/match_result";
import { MatchCheckinFields } from "@graphql/types/checkin";

export const GetTournament = gql`
  query GetTournament($id: ID!) {
    tournament(id: $id) {
      ...TournamentFields
    }
  }
  ${TournamentFields}
`;

export const GetTournamnetWithDetails = gql`
  query GetTournamnetWithDetails($id: ID!) {
    tournament(id: $id) {
      ...DetailedTournamentFields
    }
  }
  ${DetailedTournamentFields}
`;

export const GetTournamentWithMatches = gql`
  query GetTournamentWithMatches($id: ID!) {
    tournament(id: $id) {
      ...DetailedTournamentFields
      matches {
        ...MatchFields
        result {
          ...MatchResultFields
        }
        checkins {
          ...MatchCheckinFields
        }
      }
    }
  }
  ${MatchFields}
  ${DetailedTournamentFields}
  ${MatchResultFields}
  ${MatchCheckinFields}
`;
