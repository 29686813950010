




























































import { Component, Vue, Prop } from "@components/common";
import PodiumBadge from "@/components/events/common/podium_badge.vue";
import User from "@graphql/types/user";
import Event from "@graphql/types/event";

@Component({components: {PodiumBadge}})
export default class Podium extends Vue {
  @Prop({ default: () => ({ name: null})}) readonly winner!: User
  @Prop({ default: () => ({ name: null})}) readonly second!: User
  @Prop({ default: () => ({ name: null})}) readonly third!: User
  @Prop() readonly event!: Event;

  get eventName() {
    return this.event.name;
  }

  get winnerAvatar() {
    return this.winner.avatar?.url ?? '';
  }
  get secondAvatar() {
    return this.second.avatar?.url ?? '';
  }
  get thirdAvatar() {
    return this.third.avatar?.url ?? '';
  }

  getParticiapntLink (id) {
    if (id) {
      return {name: 'Players', params: { userId: id }};
    } else {
      return false;
    }
  }
}

