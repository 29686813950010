













































import { Component, Vue, Prop, Divider } from "@components/common";
import { FormattedTime } from "@components/common/helpers";
import { ListTeams } from "@graphql/queries/teams";

import Team from "@graphql/types/team";

@Component({
  components: { FormattedTime, Divider },
  // At first glance, this seems like it will cause a request on every card load.
  // In practice, since it's always the same userId, this is cached in
  // Apollo's InMemoryCache.
  apollo: {
    myTeams: {
      query: ListTeams,
      variables() { return {filters: {userIds: [this.currentUserId]}};},
      update: data => data.teams
    }
  }
})

export default class TeamCardDetails extends Vue {
  @Prop() readonly team!: Team;
  @Prop(Boolean) readonly allowJoin!: boolean
  @Prop({default: false}) readonly details!: boolean;

  private myTeams!: Team[];

  get getCoverImage() {
    return this.team?.coverImage?.url ?? '';
  }

  get getTeamAvatar() {
    return this.team?.avatar?.url ?? '';
  }
}
