import gql from "graphql-tag";

import { MeFields } from "@graphql/types/me";

export default gql`
  mutation UpdateMe($attributes: MeUpdateInput!) {
    updateMe(attributes: $attributes) {
      success
      me {
        ...MeFields
      }
    }
  }
  ${MeFields}
`;
