


































































































































import Event from '@graphql/types/event';
import { Component, Vue, Prop, Divider } from "@components/common";
import Match from "@graphql/types/match";
import FormattedTime from "@components/common/formatted_time.vue";
import CheckInButton from "@components/events/active/check_in_button.vue";
import ScheduleMatch from "@components/events/active/schedule_match.vue";
import { DateTime } from "luxon";
import ChatButton from "@components/chat/chat_button.vue";
import User from "@graphql/types/user";
import Team from "@graphql/types/team";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";

@Component({
  components: {
    Divider,
    FormattedTime,
    ScheduleMatch,
    CheckInButton,
    ChatButton
  }
})

export default class MatchCard extends Vue {
  @Prop() readonly match!: Match;
  @Prop() readonly event!: Event;

  eventRouteName(event) {
    return new EventCategoryHelpers(event).routeName;
  }

  get gameId() {
    return this.event.gameId || -1;
  }

  get gameName() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }

  get justButton() {
    return true;
  }

  get formattedMatchStartTime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get formattedMatchProposedStartTime() {
    return DateTime.fromISO(this.match.proposedStartTime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get captionText() {
    if (this.isPlayoffs) return this.captionTextPlayoffs;
    if (this.isDivisionMatch) return this.captionTextDivisions;
    if (this.isQualifications) return this.captionTextQualifications;
    return this.genericCaption;
  }


  get iconText() {
    if (this.isMatchPendingSchedule) {

      if(this.isPendingMyConfirmation) return this.$t("match.schedule_mymatches_waiting_my_confirmation", {proposedTime: this.formattedMatchProposedStartTime});

      if(this.isPendingOpponentConfirmation) return this.$t("match.schedule_mymatches_waiting_opponent_confirmation", {proposedTime: this.formattedMatchProposedStartTime});

      return this.$t("match.schedule_mymatches_pending_schedule");
    }
    return this.$t("match.starts_in", {timeToStart: this.formattedMatchStartTime});
  }

  get isGroupMatch() {
    return (this.match.bracket === 'group' && !this.isDivisionMatch)? true : false;
  }

  get isPlayoffMatch() {
    return !this.isGroupMatch && !this.isDivisionMatch && !this.isPlayoffsGroupMatch;
  }

  get isDivisionMatch() {
    return !!this.match.divisionId;
  }

  get isPlayoffsGroupMatch() {
    return !!this.match.playoffsGroupId;
  }

  get matchName() {
    if (this.isDivisionMatch) return this.$t("match.divisions.match_name", {weekNumber: this.divisionMatchWeekNumber});
    if (this.match.bracket === "group") return this.$t("match.group_match_name", {grouptag: this.groupTag, matchtag: this.matchTag});
    if (this.match.bracket === 'loser' && this.event.flowType === "TournamentFlows::Elimination::SingleEliminationWith3rdPlace" ) return this.$t(`matches.brackets.3rd_place_match`);
    return this.playoffTag;
  }

  get divisionMatchWeekNumber() {
    return this.match.index!== undefined? this.match.index + 1 : '';
  }

  get captionTextDivisions() {
    if (this.isMatchPendingSchedule) {
      if(this.isPendingMyConfirmation) return this.$t('match.divisions.schedule_mymatches_waiting_my_confirmation_caption', { matchName: this.matchName, opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
      if(this.isPendingOpponentConfirmation) return this.$t('match.divisions.schedule_mymatches_waiting_opponent_confirmation_caption', { matchName: this.matchName, opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
      return this.$t("match.divisions.schedule_mymatches_pending_schedule_caption", { matchName: this.matchName, opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
    }
    return this.$t('match.divisions.pending_my_match_time_and_date', { matchTag: this.matchTag, groupTag: this.groupTag,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
  }

  get captionTextQualifications() {
    if (this.isMatchPendingSchedule) {
      if(this.isPendingMyConfirmation) return this.$t('match.schedule_mymatches_waiting_my_confirmation_caption', { matchTag: this.matchTag, groupTag: this.groupTag,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
      if(this.isPendingOpponentConfirmation) return this.$t('match.schedule_mymatches_waiting_opponent_confirmation_caption', { matchTag: this.matchTag, groupTag: this.groupTag,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
      return this.$t("match.schedule_mymatches_pending_schedule_caption", { matchTag: this.matchTag, groupTag: this.groupTag,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
    }
    return this.$t('match.pending_my_match_time_and_date', { matchTag: this.matchTag, groupTag: this.groupTag,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
  }

  get captionTextPlayoffs() {
    if (this.isMatchPendingSchedule) {
      if(this.isPendingMyConfirmation) return this.$t('match.playoffs.schedule_mymatches_waiting_my_confirmation_caption', { playofftag: this.playoffTag ,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
      if(this.isPendingOpponentConfirmation) return this.$t('match.playoffs.schedule_mymatches_waiting_opponent_confirmation_caption', { playofftag: this.playoffTag ,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
      return this.$t("match.playoffs.schedule_mymatches_pending_schedule_caption", { playofftag: this.playoffTag ,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
    }
    return this.$t('match.playoffs.pending_my_match_time_and_date', { playofftag: this.playoffTag ,opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
  }

  get genericCaption() {
    if (this.isMatchPendingSchedule) {
      if(this.isPendingMyConfirmation) return this.$t('match.generic.schedule_mymatches_waiting_my_confirmation_caption', { opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
      if(this.isPendingOpponentConfirmation) return this.$t('match.generic.schedule_mymatches_waiting_opponent_confirmation_caption', { opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
      return this.$t("match.generic.schedule_mymatches_pending_schedule_caption", { opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
    }
    return this.$t('match.generic.pending_my_match_time_and_date', { opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
  }

  get participants() {
    if (this.event?.isUserBased)
      return this.event?.participatingUsers;
    else
      return this.event?.participatingTeams;
  }

  get matchPlatforms() {
    const platformIds = this.event.platformIds ?? [];
    return this.platformsByIds(platformIds);
  }

  get matchPlatformNames() {
    return (this.matchPlatforms ?? []).map(p => p.name).join(", ");
  }


  get teamLineup() {
    return false;
  }

  get isQualifications() {
    if (this.isPlayoffs) return false;
    if (this.event?.qualificationEndTime === undefined) {
      return false;
    } else if ((DateTime.fromISO(this.event.qualificationEndTime) > DateTime.local())) {
      return true;
    }
    return false;
  }

  get isPlayoffs() {
    if ((DateTime.fromISO(this.event?.playoffStartTime) < DateTime.local())) {
      return true;
    }
    return false;
  }

  get playoffTag () {
    const noStages = this.event?.lastEliminationStageNumber? this.event.lastEliminationStageNumber : 0;
    const currentStage = this.match.stage? this.match.stage : 0;
    const power = noStages - currentStage+1;

    const stage = 2**power;

    switch (stage) {
      case 2:
        return this.$t("match.finale").toString();
      case 4:
        return this.$t("match.semi_finale").toString();
      case 8:
        return this.$t("match.quater_finale").toString();
      default:
        return this.$t("match.round_of", {round: stage, roundMatches: stage/2}).toString();
    }
  }

  get groupTag() {
    if (this.match.group === undefined) return "N/A";
    return this.match.group + 1;
  }

  get matchTag() {
    if (this.match.index === undefined) return "N/A";
    return this.match.index + 1;
  }

  get matchIndex() {
    if (this.match?.index === undefined) return 0;
    return this.match?.index + 1;
  }

  get currentParticipantId() {
    if (!this.isTeamBased) return this.currentUserId;
    return this.myParticipatingTeam?.id;
  }

  get myParticipatingTeam() {
    if (this.isTeamBased) return this.event?.participatingTeams?.find(team => team.members?.find(member => member.id === this.currentUserId));
    return null;
  }

  // Participants
  get isParticipantOneMe() {
      return this.currentParticipantId === this.participantOne?.id;
  }

  get isParticipantTwoMe() {
      return this.currentParticipantId === this.participantTwo?.id;
  }

  get opponent() {
    return this.isParticipantOneMe ? this.participantTwo : this.participantOne;
  }

  get opponentUUID() {
    if(!this.isTeamBased) {
      const o = this.opponent as User;
      return o.uuid;
    } else {
      const t = this.opponent as Team;
      return t.captain?.uuid;
    }
  }


  get opponentName() {
    return this.opponent?.name;
  }

  get participantTwoName() {
    return this.participantTwo?.name;
  }

  get participantOneName() {
    return this.participantOne?.name;
  }

  get isTeamBased() {
    return this.match?.participations?.[0].type === "TEAM";
  }

  get participantOne() {
    const opponentId = this.match?.participations?.[0]?.id;

    if (!this.isTeamBased)
      return this.event?.participatingUsers?.find(p => p.id == opponentId);
    else
      return this.event?.participatingTeams?.find(p => p.id == opponentId);
  }

  get participantTwoAvatar() {
    return this.participantTwo?.avatar?.url ?? "";
  }

  get participantOneAvatar() {
    return this.participantOne?.avatar?.url ?? "";
  }

  get participantTwo() {
    const opponentId = this.match?.participations?.[1]?.id;

    if (!opponentId)
      return null;

    if (!this.isTeamBased)
      return this.event?.participatingUsers?.find(p => p.id == opponentId);
    else
      return this.event?.participatingTeams?.find(p => p.id == opponentId);
  }

  get isAutoWin() {
    return !this.participantTwo;
  }

  get isOwnMatch() {
    const id = this.currentUserId;

    return this.participantOne?.id == id ||
      this.participantTwo?.id == id;
  }

  get isMatchPendingSchedule() {
    if (this.match === undefined) return false;
    if (this.match.startTimeLocked) return false;
    return !this.match?.isStartTimeConfirmed;
  }

  get isMatchScheduleProposed() {
    return this.match.proposedStartTime === null ? false : true;
  }

  get isProposedByMe() {
    const proposedBy = this.match.startTimeProposedById;

    if (!proposedBy)
      return false;

    return this.currentUserId == proposedBy;
  }

  get isPendingOpponentConfirmation() {
    return this.isProposedByMe && !this.isConfirmed && !this.isMatchPendingStart && this.isMatchScheduleProposed;
  }

  get isConfirmed() {
    return this.match?.isStartTimeConfirmed;
  }

  get isPendingMyConfirmation() {
    return !this.isProposedByMe && !this.isConfirmed && !this.isMatchPendingStart && this.isMatchScheduleProposed;
  }

  // Date match defined but not yet started
  get isMatchPendingStart() {
    return this.match.isStartTimeConfirmed;
  }

  get hasMatchStarted() {
    return DateTime.fromISO(this.match.startTime) < DateTime.local();
  }

  // Match started and not finished yet
  get isMatchActive() {
    return this.hasMatchStarted && !this.isMatchCompleted && !this.isMatchPendingSchedule;
  }

  // Match finished
  get isMatchCompleted() {
    return DateTime.fromISO(this.match.endTime) < DateTime.local();
  }

  showSubmitScoreDialog(...args) {
    this.$emit("showSubmitScoreDialog", ...args);
  }

  checkIn() {
    this.$notify({text: this.$t("common.not_available_yet").toString()});
  }

  scheduleMatch() {
    this.$emit("scheduleMatch", this.match);
  }

  refreshEvent() {
    this.$emit("refreshEvent");
  }
}
