import ActionCable from "actioncable";
import ActionCableVue from "actioncable-vue";
import { Vue } from "@components/common";
import i18n from "@/i18n";
import { ActionCableLink } from "graphql-ruby-client";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { errorPath } from "@/lib/errors";
import { onError } from "apollo-link-error";
import VueApollo from "vue-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import {
  createApolloClient,
  restartWebsockets,
} from "vue-cli-plugin-apollo/graphql-client";

export const AUTH_TOKEN = "senior-esports-bearer-token";

Vue.use(VueApollo);
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectImmediately: true,
});

import eventBus from "@/event_bus";

const LOGOUT_ERRORS = [
  "errors.authentication.no_access",
  "errors.authentication.not_authenticated",
  "errors.authentication.invalidated_token",
];

const uri = process.env.VUE_APP_GRAPHQL_URL || "http://localhost:3000/graphql";
const uploadLink = createUploadLink({ uri });
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      const path = errorPath(message);
      const text = i18n.t(path).toString();

      if (LOGOUT_ERRORS.includes(path)) {
        eventBus.$emit("auth:force-logout");
      }

      if (path == text) return;

      Vue.notify({
        text,
        type: "error",
      });
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const linkWithError = ApolloLink.from([errorLink, uploadLink]);

const cableUri = process.env.VUE_APP_CABLE_URL || "http://localhost:3000/cable";

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) =>
      kind === "OperationDefinition" && operation === "subscription"
  );
};

const cable = ActionCable.createConsumer(
  cableUri + `?token=${window.localStorage.getItem(AUTH_TOKEN)}`
);

export const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }) as unknown as ApolloLink,
  linkWithError
);

// Config
const defaultOptions = {
  wsEndpoint: null,
  tokenName: AUTH_TOKEN,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: link,
  defaultHttpLink: false,
  cache: new InMemoryCache(),
  // getAuth: (tokenName) => ...
  // apollo: { ... }
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

export function createApollo(options = {}) {
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  return apolloClient;
}

export function createProvider(options = {}) {
  const apolloClient = createApollo(options);

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "no-cache",
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });

  return apolloProvider;
}

export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);

  if (apolloClient.resetStore instanceof Function)
    await apolloClient.resetStore();
}

export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}

export const apollo = createApollo();
export const provider = createProvider({ apollo: apollo });
