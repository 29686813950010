


















































































































import { Component, Vue, Prop, Watch } from "@components/common";
import { DateTime } from "luxon";
import { sortBy, groupBy } from "underscore";

import Event from "@graphql/types/event";
import ListensForOwnMatchUpdates from "@/mixins/listens_for_own_match_updates";
import Match from "@graphql/types/match";
import MatchCard from "@components/v2/ActivityCards/MatchCard.vue";

import { namespace } from "@/store";
const AuthenticationState = namespace("authentication");

@Component({
  mixins: [ListensForOwnMatchUpdates],
  components: { MatchCard }
})

export default class DivisionMatches extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly matches!: Match[];
  @Prop() readonly selectedDivisionId!: number;
  @Prop() readonly divisionId!: number;
  @Prop() readonly weekId!: number;

  private filterMyMatches = true;

  @AuthenticationState.State
  private teamRoles;

  private tab = null

  get participants() {
    if (this.event.isUserBased)
      return this.event.participatingUsers;
    else
      return this.event.participatingTeams;
  }

  private noDates(dates) {
    return Object.keys(dates).length;
  }

  get matchDates() {
    const formatedMatches = this.scheduledMatches.map((m) => ({
      ...m,
      groupDate: DateTime.fromISO(m.startTime).toLocaleString(DateTime.DATE_MED)
    }));

    const groupByDate = groupBy(sortBy(formatedMatches, 'startTime'), "groupDate");

    return groupByDate;
  }

  get pastMatchesDates() {
    const formatedMatches = this.pastMatches?.map((m) => ({
      ...m,
      groupDate: DateTime.fromISO(m.startTime).toLocaleString(DateTime.DATE_MED)
    }));

    const groupByDate = groupBy(sortBy(formatedMatches, 'startTime').reverse(), "groupDate");
    return groupByDate;
  }

  get scheduledMatches() {
    return sortBy(this.filteredMatches?.filter(m => m.isStartTimeConfirmed && !m.result));
  }

  get hasPendingMatches() {
    return this.pendingMatches?.length > 0 ? true : false;
  }

  get pendingMatches() {
    return this.filteredMatches?.filter(m=> !m.isStartTimeConfirmed && !m.result);
  }

  get pastMatches() {
    return this.filteredMatches?.filter(m=> m.result);
  }

  // just group matches need to be here

  get teamIds () {
    return (this.teamRoles ?? []).map(id => id.teamId);
  }

  get filteredMatches() {
    const filteredMatches = (this.filterMyMatches && this.hasMatches) ? this.myMatches : this.matches;

    return this.weekId !== null ? filteredMatches.filter(m => m.index == this.weekId) : filteredMatches;

  }

  get myMatches() {
    return (this.matches ?? []).filter(match =>
      match.participations?.some(p => p.id == this.currentUserId || this.teamIds.includes(p.id ?? 0))
    );
  }

  get hasMatches() {
    return !!this.myMatches.length;
  }

  toggleMyMatchesFilter() {
    this.filterMyMatches = !this.filterMyMatches;
  }
}
