



















import { Component, Vue, Prop, Divider } from "@components/common";
import User from "@graphql/types/user";

@Component({
  components: { Divider },
})
export default class DiscordNameCard extends Vue {
  @Prop() readonly user!: User;
}
