





















import { Component, Vue, Prop, Divider } from "@components/common";
import UserPlatform from '@/graphql/types/user_platform';

@Component({
  components: { Divider },
})
export default class GameAccountCard extends Vue {
  @Prop() readonly gameAccount!: UserPlatform;

  accountIcon(tag) {
    return require(`../../assets/profile/img-game-acc-${tag}.svg`);
  }
}
