















































































































































import { Component, Vue, Prop, FormLabel, Watch } from "@components/common";
import { DateTime, Interval } from "luxon";
import MatchHelpers, { MatchStage } from "@lib/helpers/match_helpers";
import { notEmpty } from "@lib/validations";

import Match from "@/graphql/types/match";
import Team from "@graphql/types/team";
import Event from "@/graphql/types/event";

import { AVAILABLE_STREAMING_ACCOUNTS } from "@graphql/types/streaming_account";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  MatchCardBasics,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";

import 'vue-datetime/dist/vue-datetime.css';

@Component({
  components: {
    ActionBtn,
    CardBody,
    CardHeader,
    DateTimeInfo,
    Expander,
    FormLabel,
    MatchCardBasics,
    Meta,
    MetaLine,
    UserAvatar,
  }
})
export default class RecheduleStage extends Vue {
  @Prop() private match!: Match;
  @Prop() private event!: Event;
  @Prop() private myTeams!: Team[];
  @Prop() private forceOpen?: boolean;
  @Prop() private show!: boolean;

  private loading = false;
  private timer;
  private ticker = 0;

  private streamingLinks = {};
  private enableStreamingLinks = false;

  get proposedTime() {
    return DateTime.fromISO(this.match.proposedStartTime);
  }

  get formattedMatchStartTime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  mounted() {
    this.timer = setInterval(this.tick, 1000);

    this.loadMyStreamingLinks();
  }

  unmounted() {
    clearInterval(this.timer);
  }

  tick() {
    this.ticker += 1;
  }

  loadMyStreamingLinks() {
    const user = this.currentUser;
    if (!user || !user.id) return;

    let streamingLinks = {};

    user.streamingAccounts?.forEach(streamingAccount => {
      streamingLinks[streamingAccount.tag as string] = streamingAccount.value;
    });

    const links = this.match?.streamingLinks?.[user.id] ?? {};

    if (links && Object.values(links).some(Boolean)) {
      streamingLinks = {...streamingLinks, ...links};
      this.enableStreamingLinks = true;
    }

    this.streamingLinks = streamingLinks;
  }

  saveStreamingLinks() {
    const match = this.match;

    this.loading = true;

    this.$store.dispatch("matches/setStreamingLinks", {
      matchIds: match.grouped ? match.groupedMatchIds : [match.id],
      streamingLinks: this.streamingLinks
    })
      .then(() => this.$notify({type: "success", text: this.$t("common.saved").toString()}))
      .finally(() => this.loading = false);
  }

  @Watch("enableStreamingLinks")
  clearStreamingLinks(newVal) {
    if (newVal === true)
      return;

    this.streamingLinks = {};
    const match = this.match;

    this.loading = true;

    this.$store.dispatch("matches/setStreamingLinks", {
      matchIds: match.grouped ? match.groupedMatchIds : [match.id],
      streamingLinks: {}
    })
      .then(() => this.$notify({type: "success", text: this.$t("common.saved").toString()}))
      .finally(() => this.loading = false);
  }

  proposeNewTime() {
    this.$emit("forceStage", MatchStage.Schedule, true);
    this.$emit("updateMatch", {...this.match, startTimeProposedById: null, isStartTimeConfirmed: false});
  }

  acceptProposedTime() {
    this.loading = true;

    const match = this.match;

    let mutation = "matches/confirmStartTime";
    let variables: Record<string, any> = { matchId: match.id };

    if (match.grouped) {
      mutation = "matches/confirmStartTimes";
      variables = { matchIds: match.groupedMatchIds };
    }

    this.$store.dispatch(mutation, {
      ...variables,
      time: this.match.proposedStartTime
    })
      .finally(() => this.loading = false);
  }

  openChat() {
    const otherUser = this.matchHelpers.otherParticipation(this.currentUserId as number);

    if (!otherUser)
      return;

    this.$eventBus.$emit("chat:open:1on1id", otherUser.id);
  }

  get opponentParticipation() {
    return this.matchHelpers.opponentParticipation;
  }

  get matchHelpers() {
    return new MatchHelpers(this.match, [], {
      me: this.currentUser,
      matchStage: MatchStage.Reschedule,
      event: this.event,
      myTeams: this.myTeams,
    });
  }

  get isCheckedIn() {
    return !!this.match?.checkins?.find(c => c.userId == this.currentUserId);
  }

  get priority() {
    const secondsLeft = this.secondsLeft;

    if (secondsLeft != secondsLeft || secondsLeft < 3600 * 6) return 2;
    else return 1;
  }

  get icon() {
    return this.priority == 2 ? "mdi-clock-time-eight-outline" : "$icn-browse";
  }

  get secondsLeft() {
    // Make computed prop dependent on ticker to refresh every 1s
    this.ticker;

    const match = this.match;

    if (!match) return 86400;

    return Interval.fromDateTimes(
      DateTime.local(),
      DateTime.fromISO(match.startTime)
    ).length("seconds");
  }

  get timeLeftText() {
    return this.matchHelpers.intervalText(this.secondsLeft);
  }

  get expanded() {
    return this.forceOpen || this.show || this.priority > 2;
  }

  get formattedLatestStartDatetime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_MED);
  }

  private notEmpty = notEmpty;
  private availableStreamingAccounts = AVAILABLE_STREAMING_ACCOUNTS;
}
