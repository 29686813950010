





































































































































































import { Component, Prop, Vue, Watch } from "@components/common";
import { DateTime } from "luxon";

import Countdown from "@components/events/countdown.vue";
import Event from "@graphql/types/event";
import IconWaiting from "@components/cups/icon_waiting.vue";
import BRRound from "@graphql/types/br_round";
import iconGameTime from "@components/cups/icon_game_time.vue";
import FormattedTime from "@components/common/formatted_time.vue";
import BRSubmitRoundScore from "@components/events/active/BR_submit_round_score.vue";
import GetMatchKey from "@components/events/active/get_match_key.vue";

@Component({
  components: {
    IconWaiting,
    iconGameTime,
    Countdown,
    FormattedTime,
    BRSubmitRoundScore,
    GetMatchKey
  }
})

export default class BRCheckInButton extends Vue {
  @Prop() event!: Event;
  @Prop() round!: BRRound;
  @Prop(String) eventType!: string;
  @Prop(String) action!: string;
  @Prop({default:false}) justButton!: boolean;

  private show = false;
  private showSubmitScore = false;
  private isRefreshing = false;

  private submitScoreDialog = {
    show: false,
    roundId: null
  };

  get playDayNumber() {
    return this.event?.playDays?.find(p => p.id == this.round.playDayId)?.dayNumber;
  }

get translationVariables () {
    const variables = {
      // current round
      roundStartTime: this.round ? DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundStartDate: this.round ? DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.DATE_MED) : '',
      roundEndTime: this.round ? DateTime.fromISO(this.round.endTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundEndDate: this.round ? DateTime.fromISO(this.round.endTime).toLocaleString(DateTime.DATE_MED) : '',
      roundCheckInTime: this.round ? DateTime.fromISO(this.checkInEndTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundCheckInDate: this.round ? DateTime.fromISO(this.checkInEndTime).toLocaleString(DateTime.DATE_MED) : '',
      roundNumber: this.round ? this.round.roundNumber : '',
      roundHostKey: this.round.hostKey,

      // Discord
      gameDiscordUrl: this.event.game?.discordChannelUrl,
      gameDiscordChannel: this.event.game?.discordChannelName,
      playDayNumber: this.playDayNumber,
    };
    return variables;
  }

  get checkInEndTime() {
    return (DateTime.fromISO(this.round.startTime).plus({seconds: this.event.checkin}));
  }

  get checkinPassed() {
    return (DateTime.fromISO(this.checkInEndTime) < DateTime.local()) || false;
  }

  get myParticipatingTeamId() {
    if (!this.myParticipatingTeam) return null;
    return this.myParticipatingTeam.id;
  }

  get myParticipatingTeam() {
    if (!this.event.participatingTeams) return { captain: null, id: null };
    return this.event.participatingTeams?.find(a => a.members?.find(member => member.id === this.currentUserId));
  }

  get isParticipatingTeamCaptain() {
    if (!this.isTeamBased) return true;
    return this.myParticipatingTeam?.captain?.id === this.currentUserId;
  }

  openSubmitResultDialog(round) {
    this.submitScoreDialog.roundId = round.id;
    this.submitScoreDialog.show = true;
  }

  closeSubmitScoreDialog() {
    this.submitScoreDialog.show = false;
    this.submitScoreDialog.roundId = null;
  }

  get formattedRoundStartTime() {
    return DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get computedAction() {
    if (!this.isUserCheckedIn && !this.checkinPassed) return 'check_in';

    if (!this.checkinPassed) {
      if (this.allowInstantResultSubmission)
        return "submit_score";
      else
        return 'checked_in';
    }

    if (this.checkinPassed && !this.isUserCheckedIn) return 'check_in_missed';
    else {
      return 'submit_score';
    }
  }

  get allowInstantResultSubmission(): boolean {
    return this.event?.game?.allowsInstantResultSubmission ?? false;
  }

  get roundStarted() {
    const round = this.round;
    if (!round)
      return false;

    return (DateTime.fromISO(round.startTime) <= DateTime.local());
  }

  get roundEndTime() {
    const round = this.round;
    if (!round)
      return false;

    return new Date(DateTime.fromISO(round.endTime)).toLocaleString();
  }

  get canSubmitScore() {
    return this.isParticipant && this.roundStarted;
  }

  get isParticipant() {
    if (this.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.event.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isParticipantTeamMember() {
    if (this.isTeamBased) {
      return this.event.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }

  get myParticipationId(){
    if (!this.isParticipant) return null;

    const participations = this.event.participations;
    const myId = this.isTeamBased ? this.myParticipatingTeamId : this.currentUser?.id;

    return participations?.find(p => p.id === myId)?.participationId;
  }

  get isResultAlreadySubmitted() {
    return !!this.round.results?.some(r => r.participationId === this.myParticipationId);
  }

  @Watch("round")
  disableLoad() {
    this.isRefreshing = false;
  }

  get currentParticipantId() {
    if (!this.isTeamBased) return this.currentUserId;
    return this.myParticipatingTeamId;
  }

  get isUserCheckedIn() {
    return (this.round?.checkins ?? []).some(checkin =>
      checkin?.userId == this.currentUserId
    );
  }

  get isTeamBased() {
    return this.event.mode === "1v1" ? false : true;
  }

  showDialog() {
    this.show = true;
  }

  closeRoundStartDialog() {
    this.show = false;
  }

  showSubmitScoreDialog() {
    //this.showSubmitScore = true
    this.$emit("showSubmitScoreDialog", this.round);
  }

  refreshTournament(...args) {
    this.isRefreshing = true;
    this.$emit("refreshTournament", ...args);
  }

  async checkIn() {
    const result = await this.$store.dispatch(
      "rounds/checkIn",
      { roundId: this.round?.id}
    );

    if (result.success) {
      this.showDialog();
      this.refreshTournament();
      this.trackAnalytics();
    }
  }

  async checkInAsTeam() {
    const result = await this.$store.dispatch(
      "rounds/checkIn",
      { roundId: this.round?.id, asTeam: true, teamId: this.myParticipatingTeamId}
    );

    if (result.success) {
      this.showDialog();
      this.refreshTournament();
      this.trackAnalytics();
    }
  }

  private trackAnalytics() {
      this.$gtag.event('round_check_in', {
        'event_category': 'round_action',
        'event_label': 'Check in to round'
      });
  }
}
