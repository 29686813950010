




































































































































































import DivisionsMatches from "@components/leagues/divisions_matches.vue";
import DivisionStandings from "@components/standings/division_standings.vue";
import PlayoffsGroupStandings from "@components/standings/playoffsgroup_standings.vue";
import DivisionSelector from "@components/leagues/division_selector.vue";
import LeagueCardDetails from "@components/leagues/league_card_detail.vue";
import LeagueModel from "@graphql/types/league";
import PlayerCard from "@components/players/card.vue";
import PlayerGridCard from "@components/players/grid_card.vue";
import TeamCard from "@components/teams/card.vue";
import User from "@graphql/types/user";
import Team from "@graphql/types/team";
import Division from "@graphql/types/division";
import DivisionsPlayoffs from "@components/leagues/divisions_playoffs.vue";

import Skeleton from "@components/events/skeleton.vue";

import { Component, Page, Layout, Prop } from "@components/common";
import { GetLeagueWithDetails } from "@graphql/queries/league";

import { sortBy } from "underscore";

@Component({
  components: {
    PlayerCard,
    PlayerGridCard,
    TeamCard,
    LeagueCardDetails,
    Layout,
    DivisionStandings,
    PlayoffsGroupStandings,
    DivisionsMatches,
    DivisionSelector,
    DivisionsPlayoffs,
    Skeleton
  },
  apollo: {
    league: {
      query: GetLeagueWithDetails,
      variables() { return {id: this.leagueId}; },
      update: ({ league }) => new LeagueModel(league),
      result() {  this.activeDivision = this.activeDivision.id ? this.activeDivision : this.defaultDivision; this.loading = false; },
      pollInterval: 40000,
    },
  }
})

export default class League extends Page {
  @Prop() readonly route;

  private league = new LeagueModel;
  private errorLoading = false;
  private showGrid = false;
  private currentTab = null
  private loading = true

  private submitScoreDialog = {
    show: false,
    matchId: null
  };

  private activeDivision: Division = new Division;

  private divisionName (level: number, position: number): string {
    return this.$t(`league.division.name.level_${level + 1}`, {divisionNumber: position + 1, gameName: this.eventGameName}) + ' - ' + this.$t(`league.division.division_tag`, {divisionNumber: position + 1}).toString();
  }

  get eventGame() {
    return this.league.gameId ? this.gameById(this.league.gameId) : undefined;
  }

  get eventGameName() {
    return this.eventGame ? this.eventGame.name : '';
  }

  get sectionParticipants() {
    return this.hasDivisions ? this.sortedActiveDivisionParticipants : this.isTeamBased ? this.league.participatingTeams : this.league.participatingUsers;
  }

  get sortedActiveDivisionParticipants() {
    return sortBy(this.activeDivision.participations,"name");
  }

  get divisions() {
    const divisions = sortBy(this.league.divisions, "level");

    const namedDivisions = divisions?.map(division => ({
      id: division.id,
      name: this.divisionName(division.level, division.position),
      type: 'division',
      tag: division.tag,
      participations: division.participations.map(p => ({
        ...p,
        avatar: this.participantAvatar(p.id),
        games: this.participantGames(p.id),
        members: this.participantTeamMembers(p.id)
      }))
    }));

    return namedDivisions;
  }

  get playoffs() {
    const playoffs = sortBy(this.league.playoffsGroups, "position");
    const namedPlayofs = playoffs?.map(playoff => ({
      id: playoff.id,
      name: this.playoffsGroupName(playoff.tag),
      type: 'playoffsGroup',
      tag: playoff.tag,
      participations: playoff.participations.map(p => ({
        ...p,
        avatar: this.participantAvatar(p.id),
        games: this.participantGames(p.id),
        members: this.participantTeamMembers(p.id)
      }))
    }));

    return namedPlayofs;
  }


  private playoffsGroupName(tag) {
    return this.$t(`event.playoff.${tag}`);
  }

  private participantAvatar(id) {
    return this.participants.find( p=> p.id === id )?.avatar;
  }

  private participantGames(id) {
    const participation = this.participants.find( p=> p.id === id );
    return (participation instanceof User) ? participation?.games : [];
  }

  private participantTeamMembers(id) {
    if (!this.isTeamBased) return [];

    const participation = this.participants.find( p=> p.id === id );
    return (participation instanceof Team) ? participation?.members: [];
  }

  get participants() {
    return [
      ...this.league.participatingUsers ?? [],
      ...this.league.participatingTeams ?? []
    ];
  }

  get hasDivisions() {
    return !!this.divisions.length;
  }

  get hasPlayoffs() {
    return !!this.playoffs.length;
  }

  get selectedDivison() {
    if (this.hasDivisions) {
      return this.activeDivision? this.activeDivision : this.defaultDivision;
    } else {
      return { type: ''};
    }
  }

  set selectedDivison(division) {
    this.activeDivision = division;
  }

  get selectedPlayoff() {
    //return this.defaultPlayoff;
    // TOOD
    return "";
  }

  set selectedPlayoff(playoff) {
    //this.activePlayoff = playoff;
  }

  get defaultDivision() {
    return this.isParticipant? this.myDivision : this.divisions[0];
  }

  get myDivision() {
    const myDivision = this.divisions.find(d => d.participations.find(p => p.participationId === this.myParticipationId));

    return myDivision;
  }

  get isParticipant() {
    if (this.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.league.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isParticipantTeamMember() {
    if (this.isTeamBased) {
      return this.league.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }

  get myParticipatingTeamId() {
    if (!this.myParticipatingTeam) return null;
    return this.myParticipatingTeam.id;
  }

  get myParticipatingTeam() {
    if (!this.league.participatingTeams) return { captain: null, id: null };
    return this.league.participatingTeams?.find(a => a.members?.find(member => member.id === this.currentUserId));
  }

  get myParticipationId(){
    if (!this.isParticipant) return null;

    const participations = this.league.participations;
    const myId = this.isTeamBased ? this.myParticipatingTeamId : this.currentUser?.id;

    return participations?.find(p => p.id === myId)?.participationId;
  }

  get isRoundBased() {
    return this.league.roundType === "Round";
  }

  get getRoute() {
    return this.route ? this.route : '/browse/leagues';
  }

  get description() {
    return this.league?.descriptions?.[this.$i18n.locale] ??
      this.league?.descriptions?.[this.$i18n.fallbackLocale[0]];
  }

  get rules() {
    return this.league?.rules?.[this.$i18n.locale] ??
      this.league?.rules?.[this.$i18n.fallbackLocale[0]];
  }

  get leagueId() {
    return this.$route.params.leagueId ?? this.$route.params.id;
  }

  get match() {
    if (this.league.matches === undefined)
      return null;
    return this.league?.matches[0];
  }

  updateLeague({ league }) {
    this.league = league;
  }


  refreshLeague() {
    this.$apollo.queries.league.refetch();
  }

  get participantsTabName() {
    if (this.league?.isTeamBased) {
      return this.$t("league.tabs.teams");
    } else {
      return this.$t("league.tabs.players");
    }
  }

  get isUserBased() {
    return this.league?.isUserBased;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }
}
