import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";
import { DateTime } from "luxon";
import createStore, { getFullSelection } from "@store/generic_store";
import Game from "@graphql/types/game";
import gql from "graphql-tag";

export default class Games extends createStore({
  name: "game",
  recordType: Game,
}) {
  @Action({ rawError: true })
  async popular({ extraSelection = [], page = 1, limit = 10 } = {}) {
    const selection = getFullSelection(this.recordSelectables, extraSelection);

    const { data } = await apollo.query({
      query: gql`
        query PopularGames($day : Date, $page : Int, $limit : Int) {
          popularGames(day: $day, page: $page, limit: $limit) {
            id
            gameId
            position
            day
            interval
            playerCount

            game {
              ${selection.join(" ")}
            }
          }
        }
      `,
      variables: {
        day: DateTime.local().toISODate(),
        page,
        limit,
      },
    });

    return data.popularGames.map((pg) => ({ ...pg, game: new Game(pg.game) }));
  }
}
