
























import { Prop, Component, Vue } from "@components/common";

import MetaLine from "./MetaLine.vue";
import { DateTime } from "luxon";

import Event from "@graphql/types/event";
import Round from "@graphql/types/br_round";
import PlayDay from "@graphql/types/play_day";

@Component({
  components: { MetaLine }
})
export default class RoundCardBasics extends Vue {
  @Prop() round!: Round;
  @Prop() event!: Event;
  @Prop() playDay!: PlayDay;
  @Prop() name!: string;

  get startTime() {
    return DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.DATETIME_MED);
  }
}
