import gql from "graphql-tag";
import { MatchFields } from "@graphql/types/match";
import { MatchCheckinFields } from "@graphql/types/checkin";

export default gql`
  mutation CheckinToMatches($matchIds: [ID!]!, $asTeam: Boolean, $teamId: ID) {
    checkinToMatches(matchIds: $matchIds, asTeam: $asTeam, teamId: $teamId) {
      success
      matches {
        ...MatchFields

        checkins {
          ...MatchCheckinFields
        }
      }
    }
  }
  ${MatchFields}
  ${MatchCheckinFields}
`;
