import gql from "graphql-tag";

export default gql`
  mutation UpdateStreamingAccounts($streamingAccounts : [StreamingAccountInput!]!) {
    updateMyStreamingAccounts(streamingAccounts: $streamingAccounts) {
      success
      streamingAccounts {
        tag
        value
      }
    }
  }
`;
