import gql from "graphql-tag";

import { MatchFields } from "@graphql/types/match";
import { ParticipationFields } from "@graphql/types/participation";
import { MatchResultFields } from "@graphql/types/match_result";

export const GetMatch = gql`
  query GetMatch($id: ID!) {
    match(id: $id) {
      ...MatchFields
    }
  }
  ${MatchFields}
`;

export const GetMatchWithParticipations = gql`
  query GetWithParticipations($id: ID!) {
    match(id: $id) {
      ...MatchFields
      participations {
        ...ParticipationFields
      }
      result {
        ...MatchResultFields
      }
    }
  }
  ${MatchFields}
  ${ParticipationFields}
  ${MatchResultFields}
`;

export const GetMatchWithResult = gql`
  query GetMatch($id: ID!) {
    match(id: $id) {
      ...MatchFields
      participations {
        ...ParticipationFields
        participant {
          id
          name
          avatar {
            url
          }
        }
      }
      result {
        ...MatchResultFields
      }
    }
  }
  ${MatchFields}
  ${ParticipationFields}
  ${MatchResultFields}
`;
