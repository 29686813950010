








































import { Component, Mixins } from "@components/common";
import MatchModel from "@graphql/types/match";
import { GetMatchWithParticipations } from "@graphql/queries/match";
import ResultCard from "@components/matches/result_card.vue";
import MatchHelpersMixin from "@/mixins/match_helpers";

@Component({
  components: { ResultCard },
  apollo: {
    match: {
      query: GetMatchWithParticipations,
      variables() { return {id: this.matchId}; }
    }
  }
})
export default class Match extends Mixins(MatchHelpersMixin) {
  private match!: MatchModel;

  private firstParticipantResult = "0";
  private secondParticipantResult = "0";


  get matchId() {
    return this.$route.params.matchId;
  }
}
