

























































import { Component, Vue, Prop } from "@components/common";
import { Datetime } from "vue-datetime";
import { DateTime, Interval } from "luxon";

import RoundHelpers, { RoundStage } from "@lib/helpers/round_card_helpers";

import Event from "@/graphql/types/event";
import PlayDay from "@/graphql/types/play_day";
import Round from "@/graphql/types/br_round";
import Team from "@/graphql/types/team";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  RoundCardBasics,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";

import 'vue-datetime/dist/vue-datetime.css';

const ROUND_PRIORITY_2_OFFSET = 5 * 60;
const ROUND_PRIORITY_3_OFFSET = 15 * 60;

@Component({
  components: {
    ActionBtn,
    CardBody,
    CardHeader,
    DateTimeInfo,
    Datetime,
    Expander,
    RoundCardBasics,
    Meta,
    MetaLine,
    UserAvatar,
  }
})
export default class WaitingStage extends Vue {
  @Prop() private readonly round!: Round;

  @Prop() private readonly event!: Event;
  @Prop() private readonly myTeams!: Team[];
  @Prop() private readonly playDay!: PlayDay;

  @Prop() private readonly show!: boolean;

  // Common starting string for translations
  private loading = false;

  get roundName() {
    return this.roundHelpers.roundName;
  }

  get roundHelpers() {
    return new RoundHelpers(this.round, {
      me: this.currentUser,
      stage: RoundStage.Waiting,
      event: this.event,
      myTeams: this.myTeams,
      playDay: this.playDay,
    });
  }

  get isStarted() {
    // NaN when negative interval.
    return this.remainingTime != this.remainingTime;
  }

  get remainingTime() {
    this.ticker;

    return Interval.fromDateTimes(
      DateTime.local(),
      DateTime.fromISO(this.round.startTime)
    ).length("seconds");
  }

  get remainingTimeText() {
    return this.roundHelpers.intervalText(this.remainingTime, {short: true});
  }

  get priority() {
    if (this.isStarted || this.remainingTime < ROUND_PRIORITY_3_OFFSET)
      return 3;
    else if (this.remainingTime < ROUND_PRIORITY_2_OFFSET)
      return 2;
    else
      return 1;
  }

  get icon() {
    return "mdi-clock";
  }

  get expanded() {
    return this.show || this.priority > 2;
  }

  get formattedLatestStartDatetime() {
    return DateTime.fromISO(this.round.startTime).toLocaleString(DateTime.DATETIME_MED);
  }

  async loadData() {
    this.loading = true;

    Promise.all([
      // lol
    ]).finally(() => {
      this.loading = false;
    });
  }

  private timer: ReturnType<typeof setInterval> | null = null;
  private ticker = 0;

  mounted() {
    this.timer = setInterval(this.tick, 1000);

    this.loadData();
  }

  unmounted() {
    const timer = this.timer;
    if (timer)
      clearInterval(timer);
  }

  tick() {
    this.ticker += 1;
  }
}
