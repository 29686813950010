























import { Component, GuestPage, Layout,FormLabel } from "@components/common";
import { FindActivationUserByToken } from "@graphql/queries/user";

class ActivationUser {
  id?: number;
  email?: string;
  hasPassword?: boolean;
}

@Component({
  components: { Layout, FormLabel },
  apollo: {
    activationUser: {
      skip() { return !this.$route.query.token; },
      query: FindActivationUserByToken,
      variables() { return { token: this.$route.query.token }; },
      error() { this.error = true; }
    }
  }
})
export default class Activate extends GuestPage {
  private activationUser = new ActivationUser();
  private error = false;
  private noToken = false;
  private activating = true;

  private form = {
    token: ""
  }

  created() {
    if (this.activationToken) {
      this.form.token = this.activationToken;
      this.activating = false;
      this.submit();
    } else {
      this.noToken = true;
      this.activating = false;
    }
  }

  get activationToken() {
    return this.$route.query.token as string;
  }

  async submit() {
    try {
      const result = await this.$store.dispatch("users/activate", this.form);
      if (result.token) {
        this.$notify({text: this.$t("activation.success").toString()});
        this.$router.push("/register/1");
      }
    } catch {
      this.activating = false;
      this.error = true;
    }
  }
}
