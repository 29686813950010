




































import { Page, Component, Layout, FormLabel } from "@components/common";
import { GetMyStreamingAccounts } from "@graphql/queries/me";
import StreamingAccount, { AVAILABLE_STREAMING_ACCOUNTS } from "@graphql/types/streaming_account";

@Component({
  components: { Layout, FormLabel },
  apollo: {
    streamingAccounts: {
      query: GetMyStreamingAccounts,
      update: ({ me: { streamingAccounts } }) => streamingAccounts,
      result({ data: { me: { streamingAccounts }}}) { this.updateData(streamingAccounts); }
    }
  },
  data() {
    return {
      availableAccounts: AVAILABLE_STREAMING_ACCOUNTS
    };
  }
})
export default class StreamingAccounts extends Page {
  private streamingAccounts!: StreamingAccount[];
  private form = AVAILABLE_STREAMING_ACCOUNTS.reduce((obj, account) => ({
    ...obj, [account]: ""
  }), {});

  get streamingAccountsInput() {
    return Object.keys(this.form).map(tag => ({
      tag: tag,
      value: this.form[tag]
    }));
  }

  async submit() {
    const result = await this.$store.dispatch("me/setStreamingAccounts", {
      streamingAccounts: this.streamingAccountsInput
    });

    if (result.success) {
      this.$notify({text: this.$t("profile_settings.saved.streaming_accounts").toString()});
      this.updateData(result.streamingAccounts);
      this.$router.push({ name: 'Profile Settings'});
    }
  }

  updateData(streamingAccounts) {
    streamingAccounts.forEach(account =>
      this.form[account.tag] = account.value
    );
  }

  notEmpty(val) {
    return !!val && val.length > 0;
  }
}
