




























































































































import { Prop, Component, Vue, Watch } from "@components/common";
import { DateTime } from "luxon";
import { ListFilteredEventIds } from "@graphql/queries/events";
import Event from "@graphql/types/event";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";

interface LeaveEventParams {
  id?: number;
  teamId?: number;
  asTeam?: boolean;
}

interface EventIdEntry {
  id: number;
}

type EventIdList = Array<EventIdEntry>;

interface EventIdsContainer {
  events: EventIdList;
  tournaments: EventIdList;
}

@Component({
  apollo: {
    myEventIds: {
      query: ListFilteredEventIds,
      variables() { return {filters: {userIds: [this.currentUserId]}};},
      skip() { return !this.isLoggedIn; },
      update: data => data
    }
  }
})
export default class EventCard extends Vue {
  @Prop() readonly event!: Event;

  private myEventIds: EventIdsContainer | null = null;

  @Watch("event.id")
  get helpers() {
    return new EventCategoryHelpers(this.event);
  }

  get eventStartEndDate() {
    const startDate = DateTime.fromISO(this.event.startTime).toLocaleString(DateTime.DATE_MED);
    const endDate = DateTime.fromISO(this.event.endTime).toLocaleString(DateTime.DATE_MED);

    return `${startDate} - ${endDate}`;
  }

  get isUserBased() {
    return this.event.isUserBased;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get myParticipatingTeam() {
    const teams = this.event.participatingTeams;

    if (!teams || teams.length == 0)
      return null;

    return teams.find(team =>
      team.members?.find(member => member.id === this.currentUserId)
    );
  }

  get isParticipatingTeamCaptain() {
    return this.myParticipatingTeam?.captain?.id === this.currentUserId;
  }

  get canLeaveEvent() {
    if (this.hasEventStarted)
      return false;

    return this.isParticipant && (!this.isTeamBased || this.isParticipatingTeamCaptain);
  }

  get hasEventStarted() {
    const event = this.event;

    if (!event) return false;

    return DateTime.fromISO(event.startTime) < DateTime.local();
  }

  get isParticipant() {
    if (!this.isLoggedIn)
      return false;

    const eventIds = this.myEventIds;
    if (!eventIds)
      return false;

    const events = Event.isLegacyEvent(this.event) ? eventIds.tournaments : eventIds.events;

    return events.some(e => e.id == this.event.id);
  }

  async leaveEvent() {
    if (!await this.confirmLeave())
      return;

    let params: LeaveEventParams = {id: this.event.id};
    if (this.isTeamBased)
      params = { ...params, teamId: this.myParticipatingTeam?.id, asTeam: true};

    // TODO: Remove legacy tournament support
    const action = Event.isLegacyEvent(this.event) ? "tournaments/leave" : "events/leave";
    const result = await this.$store.dispatch(action, params);

    if (result.success) {
      this.$store.dispatch("myEnrolments/removeEvent", this.event);

      this.$notify({
        type: "success",
        text: this.$t(`event.${this.helpers.translationKey}.leave_success`, {name: this.event.name}).toString()
      });

      this.$emit("update", result);
    }
  }

  confirmLeave() {
    return this.$confirm(
      this.$t(`event.${this.helpers.translationKey}.confirm_leave`).toString()
    );
  }

  get avatarUrl() {
    return this.event?.avatar?.url ?? "";
  }

  get eventPlatforms() {
    return this.platformsByIds(this.event?.platformIds ?? []);
  }

  get uniquePlatformsIcons() {
    return [...new Set(this.eventPlatforms?.map(x=> x.icon))];
  }

  get participantCount() {
    return this.event?.participations?.length ?? 0;
  }

  get gameName() {
    const gameId = this.event?.gameId;
    if (!gameId)
      return "";

    return this.gameById(gameId)?.name ?? "";
  }
}
