

































import { Vue, Component, Prop, Divider, FormLabel} from "@components/common";
import ActivityCard from "@components/v2/ActivityCards/ActivityCard.vue";

import Event from "@graphql/types/event";
import Match from "@graphql/types/match";
import MatchResult from "@graphql/types/match_result";
import Participation from "@graphql/types/participation";
import Checkin from "@graphql/types/checkin";

import MatchHelpers, { MatchStage } from "@/lib/helpers/match_helpers";
import { groupBy } from "underscore";

import { DateTime } from "luxon";

const MATCH_STAGE_CATEGORIES = {
  [MatchStage.Schedule]:   "unscheduled",
  [MatchStage.Reschedule]: "upcoming",
  [MatchStage.Checkin]:    "upcoming",
  [MatchStage.Result]:     "pending_result",
  [MatchStage.Overview]:   "completed",
  [MatchStage.Error]:      "completed"
};

const CATEGORY_ORDER = [
  "pending_result",
  "upcoming",
  "unscheduled",
  "completed"
];

@Component({ components: { Divider, FormLabel, ActivityCard } })
export default class MyMatches extends Vue {
  @Prop() readonly event!: Event;

  private loading = true;
  private matches: Match[] = [];
  private refreshInterval: any = null;

  mounted() {
    this.loadData();

    this.$eventBus.$on("store:update", this.updateMatch);
    this.refreshInterval = setInterval(this.loadMatches, 60_000);
  }

  unmounted() {
    this.$eventBus.$off("store:update", this.updateMatch);

    clearInterval(this.refreshInterval);
  }

  updateMatch({ name, record: match }) {
    if (name !== "match")
      return;

    const index = this.matches.findIndex(m => m.id == match.id);

    if (index != -1)
      this.$set(this.matches, index, match);
  }

  loadData() {
    this.loading = true;

    Promise.all([
      this.loadMatches()
    ]).finally(() => this.loading = false);
  }

  get groupedMatches() {
    return MatchHelpers.combineGroupedMatches(this.matches);
  }

  async loadMatches() {
    this.matches = await this.$store.dispatch("matches/search", {
      filters: {eventId: Number(this.event.id), userIds: [Number(this.currentUserId)]},
      limit: 999,
      extraSelection: [{
        participations: Participation,
        result: MatchResult,
        checkins: Checkin
      }],
      noCache: true
    });
  }

  get categorizedMatches() {
    return groupBy(this.groupedMatches, match => {
      const stage = new MatchHelpers(match, [], {me: this.currentUser, event: this.event}).matchCardStage;

      return MATCH_STAGE_CATEGORIES[stage];
    });
  }

  get pendingMatches() {
    const now = DateTime.local();

    return this.groupedMatches.filter(m => !m.result?.id && DateTime.fromISO(m.startTime) < now);
  }

  get futureMatches(){
    const now = DateTime.local();

    return this.groupedMatches.filter(m => DateTime.fromISO(m.startTime) > now);
  }

  get completedMatches() {
    return this.groupedMatches.filter(m => !!m.result?.id);
  }

  private categoryOrder = CATEGORY_ORDER;
}
