import gql from "graphql-tag";
import { UserFields, UserDetailFields } from "@graphql/types/user";
import { TeamFields } from "../types/team";

export const FindUserWithTeams = gql`
  query FindUserWithTeams($id: ID, $uuid: ID) {
    user(id: $id, uuid: $uuid) {
      ...UserFields
      teams {
        id
        name
      }
    }
  }
  ${UserFields}
`;

export const FindUserDetails = gql`
  query FindUserWithDetails($id: ID, $uuid: ID) {
    user(id: $id, uuid: $uuid) {
      ...UserDetailFields
      teams {
        ...TeamFields
      }
    }
  }
  ${UserDetailFields}
  ${TeamFields}
`;

export const FindActivationUserByToken = gql`
  query FindActivationUserByToken($token: String!) {
    activationUser(token: $token) {
      id
      email
      hasPassword
      activated
    }
  }
`;

export const FindUser = gql`
  query FindUser($id: ID, $uuid: ID) {
    user(id: $id, uuid: $uuid) {
      ...UserFields
    }
  }
  ${UserFields}
`;
