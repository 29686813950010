















































import { Component, Vue, Prop, Divider } from "@components/common";
import Match from "@graphql/types/match";
import Event from "@graphql/types/event";
import User from "@graphql/types/user";
import FormattedTime from "@components/common/formatted_time.vue";
import CheckInButton from "@components/events/active/check_in_button.vue";
import ScheduleMatch from "@components/events/active/schedule_match.vue";
import { DateTime } from "luxon";
import FeedCard from '@components/feed/feed_card.vue';
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";

@Component({
    components: {
        Divider,
        FormattedTime,
        ScheduleMatch,
        CheckInButton,
        FeedCard
    }
})

export default class FeedMatchCard extends Vue {
    @Prop() readonly match!: Match;
    @Prop() readonly event!: Event;

    get justButton() {
        return true;
    }

    get gameName() {
        return this.event?.game?.name;
    }

    eventRouteName(event) {
      return new EventCategoryHelpers(event).routeName;
    }

    get formattedMatchStartTime() {
        return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_SHORT);
    }

    get formattedMatchProposedStartTime() {
        return DateTime.fromISO(this.match.proposedStartTime).toLocaleString(DateTime.DATETIME_SHORT);
    }

    get cardTitle() {
        return this.$t('feed.match_card.schedule_pending', { matchTag: this.matchTag, groupTag: this.groupTag, gameName: this.gameName, opponentName: this.opponentName, proposedTime: this.formattedMatchProposedStartTime});
    }

    get participants() {
        if (this.event?.isUserBased)
        return this.event.participatingUsers;
        else
        return this.event?.participatingTeams;
    }

    get matchPlatforms() {
        if (this.event === null) return "";
        return this.event?.platforms?.map(function(p){
            return p.name;
        }).join(", ");
    }

    get teamLineup() {
        return false;
    }

    get groupTag() {
        if (this.match.group === undefined) return "N/A";
        return this.match.group + 1;
    }

    get matchTag() {
        if (this.match.index === undefined) return "N/A";
        return this.match.index + 1;
    }

    get matchIndex() {
        if (this.match?.index === undefined) return 0;
        return this.match?.index + 1;
    }

    get currentParticipantId() {
        if (!this.isTeamBased) return this.currentUserId;
        return this.myParticipatingTeam?.id;
    }

    get myParticipatingTeam() {
        if (this.isTeamBased) return this.event?.participatingTeams?.find(team => team.members?.find(member => member.id === this.currentUserId));
        return null;
    }

    // Participants
    get isParticipantOneMe() {
        return this.currentParticipantId === this.participantOne?.id;
    }

    get isParticipantTwoMe() {
        return this.currentParticipantId === this.participantTwo?.id;
    }

    get opponent() {
        return this.isParticipantOneMe ? this.participantTwo : this.participantOne;
    }

    get opponentName() {
        return this.opponent?.name;
    }

    get participantTwoName() {
        return this.participantTwo?.name;
    }

    get participantOneName() {
        return this.participantOne?.name;
    }

    get isTeamBased() {
        return this.match?.participations?.[0].type === "TEAM";
    }

    get participantOne() {
        const opponentId = this.match?.participations?.[0]?.id;

        if (!this.isTeamBased)
        return this.event?.participatingUsers?.find(p => p.id == opponentId);
        else
        return this.event?.participatingTeams?.find(p => p.id == opponentId);
    }

    get participantTwoAvatar() {
        return this.participantTwo?.avatar?.url ?? "";
    }

    get participantOneAvatar() {
        return this.participantOne?.avatar?.url ?? "";
    }

    get participantTwo() {
        const opponentId = this.match?.participations?.[1]?.id;

        if (!opponentId)
        return null;

        if (!this.isTeamBased)
        return this.event?.participatingUsers?.find(p => p.id == opponentId);
        else
        return this.event?.participatingTeams?.find(p => p.id == opponentId);
    }

    get isAutoWin() {
        return !this.participantTwo;
    }

    get isOwnMatch() {
        const id = this.currentUserId;

        return this.participantOne?.id == id ||
        this.participantTwo?.id == id;
    }

    get isMatchPendingSchedule() {
        if (this.match.isStartTimeConfirmed === undefined) return false;
        return !this.match.isStartTimeConfirmed;
    }

    get isMatchScheduleProposed() {
        return this.match.proposedStartTime === null ? false : true;
    }

    get isProposedByMe() {
        if(this.match.startTimeProposedById === undefined) return false;
        return this.currentUserId?.toString() === this.match.startTimeProposedById?.toString();
    }

    get isPendingOpponentConfirmation() {
        return this.isProposedByMe && !this.isConfirmed && !this.isMatchPendingStart && this.isMatchScheduleProposed;
    }

    get isConfirmed() {
        return this.match?.isStartTimeConfirmed;
    }

    get isPendingMyConfirmation() {
        return !this.isProposedByMe && !this.isConfirmed && !this.isMatchPendingStart && this.isMatchScheduleProposed;
    }

    // Date match defined but not yet started
    get isMatchPendingStart() {
        return this.match.isStartTimeConfirmed;
    }

    get hasMatchStarted() {
        return DateTime.fromISO(this.match.startTime) < DateTime.local();
    }

    // Match started and not finished yet
    get isMatchActive() {
        return this.hasMatchStarted && !this.isMatchCompleted && !this.isMatchPendingSchedule;
    }

    // Match finished
    get isMatchCompleted() {
        return DateTime.fromISO(this.match.endTime) < DateTime.local();
    }

    showSubmitScoreDialog(...args) {
        this.$emit("showSubmitScoreDialog", ...args);
    }

    checkIn() {
        this.$notify({text: this.$t("common.not_available_yet").toString()});
    }

    scheduleMatch() {
        this.$emit("scheduleMatch", this.match);
    }

    refreshEvent() {
        this.$emit("refreshEvent");
    }

    openChat() {
      const opponent = this.opponent;
      if (!opponent)
        return;

      this.$eventBus.$emit("chat:open:1on1", (opponent as User).uuid);
    }
}
