




































import { Component, Vue, Prop, Divider } from "@components/common";

import EventAction from "@components/events/action.vue";
import JoinDialog from "@components/tournaments/join_dialog.vue";
import CardDetailHeader from "@components/tournaments/card_detail_header.vue";
import ConfirmationDialog from "@components/events/confirmation_popup.vue";
import ErrorDialog from "@components/events/error_popup.vue";

import Tournament from "@graphql/types/tournament";
import Platform from "@graphql/types/platform";
import Team from "@graphql/types/team";

@Component({
  components: {
    ConfirmationDialog,
    JoinDialog,
    EventAction,
    CardDetailHeader,
    Divider,
    ErrorDialog
  },
})
export default class TournamentCardDetail extends Vue {
  @Prop(Object) readonly tournament!: Tournament;
  @Prop(Boolean) readonly allowJoin!: boolean
  @Prop({default: false}) readonly details!: boolean;

  private myTeams!: Team[];
  private platforms: Platform[] = [];
  private selectedTeamId = null;
  private showConfirmation = false;
  private showError = false

  get showTeamsDropdown() {
    return this.isTeamBased && this.myTeams?.length > 1;
  }

  get confirmationDialog() {
    return this.showConfirmation;
  }

  private closeConfirmationDialog() {
    this.showConfirmation = false;
  }

  get checkinTime() {
    if (this?.tournament.checkin !== undefined) {
      return Math.round(this.tournament.checkin / 60);
    }
      return undefined;
  }

  get uniquePlatformsIcons() {
    const platforms = this.tournament?.platforms;

    if (!platforms)
      return [];

    return Array.from(
      new Set(platforms.map(x => x.icon))
    );
  }

  get participantCount() {
    const tournament = this.tournament;

    if (!tournament)
      return 0;

    return Math.max(
      tournament?.participatingUsers?.length ?? 0,
      tournament?.participatingTeams?.length ?? 0
    );
  }

  get tournamentFull() {
    const tournament = this.tournament;

    if (!tournament || !tournament.maxPlayers)
      return false;

    return this.participantCount >= tournament.maxPlayers;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get isUserBased() {
    return this.tournament?.mode === "1v1";
  }

  get isReschedule() {
    // TODO: needs implementation
    return false;
  }

  isTeamAlreadyParticipating(team) {
    return this?.tournament?.participatingTeams?.some(p => p.id == team.id);
  }

  showSubmitScoreDialog(...args) {
    this.$emit("showSubmitScoreDialog", ...args);
  }

  async joinTournamentAsTeam({ tournament, teamId }) {
    return this.joinTournament(tournament,{
      tournamentId: this.tournament.id,
      asUser: false,
      asTeam: true,
      teamId: teamId
    });
  }

  async joinTournamentAsUser({ tournament }) {
    return this.joinTournament(tournament, {
      tournamentId: this.tournament.id,
      asUser: true
    });
  }

  async joinTournament(tournament, variables) {
    let result;

    if (!this.isLoggedIn) {
      this.$router.push({name: 'Join'});
      return;
    }

    try {
      result = await this.$store.dispatch("tournaments/join",variables);

      this.showConfirmation = true;
      this.$emit("update", {tournament: new Tournament(result.tournament)});
      this.$gtag.event('signup_to_tournament', {
        'event_category': 'event_action',
        'event_label': 'Sign up to Tournament',
        'value': { userId: this.currentUserId, eventId: this.tournament.id, eventName: this.tournament.name, eventType: 'Tournament' }
      });
    } catch (error) {
      this.showError = true;
    }
  }

  refreshTournament(...args) {
    this.$emit("refreshTournament", ...args);
  }
}
