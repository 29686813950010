import gql from "graphql-tag";
import Base from "@graphql/types/base";
import { DateTime } from "luxon";
import EventFlowStage, {
  BaseEventFlowStageFields,
} from "@graphql/types/event_flow_stage";

import { selectable as s } from "@/store/generic_store";

export const BaseEventFlowFields = gql`
  fragment BaseEventFlowFields on EventFlow {
    id
    eventId
    eventType
    status
    startTime
    endTime
    previousEventFlowStageId
    currentEventFlowStageId
    nextEventFlowStageId
  }
`;

export const EventFlowWithStagesFields = gql`
  fragment EventFlowWithStagesFields on EventFlow {
    ...BaseEventFlowFields
    eventFlowStages {
      ...BaseEventFlowStageFields
    }
  }
  ${BaseEventFlowStageFields}
  ${BaseEventFlowFields}
`;

export default class EventFlow extends Base<EventFlow> {
  @s() id?: number;
  @s() eventId?: number;
  @s() eventType?: string;
  @s() status?: string;
  @s() startTime?: DateTime;
  @s() endTime?: DateTime;
  @s() previousEventFlowStageId?: number;
  @s() currentEventFlowStageId?: number;
  @s() nextEventFlowStageId?: number;

  eventFlowStages?: EventFlowStage[];
}
