

























































import { Component, Vue, Prop } from "@components/common";
import { DateTime } from "luxon";
import { FormattedTime } from "@components/common/helpers";
import Event from "@graphql/types/event";
import Platform from "@graphql/types/platform";
import Team from "@graphql/types/team";

@Component({ components: { FormattedTime } })
export default class EventCardDetailHeader extends Vue {
  @Prop(Object) readonly event!: Event;

  private myTeams!: Team[];
  private platforms: Platform[] = [];
  private selectedTeamId = null;

  get showTeamsDropdown() {
    return this.isTeamBased && this.myTeams?.length > 1;
  }

  get checkinTime() {
    if (this?.event.checkin !== undefined) {
      return Math.round(this.event.checkin / 60);
    }
      return undefined;
  }

  get uniquePlatformsIcons() {
    const platforms = this.event?.platforms;

    if (!platforms)
      return [];

    return Array.from(
      new Set(platforms.map(x => x.icon))
    );
  }

  get participantCount() {
    const event = this.event;

    if (!event)
      return 0;

    return Math.max(
      event?.participatingUsers?.length ?? 0,
      event?.participatingTeams?.length ?? 0
    );
  }

  get eventFull() {
    const event = this.event;

    if (!event || !event.maxPlayers)
      return false;

    return this.participantCount >= event.maxPlayers;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get isUserBased() {
    return this.event?.mode === "1v1";
  }

  get coverImage() {
    return this.event?.coverImage?.url ?? '';
  }

  isTeamAlreadyParticipating(team) {
    return this?.event?.participatingTeams?.some(p => p.id == team.id);
  }

    get eventStartEndDate() {
    const startDate = DateTime.fromISO(this.event.startTime).toLocaleString(DateTime.DATE_MED);
    const endDate = DateTime.fromISO(this.event.endTime).toLocaleString(DateTime.DATE_MED);

    return `${startDate} - ${endDate}`;
  }
}
