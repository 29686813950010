import gql from "graphql-tag";

import { TeamFields } from "@graphql/types/team";

export default gql`
  mutation RemoveTeamMember($teamId : ID!, $userId : ID!) {
    removeTeamMember(teamId: $teamId, userId: $userId) {
      success
      team {
        ...TeamFields
      }
    }
  }
  ${TeamFields}
`;
