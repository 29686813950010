import gql from "graphql-tag";
import {
  TournamentFields,
  ListTournamentFields,
  MyTournamentsFields
} from "@graphql/types/tournament";
import { ListEventFields } from "@graphql/types/event";
import { GameFields } from "@graphql/types/game";
import { PlatformFields } from "@graphql/types/platform";
import { TeamFields } from "../types/team";

export const ListTournaments = gql`
  query ListTournaments($filters: TournamentsFilterInput) {
    tournaments(filters: $filters) {
      ...TournamentFields
    }
  }
  ${TournamentFields}
`;

export const ListTournamentsCards = gql`
  query ListTournaments(
    $filters: TournamentsFilterInput
    $eventFilters: EventsFilterInput
  ) {
    tournaments(filters: $filters) {
      ...ListTournamentFields
    }

    events(category: TOURNAMENT, filters: $eventFilters) {
      ...ListEventFields
    }
  }
  ${ListTournamentFields}
  ${ListEventFields}
`;

export const ListTournamentsWithGame = gql`
  query ListTournamentsWithGame($filters: TournamentsFilterInput) {
    tournaments(filters: $filters) {
      ...TournamentFields
      game {
        ...GameFields
      }
    }
  }
  ${TournamentFields}
  ${GameFields}
`;

export const ListTournamentsWithGameAndTeamParticipation = gql`
  query ListTournaments($filters: TournamentsFilterInput) {
    tournaments(filters: $filters) {
      ...TournamentFields
      game {
        ...GameFields
      }
      participatingTeams {
        ...TeamFields
      }
      platforms {
        ...PlatformFields
      }
    }
  }
  ${TournamentFields}
  ${PlatformFields}
  ${GameFields}
  ${TeamFields}
`;

export const ListMyTournamentsCards = gql`
  query ListMyTournaments($filters: TournamentsFilterInput) {
    myTournaments(filters: $filters) {
      ...MyTournamentsFields
    }
  }
  ${MyTournamentsFields}
`;
