



















































































































































































import Skeleton from "@components/events/skeleton.vue";
import Standings from "@components/tournaments/standings.vue";
import BRStandings from "@components/tournaments/BR_standings.vue";
import Matches from "@components/tournaments/matches.vue";
import Rounds from "@components/tournaments/rounds.vue";
import Brackets from "@components/matches/brackets.vue";
import PlayerCard from "@components/players/card.vue";
import PlayerGridCard from "@components/players/grid_card.vue";
import SubmitMatchScore from "@components/events/active/submit_match_score.vue";
import TeamCard from "@components/teams/card.vue";
import TournamentCardDetails from "@components/tournaments/card_detail.vue";
import TournamentModel from "@graphql/types/tournament";
import { Component, Page, Layout, Prop } from "@components/common";
import { GetTournamentWithMatches } from "@graphql/queries/tournament";
// import { TournamentWasUpdated } from "@graphql/subscriptions/tournament";

@Component({
  components: {
    PlayerCard,
    PlayerGridCard,
    TeamCard,
    TournamentCardDetails,
    Layout,
    SubmitMatchScore,
    Brackets,
    Standings,
    Matches,
    Rounds,
    Skeleton,
    BRStandings
  },
  apollo: {
    tournament: {
      query: GetTournamentWithMatches,
      variables() { return {id: this.tournamentId}; },
      update: ({ tournament }) => new TournamentModel(tournament),
      result() { this.loading = false; },
      pollInterval: 30000,
    },
    /*
    $subscribe: {
      tournamentUpdate: {
        query: TournamentWasUpdated,
        variables() { return {id: this.tournamentId}; },
        result({ data: { tournamentWasUpdated: tournament }}) {
          if (tournament)
            this.updateTournament({tournament: new TournamentModel(tournament)});
        }
      }
    }
    */
  }
})
export default class Tournament extends Page {
  @Prop() readonly route;

  private tournament = new TournamentModel;
  private showGrid = false;
  private currentTab = null
  private loading = true

  private submitScoreDialog = {
    show: false,
    matchId: null
  };

  get isRoundBased() {
    return this.tournament.roundType === "Round";
  }

  get getRoute() {
    return this.route ? this.route : '/browse/tournaments';
  }

  get description() {
    return this.tournament?.descriptions?.[this.$i18n.locale] ??
      this.tournament?.descriptions?.[this.$i18n.fallbackLocale[0]];
  }

  get rules() {
    return this.tournament?.rules?.[this.$i18n.locale] ??
      this.tournament?.rules?.[this.$i18n.fallbackLocale[0]];
  }

  get tournamentId() {
    return this.$route.params.tournamentId ?? this.$route.params.id;
  }

  get match() {
    if (this.tournament.matches === undefined)
      return null;
    return this.tournament?.matches[0];
  }

  updateTournament({ tournament }) {
    this.tournament = tournament;
  }

  get hasMatches() {
    if (this.tournament.matches === undefined) return false;
    if (this.tournament.matches.length > 0) return true;
    return false;
  }

  get hasRounds() {
    if (this.roundsCount > 0) return true;
    return false;
  }

  get roundsCount() {
    return this.rounds?.length || 0;
  }

  get rounds() {
    const rounds = this.tournament.playDays?.map(o => o.rounds).flat();
    return rounds;
  }

  get hasPlayoffMatches() {
    if (this.playoffMatches === undefined) return [];
    return this.playoffMatches.length > 0 ? true : false;
  }

  get playoffMatches() {
    return this.tournament.matches?.filter(m=> m.bracket !== "group");
  }

  refreshTournament() {
    this.$apollo.queries.tournament.refetch();
  }

  get participantsTabName() {
    if (this.tournament?.isTeamBased) {
      return this.$t("tournament.tabs.teams");
    } else {
      return this.$t("tournament.tabs.players");
    }
  }

  get isUserBased() {
    return this.tournament?.isUserBased;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  updateMatch(match) {
    console.log(`Updating match ${match.id} in tournament`);
    const tournament = this.tournament;
    const matches = tournament?.matches;

    if (!tournament || !matches)
      return;

    const index = matches.map(m => m.id).indexOf(match.id);

    if (index !== -1)
      matches[index] = match;
  }

  mounted() {
    this.$eventBus.$on("match:update", this.updateMatch);
  }

  unmounted() {
    this.$eventBus.$off("match:update", this.updateMatch);
  }
}
