






















































































import { Component, Vue, Prop } from "@components/common";

@Component
export default class PlayerCard extends Vue {
  @Prop() readonly player?;
  @Prop({type: Boolean, default: false}) readonly team?: boolean;
  @Prop({type: Boolean, default: false}) readonly noGames?: boolean;

  get avatarUrl() {
    return this.player?.avatar?.url ?? "";
  }

  private maxGames = 3;

  get mainGames() {
    return this.games.slice(0, this.maxGames);
  }

  get remainingGameCount() {
    const gameCount = this.games?.length;

    if (this.maxGames > gameCount)
      return 0;

    return gameCount - this.maxGames;
  }

  get games() {
    return this.player?.games ?? [];
  }

  private s = 72 // Saturation
  private l = 50 // Lightness

  get membersCount() {
    if(this.player.members === undefined) {
      return 0;
    } else {
      return this.player.members.length;
    }
  }

  get playerDetailsLink() {
    const query = {r: this.$route.fullPath};

    return this.team ?
      {name: 'Team', params: {teamId: this.player.id}, query} :
      {name: 'Players', params: {userId: this.player.id}, query};
  }

  getFirstChar(str: string): string {
    return str.slice(0,2);
  }

  stringToHslColor(str: string, s: number, l: number): string {
    let hash = 0;

    str.split('').forEach((letter, i) => {
      hash += str.charCodeAt(i) + ((hash << 5) - hash);
    });

    return `hsl(${hash % 360}, ${s}%, ${l}%)`;
  }
}
