











import { Component, Vue, Prop } from "@components/common";
import MatchCard from "@components/v2/ActivityCards/MatchCard.vue";
import RoundCard from "@components/v2/ActivityCards/RoundCard.vue";
import Match from '@/graphql/types/match';
import Round from '@/graphql/types/br_round';

@Component({ components: { MatchCard, RoundCard } })
export default class ActivityCard extends Vue {
  @Prop() private activity!: Match | Round

  get isMatch () {
    return this.activity.__typename === 'Match';
  }

  created() {
    console.log("initialised");
  }
}
