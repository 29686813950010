


























import { Component, Vue, Prop } from "@components/common";

import Notification from "@graphql/types/notification";

import MatchNotification from "@components/v2/Notifications/MatchNotification.vue";
import BasicNotification from "@components/v2/Notifications/BasicNotification.vue";

@Component({
  components: {
    BasicNotification,
    MatchNotification,
  },
})
export default class NotificationsContent extends Vue {
  @Prop() private readonly notifications!: Notification[];

  notificationComponentName(notification) {
    if (Object.keys(notification.data ?? {}).includes("match_id"))
      return "MatchNotification";
    else
      return "BasicNotification";
  }

  get hasNotifications(): boolean {
    return (this.notifications?.length ?? 0) > 0;
  }
}
