import gql from "graphql-tag";
import { MeFields } from "@graphql/types/me";
import { StreamingAccountFields } from "@graphql/types/streaming_account";

export const GetMyInfo = gql`
  query GetMyInfo {
    me {
      ... MeFields
    }
  }
  ${MeFields}
`;

export const GetMyStreamingAccounts = gql`
  query GetMyStreamingAccounts {
    me {
      streamingAccounts {
        ...StreamingAccountFields
      }
    }
  }
  ${StreamingAccountFields}
`;

export const ListMyPlatforms = gql`
  query ListMyPlatforms {
    myPlatforms {
      platformId
      accountIdentifier
      data
    }
  }
`;

export const ListMyGames = gql`
  query ListMyGames {
    myGames {
      games {
        gameId
      }
    }
  }
`;
