import gql from "graphql-tag";
import Base from "@graphql/types/base";
import User from "@graphql/types/user";

import { selectable as s } from "@store/generic_store";

export const RoundResultFields = gql`
  fragment RoundResultFields on RoundResult {
    rank
    kills
    points
    participationId
  }
`;

export default class RoundResult extends Base<RoundResult> {
  // battleRoyaleRoundId?: number; --this can be added to the fragment as soon as backend has it
  @s() rank?: number;
  @s() kills?: number;
  @s() points?: number;
  @s() participationId?: string;
}
