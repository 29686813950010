






















































import GroupStage from "@components/tournaments/group_stage.vue";
import Event from "@graphql/types/event";
// import Brackets from "@components/matches/brackets.vue";
import { Component, Vue, Prop } from "@components/common";
import { ESFQualificationLeagueDivisionsConfiguration } from "@graphql/types/event_flow_stage";

import { namespace } from "@/store";
const AuthenticationState = namespace("authentication");

const DIVISION_GROUP_QUALIFICATION_FLOW_DATA_KEY = "EventStageFlows::Qualification::LeagueDivisions";

interface Group {
  id: number;
  name: string;
  standings: GroupEntry[];
}

interface GroupEntry {
  name: string;
  id: number;
  p: number;
  w: number;
  d: number;
  l: number;
  pts: number;
  f: number;
  sf: number;
  sa: number;
  diff: number;
}

const emptyData = {
  matches: 0,
  wins: 0,
  losses: 0,
  draws: 0,
  points: 0,
  forfeits: 0,
  score_for: 0,
  score_against: 0,
  difference: 0
};

@Component({
  components: {GroupStage}
})
export default class DivisionStandings extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly selectedDivision: any;

  private tab = null

  private headers = [
    { field: 'position', label: '#' },
    { field: 'name' },
    { field: 'p' },
    { field: 'w' },
    { field: 'd' },
    { field: 'l' },
    { field: 'pts' },
    { field: 'f' },
    { field: 'sf' },
    { field: 'sa' },
    { field: 'diff', label: '+/-' },
  ]

  get divisionStandings() {
    const participations = this.selectedDivision.participations;

    return participations.map(p => ({
      ...this.groupParticipantEntry(p)
    }));
  }

  get items() {
    return this.divisionStandings.find(g => g.id === this.selectedDivision.id);
  }

  get isTeamBased() {
    return this.event?.mode !== "1v1";
  }

  groupParticipantEntry(participation): GroupEntry {
    const participant = this.selectedDivision.participations.find(p => p.participationId == participation.participationId);

    if (!participant)
      return {} as GroupEntry;

    const data = participation.data[DIVISION_GROUP_QUALIFICATION_FLOW_DATA_KEY ] || emptyData;

    return {
      id: participant.id!,
      name: participant.name!,
      p: data.matches,
      w: data.wins,
      l: data.losses,
      d: data.draws,
      pts: data.points,
      f: data.forfeits,
      sf: data.score_for,
      sa: data.score_against,
      diff: data.difference,
    };
  }

  get standings() {
    const standings = this.divisionStandings || [];
    const divisionSize = Math.max(this.divisionSize, standings?.length ?? 0);

    const emptySeats = Number(divisionSize - standings?.length ?? 0);
    const noOpponent = {
      name: this.$t('match.group_match_no_opponent'),
      id: null,
      p: 0,
      w: 0,
      l: this.minGamesPerParticipant,
      d: 0,
      pts: 0,
      f: 0,
      sf: 0,
      sa: this.minGamesPerParticipant,
      diff: -this.minGamesPerParticipant,
      position: null
    };

    function noOpponents(slots) {
      const arr = [...Array(slots)];
      return arr.map(() => { return noOpponent; });
    }

    standings?.sort((left, right) => {
      if (left.pts > right.pts) return -1;
      if (right.pts > left.pts) return 1;

      if (left.diff > right.diff) return -1;
      if (right.diff > left.diff) return 1;

      if (left.sf > right.sf) return -1;
      if (right.sf > left.sf) return 1;

      return 0;
    });

    const sorted = [...standings, ...noOpponents(emptySeats)];

    sorted.forEach((val, i) => {
      val.position = i + 1;
    });

    return sorted;
  }

  get divisionSize(): number {
    return this.divisionFlowConfiguration?.division_size ?? 0;
  }

  get divisionFlowConfiguration(): ESFQualificationLeagueDivisionsConfiguration {
    return this.event.eventFlow?.eventFlowStages?.find(efs => efs.flowType === DIVISION_GROUP_QUALIFICATION_FLOW_DATA_KEY)?.configuration as ESFQualificationLeagueDivisionsConfiguration;
  }

  getParticiapntLink (id) {
    return this.isTeamBased ?
      {name: 'Team', params: { teamId: id }}
      : {name: 'Players', params: { userId: id }};
  }

  get minGamesPerParticipant() {
    return (this.divisionSize - 1) * this.gamesPerMatchGroup;
  }

  get gamesPerMatchGroup() {
    switch (this.divisionFlowConfiguration.match_type) {
      case 'bo1': return 1;
      case 'bo3': return 2;
      case 'bo5': return 3;
      case 'bo7': return 4;
      case 'home_away': return 2;
      default: return 1;
    }
  }

  @AuthenticationState.State
  private teamRoles;

  isMe(item) {
    const teamRoles = this.teamRoles;
    const currentUserId = this.currentUserId;

    if (this.isTeamBased) {
      return teamRoles.some(role => role.teamId == item.id);
    } else {
      return currentUserId == item.id;
    }
  }
}
