import gql from "graphql-tag";
import { MatchResultFields } from "@graphql/types/match_result";

export default gql`
  mutation SubmitMatchResult(
    $matchId: ID!
    $scores: [MatchResultScoreInput!]!
    $data: JSON
  ) {
    submitMatchResult(matchId: $matchId, scores: $scores, data: $data) {
      success
      matchResult {
        ...MatchResultFields
      }
    }
  }
  ${MatchResultFields}
`;
