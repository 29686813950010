import { Mutation, Action } from "vuex-module-decorators";
import gql from "graphql-tag";
import { apollo } from "@lib/graphql";
import createStore from "@store/generic_store";

import Chatroom from "@graphql/types/chatroom";

export default class ChatroomsStore extends createStore({
  name: "chatroom",
  recordType: Chatroom,
}) {
  @Action({ rawError: true })
  async leave({ id }: Chatroom) {
    const {
      data: { leaveChatroom: result },
    } = await apollo.mutate({
      mutation: gql`
        mutation LeaveChatroom($id: ID!) {
          leaveChatroom(id: $id) {
            success
          }
        }
      `,
      variables: { id },
    });

    return result;
  }

  @Action({ rawError: true })
  async join({ id }: Chatroom) {
    const {
      data: { joinChatroom: result },
    } = await apollo.mutate({
      mutation: gql`
        mutation JoinChatroom($id: ID!) {
          joinChatroom(id: $id) {
            success
          }
        }
      `,
      variables: { id },
    });

    return result;
  }

  @Action({ rawError: true })
  async joinEvent({ eventId }: { eventId: number }) {
    const {
      data: { joinEventChatroom: result },
    } = await apollo.mutate({
      mutation: gql`
        mutation JoinEventChatroom($eventId: ID!) {
          joinEventChatroom(eventId: $eventId) {
            success
          }
        }
      `,
      variables: { eventId },
    });

    return result;
  }
}
