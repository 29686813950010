import gql from "graphql-tag";
import { MatchResultFields } from "@graphql/types/match_result";

export default gql`
  mutation ConfirmMatchResult(
    $matchId: ID!
  ) {
    confirmMatchResult(
      matchId: $matchId
    ) {
      success
      matchResult {
        ...MatchResultFields
      }
    }
  }
  ${MatchResultFields}
`;
