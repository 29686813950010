
















































































































import { Component, Page, Layout, Divider } from "@components/common";
import Conversation from "@graphql/types/conversation";
import { namespace } from "@store";
import ChatCard from "@components/chat/chat_card.vue";

import Chatroom from "@graphql/types/chatroom";

import { sortBy } from "underscore";

const ChatStore = namespace("chat");

@Component({components: { Layout, ChatCard, Divider }})
export default class Chat extends Page {
  @ChatStore.State
  private myConversations!: Conversation[];

  @ChatStore.State
  private unreadConversationCount!: number;

  @ChatStore.Getter
  private hasUnreadConversations!: number;

  private publicChatrooms: Chatroom[] = [];
  private loading = false;

  openChat(chat) {
    switch (chat.custom.data.type) {
      case "channel":
      case "custom":
      case "group":
        this.$eventBus.$emit("chat:open:group", chat);
        break;
      default:
        this.$eventBus.$emit("chat:open:1on1", this.getUuid(chat));
    }
  }

  successRefresh(success) {
    if (!success) return;

    this.$store.dispatch("chat/refreshMyConversations");
  }

  async joinChatroom(chatroom) {
    this.loading = true;

    this.$store.dispatch("chatrooms/join", chatroom)
      .then(this.successRefresh)
      .finally(() => this.loading = false);
  }

  async leaveChatroom(id: number) {
    this.loading = true;

    this.$store.dispatch("chatrooms/leave", {id: id})
      .then(this.successRefresh)
      .finally(() => this.loading = false);
  }

  async openLinkedChat() {
    const { chatroomId, uuid } = this.$route.query;

    if (chatroomId) {
      const chatroom = await this.$store.dispatch("chatrooms/find", chatroomId);

      if (this.isParticipant(chatroom.talkjsConversationId)) {
        this.$eventBus.$emit("chat:open:group", {id: chatroom.talkjsConversationId});
      }
      else {
        this.openReadonlyChatroom(chatroom);
      }
    } else if (uuid) {
      this.$eventBus.$emit("chat:open:1on1", uuid);
    }
  }

  isCustomChatroom(conversation) {
    return conversation?.custom?.data?.type == "custom";
  }

  isParticipant(id): boolean {
    const uuid = this.currentUser?.uuid;

    if (!uuid)
      return false;

    return !!this.myConversations.find(c =>
      c.id == id && Object.keys(c.participants ?? {}).map(this.fixUuid).includes(uuid)
    );
  }

  fixUuid(uuid) {
    return uuid.toLowerCase().replaceAll("_", "-");
  }

  openReadonlyChatroom(chatroom) {
    this.$eventBus.$emit("chat:open:readonly", chatroom);
  }

  get hasPublicChatrooms() {
    return this.publicChatrooms.length > 0;
  }

  get hasConversations() {
    return this.hasUnreadConversations || this.hasNormalConversations;
  }

  get hasNormalConversations() {
    return this.myConversations.length > 0;
  }

  get directConversations() {
    const conversations =
      this.myConversations
        .filter(c => !!(c.custom?.data as any)?.initiatorId) // don't understand why we need the cast here, c.custom is already ConversationCustom.
        .filter(c => !!c.lastMessage?.createdAt);

    return sortBy(conversations, c => { return c.lastMessage?.createdAt ?? 0; }).reverse();
  }

  get groupConversations() {
    // TODO: Not technically correct, update when all chatrooms have "type" in
    // custom
    const conversations =  this.myConversations.filter(c => !(c.custom?.data as any)?.initiatorId);

    return sortBy(conversations, c => { return c.lastMessage?.createdAt ?? 0; }).reverse();
  }

  get orderedConversations() {
    return [...this.directConversations, ...this.groupConversations];
  }

  otherParticipant(conversation) {
    const id = this.currentUser?.uuid;
    const data = conversation.custom?.data;

    if (!data || !id)
      return;

    return data?.participants?.find((p) => p.id != id);
  }

  conversationSubject(conversation) {
    if (!conversation.subject)
      return this.otherParticipant(conversation).name;

    return conversation.subject;
  }

  async refreshConversations() {
    if (!this.isLoggedIn)
      return;

    await this.$store.dispatch("chat/refreshMyConversations");
  }

  mounted() {
    this.refreshConversations()
      .then(this.openLinkedChat);

    //this.loadPublicChatrooms();

    setInterval(() =>
      this.refreshConversations(),
      120_000
    );
  }

  async loadPublicChatrooms() {
    this.publicChatrooms = await this.$store.dispatch("chatrooms/search", {});
  }

  getAvatar(chat) {
    // This is a lot, but really should work for all of the options
    return chat?.custom?.data?.avatar ?? // custom data on TalkJS
      chat?.avatarUrl ?? // models with avatarUrl implemented
      chat?.avatar?.url ?? // models with avatar File type
      this.otherParticipant(chat)?.custom?.avatar ?? // custom data on TalkJS
      "";
  }

  getName(chat) {
    try {
      return this.conversationSubject(chat);
    } catch {
      return this.currentUser?.name;
    }
  }

  getUuid(chat) {
    try {
      return this.otherParticipant(chat).custom.uuid;
    } catch {
      return this.currentUser?.uuid;
    }
  }
}
