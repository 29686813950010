



























































import { Component, Page, Prop } from "@components/common";
import Bracket from '@components/matches/bracket.vue';
import Event from '@/graphql/types/event';
import { groupBy, max, min } from "underscore";
import Match from '@/graphql/types/match';

enum BracketType {
  singleElimination = 1,
  doubleElimination = 2
}

@Component({ components: { Bracket }})
export default class Brackets extends Page {
  @Prop(Object) readonly event!: Event;
  @Prop(Array) readonly matches!: Match[];
  @Prop(Array) readonly allMatches!: Match[];

  private showGrid = false;
  private bracketOrder = ["initial", "finals", "winner", "loser", "third_place"];
  private currentTab = this.initialTab

  private bracketName(bracket) {
    if (this.hideWinnerBracketTitle && (bracket === 'winner' || bracket === 'initial')) return "";
    if ((bracket === 'loser' && this.has3rdPlaceMatch) || bracket === 'third_place' ) return this.$t(`matches.brackets.3rd_place_match`);
    return this.$t(`matches.brackets.${bracket}`);
  }

  get dblElmBrackets() {
    const newBrackets = {};
    let stageNo = 0;

    if (this.bracketType === BracketType.doubleElimination) {
      for (const [key, value] of Object.entries(this.brackets)) {
        if(this.brackets[key].winner) {
          newBrackets[stageNo] = { 'winner': this.brackets[key].winner, 'name': 'WB'+(Number(key)+1)};
          stageNo++;
        }
        if (this.brackets[key].loser) {
          newBrackets[stageNo] = { 'loser': this.brackets[key].loser, 'name': 'LB'+(Number(key)+1)};
          stageNo++;
        }
        if (this.brackets[key].finals) {
          newBrackets[stageNo] = { 'finals': this.brackets[key].finals, 'name': 'finals'};
          stageNo++;
        }
      }
      return newBrackets;
    } else {
      return this.brackets;
    }
  }

  get bracketType(): BracketType {
      return this.matches.some(m => m.bracket === 'loser') ? BracketType.doubleElimination : BracketType.singleElimination;
  }

  get has3rdPlaceMatch() {
    return this.matches.some(m => m.bracket === 'third_place');
  }

  // If stage does not have a loser bracket we hide winner bracket title
  get hideWinnerBracketTitle() {
    return this.bracketType === BracketType.singleElimination;
  }

  get isTeamBased() {
    return this.event.mode === 'teams';
  }

  get initialTab() {
    const tabNo = this.gamesPredefined ? min(Object.keys(this.stages)):max(Object.keys(this.stages));
    return "tab-"+tabNo;
  }

  get brackets() {
    return Object.keys(this.stages).reduce((res, key) => (
      {...res, [key]: groupBy(this.stages[key], "bracket")}
    ), {});
  }

  get stages() {
    const fixedMatches = this.matches.map(m => ({
      ...m,
      stage: this.fixMatchStage(m)
    }));
    return groupBy(fixedMatches, "stage");
  }

  get originalNoStages() {
    return groupBy(this.matches,"stage").length;
  }

  get maxLoserStage() {
    return max(this.matches, function(m) { return m.stage;}).stage;
  }

  private fixMatchStage(match) {
    if (this.bracketType === BracketType.doubleElimination)
      return match.bracket === 'finals'? this.maxLoserStage + 1 : match.stage;
    else
      return match.stage;
  }

  get gamesPredefined(): boolean {
    return !this.event.lastEliminationStageNumber;
  }

  get noStages() {
    // Backwards compatibility (lastEliminationStageNumber if stages are not created upfront), Object.keys(this.stages.length-1) if matches are created upfront
    // -1 due to base 0 start
    return !this.gamesPredefined? this.event.lastEliminationStageNumber : Object.keys(this.stages).length - 1;
  }

  private stageName (stage) {
    const noStages = this.noStages? this.noStages : 0;
    const currentStage = stage[0].stage? stage[0].stage : 0;
    const power = noStages - currentStage + 1;

    const stagePower = 2**power;

    if (this.bracketType === BracketType.doubleElimination && stagePower > 2) {
      return stage.name;
    }

    switch (stagePower) {
      case 2:
        return this.$t("match.finale").toString();
      case 4:
        return this.$t("match.semi_finale").toString();
      case 8:
        return this.$t("match.quater_finale").toString();
      default:
        return this.$t("match.round_of", {round: stagePower, roundMatches: stagePower/2}).toString();
    }
  }

  get participants() {
    if (this.event.isUserBased)
      return this.event.participatingUsers;
    else
      return this.event.participatingTeams;
  }

  isFinalStageMatch (match) {
    return match.stage === this.event.lastEliminationStageNumber && !!this.event.standings;
  }

  openSubmitResultDialog(match) {
    this.$emit("openSubmitResultDialog", match);
  }
}
