export function errorPath(errorName: string) {
  return "errors." + errorName
    .split("/")
    .map(part => part.toLowerCase())
    .join(".");
}

export interface ErrorMessage {
  message: string;
}
