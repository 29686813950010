















































import { Component, Vue, Prop } from "@components/common";

@Component({})
export default class DivisionSelector extends Vue {
  @Prop() private readonly list;
  @Prop({ default: () => []}) private readonly groups;

  private currentSelection = this.$attrs.value['name']

  selectItem(val) {
    this.$emit('input', val);
  }

  mounted() {
    this.$on('input', (payload) => {
      this.currentSelection = payload.name;
    });
  }
}
