<template>
  <div
    v-if="or || more"
    class="divider or"
  >
    <div :class="['label', {more}]">
      <slot>
        {{ $t('common.or') }}
      </slot>
    </div>
  </div>
  <div
    v-else
    :class="['divider', {inline, thin, field, list, filter, short, auto}]"
  />
</template>

<script>
export default {
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    thin: {
      type: Boolean,
      default: false
    },
    field: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Boolean,
      default: false
    },
    short: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    or: {
      type: Boolean,
      default: false
    },
    more: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 2px;
  background-color: #e9ecf5;
  margin: 24px 0;

  &.short {
    margin: 18px 0;
  }

  &.inline {
    margin: 18px 0 0;
  }

  &.list {
    margin: 6px 0 0;
  }

  &.thin {
    margin: -10px 0 -10px;
  }

  &.field {
    margin: -6px 0 24px;
  }

  &.filter {
    margin: -24px 0 4px;
  }

  &.auto {
    margin: auto 0 0;
  }

  &.or {
    position: relative;
  }

  .label {
    position: absolute;
    padding: 2px 20px;
    background-color: white;
    left: calc(50% - 30px);
    top: -12px;
  }

  .more {
    left: calc(50% - 55px);
    font-weight: bold;
    color: $inactive-icon;
  }
}
</style>