import gql from "graphql-tag";
import { TeamFields } from "@graphql/types/team";

export default gql`
  mutation CreateTeam($attributes : TeamCreateInput!) {
    createTeam(attributes: $attributes) {
      success
      team {
        ...TeamFields
      }
    }
  }
  ${TeamFields}
`;
