











import { Component, Vue } from "@components/common";

@Component
export default class TableExpander extends Vue {

}
