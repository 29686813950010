






















































import MatchCard from "@components/v2/ActivityCards/MatchCard.vue";

import MyMatchesMatchCard from '@pages/matches/my_matches_match_card.vue';
import MyMatchesRoundCard from '@pages/matches/my_matches_round_card.vue';
import { Page, Component, Layout } from "@components/common";
import { sortBy, union, uniq } from "underscore";
import { namespace } from "@store";
import Match from "@graphql/types/match";
import Round from "@graphql/types/br_round";
import Event from "@graphql/types/event";

const MyMatchesStore = namespace("myMatches");
const MyRoundsStore = namespace("myRounds");
const MyEnrolmentsStore = namespace("myEnrolments");

@Component({
  components: {
    Layout,
    MyMatchesMatchCard,
    MyMatchesRoundCard,
    MatchCard
  }
})
export default class MyMatches extends Page {
  private isUpdating = false;

  @MyMatchesStore.Getter("matches")
  public myMatches!: Match[];

  @MyRoundsStore.Getter("rounds")
  public myRounds!: Round[];

  @MyEnrolmentsStore.Getter("tournaments")
  public myTournaments!: Event[];

  @MyEnrolmentsStore.Getter("events")
  public myEvents!: Event[];

  isRound(match) {
    return match instanceof Round || match.__typename === "Round";
  }

  isMatch(match) {
    return match instanceof Match || match.__typename === "Match";
  }

  get hasRoundsAndMatches() {
    return this.hasRounds || this.hasMatches;
  }

  get roundsAndMatchesEvents() {

    const events = {};
    const eventIds =
    uniq(this.roundsAndMatches.map(m => (
          {
            identifier: this.eventIdentifier(m.eventId, m.eventType)
          }
        )
      ),
    id => id.identifier);

    eventIds.forEach(e => {
        events[e.identifier] = this.getEventByIdentifier(e.identifier);
    });

    return events;
  }

  private matchEvent(identifier) {
    return this.roundsAndMatchesEvents[identifier];
  }

  private eventIdentifier(eventId, eventType) {
    return `${eventType}.${eventId}`;
  }

  private getEventByIdentifier(identifier) {
    const tmp = identifier.split(".");

    const eventType = tmp[0];
    const eventId = tmp[1];

    switch(eventType) {
      case "Event" : return this.myEvents.find(l => l.id == eventId);
      case "Tournament": return this.myTournaments.find(l => l.id == eventId);
      default: return new Event;
    }
  }

  get roundsAndMatches() {
    return union(this.myRounds, this.myMatches);
  }

  get sortedRoundsAndMatches() {
    return sortBy(this.roundsAndMatches, function(o) {return o.startTime;});
  }

  get hasMatches() {
    return this.myMatches?.length ?? 0 > 0;
  }

  get hasRounds() {
    return this.myRounds?.length ?? 0 > 0;
  }

  refreshScheduledMatches() {
    this.$store.dispatch("myMatches/reload");
  }

  refreshActiveRounds() {
    this.$store.dispatch("myRounds/reload");
  }
}
