























































import { Component, Vue, Prop, Divider } from "@components/common";
import { DateTime } from "luxon";
import { Conversation } from "@store/chat";

@Component({components:{ Divider}})
export default class ChatCard extends Vue {
  @Prop() chat!: Conversation;

  @Prop({default: ""}) avatar!: string;
  @Prop({default: ""}) name!: string;
  @Prop({default: ""}) date!: string;
  @Prop({default: false}) isRead!: boolean;

  get isReadOnlyChannel() {
    const chat = this.chat;

    if (!chat)
      return false;

    return chat.custom?.data?.type == "channel";
  }

  get hasDate() {
    const date = this.date;

    return !!date && date.length > 0;
  }

  get formattedDate() {
    return DateTime.fromMillis(Number(this.date)).toLocaleString(DateTime.TIME_24_SIMPLE);
  }
}
