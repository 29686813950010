import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import Match from "@graphql/types/match";
import {
  ListMyActiveMatches,
  ListMyPendingMatches,
} from "@graphql/queries/matches";
import { createApollo } from "@lib/graphql";
import { store } from "@store";
import AuthenticationStore from "@store/authentication";
import { union, groupBy } from "underscore";
import eventBus from "@/event_bus";

const apollo = createApollo();

const INVALIDATION_NOTIFICATION_NAME_PARTS = [
  "match_time",
  "match_start_time",
  "match_reminder_short",
  "invalidate_my_matches",
];

function combineGroupedMatches(matches: Match[]) {
  const toGroupMatches = matches.filter(
    (m) => m.grouped !== false && !m.groupedMatchIds
  );
  const noGroupedMatches = matches.filter((m) => m.grouped === false);

  const groupedMatches = (
    Object.values(groupBy(toGroupMatches, "identifier")) as Match[][]
  ).map((matches) => ({
    ...matches[0],
    groupedMatchIds: matches.map((m) => m.id),
  }));

  return union(noGroupedMatches, groupedMatches);
}

@Module({ namespaced: true })
export default class MyMatches extends VuexModule {
  private loaded = false;
  private myMatches: Match[] = [];
  private myPendingMatches: Match[] = [];

  get matches() {
    return combineGroupedMatches(
      this.myMatches.map((match) => new Match(match))
    );
  }

  get pendingMatches() {
    return combineGroupedMatches(
      this.myPendingMatches.map((match) => new Match(match))
    );
  }

  get allMatches() {
    return union(this.pendingMatches, this.matches);
  }

  get matchesCount() {
    return this.allMatches.length;
  }

  get pendingMatchesCount() {
    return this.pendingMatches.length;
  }

  get activeMatchesCount() {
    return this.matches.length;
  }

  @Action({ rawError: true })
  updateRecord(match) {
    console.log(`Updating myMatches for ${match.id}:`, match);
    const index = this.myMatches.findIndex((m) => m.id === match.id);
    if (index === -1) return;

    this.myMatches[index] = match;
  }

  constructor(stuff) {
    super(stuff);

    eventBus.$on(
      "store:update",
      ({ name, record: match }) =>
        name == "match" && store.dispatch("myMatches/updateRecord", match)
    );

    setInterval(() => {
      store.dispatch("myMatches/reload");
    }, 110_989); // different than myRounds/reload so ti is not happening simuntaniously is prime number

    /*  For time being all websocket based refreshes are suspended, should not query BE !!!
    // ALERT !!! - this causes for all current users to refresh at once ->> CRASH APP

    ---------------------!!!!!!!!!!!!!!--------------------------------

    eventBus.$on("notification:received", notification => {
      const invalidation = INVALIDATION_NOTIFICATION_NAME_PARTS.some(
        part => notification.notificationType.indexOf(part) != -1
      );

      console.log("Invalidating: ", invalidation);
      if (invalidation) {
        store.commit("myMatches/clear");
        store.dispatch("myMatches/reload");
      }
    });*/
  }

  @Action({ rawError: true })
  async reload() {
    if (!AuthenticationStore.getters?.isLoggedIn) return;

    this.context.dispatch("reloadMyActiveMatches");
    this.context.dispatch("reloadMyPendingMatches");
  }

  @Action({ rawError: true })
  async reloadMyActiveMatches() {
    store.commit("progress/startLoading");
    const {
      data: { myMatches: result },
    } = await apollo.query({
      query: ListMyActiveMatches,
      fetchPolicy: "no-cache",
    });

    if (result) store.commit("progress/finishLoading");

    this.context.commit("setMyActiveMatches", result);
  }

  @Action({ rawError: true })
  async reloadMyPendingMatches() {
    store.commit("progress/startLoading");
    const {
      data: { myMatches: result },
    } = await apollo.query({
      query: ListMyPendingMatches,
      fetchPolicy: "no-cache",
    });

    if (result) store.commit("progress/finishLoading");

    this.context.commit("setMyPendingMatches", result);
  }

  @Mutation
  setMyActiveMatches(myMatches) {
    this.myMatches = myMatches;
    this.loaded = true;
  }

  @Mutation
  setMyPendingMatches(myMatches) {
    this.myPendingMatches = myMatches;
    this.loaded = true;
  }

  @Mutation
  clear() {
    this.myMatches = [];
    this.myPendingMatches = [];
    this.loaded = false;
  }
}
