































import { Component, Prop, Vue } from "@components/common";
@Component
export default class ConfirmationPopup extends Vue {
  @Prop() event!: Event;
  @Prop(String) private readonly userListed;
  @Prop(String) private readonly checkinTip;
  @Prop(Boolean) private show;

  private selectedTeamId = null

  get showDialog() {
    return this.show;
  }

  close(...args) {
    this.$emit("close-confirmation", ...args);
  }
}
