

































import { Prop, Component, Vue } from "@components/common";

import MetaLine from "./MetaLine.vue";
import { DateTime } from "luxon";

import Event from "@graphql/types/event";
import Match from "@graphql/types/match";

@Component({
  components: { MetaLine }
})
export default class MatchCardBasics extends Vue {
  @Prop() match!: Match;
  @Prop() event!: Event;

  get startTime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_MED);
  }

  get isStartTimeConfirmed() {
    return this.match.isStartTimeConfirmed;
  }

  get formattedLatestStartDatetime() {
    const latestStartTime = DateTime.fromISO(this.match.originalEndTime).minus({seconds: this.event.matchDuration});

    return DateTime.fromISO(latestStartTime).toLocaleString(DateTime.DATETIME_MED);
  }

  get isPlayPeriodSameYear() {
    return DateTime.fromISO(this.playPeriod.startTime).year === DateTime.fromISO(this.playPeriod.endTime).year;
  }

  get playPeriodStart() {
    if (this.isPlayPeriodSameYear) {
      let parts = DateTime.fromISO(this.playPeriod.startTime).toLocaleParts(DateTime.DATE_SHORT);
      const index = parts.findIndex(part => part.type == "year");

      return parts.slice(0, index - 1).map(p => p.value).join("");
    } else {
      return DateTime.fromISO(this.playPeriod.endTime).toLocaleString(DateTime.DATE_SHORT);
    }
  }

  get playPeriodEnd() {
    return DateTime.fromISO(this.playPeriod.endTime).toLocaleString(DateTime.DATE_SHORT);
  }

  get eventFlowStage() {
    return this.event?.eventFlowStages?.find(s => s.id == this.match.eventFlowStageId);
  }

  get hasConfiguredPlayPeriod() {
    const match = this.match;

    // This actually can't happen.
    if (!match?.index)
      return false;

    return !!this.eventFlowStage?.configuration?.["playPeriods"]?.[match.index];
  }

  get playPeriod() {
    const match = this.match;
    const config = match.index ? this.eventFlowStage?.configuration?.["playPeriods"]?.[match.index] : {};

    return {
      startTime: config?.startTime ?? match.originalStartTime,
      endTime: config?.endTime ?? match.originalEndTime
    };
  }

  get scheduleLineTime() {
    return this.isStartTimeConfirmed ? this.startTime : this.formattedLatestStartDatetime;
  }

}
