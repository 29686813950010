












































































import { Component, Vue, Prop } from "@components/common";

import Event from "@graphql/types/event";
import Round from "@graphql/types/br_round";

import RoundCard from "@components/events/round_card.vue";
import ActivityCard from "@components/v2/ActivityCards/ActivityCard.vue";
import Heading from "@components/v2/Heading.vue";
import RoundOverviewTable from "@components/events/round_overview_table.vue";
import BRStandings from "@components/events/battle_royale/br_standings.vue";

import { DateTime } from "luxon";
import { sortBy } from "underscore";

@Component({
  components: {
    ActivityCard,
    BRStandings,
    Heading,
    RoundCard,
    RoundOverviewTable,
  }
})
export default class EventRoundsTab extends Vue {
  @Prop() readonly event!: Event;

  get latestFinishedRound(): Round | null {
    const now = DateTime.local();

    return this.rounds.reverse().find(pd => DateTime.fromISO(pd.endTime) < now);
  }

  get latestFinishedRoundPlayDay() {
    const latest = this.latestFinishedRound;

    if (!latest)
      return null;

    return this.playDays.find(pd => pd.id == latest.playDayId);
  }

  get isTeamBased() {
    return this.event.mode === "1v1" ? false : true;
  }

  get playDays () {
    return sortBy(this.event?.playDays ?? [], pd => DateTime.fromISO(pd.startTime));
  }

  get playDaysWithGroupedRounds() {
    return this.playDays.map(pd => ({ ...pd, ...this.sortedGroupedRounds(pd.rounds, true) }));
  }

  sortedGroupedRounds(rounds, desc) {
    const now = DateTime.local();
    let sorted = this.sortedRounds(rounds, desc);

    return {
      pastRounds: sorted.filter(r => DateTime.fromISO(r.endTime) < now),
      futureRounds: sorted.filter(r => DateTime.fromISO(r.startTime) > now)
    };
  }

  sortedRounds(rounds, desc) {
    const sortedRounds = sortBy(rounds, function(o) { return o.startTime; });

    return desc ? sortedRounds : sortedRounds.reverse();
  }

  get rounds() {
    return this.playDays.map(o => sortBy(o.rounds, "startTime")).flat();
  }

  get isParticipant() {
    if (this.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.event.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isParticipantTeamMember() {
    if (this.isTeamBased) {
      return this.event.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }

  get myParticipatingTeamId() {
    if (!this.myParticipatingTeam) return null;
    return this.myParticipatingTeam.id;
  }

  get myParticipatingTeam() {
    if (!this.event.participatingTeams) return { captain: null, id: null };
    return this.event.participatingTeams?.find(a => a.members?.find(member => member.id === this.currentUserId));
  }

  get myParticipationId(){
    if (!this.isParticipant) return null;

    const participations = this.event.participations;
    const myId = this.isTeamBased ? this.myParticipatingTeamId : this.currentUser?.id;

    return participations?.find(p => p.id === myId)?.participationId;
  }

  get participations() {
    return this.event?.participations ?? [];
  }

  // dunnolol?
  DateTime = DateTime;
}
