



































































































































































































































import { Page, Prop, Component, Layout } from "@components/common";

import { DateTime } from "luxon";

import Chatroom from "@graphql/types/chatroom";
import Game from "@graphql/types/game";
import User from "@graphql/types/user";
import { HallOfFameEntry } from "@store/hall_of_fame_entries";

import BestPlayers from "@components/v2/BestPlayers.vue";
import CommunityCard from "@/components/v2/CommunityCard.vue";
import EventCard from "@components/v2/EventCard.vue";
import HScroller from "@components/v2/HScroller.vue";
import Heading from "@components/v2/Heading.vue";
import PlayerCard from "@components/v2/PlayerCard.vue";

// News
import axios from "axios";
import { newsURL } from "@/pages/feed/all.vue";
import NewsCard from "@components/v2/NewsCard.vue";

@Component({
  components: {
    BestPlayers,
    CommunityCard,
    EventCard,
    HScroller,
    Heading,
    Layout,
    NewsCard,
    PlayerCard,
  }
})
export default class BrowseGamePage extends Page {
  @Prop() id!: number;

  private hallOfFame: HallOfFameEntry[] = [];
  private users: User[] = [];

  private game: Game | null = null;
  private chatrooms: Chatroom[] = [];
  private activeEvents:   Event[] = [];
  private pastEvents:     Event[] = [];
  private upcomingEvents: Event[] = [];

  private gameNews: any[] = [];

  private loading = true;

  private postModal = false;
  private activePost: any = null;

  loadPost(post) {
    this.activePost = post;
    this.postModal = true;
  }

  get bestPlayers() {
    return this.hallOfFame
      .filter(entry => entry.entryType == "most_wins")
      .sort((a, b) => b.value - a.value)
      .map(entry => ({...entry, user: (this.users.find(u => u.id == entry.userId)) }));
  }

  removeEmptyParagraphs(html) {
    return html.replace('<p>&nbsp;</p>','');
  }

  mounted() {
    this.loadData();
  }

  loadData() {
    this.loading = true;

    Promise.all([
      this.loadGame().then(this.loadChatrooms).then(this.loadGameNews),
      this.loadHallOfFame().then(this.loadUsers),
      this.loadUpcomingEvents(),
      this.loadPastEvents(),
      this.loadActiveEvents(),
    ]).then(() => this.loading = false);
  }

  get descriptionLanguage() {
    return this.$i18n.locale == "de" ? "de": "en";
  }

  async loadGameNews() {
    const game = this.game;

    if (!game || !game.wordpressNewsTag) {
      this.gameNews = [];
      return;
    }

    const response = await axios.get(newsURL, {params: {
      per_page: 20,
      categories: game.wordpressNewsTag,
      page: 1,
      _embed: true,
    }});

    this.gameNews = response.data;
  }

  async loadChatrooms() {
    const ids = this.game?.publicChatroomIds;
    if (!ids || ids.length == 0)
      return;

    this.chatrooms = await this.$store.dispatch("chatrooms/search", {filters: { ids }});
  }

  async loadGame() {
    this.game = await this.$store.dispatch("games/find", {
      id: this.id,
      extraSelection: ["coverImage { url }"]
    });
  }

  async loadUpcomingEvents(): Promise<void> {
    this.upcomingEvents = await this.$store.dispatch("events/search", {
      filters: {state: "notstarted", gameIds: [this.id]},
      limit: 10
    });
  }

  async loadPastEvents(): Promise<void> {
    this.pastEvents = await this.$store.dispatch("events/search", {
      filters: {state: "finished", gameIds: [this.id]},
      limit: 10
    });
  }

  async loadActiveEvents(): Promise<void> {
    this.activeEvents = await this.$store.dispatch("events/search", {
      filters: {state: "running", gameIds: [this.id]},
      limit: 10,
    });
  }

  async loadUsers() {
    this.users = await this.$store.dispatch("users/search", {
      filters: {ids: this.hallOfFameUserIds},
      extraSelection: "avatar { url }"
    });
  }

  get hallOfFameUserIds() {
    return this.hallOfFame.map(entry => entry.userId).filter(Boolean);
  }

  async loadHallOfFame(): Promise<void> {
    this.hallOfFame = await this.$store.dispatch("hallOfFameEntries/forGame", {
      gameId: this.id,
      type: "user",
      day: DateTime.local().toISODate(),
      limit: 10
    });
  }

  get coverImage() {
    return this.game?.coverImage?.url ?? '';
  }
}
