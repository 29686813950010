import gql from "graphql-tag";
import Base from "@graphql/types/base";
import { DateTime } from "luxon";

import BRRound, { BRRoundFields } from "@graphql/types/br_round";

import { selectable as s } from "@store/generic_store";

export const PlayDayBasicFields = gql`
  fragment PlayDayBasicFields on PlayDay {
    id
    dayNumber
    startTime
    endTime
  }
  ${BRRoundFields}
`;

export const PlayDayFields = gql`
  fragment PlayDayFields on PlayDay {
    id
    dayNumber
    startTime
    endTime

    rounds {
      ...BRRoundFields
    }
  }
  ${BRRoundFields}
`;
export default class PlayDay extends Base<PlayDay> {
  @s() id?: number;
  @s() dayNumber?: number;
  @s() startTime?: DateTime;
  @s() endTime?: DateTime;
  rounds?: BRRound[];
}
