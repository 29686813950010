import gql from "graphql-tag";
import { TeamFields } from "@graphql/types/team";

export default gql`
  mutation UpdateTeam($id : ID!, $attributes : TeamUpdateInput!) {
    updateTeam(id: $id, attributes: $attributes) {
      success
      team {
        ...TeamFields
      }
    }
  }
  ${TeamFields}
`;
