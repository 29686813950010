




































import { Component, Vue, Prop } from "@components/common";
import Game from '@/graphql/types/game';

@Component
export default class GamesCard extends Vue {
  @Prop() readonly game!: Game;
  @Prop() readonly playerCount!: number;
  @Prop() private readonly to!: any;

  get avatar() {
    return this.game?.avatar?.url ?? '';
  }
}
