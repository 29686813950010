import gql from "graphql-tag";

export default class EventInformation {
  showPlatformIcons: boolean;

  showCustomText: boolean;
  customText: boolean;

  showQualificationTimes: boolean;
  showPlayoffsTimes: boolean;

  showEventAdmins: boolean;
  showEventAdminsDiscord: boolean;
  showEventAdminsInappChat: boolean;
  shownEventAdminIds: number[];

  showPrizes: boolean;
  prizeNames: string[];
  prizeSponsorLogoUrl: string;

  showEventChart: boolean;
  eventChartUrl: string;

  showDiscordChannel: boolean;
  discordChannelUrl: string;
  discordChannelName: string;

  showChatrooms: boolean;
  shownChatroomIds: number[];

  showCustomSocialLinks: boolean;
  customSocialLinks: {
    name: string;
    tag: string;
    avatarUrl: string;
    url: string;
  };

  showDisclaimer: boolean;
  disclaimerTemplateName: string;
  disclaimer: string;

  showRules: boolean;
  rulesTemplateName: string;
  rules: string;

  constructor({
    showPlatformIcons,
    showCustomText,
    customText,
    showQualificationTimes,
    showPlayoffsTimes,
    showEventAdmins,
    showEventAdminsDiscord,
    showEventAdminsInappChat,
    shownEventAdminIds,
    showPrizes,
    prizeNames,
    prizeSponsorLogoUrl,
    showEventChart,
    eventChartUrl,
    showDiscordChannel,
    discordChannelUrl,
    discordChannelName,
    showChatrooms,
    shownChatroomIds,
    showCustomSocialLinks,
    customSocialLinks,
    showDisclaimer,
    disclaimerTemplateName,
    disclaimer,
    showRules,
    rulesTemplateName,
    rules,
  }: EventInformation) {
    this.showPlatformIcons = showPlatformIcons;
    this.showCustomText = showCustomText;
    this.customText = customText;
    this.showQualificationTimes = showQualificationTimes;
    this.showPlayoffsTimes = showPlayoffsTimes;
    this.showEventAdmins = showEventAdmins;
    this.showEventAdminsDiscord = showEventAdminsDiscord;
    this.showEventAdminsInappChat = showEventAdminsInappChat;
    this.shownEventAdminIds = shownEventAdminIds;
    this.showPrizes = showPrizes;
    this.prizeNames = prizeNames;
    this.prizeSponsorLogoUrl = prizeSponsorLogoUrl;
    this.showEventChart = showEventChart;
    this.eventChartUrl = eventChartUrl;
    this.showDiscordChannel = showDiscordChannel;
    this.discordChannelUrl = discordChannelUrl;
    this.discordChannelName = discordChannelName;
    this.showChatrooms = showChatrooms;
    this.shownChatroomIds = shownChatroomIds;
    this.showCustomSocialLinks = showCustomSocialLinks;
    this.customSocialLinks = customSocialLinks;
    this.showDisclaimer = showDisclaimer;
    this.disclaimerTemplateName = disclaimerTemplateName;
    this.disclaimer = disclaimer;
    this.showRules = showRules;
    this.rulesTemplateName = rulesTemplateName;
    this.rules = rules;
  }
}

export const EventInformationFields = gql`
  fragment EventInformationFields on EventInformation {
    showPlatformIcons
    showCustomText
    customText
    showQualificationTimes
    showPlayoffsTimes
    showEventAdmins
    showEventAdminsDiscord
    showEventAdminsInappChat
    shownEventAdminIds
    showPrizes
    prizeNames
    prizeSponsorLogoUrl
    showEventChart
    eventChartUrl
    showDiscordChannel
    discordChannelUrl
    discordChannelName
    showChatrooms
    shownChatroomIds
    showCustomSocialLinks
    customSocialLinks {
      name
      tag
      url
      avatarUrl
    }
    showDisclaimer
    disclaimerTemplateName
    disclaimer
    showRules
    rulesTemplateName
    rules
  }
`;
