
































































import { Component, Vue, Prop } from "@components/common";

@Component
export default class CommunityCard extends Vue {
  @Prop() readonly chat?;
  @Prop({type: Boolean, default: false}) readonly external!: boolean;
  @Prop({default: "$icn-inbox"}) readonly icon!: string;
  @Prop({default: ""}) readonly url!: string;
  @Prop({default: ""}) readonly title!: string;

  get avatarUrl() {
    return this.chat?.avatar?.url ?? "";
  }

  async openChat() {
    if (this.external) {
      window.open(this.url, "_blank");
    } else {
      this.$eventBus.$emit("chat:open:readonly", this.chat);
    }
  }
}
