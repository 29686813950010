

















import { Component, Vue, Prop } from "@components/common";
import { DateTime } from "luxon";

@Component
export default class DateTimeInfo extends Vue {
  @Prop() private datetime: DateTime | string;

  get _datetime() {
    const dt = this.datetime;

    if (typeof dt === "string")
      return DateTime.fromISO(dt);
    else
      return dt;
  }

  get date() {
    return this._datetime.toLocaleString(DateTime.DATE_SHORT);
  }

  get time() {
    return this._datetime.toLocaleString(DateTime.TIME_24_SIMPLE);
  }
}
