

















































































































import { Page, Component, Watch } from "@components/common";
import { DateTime } from "luxon";
import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;

const alterPostsURL = [
  apiUrl +"/dailygame_news_feeds/videogames",
  apiUrl +"/dailygame_news_feeds/hardware",
  apiUrl +"/dailygame_news_feeds/streaming",
  apiUrl +"/dailygame_news_feeds/mobile"
];

@Component
export default class News extends Page {

  private tabs = [
    "Video Games",
    "Hardware",
    "Streaming",
    "Mobile Games"
  ]

  private newsModal = false;
  private initFetch = true;
  private loading = true;

  private remotePosts: Array<Array<any>> = []
  private tab = null

  private activePost = {}

  @Watch("tab")
  getResources(tab) {
    this.loading = true;
    axios.get(alterPostsURL[tab])
      .then(response => {
        if (response.data.items.length > 0 ) {
          this.remotePosts[tab] = response.data.items;
          this.loading = false;
          this.$store.commit("progress/finishLoading");
        }
      });
  }

  created():void {
    this.$store.commit("progress/startLoading");
  }

  getFormatedDate(date) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
  }

  loadNews(post) {
    const mappedPost = post.content_html ? {
        image:  post.image,
        title:  post.title,
        content: post.content_html,
      } : {
        image: post._embedded['wp:featuredmedia'][0].source_url,
        title:  post.title.rendered,
        content: post.content.rendered,
      };
    this.activePost = mappedPost;
    this.newsModal = true;
  }

  sanitize(html) {
    // Remove Wordpress shortcodes from HTML
    // const clean = html.replace(/\[\/?et_pb.*?\]/g, '');
    // Remove empty paragraphs
    const empties = html.replace('<p>&nbsp;</p>','');
    return empties;
  }
}
