












import { Component, Vue, Prop } from "@components/common";

@Component({})
export default class PreferedGameTime extends Vue {
  @Prop() readonly gameTime;

  get preferedTimeTranslation() {
    return this.$t(`preferred_game_times.${this.gameTime}`);
  }
}
