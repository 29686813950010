











































































import { Component, Vue, Prop } from "@components/common";
import Tournament from "@graphql/types/tournament";
import RoundCard from "@components/tournaments/round_card.vue";
import { sortBy, difference } from "underscore";
import { DateTime } from "luxon";

import { namespace } from "@/store";
const AuthenticationState = namespace("authentication");


@Component({
  components: { RoundCard }
})
export default class TournamentRounds extends Vue {
  @Prop() readonly event!: Tournament;

  private tab = null

  @AuthenticationState.State
  private teamRoles;

  get isTeamBased() {
    return this.event.mode === "1v1" ? false : true;
  }

  get teamIds () {
    return (this.teamRoles ?? []).map(id => id.teamId);
  }

  get playDays () {
    return this.event.playDays;
  }

  private sortedRounds(rounds, desc) {
    const sortedRounds = sortBy(rounds, function(o) { return o.startTime;});

    return desc? sortedRounds : sortedRounds.reverse();
  }

  get participants() {
    if (this.event.isUserBased)
      return this.event.participatingUsers;
    else
      return this.event.participatingTeams;
  }

  get rounds() {
    return this.event.playDays?.map(o => o.rounds).flat();
  }

  get hasPendingRounds() {
    return this.pendingRounds.length > 0 ? true : false;
  }

  get pendingRounds() {
    return this.rounds?.filter(m=>
      (DateTime.fromISO(m?.startTime) > DateTime.local()) && !m?.results?.some(r => r.participationId === this.myParticipationId)) || [];
  }

  get isParticipant() {
    if (this.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.event.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isParticipantTeamMember() {
    if (this.isTeamBased) {
      return this.event.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }

  get myParticipatingTeamId() {
    if (!this.myParticipatingTeam) return null;
    return this.myParticipatingTeam.id;
  }

  get myParticipatingTeam() {
    if (!this.event.participatingTeams) return { captain: null, id: null };
    return this.event.participatingTeams?.find(a => a.members?.find(member => member.id === this.currentUserId));
  }

  get myParticipationId(){
    if (!this.isParticipant) return null;

    const participations = this.event.participations;
    const myId = this.isTeamBased ? this.myParticipatingTeamId : this.currentUser?.id;

    return participations?.find(p => p.id === myId)?.participationId;
  }

  get pastRounds() {
    return difference(this.rounds, this.pendingRounds);
  }

  get pendingPlayDays() {
    return this.playDays?.filter(m=>
      (DateTime.fromISO(m?.endTime) > DateTime.local())) || [];
  }

  get sortedPendingPlayDays() {
    const formattedPlayDays = this.pendingPlayDays.map((m) => ({
      ...m,
      playDayStartDate: DateTime.fromISO(m.startTime).toLocaleString(DateTime.DATE_MED),
      playDayStartTime: DateTime.fromISO(m.startTime).toLocaleString(DateTime.TIME_SIMPLE),
      playDayEndDate: DateTime.fromISO(m.endTime).toLocaleString(DateTime.DATE_MED),
      playDayEndTime: DateTime.fromISO(m.endTime).toLocaleString(DateTime.TIME_SIMPLE),
    }));

    return sortBy(formattedPlayDays, function(o) { return o.startTime; });
  }

  get pastPlayDays() {
    return difference(this.playDays, this.pendingPlayDays);
  }

  get sortedPastPlayDays() {
        const formattedPlayDays = this.pastPlayDays.map((m) => ({
      ...m,
      playDayStartDate: DateTime.fromISO(m.startTime).toLocaleString(DateTime.DATE_MED),
      playDayStartTime: DateTime.fromISO(m.startTime).toLocaleString(DateTime.TIME_SIMPLE),
      playDayEndDate: DateTime.fromISO(m.endTime).toLocaleString(DateTime.DATE_MED),
      playDayEndTime: DateTime.fromISO(m.endTime).toLocaleString(DateTime.TIME_SIMPLE),
    }));

    return sortBy(formattedPlayDays, function(o) { return o.startTime; });
  }
}
