import gql from "graphql-tag";
import Base from "@graphql/types/base";
import Participation, { ParticipationFields } from "@graphql/types/participation";
import Match, { MatchBaseFields } from "@graphql/types/match";

export const BasePlayoffsGroupFields = gql`
  fragment BasePlayoffsGroupFields on PlayoffsGroup {
    id
    startTime
    endTime
    tag
    position
  }
`;

export const PlayoffsGroupWithParticipationsFields = gql`
  fragment PlayoffsGroupWithParticipationsFields on PlayoffsGroup {
    ...BasePlayoffsGroupFields
    participations {
      ...ParticipationFields
    }
  }
  ${BasePlayoffsGroupFields}
  ${ParticipationFields}
`;

export const PlayoffsGroupWithParticipationsAndMatchesFields = gql`
  fragment PlayoffsGroupWithParticipationsFields on PlayoffsGroup {
    ...BasePlayoffsGroupFields
    participations {
      ...ParticipationFields
    }
    matches {
      ...MatchBaseFields
    }
  }
  ${BasePlayoffsGroupFields}
  ${ParticipationFields}
  ${MatchBaseFields}
`;

export default class PlayoffsGroup extends Base<PlayoffsGroup> {
  startTime?: Date;
  endTime?: Date;
  tag?: string; // this is unique per event
  participations?: Participation[];
  matches?: Match[];
  position?: number;
}
