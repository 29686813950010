import gql from "graphql-tag";
import Base from "@graphql/types/base";

export const AVAILABLE_STREAMING_ACCOUNTS = [
  "twitch",
  "youtube",
  "facebook_live",
];

export const StreamingAccountFields = gql`
  fragment StreamingAccountFields on StreamingAccount {
    tag
    value
  }
`;

export default class StreamingAccount extends Base<StreamingAccount> {
  tag?: string;
  value?: string;
}
