import gql from "graphql-tag";
import Base from "@graphql/types/base";

import { selectable as s } from "@store/generic_store";

export const RoundCheckInFields = gql`
  fragment RoundCheckinFields on RoundCheckin {
    userId
    participationId
  }
`;

export default class RoundCheckin extends Base<RoundCheckin> {
  @s() userId?: number;
  @s() participationId?: number;
}
