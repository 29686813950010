














































































































import { Component, Vue, Prop, FormLabel } from "@components/common";

import { DateTime, Interval } from "luxon";
import { union } from "underscore";

import MatchHelpers, { MatchStage } from "@lib/helpers/match_helpers";
import { notEmpty } from "@lib/validations";

import Event from "@graphql/types/event";
import Match from "@/graphql/types/match";
import Team from "@/graphql/types/team";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  MatchCardBasics,
  MatchKey,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";

@Component({
  components: {
    ActionBtn,
    CardBody,
    CardHeader,
    DateTime,
    DateTimeInfo,
    Expander,
    FormLabel,
    MatchCardBasics,
    MatchKey,
    Meta,
    MetaLine,
    UserAvatar,
  }
})
export default class CheckinStage extends Vue {
  @Prop() private match!: Match;
  @Prop() private event!: Event;
  @Prop() private myTeams!: Team[];
  @Prop() private show!: boolean;

  private timer: ReturnType<typeof setInterval> | null = null;
  private ticker = 0;

  private loading = false;

  get skipCheckin() {
    return this.match.skipCheckin;
  }

  mounted() {
    this.timer = setInterval(this.tick, 1000);
  }

  unmounted() {
    const timer = this.timer;
    if (timer)
      clearInterval(timer);
  }

  tick() {
    this.ticker += 1;
  }

  checkIn() {
    this.loading = true;

    const match = this.match;

    let mutation = "matches/checkIn";
    let variables = {};

    if (this.matchHelpers.isTeamBased)
      variables = { ...variables, asTeam: true, teamId: this.matchHelpers.myParticipantId };

    if (match.grouped) {
      mutation = "matches/checkIns";
      variables = { ...variables, matchIds: match.groupedMatchIds };
    } else {
      variables = { ...variables, matchId: match.id };
    }

    this.$store.dispatch(mutation, variables)
      .finally(() => this.loading = false);
  }

  get matchHelpers() {
    return new MatchHelpers(this.match, [], {
      me: this.currentUser,
      matchStage: MatchStage.Checkin,
      event: this.event,
      myTeams: this.myTeams
    });
  }

  get formattedLatestStartDatetime() {
    return DateTime.fromISO(this.match.startTime).toLocaleString(DateTime.DATETIME_MED);
  }

  get isCheckedIn() {
    const myParticipation = this.matchHelpers.myParticipation;

    return this.checkins.some(c =>
      c.matchId == this.match.id && c.participationId == myParticipation?.participationId
    );
  }

  get checkins() {
    const eventCheckins = this.event?.matchCheckins ?? [];
    const matchCheckins = this.match?.checkins ?? [];

    return union(eventCheckins, matchCheckins);
  }

  get remainingTime() {
    this.ticker;

    return Interval.fromDateTimes(
      DateTime.local(),
      this.matchHelpers.checkinEndTime,
    ).length("seconds");
  }

  get priority() {
    if (this.isCheckedIn)
      return 1;

    const seconds = this.remainingTime;

    if (seconds < 300)
      return 3;

    return 2;
  }

  get expanded() {
    return this.show || this.priority > 2;
  }

  get checkinOpen() {
    return true;
  }

  private notEmpty = notEmpty;
}
