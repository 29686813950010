import gql from "graphql-tag";
import { DateTime } from "luxon";

import Base from "@graphql/types/base";
import Division from "@graphql/types/division";
import EventFlow from "@graphql/types/event_flow";
import EventFlowStage from "@graphql/types/event_flow_stage";
import EventInformation from "@graphql/types/event_information";
import File, { FileFields } from "@graphql/types/file";
import Game, { GameFields } from "@graphql/types/game";
import Group from "@graphql/types/group";
import Match from "@graphql/types/match";
import MatchCheckin from "@graphql/types/checkin";
import Participation from "@graphql/types/participation";
import Platform from "@graphql/types/platform";
import PlayDay from "@graphql/types/play_day";
import PlayoffsGroup from "@graphql/types/playoffs_group";
import StageOverride from "./stage_overrides";
import Team from "@graphql/types/team";
import User from "@graphql/types/user";

import { BRRoundDetailedFields } from "@graphql/types/br_round";
import { DivisionWithParticipationsFields } from "@graphql/types/division";
import { EventFlowWithStagesFields } from "@graphql/types/event_flow";
import { EventInformationFields } from "@graphql/types/event_information";
import { GroupWithParticipationsFields } from "@graphql/types/group";
import { ParticipationFields } from "@graphql/types/participation";
import { PlayDayBasicFields } from "@graphql/types/play_day";
import { PlayoffsGroupWithParticipationsFields } from "@graphql/types/playoffs_group";
import { RoundResultFields } from "@graphql/types/br_round_result";
import { TeamMetaFields } from "@graphql/types/team";
import { UserEventFields } from "@graphql/types/user";

export const EventBaseFields = gql`
  fragment EventBaseFields on Event {
    id
    category
    checkin
    conversationId
    descriptions
    enableChat
    endTime
    featured
    gameId
    matchCount
    roundCount
    maxPlayers
    minimumAge
    mode
    name
    platformIds
    rules
    seasonId
    startTime
    status
    tag
    chatroomId

    information {
      ...EventInformationFields
    }
  }
  ${EventInformationFields}
`;

export const EventFields = gql`
  fragment EventFields on Event {
    ...EventBaseFields
    avatar {
      ...FileFields
    }
    coverImage {
      ...FileFields
    }
  }
  ${FileFields}
  ${EventBaseFields}
`;

export const ListEventFields = gql`
  fragment ListEventFields on Event {
    ...EventBaseFields
    avatar {
      url
    }
    participations {
      id
    }
  }
  ${EventBaseFields}
`;

export const EventParticipationsFields = gql`
  fragment EventParticipationsFields on Event {
    participations {
      ...ParticipationFields
    }
    participatingUsers {
      ...UserEventFields
    }
    participatingTeams {
      ...TeamMetaFields
    }
  }
  ${ParticipationFields}
  ${UserEventFields}
  ${TeamMetaFields}
`;

export const MyEventsFields = gql`
  fragment MyEventsFields on Event {
    ...EventBaseFields
    ...EventParticipationsFields
    avatar {
      ...FileFields
    }
    coverImage {
      ...FileFields
    }
    participations {
      ...ParticipationFields
    }
  }
  ${FileFields}
  ${EventBaseFields}
  ${EventParticipationsFields}
`;

export const DetailedEventFields = gql`
  fragment DetailedEventFields on Event {
    ...EventBaseFields
    ...EventParticipationsFields

    avatar {
      url
    }
    coverImage {
      url
    }

    divisions {
      ...DivisionWithParticipationsFields
    }

    groups {
      ...GroupWithParticipationsFields
    }

    game {
      ...GameFields
    }

    playDays {
      ...PlayDayBasicFields

      rounds {
        ...BRRoundDetailedFields

        results {
          ...RoundResultFields
        }
      }
    }

    eventFlow {
      ...EventFlowWithStagesFields
    }

    playoffsGroups {
      ...PlayoffsGroupWithParticipationsFields
    }

    standings {
      position
      participantId
      playoffsGroupId
    }

    administratorUserIds
  }

  ${BRRoundDetailedFields}
  ${DivisionWithParticipationsFields}
  ${EventBaseFields}
  ${EventFlowWithStagesFields}
  ${EventParticipationsFields}
  ${GameFields}
  ${GroupWithParticipationsFields}
  ${PlayDayBasicFields}
  ${PlayoffsGroupWithParticipationsFields}
  ${RoundResultFields}
`;

import { selectable as s } from "@store/generic_store";

export default class Event extends Base<Event> {
  @s() category?: string;
  @s() checkin?: number;
  @s() conversationId?: string;
  @s() createdAt?: string;
  @s() declare id?: number;
  @s() descriptions?: Record<string, string>;
  @s() enableChat?: boolean;
  @s() endTime?: DateTime;
  @s() gameId?: number;
  @s() matchCount?: number;
  @s() maxPlayers?: number;
  @s() minimumAge?: number;
  @s() mode?: string;
  @s() name?: string;
  @s() platformIds?: number[];
  @s() roundCount?: number;
  @s() rules?: Record<string, string>;
  @s() startTime?: DateTime;
  @s() tag?: string;
  @s() updatedAt?: string;
  @s() participationsCount?: number;
  administratorUserIds?: number[];
  @s("avatar { url }") avatar?: File;
  matchCheckins?: MatchCheckin[];
  @s("coverImage { url } ") coverImage?: File;
  divisions?: Division[];
  eventFlow?: EventFlow;
  eventFlowStages?: EventFlowStage[];
  flowType?: string;
  game?: Game;
  groups?: Group[];
  lastEliminationStageNumber?: number;
  @s() matchDuration?: number;
  matches?: Match[];
  participatingTeams?: Team[];
  participatingUsers?: User[];
  participations?: Participation[];
  phase?: string;
  platforms?: Platform[];
  playDays?: PlayDay[];
  playoffStartTime?: DateTime; // Only in tournaments
  playoffsGroups?: PlayoffsGroup[];
  qualificationEndTime?: DateTime; // Only in tournaments and leagues
  roundType?: string;
  signUpEndTime?: DateTime;
  stage?: number;
  stages?: StageOverride[];
  standings?: any;
  status?: string;
  @s() chatroomId?: number;

  information?: EventInformation;

  __typename?: string;

  constructor({
    startTime = "",
    endTime = "",
    participatingTeams = [] as Team[],
    participatingUsers = [] as User[],
    information = new EventInformation({} as EventInformation), // Hacky, but fine
    ...rest
  } = {}) {
    super(rest);

    this.startTime = DateTime.fromISO(startTime);
    this.endTime = DateTime.fromISO(endTime);
    this.participatingUsers = participatingUsers.map((user) => new User(user));
    this.participatingTeams = participatingTeams.map((team) => new Team(team));

    if (information) this.information = new EventInformation(information);
  }

  get coverImageUrl(): string {
    return this?.coverImage?.url ?? "";
  }

  get avatarUrl(): string {
    return this?.avatar?.url ?? "";
  }

  get isUserBased() {
    return this.mode == "1v1";
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get isFull() {
    return this.participationCount >= (this.maxPlayers ?? 0);
  }

  get participationCount() {
    return Math.max(
      this.participatingTeams?.length ?? 0,
      this.participatingUsers?.length ?? 0
    );
  }

  static isLegacyEvent(event) {
    if (typeof event.category === "undefined") return true;

    return event.__typename !== "Event";
  }

  static currentEventFlowStage(event) {
    return event.eventFlow?.eventFlowStages?.find(
      (efs) =>
        Number(efs.id) === Number(event.eventFlow?.currentEventFlowStageId)
    );
  }

  static isLeague({
    category,
    __typename,
  }: {
    category: string;
    __typename: string;
  }) {
    return category == "LEAGUE" || __typename == "League";
  }

  static isTournament({
    category,
    __typename,
  }: {
    category: string;
    __typename: string;
  }) {
    return category == "TOURNAMENT" || __typename == "Tournament";
  }

  static isCup({
    category,
    __typename,
  }: {
    category: string;
    __typename: string;
  }) {
    return category == "CUP" || __typename == "Cup";
  }
}
