










import { Component, Prop, Vue } from "@components/common";
import Team from "@graphql/types/team";

@Component
export default class Avatar extends Vue {
  @Prop(Object) readonly team!: Team
  @Prop({default: false}) readonly editable!: boolean
  @Prop({default: 64}) readonly size!: number

  get shouldShowAvatar() {
    if (this.editable)
      return true;

    return this.team.avatar && this.team.avatar.url;
  }

  get placeholderUrl() {
    return `https://placekitten.com/${this.size}/${this.size}`;
  }

  get avatarUrl() {
    return this.team.avatar?.url ?? this.placeholderUrl;
  }

  async uploadAvatar({ target: { files: [file] } }) {
    const result = await this.$store.dispatch("teams/uploadAvatar", {
      teamId: this.team.id,
      avatar: file,
    });

    this.$emit("setAvatar", result.avatar);
  }
}
