import Match from "@graphql/types/match";

import { uniq, sortBy } from "underscore";

export default class MatchFilter {
  constructor(public matches: Match[]) {}

  get sortedMatches() {
    return sortBy(this.matches, "startTime");
  }

  get pastMatches() {
    return this.matches.filter((m) => !!m.result?.id);
  }

  get futureMatches() {
    return this.matches.filter((m) => !m.result?.id);
  }

  get uniqueParticipations() {
    return uniq(
      this.matches.flatMap((m) => m.participations),
      false,
      (p) => p.id
    );
  }

  get latestCompletedMatchPerParticipations() {
    return this.uniqueParticipations
      .map((participation) => [
        participation,
        this.latestCompletedMatch(participation),
      ])
      .filter((pair) => !!pair[1]);
  }

  get nextMatchPerParticipations() {
    return this.uniqueParticipations
      .map((participation) => [participation, this.nextMatch(participation)])
      .filter((pair) => !!pair[1]);
  }

  nextMatch(participation) {
    return this.sortedMatches.find(
      (m) =>
        !m.result?.id &&
        !!m.participations?.find((p) => p.id == participation.id)
    );
  }

  latestCompletedMatch(participation) {
    return this.sortedMatches
      .reverse()
      .find(
        (m) =>
          !!m.result?.id &&
          !!m.participations?.find((p) => p.id == participation.id)
      );
  }
}
