import gql from "graphql-tag";
import Base from "@graphql/types/base";

export const ConversationFields = gql`
  fragment ConversationFields on Conversation {
    id
    subject
    topicId
    photoUrl
    welcomeMessages
    custom
    lastMessage {
      text
      createdAt
    }
    participants
    createdAt
  }
`;

export default class Conversation extends Base<Conversation> {
  declare id?: number;
  subject?: string;
  topicId?: number;
  photoUrl?: number;
  welcomeMessages?: number;
  custom?: Record<string, string>;
  lastMessage?: Record<string, string>;
  participants?: {
    [id: string]: { access: "ReadWrite" | "Read"; notify: boolean };
  };
  createdAt?: number;
}
