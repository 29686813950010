import { VuexModule, Module, Mutation } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Progress extends VuexModule {

  public loading = 0

  @Mutation
  startLoading() {
    this.loading++;
  }

  @Mutation
  finishLoading() {
    this.loading--;
  }
}
