import gql from "graphql-tag";
import Base from "@graphql/types/base";

import { selectable as s } from "@store/generic_store";

export const ParticipationFields = gql`
  fragment ParticipationFields on Participation {
    participationId
    id
    divisionId
    type
    name
    seed
    group
    data
    eliminated
    playoffsGroupId
  }
`;

export default class Participation extends Base<Participation> {
  @s() id?: number;
  @s() participationId?: number;
  @s() type?: string;
  @s() divisionId?: number;
  @s() name?: string;
  @s() seed?: number;
  @s() group?: number;
  @s() data?: Record<string, number>;
  @s() eliminated?: boolean;
  @s() playoffsGroupId?: number;

  participant?;
}
