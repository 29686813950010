
















































































import { Component, Vue, Prop } from "@components/common";
import { Datetime } from "vue-datetime";
import { DateTime, Interval } from "luxon";

import RoundHelpers, { RoundStage } from "@lib/helpers/round_card_helpers";

import Event from "@/graphql/types/event";
import PlayDay from "@/graphql/types/play_day";
import Round from "@/graphql/types/br_round";
import Team from "@/graphql/types/team";

import {
  ActionBtn,
  CardBody,
  CardHeader,
  DateTimeInfo,
  Expander,
  RoundCardBasics,
  Meta,
  MetaLine,
  UserAvatar,
} from "@components/v2/ActivityCards/common";

import GetMatchKey from "@components/events/active/get_match_key.vue";

import 'vue-datetime/dist/vue-datetime.css';

@Component({
  components: {
    ActionBtn,
    CardBody,
    CardHeader,
    DateTimeInfo,
    Datetime,
    Expander,
    GetMatchKey,
    Meta,
    MetaLine,
    RoundCardBasics,
    UserAvatar,
  }
})
export default class CheckinStage extends Vue {
  @Prop() private readonly round!: Round;

  @Prop() private readonly event!: Event;
  @Prop() private readonly myTeams!: Team[];
  @Prop() private readonly playDay!: PlayDay;

  @Prop() private readonly show!: boolean;

  // Common starting string for translations
  private loading = false;

  get roundName() {
    return this.roundHelpers.roundName;
  }

  get roundHelpers() {
    return new RoundHelpers(this.round, {
      me: this.currentUser,
      stage: RoundStage.Checkin,
      event: this.event,
      myTeams: this.myTeams,
      playDay: this.playDay,
    });
  }

  get isCheckedIn() {
    return this.roundHelpers.isCheckedIn;
  }

  get isCheckinEnded() {
    return this.roundHelpers.isCheckinEnded;
  }

  get hasHostKey() {
    const key = this.round.hostKey;

    return typeof key === "string" && key.length > 0;
  }

  get remainingTime() {
    this.ticker;

    return Interval.fromDateTimes(
      DateTime.local(),
      this.roundHelpers.checkinEndTime,
    ).length("seconds");
  }

  get remainingTimeText() {
    return this.roundHelpers.intervalText(this.remainingTime, {short: true});
  }

  get priority() {
    return this.isCheckedIn ? 2 : 3;
  }

  async checkIn() {
    const round = this.round;

    if (!round)
      return;

    const result = await this.$store.dispatch("rounds/checkIn", {
      roundId: round.id,
      asTeam: this.roundHelpers.isTeamBased,
      teamId: this.roundHelpers.isTeamBased ? this.roundHelpers.myParticipantId : undefined,
    });

    if (result.success) {
      this.$notify({type: "success", text: this.$t("common.success").toString()});
      this.$gtag.event('round_check_in', {
        'event_category': 'round_action',
        'event_label': 'Check in to round'
      });
    }
  }

  get icon() {
    return "mdi-clock";
  }

  get expanded() {
    return this.show || this.priority > 2;
  }

  async loadData() {
    this.loading = true;

    Promise.all([
      // lol
    ]).finally(() => {
      this.loading = false;
    });
  }

  private timer: ReturnType<typeof setInterval> | null = null;
  private ticker = 0;

  mounted() {
    this.timer = setInterval(this.tick, 1000);

    this.loadData();
  }

  unmounted() {
    const timer = this.timer;
    if (timer)
      clearInterval(timer);
  }

  tick() {
    this.ticker += 1;
  }
}
