










import { Component, Prop, Vue } from "@components/common";

@Component
export default class CountDown extends Vue {
  @Prop(Date) readonly endDate

  private days = 0;
  private hours = 0;
  private minutes = 0;
  private seconds = 0;
  private isEnded = false;

  private timer;

  updateRemaining (distance) {
    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
  }

  tick() {
    const currentTime = new Date();
    const distance = Math.max(Number(this.endDate) - Number(currentTime), 0);
    this.updateRemaining(distance);

    if (distance === 0) {
      clearInterval(this.timer);
      this.isEnded = true;
    }
  }

  mounted() {
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }
}
