


























































import { Component, Prop, Vue } from "@components/common";

@Component

export default class Dialog extends Vue {
  @Prop() private readonly event;
  @Prop(Boolean) private readonly canJoinAsUser!: boolean;
  @Prop(Array) private readonly teamsSelect;

  private show = false;
  private activeTab = 'asUser';
  private selectedTeamId = null
  private asUser = true;
  private asTeam = false;

  setAsTeam() {
    this.asTeam = true;
    this.asUser = false;
  }

  setAsUser() {
    this.asTeam = false;
    this.asUser = true;
  }

  get canJoinAsTeam() {
    return this.teamsSelect.some(team => !team.disabled);
  }

  get canJoin() {
    return this.canJoinAsUser || this.canJoinAsTeam;
  }

  get action() {
    return "Sign up";
  }

  confirm(event) {
    this.show = false;

    if (this.asTeam)
      this.$emit("joinAsTeam", { event, teamId: this.selectedTeamId });
    else if (this.asUser)
      this.$emit("joinAsUser", { event });
  }

  cancel(...args) {
    this.show = false;
    this.$emit("cancel", ...args);
  }
}
