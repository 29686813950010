











import BRStandingsTable from "./br_standings_table.vue";
import Event from "@graphql/types/event";

import { Component, Vue, Prop } from "@components/common";
import { sortBy } from "underscore";
import _ from "underscore";

_.mixin({
  then: (obj, fun) => fun(obj),
});

const DATA_KEY = "EventStageFlows::BattleRoyale::SoloRounds";

interface Rank {
  name: string;
  id: number;
  participationId: number;
  scr: number;
  position: number;
}

@Component({components: {BRStandingsTable}})
export default class BREventStandings extends Vue {
  @Prop() readonly event!: Event;
  @Prop({type: Boolean}) readonly compact!: boolean;

  get participants() {
    return this.event.participations ?? [];
  }

  get standings() {
    const standings = sortBy(this.participants, function(o) { return o.data[DATA_KEY]?.points ?? 0; }).reverse().map((p) => {
      return {
        name: p.name,
        id: p.id,
        participationId: p.participationId,
        scr: this.getScore(p),
        ...this.rounds
      };
    });

    // Show Players with the same No of points on a same rank

    let rank = 0;
    let previousRank: number;
    let equal = false;

    return standings.reduce((array, currentRank, i) => {
      // Does previous player have equal points as the current?
      if (i && array[i-1].scr === currentRank.scr) {
        equal = true;
        // Keep the same rank
        previousRank = array[i-1].position;
        rank ++;
      } else {
        equal = false;
        // Next rank position
        rank ++;
      }

      return array.push({
        position: equal ? previousRank : rank,
        ...currentRank,
      }), array;
    }, []);
  }

  get headers() {
    return Object.keys(this.standings[0]);
  }

  getScore(user) {
    const points = user.data[DATA_KEY]?.points ?? 0;
    return points;
  }

  get isTeamBased() {
    return this.event?.participations?.[0].type === "TEAM";
  }

  roundPlayDayNumber(round) {
    const playDays = this.event?.playDays;

    if (!playDays)
      return 0;

    return playDays.find(day => day.id == round.playDayId)?.dayNumber ?? 0;
  }

  get rounds() {
    const rounds = sortBy(this.event.playDays?.map(o => o.rounds).flat(), 'startTime');

    const test = Object.assign({}, ...Object.keys(rounds).map( r => ({
      [`P${this.roundPlayDayNumber(rounds[r])}\u202fR${rounds[r].roundNumber}`]: rounds[r].results
    })));

    return test;
  }

  scrollToMyGroup() {
    if (document.getElementById('mygroup')) {
      this.$smoothScroll({
        scrollTo: document.getElementById('mygroup') as HTMLElement,
        offset: -140,
      });
    }
  }
}
