




















import { Component, Vue, Prop } from "@components/common";
import Match from "@graphql/types/match";
import Event from "@graphql/types/event";
import Team from "@graphql/types/team";
import Participation from "@graphql/types/participation";
import EventFlowStage from "@graphql/types/event_flow_stage";
import MatchCheckin from "@graphql/types/checkin";

import {
  ScheduleStage,
  ResultStage,
  RescheduleStage,
  OverviewStage,
  CheckinStage
} from "@components/v2/ActivityCards/MatchStages";

import { MatchStage } from "@lib/helpers/match_helpers";
import MatchHelpers from "@/lib/helpers/match_helpers";

@Component({ components: {
    ScheduleStage,
    RescheduleStage,
    CheckinStage, // Matchkey inside here
    ResultStage,
    OverviewStage
  }})
export default class MatchCard extends Vue {
  @Prop() private match!: Match;

  private isLoading = false;
  private event: Event | null = null;
  private myTeams: Team[] = [];

  private forcedStage: MatchStage | null = null;

  private timer: ReturnType<typeof setInterval> | null = null;
  private ticker = 0;

  private isExpanded = false;

  expand () {
    this.isExpanded = !this.isExpanded;
  }

  get stageComponentName(): string {
    return this.currentStage;
  }

  get currentStage(): MatchStage {
    this.ticker;

    const match = this.match;

    if (this.forcedStage) {
      return this.forcedStage;
    }

    if (!match)
      return MatchStage.Error;

    return this.matchHelpers.matchCardStage;
  }

  get isFreilosMatch() {
    return this.matchHelpers.isFreilos;
  }

  get hasResult() {
    return this.matchHelpers.hasResult;
  }

  get matchHelpers() {
    return new MatchHelpers(this.match, [], {me: this.currentUser, event: this.event ?? undefined});
  }

  mounted() {
    // Might make sense to make this even slower - it only exists for
    // correct matchCardStage time-based transitions
    this.timer = setInterval(this.tick, 10_000);
    this.loadData();
  }

  unmounted() {
    const timer = this.timer;
    if (timer)
      clearInterval(timer);
  }

  tick() {
    this.ticker += 1;
  }

  updateMatch(match: Match) {
    this.match = match;
  }

  async loadData() {
    this.isLoading = true;

    Promise
    .all([
      this.loadEvent(),
      this.loadMyTeams(),
    ])
    .finally(() => this.isLoading = false);
  }

  async loadMyTeams() {
    if (this.event?.mode == "1v1")
      return this.myTeams = [];

    console.log("Current", this.currentUserId);
    this.myTeams = await this.$store.dispatch("teams/search", {filters: {captainId: this.currentUserId}});
  }

  async loadEvent() {
    this.event = await this.$store.dispatch("events/find", {
      id: this.match.eventId,
      extraSelection: [{
        matchCheckins: MatchCheckin,
        participations: Participation,
        eventFlowStages: EventFlowStage
      }]
    });
  }

  forceStage(stage: MatchStage) {
    this.forcedStage = stage;
  }
}
