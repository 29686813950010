import gql from "graphql-tag";
import Event from "@graphql/types/event";

import { FileFields } from "@graphql/types/file";
import { GameFields } from "@graphql/types/game";
import { MatchCheckinFields } from "@graphql/types/checkin";
import { MatchFields } from "@graphql/types/match";
import { ParticipationFields } from "@graphql/types/participation";
import { PlatformFields } from "@graphql/types/platform";
import { StageOverrideFields } from "@graphql/types/stage_overrides";
import { PlayDayFields } from "@graphql/types/play_day";
import { TeamMetaFields } from "@graphql/types/team";
import { UserEventFields } from "@graphql/types/user";
import { DateTime } from "luxon";

export const TournamentNoAvatarFields = gql`
  fragment TournamentNoAvatarFields on Tournament {
    id
    gameId
    name
    platformIds
    featured
    mode
    maxPlayers
    startTime
    qualificationEndTime
    playoffStartTime
    signUpEndTime
    endTime
    checkin
    descriptions
    rules
    stage
    createdAt
    updatedAt
    matchDuration
    groupSize
    lastEliminationStageNumber
    phase
    status
    flowType
    roundType
    playDays {
      ...PlayDayFields
    }
    stages {
      ...StageOverrideFields
    }
    platforms {
      ...PlatformFields
    }
    participatingUsers {
      ...UserEventFields
    }
    participatingTeams {
      ...TeamMetaFields
    }
    game {
      ...GameFields
    }
  }
  ${FileFields}
  ${GameFields}
  ${PlatformFields}
  ${StageOverrideFields}
  ${UserEventFields}
  ${TeamMetaFields}
  ${PlayDayFields}
`;

export const TournamentFields = gql`
  fragment TournamentFields on Tournament {
    ...TournamentNoAvatarFields
    avatar {
      ...FileFields
    }
    coverImage {
      ...FileFields
    }
  }
  ${FileFields}
  ${TournamentNoAvatarFields}
`;

export const ListTournamentFields = gql`
  fragment ListTournamentFields on Tournament {
    id
    name
    featured
    mode
    maxPlayers
    startTime
    endTime
    stage
    phase
    status
    platformIds
    avatar {
      ...FileFields
    }
    participations {
      ...ParticipationFields
    }
    gameId
  }
  ${FileFields}
  ${ParticipationFields}
`;

export const TournamentMatchCardFields = gql`
  fragment TournamentMatchCardFields on Tournament {
    ...TournamentNoAvatarFields
    participations {
      ...ParticipationFields
    }
    checkins {
      ...MatchCheckinFields
    }
    standings {
      participantId
      position
    }
  }
  ${TournamentNoAvatarFields}
  ${MatchCheckinFields}
  ${ParticipationFields}
`;

export const MyTournamentsFields = gql`
  fragment MyTournamentsFields on Tournament {
    ...TournamentNoAvatarFields
    avatar {
      ...FileFields
    }
    participations {
      ...ParticipationFields
    }
    checkins {
      ...MatchCheckinFields
    }
    standings {
      participantId
      position
    }
  }
  ${TournamentNoAvatarFields}
  ${MatchCheckinFields}
  ${ParticipationFields}
`;

export const DetailedTournamentFields = gql`
  fragment DetailedTournamentFields on Tournament {
    ...TournamentFields
    participations {
      ...ParticipationFields
    }
    matches {
      ...MatchFields
    }
    checkins {
      ...MatchCheckinFields
    }
    standings {
      participantId
      position
    }
    administratorUserIds
  }
  ${TournamentFields}
  ${MatchFields}
  ${MatchCheckinFields}
  ${ParticipationFields}
`;

export default class Tournament extends Event {
  groupSize?: number;
  category?: string = "TOURNAMENT";
}
