import gql from "graphql-tag";
import { TeamFields } from "@graphql/types/team";

export const ListTeams = gql`
  query ListTeams($filters: TeamsFilterInput, $page: Int) {
    teams(filters: $filters, page: $page) {
      ...TeamFields
    }
  }
  ${TeamFields}
`;

export const ListTeamsWithCaptainId = gql`
  query ListTeams($filters: TeamsFilterInput) {
    teams(filters: $filters) {
      ...TeamFields
      captain {
        id
      }
    }
  }
  ${TeamFields}
`;
