











import { Component, Page, Layout, Watch} from "@components/common";

@Component({  components: { Layout }})
export default class Standings extends Page {
  private tabs = [
    'standings.tab.live',
    'standings.tab.finished',
    // TODO 'standings.tab.bestofweek',
    // TODO 'standings.tab.games'
   // TODO 'standings.tab.teams',
  // TODO 'standings.tab.players'
  ]

  private transitionName= ''

  @Watch('$route')
  updateTransition(to, from) {
    const toDepth = to.path.split('/').length;
    const fromDepth = from.path.split('/').length;
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
  }
}
