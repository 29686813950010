




import { Component, Vue, Prop } from "@components/common";
import { DateTime } from "luxon";

@Component
export default class FormattedTime extends Vue {
  @Prop() time!: string;
  @Prop({default: false}) justTime!: boolean;
  @Prop({default: 'DATETIME_MED'}) format!: string;

  get formattedTimeString() {
    // return new Date(this.time).toLocaleString();
    return DateTime.fromISO(this.time).toLocaleString(DateTime[this.format]);
  }
}

