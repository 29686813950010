import gql from "graphql-tag";
import { MatchFields } from "@graphql/types/match";

export default gql`
  mutation ConfirmMatchStartTimes($matchIds: [ID!]!, $startTime: DateTime!) {
    confirmMatchStartTimes(matchIds: $matchIds, startTime: $startTime) {
      success
      matches {
        ...MatchFields
      }
    }
  }
  ${MatchFields}
`;