

















































import { Component, Vue, Prop } from "@components/common";

import Heading from "@components/v2/Heading.vue";
import TableExpander from "@components/v2/TableExpander.vue";

import Participation from "@graphql/types/participation";
import PlayDay from "@graphql/types/play_day";
import Round from "@graphql/types/br_round";

import { chain } from "underscore";

@Component({
  components: {
    Heading,
    TableExpander,
  }
})
export default class RoundOverviewTable extends Vue {
  @Prop() round!: Round;
  @Prop() playDay!: PlayDay;
  @Prop() participations!: Participation[];
  @Prop() myParticipationId!: number;

  private preview = true;

  togglePreview() {
    this.preview = !this.preview;
  }

  get results() {
    return chain(this.round?.results ?? [])
      .sortBy("rank")
      .sortBy("points")
      .reverse()
      .value();
  }

  get shownResults() {
    return this.preview ? this.results.slice(0, 3) : this.results;
  }

  get shownResultsWithParticipations() {
    return this.shownResults.map(r => ({
      ...r,
      participation: this.participations.find(p => p.participationId == r.participationId)
    }));
  }

  isMyResult(result) {
    return this.myParticipationId && result.participationId == this.myParticipationId;
  }
}
