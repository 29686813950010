import { VuexModule, Module, Mutation } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Navigation extends VuexModule {

  public currentTopPosition = 0;
  public previousTopPosition = 0;
  public goBackTo = {
    name: '',
    path: ''
  }
  public continueTo = {
    name: '',
    path: ''
  }

  @Mutation
  recordScrollTop(n) {
    this.currentTopPosition = n;
  }

  @Mutation
  setPreviousTopPosition(n) {
    this.previousTopPosition = n;
  }

  @Mutation
  setBackPage(route) {
    this.goBackTo.name = route.name;
    this.goBackTo.path = route.path;
  }

  @Mutation
  setNextPage(route) {
    this.continueTo.name = route.name;
    this.continueTo.path = route.path;
  }


  get nextPage() {
    return this.continueTo.path;
  }
}
