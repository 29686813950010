


































































































































































































































import { Component, Page, Layout, Prop, Divider, FormLabel} from "@components/common";
import { sortBy, union } from "underscore";

import EventCardDetailHeader from "@components/events/event_card_detail_header.vue";
import DivisionSelector from "@components/events/divisions/division_selector.vue";
import EventAdmin from "@/components/events/event_admin_tab.vue";
import EventCardDetail from "@components/events/event_card_detail.vue";
import EventChat from "@/components/events/event_chat.vue";
import EventChatAdmin from "@/components/events/event_chat_admin.vue";
import EventInformation from "@components/events/tabs/event_information.vue";
import EventMatchesTab from "@components/events/tabs/event_matches_tab.vue";
import EventplayoffsTab from '@components/events/tabs/event_playoffs_tab.vue';
import PlayerCard from "@components/v2/PlayerCard.vue";
import PlayerGridCard from "@components/players/grid_card.vue";
import Rounds from "@components/events/rounds.vue";
import Skeleton from "@components/events/skeleton.vue";
import MyMatches from "@components/v2/MyMatches.vue";
import MyRounds from "@components/v2/MyRounds.vue";

import Division from "@graphql/types/division";
import Event from "@graphql/types/event";
import Participation from "@graphql/types/participation";
import Team from "@graphql/types/team";
import TeamCard from "@components/teams/card.vue";
import User from "@graphql/types/user";

import { GetEventWithDetails } from "@graphql/queries/event";
import EventHelpers from "@/lib/helpers/event_helpers";

interface Selectable {
  id?: number;
  type?: string;
  participations?: Participation[];
}

class DivisionWithType extends Division {
  type?: string;

  constructor(props: Partial<DivisionWithType> = {}) {
    super(props);
    Object.assign(this, props);
  }
}

const EVENT_POLLING_INTERVAL = 60_000;

@Component({
  components: {
    EventCardDetailHeader,
    Divider,
    DivisionSelector,
    EventAdmin,
    EventCardDetail,
    EventChat,
    EventChatAdmin,
    EventInformation,
    EventMatchesTab,
    EventplayoffsTab,
    FormLabel,
    Layout,
    PlayerCard,
    PlayerGridCard,
    Rounds,
    Skeleton,
    TeamCard,
    MyMatches,
    MyRounds
  },
  apollo: {
    event: {
      query: GetEventWithDetails,
      variables() { return {id: this.eventId }; },
      update: ({ event }) => new Event(event),
      result() { this.setActiveDivision(), this.loading = false; },
      pollInterval: EVENT_POLLING_INTERVAL,
    }
  }
})
export default class EventPage extends Page {
  @Prop() readonly route;

  private event = new Event;
  private errorLoading = false;
  private showGrid = false;
  private currentTab = null
  private loading = true

  private submitScoreDialog = {
    show: false,
    matchId: null
  };

  private activeDivision: Selectable = new DivisionWithType({type: ""});

  private divisionName(level: number, position: number): string {
    return this.$t(
      `event.division.name.level_${level + 1}`,
      {divisionNumber: position + 1, gameName: this.eventGameName}
    ).toString() + ' - ' + this.$t(`event.division.division_tag`, {divisionNumber: position + 1}).toString();
  }

  get hasSimplePlayoffs() {
    return this.event.eventFlow?.eventFlowStages?.some(efs => efs.flowType === "EventStageFlows::Playoffs::SimplePlayoffs");
  }

  private setActiveDivision() {
    return;
    //if (this.hasPlayoffs) {
    //  this.activeDivision = this.defaultPlayoff;
    //} else if (this.hasDivisions) {
    //  this.activeDivision = this.defaultDivision;
    //}
  }

  setDivision(division) {
    this.activeDivision = division;
  }

  get hasRounds() {
    return this.eventHelpers.hasRounds;
  }

  get isMyMatchesTabOpen() {
    return this.$route.hash == "my";
  }

  get hasMatches() {
    return (this.event.matchCount ?? 0) > 0;
  }

  get hasQualifications() {
    return this.eventHelpers.hasQualifications;
  }

  get eventHelpers() {
    return new EventHelpers(this.event);
  }

  get hasGroups() {
    return this.event.eventFlow?.eventFlowStages?.some(efs => efs.flowType === "EventStageFlows::Qualification::GroupQualifications");
  }

  get hasStandings() {
    // we show standings tab to if there is group stage
    if (this.hasGroups || this.hasDivisions)
      return true;

    // Battle royale always shows standings
    if (this.hasBattleRoyaleRounds)
      return true;

    return this.event.standings === undefined ? false : !!this.event.standings.length;
  }

  get hasBattleRoyaleRounds() {
    return this.event?.playDays?.length ?? 0 > 0;
  }

  get eventGame() {
    return this.event.gameId ? this.gameById(this.event.gameId) : undefined;
  }

  get eventGameName() {
    return this.eventGame ? this.eventGame.name : '';
  }

  get sectionParticipants() {
    // It can either be teams OR users, there's never any need to make sure which is
    // which ...
    return union(this.event.participatingTeams, this.event.participatingUsers);
  }

  get sortedActiveDivisionParticipants() {
    return sortBy(this.activeDivision.participations,"name");
  }

  get divisions() {
    const divisions = sortBy(this.event.divisions, "level");

    const namedDivisions = divisions?.map(division => ({
      ...division,
      id: division.id,
      name: this.divisionName(division.level, division.position),
      type: 'division',
      tag: division.tag,
      participations: division.participations.map(p => ({
        ...p,
        avatar: this.participantAvatar(p.id),
        games: this.participantGames(p.id),
        members: this.participantTeamMembers(p.id)
      }))
    }));

    return namedDivisions;
  }

  get playoffs() {
    const playoffs = sortBy(this.event.playoffsGroups, "position");
    const namedPlayofs = playoffs?.map(playoff => ({
      id: playoff.id,
      name: this.playoffsGroupName(playoff.tag),
      type: 'playoffsGroup',
      tag: playoff.tag,
      participations: playoff.participations.map(p => ({
        ...p,
        avatar: this.participantAvatar(p.id),
        games: this.participantGames(p.id),
        members: this.participantTeamMembers(p.id)
      }))
    }));

    return namedPlayofs;
  }


  private playoffsGroupName(tag) {
    return this.$t(`event.playoff.${tag}`);
  }

  private participantAvatar(id) {
    return this.participants.find( p=> p.id === id )?.avatar;
  }

  private participantGames(id) {
    const participation = this.participants.find(p => p.id === id );
    return (participation instanceof User) ? participation?.games : [];
  }

  private participantTeamMembers(id) {
    if (!this.isTeamBased) return [];

    const participation = this.participants.find(p => p.id === id );
    return (participation instanceof Team) ? participation?.members: [];
  }

  get participants() {
    return [
      ...this.event.participatingUsers ?? [],
      ...this.event.participatingTeams ?? []
    ];
  }

  get hasDivisions() {
    return this.divisions.length > 0;
  }


  get hasPlayoffs() {
    return this.playoffs.length > 0;
  }

  get shouldShowPlayoffsTab() {
    const activeDivision = this.activeDivision;

    if (!activeDivision)
      return false;

    if (activeDivision.type == "playoffsGroup")
      return true;
    else
      return this.hasPlayoffs && this.activeDivision.type != "division";
  }

  get shouldShowMatchesTab() {
    const activeDivision = this.activeDivision;

    if (!activeDivision)
      return true;

    if (activeDivision.type == "division")
      return true;
    else
      return (this.hasDivisions || this.hasGroups) && this.activeDivision.type != "playoffsGroup";
  }


  get defaultPlayoff() {
    return this.myPlayoffs ?? this.playoffs[0];
  }

  get defaultDivision() {
    return this.isParticipant? this.myDivision : this.divisions[0] ?? new DivisionWithType;
  }

  get myDivision() {
    const myDivision = this.divisions.find(d => d.participations.find(p => p.participationId === this.myParticipationId));

    return myDivision;
  }

  get myPlayoffs() {
    const myPlayoffs = this.playoffs.find(d => d.participations.find(p => p.participationId === this.myParticipationId));

    return myPlayoffs;
  }


  get isParticipant() {
    if (!this.isLoggedIn)
      return false;

    if (this.isTeamBased) {
      return this.isParticipantTeamMember; // Also includes captains
    } else {
      return this.event.participatingUsers?.some(
        user => user.id == this.currentUserId
      );
    }
  }

  get isParticipantTeamMember() {
    if (!this.isLoggedIn)
      return false;

    if (this.isTeamBased) {
      return this.event.participatingTeams?.some(
        team => team.members?.some(member => member.id === this.currentUserId)
      );
    }
    return false;
  }

  get myParticipatingTeamId() {
    if (!this.myParticipatingTeam) return null;
    return this.myParticipatingTeam.id;
  }

  get myParticipatingTeam() {
    if (!this.event.participatingTeams) return { captain: null, id: null };
    return this.event.participatingTeams?.find(a => a.members?.find(member => member.id === this.currentUserId));
  }

  get myParticipationId(){
    if (!this.isParticipant) return null;

    const participations = this.event.participations;
    const myId = this.isTeamBased ? this.myParticipatingTeamId : this.currentUser?.id;

    return participations?.find(p => p.id === myId)?.participationId;
  }

  get isRoundBased() {
    return this.event.roundType === "Round";
  }

  get getRoute() {
    return this.route ? this.route : '/browse/featured';
  }

  get description() {
    return this.event?.descriptions?.[this.$i18n.locale] ??
      this.event?.descriptions?.[this.$i18n.fallbackLocale[0]];
  }

  get rules() {
    return this.event?.rules?.[this.$i18n.locale] ??
      this.event?.rules?.[this.$i18n.fallbackLocale[0]];
  }

  get eventId() {
    return this.$route.params.eventId ?? this.$route.params.id;
  }

  get match() {
    if (this.event.matches === undefined)
      return null;
    return this.event?.matches[0];
  }

  updateEvent({ event }) {
    this.event = event;
  }

  refreshEvent() {
    this.$apollo.queries.event.refetch();
  }

  get participantsTabName() {
    if (this.event?.isTeamBased) {
      return this.$t(`event.tabs.teams`);
    } else {
      return this.$t(`event.tabs.players`);
    }
  }

  get isUserBased() {
    return this.event?.isUserBased;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get uniquePlatformsIcons() {
    const platforms = this.event?.platforms;

    if (!platforms)
      return [];

    return Array.from(
      new Set(platforms.map(x => x.icon))
    );
  }

  updateRound({name, record: round}) {
    if (name !== "round")
      return;

    const playDay = this.event?.playDays?.find(pd => pd.id == round.playDayId);
    const rounds = playDay?.rounds;

    if (!playDay || !rounds)
      return;

    const index = rounds.findIndex(r => r.id == round.id);

    if (index !== -1)
      this.$set(rounds, index, round);

    // Stop and restart polling to prevent updating to old cached data.
    // We should be able to get rid of this once we push data and entirely
    // stop polling.
    this.$apollo.queries.event.stopPolling();
    setTimeout(() =>
      this.$apollo.queries.event.startPolling(EVENT_POLLING_INTERVAL),
      EVENT_POLLING_INTERVAL
    );
  }

  mounted() {
    this.$eventBus.$on("store:update", this.updateRound);
  }

  umnounted() {
    this.$eventBus.$off("store:update", this.updateRound);
  }
}
