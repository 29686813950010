import { Vue, Component } from "@components/common";

@Component
class Page extends Vue {
  // created() {
  //   if (!this.isLoggedIn) {
  //     this.$notify({
  //       title: "You need to be logged in to access this.",
  //       type: "warn"
  //     });
  //     this.$router.push("/login");
  //   }
  // }
}

@Component
class GuestPage extends Vue {}

export { Page, GuestPage };
