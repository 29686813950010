







































import { Component, Vue, Prop, Divider } from "@components/common";

import EventAction from "@components/v2/EventAction.vue";
import JoinDialog from "@components/events/join_dialog.vue";
import ConfirmationDialog from "@components/events/confirmation_popup.vue";
import ErrorDialog from "@components/events/error_popup.vue";
import Heading from "@components/v2/Heading.vue";

import Event from "@graphql/types/event";
import Platform from "@graphql/types/platform";
import Team from "@graphql/types/team";

@Component({
  components: {
    ConfirmationDialog,
    JoinDialog,
    EventAction,
    Divider,
    ErrorDialog,
    Heading
  },
})
export default class EventCardDetail extends Vue {
  @Prop(Object) readonly event!: Event;
  @Prop(Boolean) readonly allowJoin!: boolean
  @Prop({default: false}) readonly details!: boolean;

  private myTeams!: Team[];
  private platforms: Platform[] = [];
  private selectedTeamId = null;
  private showConfirmation = false;
  private showError = false
  private wrongAge = false

  get showTeamsDropdown() {
    return this.isTeamBased && this.myTeams?.length > 1;
  }

  get confirmationDialog() {
    return this.showConfirmation;
  }

  private closeConfirmationDialog() {
    this.showConfirmation = false;
  }

  get checkinTime() {
    if (this?.event.checkin !== undefined) {
      return Math.round(this.event.checkin / 60);
    }
      return undefined;
  }

  get uniquePlatformsIcons() {
    const platforms = this.event?.platforms;

    if (!platforms)
      return [];

    return Array.from(
      new Set(platforms.map(x => x.icon))
    );
  }

  get participantCount() {
    const event = this.event;

    if (!event)
      return 0;

    return Math.max(
      event?.participatingUsers?.length ?? 0,
      event?.participatingTeams?.length ?? 0
    );
  }

  get eventFull() {
    const event = this.event;

    if (!event || !event.maxPlayers)
      return false;

    return this.participantCount >= event.maxPlayers;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get isUserBased() {
    return this.event?.mode === "1v1";
  }

  get isReschedule() {
    // TODO: needs implementation
    return false;
  }

  isTeamAlreadyParticipating(team) {
    return this?.event?.participatingTeams?.some(p => p.id == team.id);
  }

  showSubmitScoreDialog(...args) {
    this.$emit("showSubmitScoreDialog", ...args);
  }

  async joinEventAsTeam({ event, teamId }) {
    return this.joinEvent(event,{
      id: this.event.id,
      asUser: false,
      asTeam: true,
      teamId: teamId
    });
  }

  async joinEventAsUser({ event }) {
    return this.joinEvent(event, {
      id: Number(this.event.id),
      asUser: true
    });
  }

  async joinEvent(event, variables) {
    let result;

    if (!this.isLoggedIn) {
      this.$router.push({name: 'Join'});
      return;
    }

    try {
      result = await this.$store.dispatch("events/join",variables);

      this.showConfirmation = true;
      this.updateEvent({event: new Event(result.event)});
      this.$gtag.event('signup_to_event', {
        'event_category': 'event_action',
        'event_label': 'Sign up to Event',
        'value': { userId: this.currentUserId, id: this.event.id, eventName: this.event.name, eventType: 'Event' }
      });
    } catch (errors) {
      errors.graphQLErrors.forEach((err) => {
        if (err.message == "EVENTS/NOT_OLD_ENOUGH") {
          this.wrongAge = true;
        }
      });
      this.showError = true;
    }
  }

  updateEvent(...args) {
    this.$emit("update", ...args);
  }

  refreshEvent(...args) {
    this.$emit("refreshEvent", ...args);
  }
}
