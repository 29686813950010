import Event from "@graphql/types/event";
import PlayDay from "@graphql/types/play_day";
import Round from "@graphql/types/br_round";
import Team from "@graphql/types/team";
import User from "@graphql/types/user";

import MatchCardHelpers from "./match_helpers";
import { DateTime } from "luxon";

import i18n from "@/i18n";

export enum RoundStage {
  Waiting = "WaitingStage",
  Checkin = "CheckinStage",
  Result = "ResultStage",
  Overview = "OverviewStage",
  Error = "ErrorStage",
}

export default class RoundCardHelpers {
  public me: User;
  public stage: RoundStage;
  public event?: Event;
  public myTeams: Team[];
  public playDay: PlayDay;

  constructor(public round: Round, { me, stage, event, myTeams, playDay }) {
    this.me = me;
    this.stage = stage;
    this.event = event;
    this.myTeams = myTeams;
    this.playDay = playDay;
  }

  get roundName() {
    return i18n.t("round.name", {
      roundNumber: this.roundNumber,
      playDayNumber: this.playDayNumber,
    });
  }

  get roundNumber() {
    return this.round?.roundNumber ?? 0;
  }

  get playDayNumber() {
    return this.playDay?.dayNumber ?? 0;
  }

  intervalText = MatchCardHelpers.intervalText;

  get translationData() {
    return {
      startTime: this.formattedDateTime(this.round.startTime),
      endTime: this.formattedDateTime(this.round.endTime),
    };
  }

  get translationSubkey() {
    const stage = this.stage;
    if (!stage) return "error";

    return stage
      .split(/(?=[A-Z])/)
      .join("_")
      .toLowerCase();
  }

  t(key, opts = {}) {
    return i18n.t(`round_card.${this.translationSubkey}.${key}`, {
      ...this.translationData,
      ...opts,
    });
  }

  formattedDateTime(datetime) {
    return DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_SHORT);
  }

  get isParticipating() {
    return this.myParticipation != null;
  }

  get isCheckinEnded() {
    return DateTime.local() > this.checkinEndTime;
  }

  get checkinEndTime() {
    const startTime = DateTime.fromISO(this.round.startTime);

    return startTime.plus({ seconds: this.round.checkin });
  }

  get isCheckedIn() {
    const myParticipation = this.myParticipation;

    if (!myParticipation) return false;

    return this.round.checkins?.some(
      (c) => c.participationId == myParticipation.participationId
    );
  }

  get hasSubmittedOwnResult() {
    const myParticipation = this.myParticipation;

    if (!myParticipation) return false;

    return this.round.results?.some(
      (r) => r.participationId == myParticipation.participationId
    );
  }

  get myResult() {
    const myParticipation = this.myParticipation;

    if (!myParticipation) return;

    return this.round.results?.find(
      (r) => r.participationId == myParticipation.participationId
    );
  }

  get isTeamBased() {
    const event = this.event;

    if (!event)
      throw new Error("RoundCardHelpers.isTeamBased: event is undefined");

    return event.mode == "teams";
  }

  get myParticipation() {
    const { me, event } = this;

    if (!me || !event) return;

    return event.participations?.find((p) => p.id == this.myParticipantId);
  }

  get myParticipantId() {
    if (this.isTeamBased) {
      return this.myRelevantTeam?.id;
    } else return this.me.id;
  }

  get myRelevantTeam() {
    const { event, myTeams } = this;

    if (!event)
      throw new Error("RoundCardHelpers.myRelevantTeam: event is undefined");

    return myTeams.find((t) => event.participations?.find((p) => p.id == t.id));
  }
}
