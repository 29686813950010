import gql from "graphql-tag";
import Base from "@graphql/types/base";
import File, { FileFields } from "@graphql/types/file";
import { selectable as s } from "@store/generic_store";

export const GameFields = gql`
  fragment GameFields on Game {
    id
    tag
    name
    allowsOvertime
    allowsManualDraw
    discordChannelName
    discordChannelUrl
    allowsInstantResultSubmission
    publicChatroomIds
  }
`;

export const GameWithAvatarFields = gql`
  fragment GameWithAvatarFields on Game {
    id
    tag
    name
    discordChannelName
    discordChannelUrl
    allowsOvertime
    allowsManualDraw
    allowsInstantResultSubmission
    publicChatroomIds
    coverImage {
      ...FileFields
    }
    avatar {
      ...FileFields
    }
  }
  ${FileFields}
`;

export default class Game extends Base<Game> {
  @s() id?: number;
  @s() tag?: string;
  @s() name?: string;
  @s("avatar { url }") avatar?: File;
  @s() discordChannelName?: string;
  @s() discordChannelUrl?: string;
  @s() allowsOvertime?: boolean;
  @s() allowsManualDraw?: boolean;
  @s() allowsInstantResultSubmission?: boolean;
  @s() publicChatroomIds?: number[];
  @s() descriptions?: { en: string; de: string };
  @s("customSocialLinks { url avatarUrl name tag}") customSocialLinks?: {
    url;
    avatarUrl;
    name;
    tag;
  }[];

  @s() wordpressNewsTag?: string;
  coverImage?: File;
}
