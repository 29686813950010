import gql from "graphql-tag";
import Base from "@graphql/types/base";
import Team from "@graphql/types/team";
import File, { FileFields } from "@graphql/types/file";
import StreamingAccount, {
  StreamingAccountFields,
} from "@graphql/types/streaming_account";
import Platform, { PlatformFields } from "@graphql/types/platform";
import UserPlatform from "@graphql/types/user_platform";

import Game from "./game";
import { selectable as s } from "@store/generic_store";

export const UserFields = gql`
  fragment UserFields on User {
    id
    uuid
    name
    activated
    preferredGameTime
    nickname
    discordNickname
    avatar {
      ...FileFields
    }
    coverImage {
      ...FileFields
    }
    games {
      id
      tag
      name
      avatar {
        ...FileFields
      }
    }
  }
  ${FileFields}
`;

export const UserEventFields = gql`
  fragment UserEventFields on User {
    id
    uuid
    name
    activated
    preferredGameTime
    nickname
    discordNickname
    avatar {
      ...FileFields
    }
    games {
      id
      tag
      name
    }
  }
  ${FileFields}
`;

export const UserMetaFields = gql`
  fragment UserMetaFields on User {
    id
    uuid
    name
    nickname
  }
`;

export const UserIdFields = gql`
  fragment UserIdFields on User {
    id
  }
`;

export const UserDetailFields = gql`
  fragment UserDetailFields on User {
    ...UserFields
    userPlatforms {
      accountIdentifier
      platform {
        ...PlatformFields
      }
    }
    streamingAccounts {
      ...StreamingAccountFields
    }
  }
  ${PlatformFields}
  ${StreamingAccountFields}
`;

export const TeamMemberFields = gql`
  fragment TeamMemberFields on TeamMember {
    id
    firstName
    lastName
    name
    activated
    preferredGameTime
    role
    avatar {
      ...FileFields
    }
    coverImage {
      ...FileFields
    }
  }
  ${FileFields}
`;

export default class User extends Base<User> {
  @s() id?: number;
  @s() uuid?: string;
  @s() name?: string;
  @s() nickname?: string;
  @s() activated?: boolean;
  birthDate?: string;
  @s() preferredGameTime?: string;
  @s() discordNickname?: string;
  @s() profileImage?: string;
  showNickname?: boolean;
  streamingAccounts?: StreamingAccount[];
  platforms?: Platform[];
  userPlatforms?: UserPlatform[];
  games?: Game[];
  avatar?: File;
  coverImage?: File;
  teams?: Team[] = [];
  teamIds?: number[];
}
