


















































import { Page, Component, Layout } from "@components/common";
import { ListTeams } from "@graphql/queries/teams";
import TeamCard from "@components/teams/card.vue";
import Filters from "@components/events/filters.vue";
import { userFilters } from "@/store/filters";
import scrollMonitor from 'scrollmonitor';
import Team from "@graphql/types/team";

@Component({
  components: { TeamCard, Layout, Filters },
  apollo: {
    teams: {
      query: ListTeams,
      variables() { return {filters: this.filters, page: 1}; },
      result() { this.isUpdating = false; this.isUpdatingSearch = false; }
    }
  }
})
export default class Teams extends Page  {
  private isUpdating = true;
  private isUpdatingSearch = true;
  private activeSearch = false;
  private loadingMore = false;
  private page = 1;
  private hasMore = true;
  private scrollId = +new Date()
  private teams: Team[] = []

  public filters = userFilters

  markUpdating() {
    this.activeSearch = true;
    this.isUpdatingSearch = true;
  }

  refresh() {
    this.$apollo.queries.teams.refetch();
    this.hasMore = true;
    this.page = 1;
  }

  async loadMore() {
    this.page++;
    this.loadingMore = true;
    await this.$apollo.queries.teams.fetchMore({
      variables: {
        page: this.page,
        filter: this.filters
      },
      updateQuery: (existing, { fetchMoreResult }) => {
        this.hasMore = fetchMoreResult.teams.length === 0 ? false : true;
        const newTeams = fetchMoreResult.teams;
        this.teams.push(...newTeams);
      },
    });
    this.loadingMore = false;
  }

  mounted () {
    const el = document.getElementById('sensor');
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      if (!this.isUpdatingSearch && this.hasMore) {
        this.loadMore();
      }
    });
  }
}
