import gql from "graphql-tag";
import {MatchStatisticFields} from "@graphql/types/user_match_stats";


export const GetTeamMatchStatistics = gql`
    query MatchStats($teamId: Int!) {
        matchStats(teamId: $teamId) {
            ...MatchStatisticFields
        }
    }
    ${MatchStatisticFields}
`;