




























































import { Vue, Component, Prop, Divider, FormLabel} from "@components/common";

import ActivityCard from "@components/v2/ActivityCards/ActivityCard.vue";
import Heading from "@components/v2/Heading.vue";

import Event from "@graphql/types/event";
import Round from "@graphql/types/br_round";
import RoundResult from "@graphql/types/br_round_result";
import Checkin from "@graphql/types/br_round_checkin";

import { DateTime } from "luxon";

import { sortBy } from "underscore";

const ROUND_CATEGORY_ORDER = [
  {name: "current", title: false},
  {name: "future", title: true},
  {name: "past", title: true}
];

@Component({ components: { Divider, FormLabel, ActivityCard, Heading } })
export default class MyRounds extends Vue {
  @Prop() readonly event!: Event;

  private loading = true;
  private myRounds: Round[] = [];
  private refreshInterval: any = null;

  private panels: number[] = [];

  mounted() {
    this.loadData()
      .then(this.expandCurrentPlayDay);

    this.$eventBus.$on("store:update", this.updateRound);
    this.refreshInterval = setInterval(this.loadRounds, 60_000);
  }

  unmounted() {
    this.$eventBus.$off("store:update", this.updateRound);

    clearInterval(this.refreshInterval);
  }

  expandCurrentPlayDay() {
    // Current is now always first
    this.panels = [0];
  }

  playDaysWithRoundsByState(playDays, ) {
    return playDays.map(pd => ({
      ...pd,
      rounds: {
        current: this.currentRounds.filter(r => r.playDayId == pd.id),
        future: this.futureRounds.filter(r => r.playDayId == pd.id),
        past: this.pastRounds.filter(r => r.playDayId == pd.id),
      }
    }));
  }

  get sortedRounds() {
    return sortBy(this.myRounds, "roundNumber");
  }

  get currentRounds() {
    const now = DateTime.local();

    return this.sortedRounds.filter(r =>
      DateTime.fromISO(r.startTime) < now && now < DateTime.fromISO(r.endTime)
    ).slice(0, 1);
  }
  get futureRounds() {
    return this.sortedRounds.filter(r => DateTime.fromISO(r.startTime) > DateTime.local()); }

  get pastRounds() {
    return this.sortedRounds.filter(r => DateTime.fromISO(r.endTime) < DateTime.local());
  }

  get currentPlayDays() {
    return this.playDays.filter(pd => DateTime.fromISO(pd.endTime) > DateTime.local()).slice(0, 1);
  }

  get futurePlayDays() {
    return this.playDays
      .filter(pd => DateTime.fromISO(pd.startTime) > DateTime.local())
      .filter(pd => !this.currentPlayDays.map(cpd => cpd.id).includes(pd.id));
  }

  get pastPlayDays() {
    return this.playDays.filter(pd => DateTime.fromISO(pd.endTime) < DateTime.local());
  }

  get listedPlayDays() {
    return {
      current: this.playDaysWithRoundsByState(this.currentPlayDays),
      past: this.playDaysWithRoundsByState(this.pastPlayDays),
      future: this.playDaysWithRoundsByState(this.futurePlayDays),
    };
  }

  roundPlayDay(round) {
    return this.playDays.find(pd => pd.id == round.playDayId);
  }

  get playDays() {
    return this.event.playDays ?? [];
  }

  get sortedPlayDays() {
    return sortBy(this.playDays, "dayNumber");
  }

  playDayRounds(id) {
    return this.sortedRounds.filter(r => r.playDayId == id);
  }

  updateRound({ name, record: round }) {
    if (name !== "round")
      return;

    const index = this.myRounds.findIndex(m => m.id == round.id);

    if (index != -1)
      this.$set(this.myRounds, index, round);
  }

  async loadData() {
    this.loading = true;

    await Promise.all([
      this.loadRounds()
    ]).finally(() => this.loading = false);
  }

  formattedDate(date: string) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
  }

  async loadRounds() {
    this.myRounds = await this.$store.dispatch("rounds/search", {
      filters: {eventId: Number(this.event.id), userIds: [Number(this.currentUserId)]},
      limit: 999,
      extraSelection: [{
        results: RoundResult,
        checkins: Checkin
      }],
      noCache: true
    });
  }

  private ROUND_CATEGORY_ORDER = ROUND_CATEGORY_ORDER;
}
