import Base from "@graphql/types/base";
import { selectable as s } from "@store/generic_store";

export default class Chatroom extends Base<Chatroom> {
  @s() id?: number;
  @s() name?: string;
  @s() tag?: string;
  @s() conversationType?: string;
  @s() authToken?: string;
  @s() talkjsConversationId?: string;
  @s() avatarUrl?: string;
  @s() isParticipant?: boolean;

  constructor(props: Partial<Chatroom>) {
    super();

    Object.assign(this, props);
  }
}
