

















































import { Page, Component, Layout } from "@components/common";
import GameCard from "@components/v2/GameCard.vue";
import scrollMonitor from 'scrollmonitor';

import Game from "@graphql/types/game";

@Component({
  components: { GameCard, Layout },
})
export default class Games extends Page {
  private isUpdating = true;
  private isUpdatingSearch = true;
  private activeSearch = false;
  private loadingMore = false;
  private page = 1;
  private hasMore = true;
  private popularGames: Game[] = []

  markUpdating() {
    this.activeSearch = true;
    this.isUpdatingSearch = true;
  }

  refresh() {
    this.page = 1;
    this.loadGames();
  }

  async loadMoreGames() {
    this.loadingMore = true;

    const games = await this.getGames(++this.page);

    games.forEach(u =>
      !this.popularGames.find(u_ => u_.id == u.id) && this.popularGames.push(u)
    );

    this.loadingMore = false;
    this.isUpdating = false;
    this.isUpdatingSearch = false;
  }

  async loadGames() {
    this.isUpdating = true;
    this.isUpdatingSearch = true;

    this.popularGames = await this.getGames();

    this.loadingMore = false;
    this.isUpdating = false;
    this.isUpdatingSearch = false;
  }

  async getGames(page = 1): Promise<Game[]> {
    const limit = 25;

    const games = await this.$store.dispatch("games/popular", {
      extraSelection: [
        "avatar { url }",
      ],
      page,
      limit
    });

    this.hasMore = games.length >= limit;

    return games;
  }

  initializeInfiniteScroll() {
    const el = document.getElementById('sensor');
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      if (!(this.isUpdating || this.loadingMore) && this.hasMore) {
        this.loadMoreGames().finally(() => this.loadingMore = false);
      }
    });
  }


  mounted() {
    this.loadGames()
      .then(this.initializeInfiniteScroll);
  }
}
