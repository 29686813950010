import gql from "graphql-tag";
import Base from "@graphql/types/base";
import User from "@graphql/types/user";
import File, { FileFields } from "@graphql/types/file";
import { UserFields, UserMetaFields } from "@graphql/types/user";
import { selectable as s } from "@store/generic_store";

export const TeamFields = gql`
  fragment TeamFields on Team {
    id
    name
    avatar {
      ...FileFields
    }
    coverImage {
      ...FileFields
    }
    members {
      ...UserFields
    }
    captain {
      ...UserFields
    }
  }
  ${FileFields}
  ${UserFields}
`;

export const TeamMetaFields = gql`
  fragment TeamMetaFields on Team {
    id
    name
    avatar {
      ...FileFields
    }
    members {
      ...UserMetaFields
    }
    captain {
      ...UserMetaFields
    }
  }
  ${UserMetaFields}
`;

export default class Team extends Base<Team> {
  @s() id?: number;
  @s() name?: string;

  avatar?: File;
  coverImage?: File;
  members?: User[];
  captain?: User;

  get nickname() {
    return this.name;
  }

  get preferredGameTime() {
    return "";
  }
}
