import { Vue, Prop, Component } from "@components/common";

import guest from "@components/common/layouts/guest.vue";
import user from "@components/common/layouts/user.vue";
import primary from "@components/common/layouts/primary.vue";
import onboarding from "@components/common/layouts/onboarding.vue";

@Component({
  components: {
    guest,
    user,
    primary,
    onboarding,
  },
})
export default class Layout extends Vue {
  @Prop() readonly name!: string;
  @Prop({ default: false }) readonly plain!: boolean;
  @Prop() readonly routeName!: string;
  @Prop() readonly routePath!: string;
  @Prop({ default: () => [] }) readonly mainTabs!: [string];

  get returnPath() {
    return this.$route.query?.r ?? this.$route.query?.returnPath;
  }

  render(h) {
    const currentComponent = this.name || "primary";
    const children = Object.keys(this.$slots).map((slot) =>
      h("template", { slot }, this.$slots[slot])
    );

    return h(
      currentComponent,
      {
        props: {
          routeName: this.routeName,
          routePath: this.routePath,
          mainTabs: this.mainTabs,
          plain: this.plain,
          returnPath: this.returnPath,
        },
      },
      [children]
    );
  }
}

export { Layout };
