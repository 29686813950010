import gql from "graphql-tag";
import Base from "@graphql/types/base";
import Participation, {
  ParticipationFields
} from "@graphql/types/participation";

export const BaseGroupFields = gql`
  fragment BaseGroupFields on Group {
    id
    position
    size
  }
`;

export const GroupWithParticipationsFields = gql`
  fragment GroupWithParticipationsFields on Group {
    ...BaseGroupFields
    participations {
      ...ParticipationFields
    }
  }
  ${BaseGroupFields}
  ${ParticipationFields}
`;

export default class Group extends Base<Group> {
  position?: number;
  size?: number;
  name?: string; // computed
  participations?: Participation[];
}
