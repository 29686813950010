


















































import { Component, Page, Layout, Divider } from "@components/common";
import { FindTeamWithDetails }  from "@graphql/queries/team";
import TeamCardDetails from "@components/teams/team_detail.vue";
import TeamModel from "@graphql/types/team";
import PlayerCard from "@components/v2/PlayerCard.vue";
import MatchStatisticsCard from "@components/teams/match_statistics_card.vue";
import Heading from "@components/v2/Heading.vue";
import Avatar from "@components/teams/avatar.vue";

@Component({
  components: { Divider, PlayerCard, TeamCardDetails, Avatar, Layout, MatchStatisticsCard, Heading },
  apollo: {
    team: {
      query: FindTeamWithDetails,
      variables() {
        return {
          id: this.$route.params.teamId
        };
      }
    }
  }
})
export default class Team extends Page {
  private team: TeamModel = new TeamModel({
    name: "",
    members: [],
    captain: { name: "", activated: false },
    avatar: {}
  });

  get teamId() {
    return this.$route.params.teamId;
  }

  get activeTab() {
    return this.$route.query.tab || 'overview';
  }

  set activeTab(tab) {
    const newQuery = {...this.$route.query, tab};

    if (this.$route.query == newQuery)
      return;

    this.$router.replace({query: newQuery});
  }

  get membersCount() {
    if (this.team.members === undefined) {
      return 0;
    } else {
      return this.team.members.length;
    }
  }
  get isTeamEditable() {
    const captainId = this.team?.captain?.id;

    if (!captainId)
      return false;

    return captainId == this.currentUserId;
  }

  private setAvatar(avatar) {
    this.team.avatar = avatar;
  }
}
