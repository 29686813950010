import { Component, Vue } from "@components/common";

const eventBus = new Vue();

@Component
export class EventBusMixin extends Vue {
  get $eventBus() {
    return eventBus;
  }
}

export default eventBus;
