












































import { Vue, Component, Prop } from "@components/common";
import gql from "graphql-tag";

import { apollo } from "@lib/graphql";
import { ListUsers } from "@graphql/queries/users";
import { ListTeams } from "@graphql/queries/teams";

import PlayerCard from "@components/players/card.vue";
import TeamCard from "@components/teams/card.vue";

import User from "@graphql/types/user";
import Team from "@graphql/types/team";

type AnyParticipant = Team | User;
interface Participant {
  id: number;
  name: string;
}

import Event from "@graphql/types/event";

@Component({
  components: { PlayerCard, TeamCard }
})
export default class EventAdmin extends Vue {
  @Prop() event!: Event;

  private availableParticipants: Team[] | User[] = [];
  private selectedParticipantId = 0;

  get isTeamBased(): boolean {
    return this.event.mode == "teams";
  }

  get selectedParticipant(): AnyParticipant | null {
    return this.participants
      .find(p => p.id == this.selectedParticipantId) as AnyParticipant;
  }

  async searchParticipants(text: string): Promise<void> {
    if (this.isTeamBased) {
      this.availableParticipants = await this.searchTeams(text);
    } else {
      this.availableParticipants = await this.searchUsers(text);
    }
  }

  async searchUsers(text: string): Promise<User[]> {
    const { data: { users } } = await apollo.query({
      query: ListUsers,
      variables: { filters: { name: text } }
    });

    return users.map(p => new User(p));
  }

  async searchTeams(text: string): Promise<User[]> {
    const { data: { teams } } = await apollo.query({
      query: ListTeams,
      variables: { filters: { name: text } }
    });

    return teams.map(t => new Team(t));
  }

  async addSelectedParticipant(): Promise<void> {
    await apollo.mutate({
      mutation: gql`
        mutation AddEventParticipant($asTeam : Boolean!, $teamId : ID, $userId : ID, $eventId : ID!) {
          admin {
            addEventParticipant(asTeam: $asTeam, teamId: $teamId, userId: $userId, eventId: $eventId) {
              success
            }
          }
        }
      `,
      variables: {
        asTeam: this.isTeamBased,
        teamId: this.selectedParticipantId,
        userId: this.selectedParticipantId,
        eventId: this.event.id
      }
    });

    this.$notify({type: "success", text: this.$t("common.saved").toString()});
  }

  get participants(): Participant[] {
    return this.availableParticipants as unknown as Participant[];
  }

  get dropdownItems(): { value: number; text: string }[] {
    return this.participants.map(p => ({
      text: p.name,
      value: p.id
    }));
  }
}
