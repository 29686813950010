import gql from "graphql-tag";
import Base from "@graphql/types/base";

import BRRoundCheckin, {
  RoundCheckInFields,
} from "@graphql/types/br_round_checkin";
import BRRoundresult, {
  RoundResultFields,
} from "@graphql/types/br_round_result";

import PlayDay from "@graphql/types/play_day";
import Event from "@graphql/types/event";

export const BRRoundFields = gql`
  fragment BRRoundFields on Round {
    id
    eventId
    eventType
    startTime
    endTime
    hostKey
    roundNumber
    playDayId
    checkin

    results {
      ...RoundResultFields
    }

    checkins {
      ...RoundCheckinFields
    }
  }

  ${RoundResultFields}
  ${RoundCheckInFields}
`;

export const BRRoundDetailedFields = gql`
  fragment BRRoundDetailedFields on Round {
    ...BRRoundFields

    checkins {
      ...RoundCheckinFields
    }
  }
  ${RoundCheckInFields}
  ${BRRoundFields}
`;

import { selectable as s } from "@store/generic_store";

export default class BRRound extends Base<BRRound> {
  __typename?: string;
  @s() id?: number;
  @s() playDayId?: number;
  @s() eventId?: number;
  @s() eventType?: string;
  @s() startTime?: string;
  @s() endTime?: string;
  @s() hostKey?: string;
  @s() roundNumber?: number;
  checkins?: BRRoundCheckin[];
  @s() checkin?: number;
  results?: BRRoundresult[];
  playDay?: PlayDay;

  constructor({
    checkins = [] as BRRoundCheckin[],
    results = [] as BRRoundresult[],
    ...rest
  } = {}) {
    super(rest);

    this.checkins = checkins.map((checkin) => new BRRoundCheckin(checkin));
    this.results = results.map((result) => new BRRoundresult(result));
  }
}
