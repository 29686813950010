import gql from "graphql-tag";
import { DetailedTournamentFields } from "@graphql/types/tournament";

export default gql`
  mutation JoinTournament(
    $tournamentId: ID!
    $asUser: Boolean!
    $asTeam: Boolean
    $teamId: ID
  ) {
    joinTournament(tournamentId: $tournamentId, asUser: $asUser, asTeam: $asTeam, teamId: $teamId) {
      success
      tournament {
        ...DetailedTournamentFields
      }
    }
  }
  ${DetailedTournamentFields}
`;
