












































import { Component, Vue, Prop, FormLabel } from "@components/common";
import First from "@/assets/icons/icn-place-1.vue";
import Second from "@/assets/icons/icn-place-2.vue";
import Third from "@/assets/icons/icn-place-3.vue";

@Component({components: {First, Second, Third, FormLabel}})
export default class PromotedPlayers extends Vue {
  @Prop({default: () => ([])}) readonly participants;
  @Prop() readonly isTeam!: boolean;

  private tempUserId = null;

  getParticiapntLink (id) {
    if (id < 0 ) return "";
    return this.isTeam ?
      {name: 'Team', params: { teamId: id }}
      : {name: 'Players', params: { userId: id }};
  }
}
