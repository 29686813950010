





















































import PlayDay from "@/graphql/types/play_day";
import { Component, Vue, Prop } from "@components/common";
import BRCheckInButton from "@components/events/active/BR_check_in_button.vue";
import Event from "@graphql/types/event";
import { DateTime } from "luxon";
import { sortBy, groupBy, max, min } from "underscore";
import FormattedTime from "@components/common/formatted_time.vue";
import GetMatchKey from "@components/events/active/get_match_key.vue";

@Component({
  components: { BRCheckInButton, FormattedTime, GetMatchKey },
})

export default class BRActiveEventUserAction extends Vue {
  @Prop(Object) event!: Event;
  @Prop(String) eventType!: string;


  // Tramslation variables

    get translationVariables () {
    const variables = {
      // current round
      roundStartTime: this.activeRound ? DateTime.fromISO(this.activeRound.startTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundStartDate: this.activeRound ? DateTime.fromISO(this.activeRound.startTime).toLocaleString(DateTime.DATE_MED) : '',
      roundEndTime: this.activeRound ? DateTime.fromISO(this.activeRound.endTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundEndDate: this.activeRound ? DateTime.fromISO(this.activeRound.endTime).toLocaleString(DateTime.DATE_MED) : '',
      roundCheckInTime: this.activeRound ? DateTime.fromISO(this.checkInEndTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      roundCheckInDate: this.activeRound ? DateTime.fromISO(this.checkInEndTime).toLocaleString(DateTime.DATE_MED) : '',
      roundNumber: this.activeRound ? this.activeRound.roundNumber : '',
      roundHostKey: this.activeRound ? this.activeRound.hostKey : '',

      // next round
      nextRoundStartTime: this.nextRound ? DateTime.fromISO(this.nextRound.startTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      nextRoundStartDate: this.nextRound ? DateTime.fromISO(this.nextRound.startTime).toLocaleString(DateTime.DATE_MED) : '',
      nextRoundEndTime: this.nextRound ? DateTime.fromISO(this.nextRound.endTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      nextRoundEndDate: this.nextRound ? DateTime.fromISO(this.nextRound.endTime).toLocaleString(DateTime.DATE_MED) : '',
      nextRoundCheckInTime: this.nextRound ? DateTime.fromISO(this.nextRoundcheckInEndTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      nextRoundCheckInDate: this.nextRound ? DateTime.fromISO(this.nextRoundcheckInEndTime).toLocaleString(DateTime.DATE_MED) : '',
      nextRoundNumber: this.nextRound ? this.nextRound.roundNumber : '',
      nextRoundHostKey: this.nextRound ? this.nextRound.hostKey : '',

      // current playDay
      playDayStartTime: this.activePlayDay ? DateTime.fromISO(this.activePlayDay.startTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      playDayStartDate: this.activePlayDay ? DateTime.fromISO(this.activePlayDay.startTime).toLocaleString(DateTime.DATE_MED) : '',
      playDayEndTime: this.activePlayDay ? DateTime.fromISO(this.activePlayDay.endTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      playDayEndDate: this.activePlayDay ? DateTime.fromISO(this.activePlayDay.endTime).toLocaleString(DateTime.DATE_MED) : '',
      playDayNumber: this.activePlayDay ? this.activePlayDay.dayNumber : '',

      // next playDay
      nextPlayDayStartTime: this.nextPlayDay ? DateTime.fromISO(this.nextPlayDay.startTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      nextPlayDayStartDate: this.nextPlayDay ? DateTime.fromISO(this.nextPlayDay.startTime).toLocaleString(DateTime.DATE_MED) : '',
      nextPlayDayEndTime: this.nextPlayDay ? DateTime.fromISO(this.nextPlayDay.endTime).toLocaleString(DateTime.TIME_SIMPLE) : '',
      nextPlayDayEndDate: this.nextPlayDay ? DateTime.fromISO(this.nextPlayDay.endTime).toLocaleString(DateTime.DATE_MED) : '',
      nextPlayDayNumber: this.nextPlayDay ? this.nextPlayDay.dayNumber : '',

      // Discord
      gameDiscordUrl: this.event.game?.discordChannelUrl,
      gameDiscordChannel: this.event.game?.discordChannelName
    };
    return variables;
  }

  // To ensure time based reactivity we do this (not good, not terrible)

  private seconds = 0;
  private interval;

  mounted() {
    this.interval = setInterval(this.timer, 10_000);
  }

  private timer() {
    this.seconds = this.seconds +1;
  }

  unmounted() {
    clearInterval(this.interval);
  }


  // Rounds
  get rounds() {
    const rounds = this.event.playDays?.map(o => o.rounds).flat();
    return rounds;
  }

  get sortedRounds() {
    return sortBy(this.rounds, function(o) { return o.startTime; }) || [];
  }

  get activeRound() {
    const time = this.seconds;
    const activeround = this.sortedRounds?.find(p =>
      ((DateTime.fromISO(p?.startTime) < DateTime.local()) && (DateTime.fromISO(p?.endTime) > DateTime.local())
      ));

    return activeround;
  }

  get isResultAlreadySubmitted() {
    return !!this.activeRound.results?.some(r => r.submitter?.id === this.currentUser?.id);
  }

  get checkInEndTime() {
    return (DateTime.fromISO(this.activeRound?.startTime).plus({seconds: this.event.checkin})) || '';
  }

  get nextRoundcheckInEndTime() {
    return (DateTime.fromISO(this.nextRound?.startTime).plus({seconds: this.event.checkin})) || '';
  }

  get checkinPassed() {
    return (DateTime.fromISO(this.checkInEndTime) < DateTime.local()) || false;
  }

  get futureRounds() {
    return this.sortedRounds?.filter(p => (DateTime.fromISO(p.startTime) > DateTime.local()));
  }

  get nextRound() {
    return this.futureRounds[0];
  }

  // PlayDays

  get playDays() {
    return this.event.playDays;
  }

  get sortedPlayDays() {
    return sortBy(this.playDays, function(o) { return o.startTime; }) || [];
  }

  get activePlayDay() {
    return this.playDays?.find(p => ((DateTime.fromISO(p.startTime) < DateTime.local()) && (DateTime.fromISO(p.endTime) > DateTime.local()))) || null;
  }

  get nextPlayDay() {
    return this.futurePlayDays[0];
  }

  get futurePlayDays() {
    return this.sortedPlayDays?.filter(p => ((DateTime.fromISO(p.startTime) > DateTime.local()) && (DateTime.fromISO(p.endTime) > DateTime.local())));
  }

  get hasEventStarted() {
    if (DateTime.fromISO(this.event.startTime) < DateTime.local())
      return true;
    return false;
  }

  showSubmitScoreDialog(...args) {
    this.$emit("showSubmitScoreDialog", ...args);
  }

  get canParticipate() {
    return !this.isUserFinished;
  }

  get isUserFinished() {
    return this.myParticipation?.eliminated;
  }

  checkIn() {
    this.$notify({text: this.$t("common.not_available_yet").toString()});
  }


  get myParticipation() {
    return this.event.participations?.find(p => p.id === this.currentUserId);
  }

  get participants() {
    return [
      ...this.event.participatingUsers ?? [],
      ...this.event.participatingTeams ?? []
    ];
  }

  refreshTournament(...args) {
    this.$emit("refreshTournament", ...args);
  }
}
