import gql from "graphql-tag";

import { BRRoundFields } from "@graphql/types/br_round";

export default gql`
  mutation SubmitRoundResult(
    $roundId: ID!
    $rank: Int!
    $kills: Int
    $data: JSON
  ) {
    submitRoundResult(
      roundId: $roundId
      rank: $rank
      kills: $kills
      data: $data
    ) {
      success

      round {
        ...BRRoundFields
      }
    }
  }
  ${BRRoundFields}
`;
