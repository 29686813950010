





























import { Component, Vue } from "@components/common";

@Component
export default class Onboarding extends Vue {
}
