




























import { Component, Vue, Prop } from "@components/common";

@Component
export default class LanguageSelector extends Vue {
  @Prop({ default: () => []}) languages!: Record<string, string>[];

  get currentLanguageIcon() {
    const current = this.languages.find(lang => lang.id == this.$i18n.locale);
    return current;
  }

  get currentLanguageFlag() {
    return this.currentLanguageIcon?.flag;
  }

  changeLanguage(lang: string) {
    this.$i18n.locale = lang;
  }
}
