import gql from "graphql-tag";
import { MatchResultFields } from "@graphql/types/match_result";
import { MatchFields } from "@graphql/types/match";

export default gql`
  mutation Admin_SubmitMatchResult(
    $matchId: ID!
    $scores: [MatchResultScoreInput!]!
    $data: JSON
    $initiatorId: ID!
    $resetMatch: Boolean
    $resetMatchParams: ResetMatchParamsInput
    $reason: String!
  ) {
    admin {
      submitMatchResult(
        matchId: $matchId
        scores: $scores
        data: $data
        initiatorId: $initiatorId
        resetMatch: $resetMatch
        resetMatchParams: $resetMatchParams
        reason: $reason
      ) {
        success
        matchResult {
          ...MatchResultFields
        }

        match {
          ...MatchFields
        }
      }
    }
  }
  ${MatchResultFields}
  ${MatchFields}
`;
