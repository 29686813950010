import { getSelectables } from "@store/generic_store";

interface Model {
  id?: number;
}

class Base<Subtype> implements Model {
  static readonly module: string;

  id?: number;

  constructor(data?: Partial<Subtype>) {
    if (!data) return;

    Object.assign(this, data);
  }
}

export { Model, Base };

export default Base;
