













































































import { Component, Vue, Prop } from "@components/common";
import { uniq } from "underscore";

import EventHelpers from "@/lib/helpers/event_helpers";
import MatchFilter from "@/lib/helpers/match_filter";

import Division from "@graphql/types/division";
import Event from "@graphql/types/event";
import Match from "@graphql/types/match";
import MatchResult from "@graphql/types/match_result";
import Participation from "@graphql/types/participation";

import DivisionStandings from "@components/standings/division_standings.vue";
import DivisionsMatches from "@components/events/divisions/divisions_matches.vue";
import DivisionsPlayoffs from "@components/events/playoffs/divisions_playoffs.vue";
import GroupMatches from "@components/events/groups/group_matches.vue";
import GroupStandings from "@components/events/groups/group_standings.vue";
import Heading from "@components/v2/Heading.vue";
import MatchesOverviewTable from "@components/events/matches_overview_table.vue";
import SimplePlayoffs from "@components/events/playoffs/simple_playoffs.vue";

@Component({
  components: {
    DivisionStandings,
    DivisionsMatches,
    DivisionsPlayoffs,
    GroupMatches,
    GroupStandings,
    Heading,
    MatchesOverviewTable,
    SimplePlayoffs,
  }
})
export default class EventMatchesTab extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly divisions!: Division[];
  @Prop() readonly selectedDivision: any;

  private matches: Match[] = [];
  private refreshInterval: any = null;
  private loading = true;

  private groupPanels: number[] = [];

  mounted() {
    this.loadData()
      .then(this.expandOwnGroup);

    this.$eventBus.$on("store:update", this.updateMatch);
    this.refreshInterval = setInterval(this.loadMatches, 60_000);
  }

  unmounted() {
    this.$eventBus.$off("store:update", this.updateMatch);

    clearInterval(this.refreshInterval);
  }

  get eventHelpers() {
    return new EventHelpers(this.event);
  }

  get sortedDivisions() {
    return this.divisions.sort((a, b) => {
      const [aLevel, bLevel, aPos, bPos] = [a.level, b.level, a.position, b.position];

      if (!aLevel || !bLevel || !aPos || !bPos)
        return 0;

      return aLevel != bLevel ? aLevel - bLevel : aPos - bPos;
    });
  }

  get topmostDivision() {
    return this.sortedDivisions[0];
  }

  expandOwnGroup() {
    let myGroup = this.myGroup;

    if (this.isDivision && this.divisions.length == 1 || !this.isDivision && this.uniqueGroupNumbers.length == 1)
      return this.groupPanels = [0];

    if (!myGroup) {
      if (this.isDivision)
        this.groupPanels = [this.divisionIndex(this.topmostDivision.id)];
      else
        this.groupPanels = this.uniqueGroupNumbers;
    } else {
      this.groupPanels = this.isDivision ? [this.divisionIndex(myGroup)] : [myGroup - 1];
    }
  }

  get myGroup() {
    return this.matches.find(m => m.participations?.some(p => p.id == this.currentUserId))?.group;
  }

  loadData() {
    this.loading = true;

    return Promise.all([
      this.loadMatches()
    ]).finally(() => this.loading = false);
  }

  updateMatch({name, record: match}) {
    if (name !== "match")
      return;

    const index = this.matches.findIndex(m => m.id == match.id);

    if (index != -1)
      this.$set(this.matches, index, match);
  }

  async loadMatches() {
    this.matches = await this.$store.dispatch("matches/search", {
      filters: {eventId: Number(this.event.id)},
      limit: 999,
      extraSelection: [{
        participations: Participation,
        result: MatchResult,
      }],
      noCache: true
    });
  }

  get isDivision() {
    return this.divisions.length > 0;
  }

  get hasGroupMatches() {
    return this.event.eventFlow?.eventFlowStages?.some(efs => efs.flowType === "EventStageFlows::Qualification::GroupQualifications") || false;
  }

  get uniqueGroupNumbers() {
    return uniq(this.matches.map(m => m.group)).sort();
  }

  get uniqueDivisionsIds() {
    return this.sortedDivisions.map(d => d.id);
  }

  get matchesPerGroup() {
    const prop = this.isDivision ? "divisionId" : "group";
    const groups = this.isDivision ? this.uniqueDivisionsIds : this.uniqueGroupNumbers;

    return groups.map(group => ({
      group: group,
      pastMatches: this.pastMatches.filter(m => m[prop] == group),
      futureMatches: this.futureMatches.filter(m => m[prop] == group),
      nextMatches: this.nextMatches.filter(m => m[prop] == group),
      previousMatches: this.previousMatches.filter(m => m[prop] == group),
    }));
  }

  divisionIndex(id) {
    return this.sortedDivisions.findIndex(d => d.id == id);
  }

  getDivision(id) {
    return this.divisions.find(d => d.id == id);
  }

  get showExpansionPanels() {
    return true;

    //return !this.isDivision;
  }

  get selectedDivisionMatches() {
//    if (this.isDivision)
//      return this.matches.filter(m => m.divisionId == this.selectedDivision.id);
//
    return this.matches;
  }

  get previousMatches() {
    return uniq(
      new MatchFilter(this.selectedDivisionMatches).latestCompletedMatchPerParticipations.map(([, match]) => match),
      false,
      match => match.id
    );
  }

  get pastMatches() {
    return new MatchFilter(this.selectedDivisionMatches).pastMatches;
  }

  get nextMatches() {
    return uniq(
      new MatchFilter(this.selectedDivisionMatches)
        .nextMatchPerParticipations
        .map(([, match]) => match),
      false,
      match => match.id
    );
  }

  get futureMatches() {
    return new MatchFilter(this.selectedDivisionMatches).futureMatches;
  }

}
