import gql from "graphql-tag";

import { TeamInvitationFields } from "@graphql/types/team_invitation";

export const ListMyReceivedInvitations = gql`
  query ListMyReceivedInvitations {
    myReceivedInvitations {
      ...TeamInvitationFields
    }
  }
  ${TeamInvitationFields}
`;
