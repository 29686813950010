<template>
  <transition
    appear
    name="slideFromBottom"
  >
    <div
      id="cookie-law-info-bar"
      class="cookie-consent-bar"
      v-if="isOpen"
    >
      <div class="text-body">
        {{ $t('cookie_consent.text') }}
      </div>
      <div class="links">
        <a
          class="accept"
          role="button"
          tabindex="0"
          id="cookie_action_close_header"
          @click="accept"
        >{{ $t('cookie_consent.accept') }}</a>
        <strong>
          <a
            href="https://www.senior-esports.ch/privacy-policy/"
            target="_blank"
          >{{ $t('cookie_consent.more') }}
          </a>
        </strong>

      </div>
    </div>
  </transition>
</template>

<script>
  import * as Cookie from 'tiny-cookie';

  const STORAGE_TYPES = {
    local: 'localStorage',
    cookies: 'cookies'
  };

  export default {
    name: 'CookieConsent',
    data() {
      return {
        supportsLocalStorage: true,
        isOpen: false,
        storageName: 'cookie:accepted',
        storageType: STORAGE_TYPES.local,
        cookieOptions: {}
      };
    },
    computed: {
      containerPosition () {
        return `Cookie--${this.position}`;
      },
      cookieTheme () {
        return `Cookie--${this.theme}`;
      },
      externalButtonLink () {
        return typeof this.buttonLink === 'string' && this.buttonLink.length;
      },
      internalButtonLink () {
        return typeof this.buttonLink === 'object' && this.buttonLink != null && Object.keys(this.buttonLink).length;
      },
      target () {
        return this.buttonLinkNewTab ? '_blank' : '_self';
      },
      canUseLocalStorage () {
        return this.storageType === STORAGE_TYPES.local && this.supportsLocalStorage;
      }
    },
    created () {
      if (this.storageType === STORAGE_TYPES.local) {
        // Check for availability of localStorage
        try {
          const test = '__vue-cookielaw-check-localStorage';
          if (typeof window !== 'undefined') {
            window.localStorage.setItem(test, test);
            window.localStorage.removeItem(test);
          }
        } catch (e) {
          console.info('Local storage is not supported, falling back to cookie use');
          this.supportsLocalStorage = false;
        }
      }
      if (!this.getVisited()) {
        this.isOpen = true;
      }
    },
    mounted () {
      if (this.isAccepted()) {
        this.$emit('accept');
      }
    },
    methods: {
      setVisited () {
        if (this.canUseLocalStorage) {
          localStorage.setItem(this.storageName, true);
        } else {
          Cookie.set(this.storageName, true, { ...this.cookieOptions, expires: '1Y' });
        }
      },
      setAccepted () {
        if (this.canUseLocalStorage) {
          localStorage.setItem(this.storageName, true);
        } else {
          Cookie.set(this.storageName, true, { ...this.cookieOptions, expires: '1Y' });
        }
      },
      setDeclined () {
        if (this.canUseLocalStorage) {
          localStorage.setItem(this.storageName, false);
        } else {
          Cookie.set(this.storageName, false, { ...this.cookieOptions, expires: '1Y' });
        }
      },
      getVisited () {
        let visited = false;
        if (this.canUseLocalStorage) {
          visited = localStorage.getItem(this.storageName);
        } else {
          visited = Cookie.get(this.storageName);
        }
        if (typeof visited === 'string') {
          visited = JSON.parse(visited);
        }
        return !(visited === null || visited === undefined);
      },
      isAccepted () {
        let accepted = false;
        if (this.canUseLocalStorage) {
          accepted = localStorage.getItem(this.storageName);
        } else {
          accepted = Cookie.get(this.storageName);
        }
        if (typeof accepted === 'string') {
          accepted = JSON.parse(accepted);
        }
        return accepted;
      },
      accept () {
        this.setVisited();
        this.setAccepted();
        this.isOpen = false;
        this.$emit('accept');
      },
      close () {
        this.isOpen = false;
        this.$emit('close');
      },
      decline () {
        this.setVisited();
        this.setDeclined();
        this.isOpen = false;
        this.$emit('decline');
      },
      revoke () {
        if (this.canUseLocalStorage) {
          localStorage.removeItem(this.storageName);
        } else {
          Cookie.remove(this.storageName);
        }
        this.isOpen = true;
        this.$emit('revoke');
      },
      open () {
        if (!this.getVisited()) {
          this.isOpen = true;
        }
      }
    }
  };
</script>

<style lang="scss">
.cookie-consent-bar {
  background-color: rgb(23, 28, 39);
  color: #ffffff;
  font-family: inherit;
  bottom: 0px;
  padding: 16px;

  @include respond-above(sm) {
    display: flex;
  }

  .links {
    text-align: center;
    padding: 16px 0 0;

    @include respond-above(sm) {
      padding: 0 16px;
    }

    a {
      display: block;
      color: #ffffff;
      padding: 8px 12px;
    }
  }

  .accept {
    display: inline-block;
    padding: 8px 12px;
    color: #ffffff;
    background-color: $primary;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.slideFromBottom-enter, .slideFromBottom-leave-to {
  transform: translateY(100%);
}
.slideFromBottom-enter-to, .slideFromBottom-leave {
  transform: translate(0px, 0px);
}
.slideFromBottom-enter-active,
.slideFromBottom-leave-active {
  transition: transform .75s cubic-bezier(.75,0,0,1);
}
</style>