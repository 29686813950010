




























































































































import MatchSettings from "@/components/matches/match_settings.vue";
import { Component, Vue, Prop, Watch } from "@components/common";
import Match from "@graphql/types/match";
import {isEmpty} from "underscore";

const EMPTY_PARTICIPANT = {id: -1, name: "?", link: ""};

@Component({components: {MatchSettings}})
export default class Bracket extends Vue {
  @Prop() readonly match!: Match;
  @Prop() readonly participants;
  @Prop() readonly standings;
  @Prop() readonly event;
  @Prop(Array) readonly allMatches!: Match[];

  @Prop({default: false}) readonly isFinalMatch!: boolean;
  @Prop({default: false}) readonly isTeamBased!: boolean;

  private emptyParticipants = [
    EMPTY_PARTICIPANT,
    EMPTY_PARTICIPANT
  ]

  @Watch("match.result.id")
  get participantOneScore() {
    return this.match?.result?.scores?.[this.participantOne.id] ?? "?";
  }

  get isAdmin() {
    return this.isEventAdmin(this.event);
  }

  get participantTwoScore() {
    if (this.isAutoWin)
      return "?";

    return this.match?.result?.scores?.[this.participantTwo?.id] ?? "?";
  }

  get matchParticipants() {
    if (!isEmpty(this.match.participations)) {
      return this.match.participations?.map(p => ({
        ...p
      }));

    } else {
      return this.emptyParticipants;
    }
  }

  getParticipantLink(id) {
    if (id < 0) return "";
    return this.isTeamBased ?
      {name: 'Team', params: { teamId: id }}
      : {name: 'Players', params: { userId: id }};
  }

  get participantTwoAvatar() {
    return this.participantTwo?.avatar?.url ?? "";
  }

  get participantOneAvatar() {
    return this.participantOne?.avatar?.url ?? "";
  }

  get participantOne() {
    const opponentId = this.matchParticipants?.[0]?.id;
    const participant = this.participants.find(p => p.id == opponentId);
    const standing = this.standings?.find(s => s.participantId == participant.id)?.position || null;

    return { ...participant, standing};
  }

  get participantTwo() {

    if (!this.match.participationsAssigned) return EMPTY_PARTICIPANT;
    const opponentId = this.matchParticipants?.[1]?.id;
    const participant = this.participants.find(p => p.id == opponentId);
    const standing = this.standings?.find(s => s.participantId == participant.id)?.position || null;

    if (!opponentId)
      return null;

    return { ...participant, standing};
  }

  get isAutoWin() {
    return !this.participantTwo;
  }

  get isOwnMatch() {
    const id = this.currentUserId;

    return this.participantOne.id == id ||
      this.participantTwo?.id == id;
  }

  get winner() {
    const winnerParticipationId = this?.match?.result?.winnerId;

    const winnerParticipation = this.match?.participations?.find(p =>
      p.participationId === winnerParticipationId
    );

    if (!winnerParticipation)
      return null;

    return this.participants.find(p => p.id == winnerParticipation.id);
  }

  get isParticipantOneWon() {
    return this?.participantOne?.id === this.winner?.id;
  }

  get isParticipantTwoWon() {
    return this?.participantTwo?.id === this.winner?.id;
  }

  get winnerAvatarUrl() {
    return this.winner?.avatar?.url ?? "";
  }

  submitResult() {
    this.$emit("openSubmitResult", this.match);
  }

  updateMatchResult({matchId, result}) {
    console.log(`Updating match ${matchId} result in Bracket`);
    if (this.match?.id == matchId) {
      this.$set(this, "match", { ...this.match, result: result });
    }
  }

  mounted() {
    this.$eventBus.$on("match:update-result", this.updateMatchResult);
  }

  unmounted() {
    this.$eventBus.$off("match:update-result", this.updateMatchResult);
  }
}
