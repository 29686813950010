










































































import EditMatchScore from "@/components/events/active/edit_match_score.vue";
import EditMatchSettings from "@/components/events/active/edit_match_settings.vue";
import { Component, Vue, Prop } from "@components/common";
import Match from "@graphql/types/match";

@Component({ components: { EditMatchScore, EditMatchSettings }})
export default class MatchSettings extends Vue {
  @Prop() readonly event;
  @Prop() readonly match!: Match;
  @Prop(Array) readonly matches!: Match[];
  @Prop({default: true}) checkFutureMatches!: boolean;
  @Prop() readonly groupedMatchIds?: number[];

  private editMatchScore = false;
  private editMatchSettings = false;

  get disableEditMatchScore() {
    return {
      state: this.isMatchBlockedByFutureMatches,
      reason: "This action would affect future matches that have already been played."
    };
  }

  get disableMatchSettings() {
    //@TODO
    return {
      state: false,
      reason: "This feature is not implemented yet"
    };
  }

  get isMatchBlockedByFutureMatches() {
    if (!this.checkFutureMatches)
      return false;

    const matches = this.matches ?? this.event.matches ?? [];
    const match = this.match;
    if (!match || !matches)
      return true;

    return matches.some(m => this.isBlockingMatch(m));
  }

  updateMatch(...args) {
    this.$emit("updateMatch", ...args);
  }

  isBlockingMatch(match: Match): boolean {
    const currentMatch = this.match as unknown as Match;
    match = match as unknown as Match;

    if (!currentMatch)
      return true;

    // HACK: Weird TS typechecking that makes no sense to me, but needs a
    // null-coalesce to not complain
    return (match?.stage ?? 0) > (currentMatch?.stage ?? 0) &&
      match.playoffsGroupId == currentMatch.playoffsGroupId &&
      match.eventFlowStageId == currentMatch.eventFlowStageId &&
      ((currentMatch?.participations ?? []).some(p => match?.participations?.find(pp => p.id == pp.id)));
  }
}
