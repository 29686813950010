












import { Component, Page, Layout } from "@components/common";

@Component({
  components: { Layout },
})
export default class Faq extends Page {

}
