import gql from "graphql-tag";
import Base from "@graphql/types/base";

import UserModel, { UserFields } from "@graphql/types/user";
import TeamModel, { TeamFields } from "@graphql/types/team";


export const TeamInvitationIdFields = gql`
  fragment TeamInvitationIdFields on TeamInvitation {
    id
    teamId
    inviterId
    inviteeId
  }
`;

export const TeamInvitationFields = gql`
  fragment TeamInvitationFields on TeamInvitation {
    id
    team {
      ...TeamFields
    }
    inviter {
      ...UserFields
    }
    invitee {
      ...UserFields
    }
  }
  ${TeamFields}
  ${UserFields}
`;

export class TeamInvitation extends Base<TeamInvitation> {
  team?: TeamModel;
  inviter?: UserModel;
  invitee?: UserModel;
}

export default TeamInvitation;
