





















































import { Page, Component, Layout } from "@components/common";
import PlayerCard from "@components/v2/PlayerCard.vue";
import Filters from "@components/events/filters.vue";
import { userFilters } from "@/store/filters";
import scrollMonitor from 'scrollmonitor';

import User from "@graphql/types/user";

@Component({
  components: { PlayerCard, Layout, Filters },
})
export default class Players extends Page {
  private isUpdating = true;
  private isUpdatingSearch = true;
  private activeSearch = false;
  private loadingMore = false;
  private page = 1;
  private hasMore = true;
  private users: User[] = []

  public filters = userFilters;

  markUpdating() {
    this.activeSearch = true;
    this.isUpdatingSearch = true;
  }

  refresh() {
    this.page = 1;
    this.loadPlayers();
  }

  async loadMorePlayers() {
    this.loadingMore = true;

    const users = await this.getPlayers(++this.page);

    users.forEach(u =>
      !this.users.find(u_ => u_.id == u.id) && this.users.push(u)
    );

    this.loadingMore = false;
    this.isUpdating = false;
    this.isUpdatingSearch = false;
  }

  async loadPlayers() {
    this.isUpdating = true;
    this.isUpdatingSearch = true;

    this.users = await this.getPlayers();

    this.loadingMore = false;
    this.isUpdating = false;
    this.isUpdatingSearch = false;
  }

  async getPlayers(page = 1): Promise<User[]> {
    const limit = 25;

    const users = await this.$store.dispatch("users/searchBest", {
      filters: this.filters,
      extraSelection: [
        "avatar { url }",
        "games { id tag name avatar {url} }"
      ],
      page,
      limit
    });

    this.hasMore = users.length >= limit;

    return users;
  }

  initializeInfiniteScroll() {
    const el = document.getElementById('sensor');
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      if (!(this.isUpdating || this.loadingMore) && this.hasMore) {
        this.loadMorePlayers().finally(() => this.loadingMore = false);
      }
    });
  }


  mounted() {
    this.loadPlayers()
      .then(this.initializeInfiniteScroll);
  }
}
