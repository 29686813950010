import { Mutation, Action } from "vuex-module-decorators";
import gql from "graphql-tag";
import { apollo } from "@lib/graphql";
import createStore from "@store/generic_store";

import Notification from "@graphql/types/notification";

export default class NotificationsStore extends createStore({
  name: "notification",
  recordType: Notification,
}) {
  @Action({ rawError: true })
  async markAllRead() {
    const result = await apollo.mutate({
      mutation: gql`
        mutation MarkAllNotificationsRead {
          markAllNotificationsRead {
            success
          }
        }
      `,
    });

    return result.success;
  }
}
