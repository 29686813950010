




import { GuestPage, Component } from "@components/common";

@Component
export default class Logout extends GuestPage {
  async mounted() {
    await this.$store.dispatch("authentication/logout");

    this.$router.push("/");
  }
}
