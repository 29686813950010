










































































































import {
  Vue,
  Component,
  Watch,
  Prop,
  Layout,
  FormLabel,
  Divider
} from "@components/common";

import TeamMembersManagementForm from "@components/teams/member_management_form.vue";
import { notEmpty } from "@lib/validations";
import Team from "@graphql/types/team";

@Component({ components: { Layout, FormLabel, Divider, TeamMembersManagementForm }} )
export default class TeamForm extends Vue {
  @Prop({default: "update"}) private action!: string;
  @Prop() team!: Team;

  private isFormValid = true;
  private notEmpty = notEmpty;
  private form = {
    name: "",
  }

  private hasAvatar = false;
  private hasCoverImage = false;
  private coverImage: File | null = null;
  private avatar: File | null = null;
  private avatarSrc? = "";
  private coverImageSrc? = "";

  mounted() {
    if (this.teamId)
      this.fillForm();
    }

  get teamId() {
    if (this.team === undefined) {
      return undefined;
    } else {
      return this.team.id;
    }
  }

  get saveButtonText() {
    if (this.teamId)
      return this.$t('common.save_changes');
    else
      return this.$t("new_team.create");
  }

  fillForm(data = this.team) {
    Object.keys(this.form).forEach(key =>
      this.form[key] = data[key]
    );

    if (data.avatar?.url) {
      this.avatarSrc = data.avatar.url;
      this.hasAvatar = true;
    }

    if (data.coverImage?.url) {
      this.coverImageSrc = data.coverImage.url;
      this.hasCoverImage = true;
    }
  }

  setCoverImage({ target: { files }}) {
    this.coverImage = files[0];
    this.hasCoverImage = true;
  }

  setAvatar({ target: { files }}) {
    this.avatar = files[0];
    this.hasAvatar = true;
  }

  @Watch("avatar")
  setAvatarSrc() {

    if (!this.hasAvatar)
      return "";

    const reader = new FileReader();

    reader.onload = event =>
      this.avatarSrc = event?.target?.result as string;

    const avatar = this.avatar;

    if (avatar && avatar.size > 5000000) {
      this.$notify({title: this.$t("errors.teams.image_to_big").toString(), type: 'error'});
      return;
    }

    if (avatar)
      reader.readAsDataURL(avatar);
  }

  @Watch("coverImage")
  setCoverImageSrc() {
    if (!this.hasCoverImage)
      return;

    const reader = new FileReader();

    reader.onload = event =>
      this.coverImageSrc = event?.target?.result as string;

    const coverImage = this.coverImage;

    if (coverImage && coverImage.size > 5000000) {
      this.$notify({title: this.$t("errors.teams.image_to_big").toString(), type: 'error'});
      return;
    }

    if (coverImage)
      reader.readAsDataURL(coverImage);
  }

  get showPlayerManagement() {
    return this.action == "update";
  }

  async saveChanges() {
    if (this.action == "create")
      return await this.createTeam();
    else
      return await this.updateTeam();
  }

  async createTeam() {
    if (!this.teamForm.validate())
      return;

    try {
      const result = await this.$store.dispatch("teams/create", {
        name: this.form.name,
        avatar: this.avatar,
        coverImage: this.coverImage
      });

      if (result.success) {
        this.$notify({title: this.$t("profile_settings.saved.team_created").toString()});
        this.$router.push({name: 'Edit Team', params: {teamId: result.team.id}});
      }

    } catch (error) {
      this.$notify({title: this.$t("errors.teams.image_to_big").toString(), type: 'error'});
    }
  }

  async updateTeam() {
    try {
      const result = await this.$store.dispatch("teams/update", {
        id: this.team.id,
        attributes: {
          name: this.form.name,
          avatar: this.avatar,
          coverImage: this.coverImage
        }
      });

      if (result.success) {
        this.$notify({text: this.$t("profile_settings.saved.team").toString()});
        this.$router.push({ name: 'Team Management'});
        this.fillForm(result.team);
      }
    } catch (error) {
      this.$notify({title: this.$t("errors.teams.image_to_big").toString(), type: 'error'});
    }
  }

  update(team) {
    this.$emit("update", team);
  }

  get teamForm() {
    return this.$refs.teamForm as Vue & { validate: () => boolean };
  }
}

