

































































































































































import { Vue, Component, Prop } from "@components/common";
import LanguageSelector from "@components/common/language_selector.vue";
import Notifications from "@components/v2/Notifications.vue";

const ALWAYS_LANGUAGES =[
  {id: 'en', title: 'English', flag: '$icn-flag-en'},
  {id: 'de', title: 'German', flag: '$icn-flag-de'},
];

const DEV_LANGUAGE = {id: "dev", title: "Develop", flag: "$icn-flag-dev"};
const EMPTY_LANGUAGE = {id: "empty", title: "Empty", flag: "$icn-flag-dev"};

@Component({ components: { LanguageSelector, Notifications } })
export default class Header extends Vue {
  @Prop({ default: ''}) readonly routeName!: string
  @Prop({ default: ''}) readonly routePath!: string
  @Prop({ default: null }) readonly returnPath!: string | null;
  @Prop({ default: false}) readonly plain!: boolean
  @Prop({ default: () => []}) readonly mainTabs!: [string]

  private drawer = false

  private toolbarOptions = {
    flat: true,
    fixed: true,
    app: true,
  }

  goBack() {
    window.history.back();
  }

  // Newest and highest priority
  get hasReturnPath() {
    return !!this.returnPath && this.returnPath.length > 0;
  }

  get hasRouteName() {
    return this.routeName && this.routeName?.length > 0;
  }

  get hasRoutePath() {
    return this.routePath && this.routePath?.length > 0;
  }

  get languages() {
    let languages = [...ALWAYS_LANGUAGES];

    if (process.env.VUE_APP_ENABLE_DEV_LANGUAGE == "true")
      languages.push(DEV_LANGUAGE);

    if (process.env.VUE_APP_ENABLE_EMPTY_LANGUAGE == "true")
      languages.push(EMPTY_LANGUAGE);

    return languages;
  }

  get hasHeading() {
    return !!this.$slots.default;
  }

  get buildNumber() {
    return process.env.VUE_APP_BUILD_NUMBER;
  }
}
