




































import { Page, Component, Layout, FormLabel } from "@components/common";
import Platform from "@graphql/types/platform";

interface PlatformWithValue extends Platform {
  value: string;
}

interface PlatformEntry {
  platformId: number;
  accountIdentifier: string;
  data: JSON;
}

@Component({
  components: { Layout, FormLabel }
})
export default class GameAccounts extends Page {
  private form = {};

  get platforms() {
    return this.appProperties.platforms;
  }

  get myPlatforms() {
    return this.currentUser?.userPlatforms;
  }

  mounted() {
    this.fillExistingValues(this.myPlatforms);
  }

  fillExistingValues(values) {
    values.forEach(val => {
      this.$set(this.form, val.platform.id, val.accountIdentifier);
    });
  }


  async savePlatforms() {
    const platforms = Object.keys(this.form).map(key =>
      ({platformId: key, accountIdentifier: this.form[key], data: {}})
    );

    const result = await this.$store.dispatch("me/setPlatforms", { platforms });

    if (result.success) {
      this.$notify({text: this.$t("profile_settings.saved.game_accounts").toString()});
      this.$store.commit("authentication/setPlatforms", result.platforms.map(p => ( {
        accountIdentifier: p.accountIdentifier,
        platform: this.platformById(p.platformId)
      })));
      this.$router.push({ name: 'Profile Settings'});
    }
  }
}
