
























































































import { Component, Vue, Prop, Divider, FormLabel } from "@components/common";
import CardDetailHeader from "@components/cups/card_detail_header.vue";
import UploadImage from "@components/events/upload_image.vue";

@Component({
  components: { CardDetailHeader, Divider, FormLabel, UploadImage },
})

export default class BRSubmitRoundScore extends Vue {
  @Prop() readonly event!: Event;
  @Prop({default: 1}) readonly roundId!: number;
  @Prop() readonly show!: boolean;
  @Prop({default: false}) readonly showUpload!: boolean;
  @Prop() readonly translationVariables;

  private finalPlace = '';
  private totalKills = '';
  private screenShot = '';

  async submitResult() {
    const result = await this.$store.dispatch("rounds/submitResult", {
      roundId: this.roundId,
      rank: parseInt(this.finalPlace),
      kills: parseInt(this.totalKills),
      screenshot: this.screenShot
    });

    if (result.success) {
      this.$gtag.event('round_submit_score', {
        'event_category': 'round_action',
        'event_label': 'Submit round score',
        'value': { userId: this.currentUserId, roundId: this.roundId}
      });

      this.$notify({text: this.$t("round.result_card.result_submitted").toString()});
      this.close();
    }
  }

  async confirmResult() {
    if (!this.roundId)
      return;

    const result = await this.$store.dispatch("rounds/confirmResult", { roundId: this.roundId });

    if (result.success) {
      this.$notify({text: this.$t("round.result_card.result_confirmed").toString()});
    }
  }

  close() {
    this.$emit("close");
    this.$emit("refreshTournament");
  }

  attachScreenshot(payload) {
    this.screenShot = payload;
  }
}
