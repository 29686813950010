import gql from "graphql-tag";
import Base from "@graphql/types/base";

import { selectable as s } from "@/store/generic_store";

export const BaseEventFlowStageFields = gql`
  fragment BaseEventFlowStageFields on EventFlowStage {
    id
    subtype
    eventFlowId
    status
    position
    role
    flowType
    configuration
    data
    output
  }
`;

export default class EventFlowStage extends Base<EventFlowStage> {
  @s() id?: number;
  @s() subtype?: string;
  @s() eventFlowId?: number;
  @s() status?: string;
  @s() position?: number;
  @s() role?: string;
  @s() flowType?: string;
  @s() configuration?: JSON;
  @s() data?: JSON;
  @s() output?: JSON;

  static isQualifications(efs) {
    const parts = efs.flowType?.split("::") ?? [];
    return parts[1] === "Qualification";
  }

  static isPlayoffs(efs) {
    const parts = efs.flowType?.split("::") ?? [];
    return parts[1] === "Playoffs";
  }

  static isSignUp(efs) {
    const parts = efs.flowType?.split("::") ?? [];
    return parts[1] === "Signup";
  }
}

export interface EventFlowStageConfiguration {
  end_time?: Date;
  start_time?: Date;
}

export interface ESFQualificationLeagueDivisionsConfiguration
  extends EventFlowStageConfiguration {
  division_size?: number;
  match_type?: string;
  play_periods?: PlayPeriod[];
}

export interface ESFPlayoffsLeagueDivisionsPlayoffsConfiguration
  extends EventFlowStageConfiguration {
  playoffs?: PlayoffConfig[];
}

interface PlayPeriod {
  end_time: Date;
  start_time: Date;
  position: number;
}

interface PlayoffConfig {
  start_time?: Date;
  end_time?: Date;
  promotion_count: number;
  relegation_count: number;
  system: string;
  tag?: string;
  stages: PlayoffStage[];
  participations: PlayoffParticipation[];
}

interface PlayoffStage {
  end_time: Date;
  start_time: Date;
  stage_number: number;
}

interface PlayoffParticipation {
  division_level: number;
  top: number;
}
