




































import { Component, Vue, Prop, Divider } from "@components/common";

import EventAction from "@components/events/action.vue";
import JoinDialog from "@components/tournaments/join_dialog.vue";
import CardDetailHeader from "@components/leagues/league_card_detail_header.vue";
import ConfirmationDialog from "@components/events/confirmation_popup.vue";
import ErrorDialog from "@components/events/error_popup.vue";

import League from "@graphql/types/league";
import Platform from "@graphql/types/platform";
import Team from "@graphql/types/team";

@Component({
  components: {
    ConfirmationDialog,
    JoinDialog,
    EventAction,
    CardDetailHeader,
    Divider,
    ErrorDialog
  },
})
export default class LeagueCardDetail extends Vue {
  @Prop(Object) readonly league!: League;
  @Prop(Boolean) readonly allowJoin!: boolean
  @Prop({default: false}) readonly details!: boolean;

  private myTeams!: Team[];
  private platforms: Platform[] = [];
  private selectedTeamId = null;
  private showConfirmation = false;
  private showError = false

  get showTeamsDropdown() {
    return this.isTeamBased && this.myTeams?.length > 1;
  }

  get confirmationDialog() {
    return this.showConfirmation;
  }

  private closeConfirmationDialog() {
    this.showConfirmation = false;
  }

  get checkinTime() {
    if (this?.league.checkin !== undefined) {
      return Math.round(this.league.checkin / 60);
    }
      return undefined;
  }

  get uniquePlatformsIcons() {
    const platforms = this.league?.platforms;

    if (!platforms)
      return [];

    return Array.from(
      new Set(platforms.map(x => x.icon))
    );
  }

  get participantCount() {
    const league = this.league;

    if (!league)
      return 0;

    return Math.max(
      league?.participatingUsers?.length ?? 0,
      league?.participatingTeams?.length ?? 0
    );
  }

  get leagueFull() {
    const league = this.league;

    if (!league || !league.maxPlayers)
      return false;

    return this.participantCount >= league.maxPlayers;
  }

  get isTeamBased() {
    return !this.isUserBased;
  }

  get isUserBased() {
    return this.league?.mode === "1v1";
  }

  get isReschedule() {
    // TODO: needs implementation
    return false;
  }

  isTeamAlreadyParticipating(team) {
    return this?.league?.participatingTeams?.some(p => p.id == team.id);
  }

  showSubmitScoreDialog(...args) {
    this.$emit("showSubmitScoreDialog", ...args);
  }

  async joinLeagueAsTeam({ league, teamId }) {
    return this.joinLeague(league,{
      leagueId: this.league.id,
      asUser: false,
      asTeam: true,
      teamId: teamId
    });
  }

  async joinLeagueAsUser({ league }) {
    return this.joinLeague(league, {
      leagueId: this.league.id,
      asUser: true
    });
  }

  async joinLeague(league, variables) {
    let result;

    if (!this.isLoggedIn) {
      this.$router.push({name: 'Join'});
      return;
    }

    try {
      result = await this.$store.dispatch("leagues/join",variables);

      this.showConfirmation = true;
      this.$emit("update", {league: new League(result.league)});
      this.$gtag.event('signup_to_league', {
        'event_category': 'event_action',
        'event_label': 'Sign up to League',
        'value': { userId: this.currentUserId, eventId: this.league.id, eventName: this.league.name, eventType: 'League' }
      });
    } catch (error) {
      this.showError = true;
    }
  }

  refreshLeague(...args) {
    this.$emit("refreshLeague", ...args);
  }
}
