import gql from "graphql-tag";
import Event from "@graphql/types/event";

import { FileFields } from "@graphql/types/file";
import { MatchCheckinFields } from "@graphql/types/checkin";
import { ParticipationFields } from "@graphql/types/participation";
import { TeamMetaFields } from "@graphql/types/team";
import { UserEventFields } from "@graphql/types/user";
import { EventFlowWithStagesFields } from "@graphql/types/event_flow";
import { DivisionWithParticipationsFields } from "@graphql/types/division";
import { PlayoffsGroupWithParticipationsFields } from "@graphql/types/playoffs_group";

export const LeagueBaseFields = gql`
  fragment LeagueBaseFields on Event {
    # Basic
    # Display
    # IDs
    # Operation
    category
    category
    checkin
    descriptions
    endTime
    featured
    gameId
    id
    matchCount
    maxPlayers
    minimumAge
    mode
    name
    platformIds
    rules
    seasonId
    startTime
    status
    tag
  }
`;

export const LeagueParticipationsFields = gql`
  fragment LeagueParticipationsFields on Event {
    participations {
      ...ParticipationFields
    }
    participatingUsers {
      ...UserEventFields
    }
    participatingTeams {
      ...TeamMetaFields
    }
  }
  ${ParticipationFields}
  ${UserEventFields}
  ${TeamMetaFields}
`;

export const LeagueFields = gql`
  fragment LeagueFields on Event {
    ...LeagueBaseFields
    avatar {
      ...FileFields
    }
    coverImage {
      ...FileFields
    }
  }
  ${FileFields}
  ${LeagueBaseFields}
`;

export const ListLeagueFields = gql`
  fragment ListLeagueFields on Event {
    ...LeagueBaseFields
    avatar {
      url
    }
    participations {
      id
    }
  }
  ${LeagueBaseFields}
`;

export const LeagueMatchCardFields = gql`
  fragment LeagueMatchCardFields on Event {
    ...LeagueBaseFields
    ...LeagueParticipationsFields
    checkins {
      ...MatchCheckinFields
    }
    standings {
      participantId
      position
    }
  }
  ${LeagueBaseFields}
  ${MatchCheckinFields}
  ${LeagueParticipationsFields}
`;

export const MyLeaguesFields = gql`
  fragment MyLeaguesFields on Event {
    ...LeagueBaseFields
    ...LeagueParticipationsFields
    avatar {
      ...FileFields
    }
    participations {
      ...ParticipationFields
    }
  }
  ${FileFields}
  ${LeagueBaseFields}
  ${ParticipationFields}
  ${LeagueParticipationsFields}
`;

export const DetailedLeagueFields = gql`
  fragment DetailedLeagueFields on Event {
    ...LeagueFields
    ...LeagueParticipationsFields
    divisions {
      ...DivisionWithParticipationsFields
    }
    eventFlow {
      ...EventFlowWithStagesFields
    }
    playoffsGroups {
      ...PlayoffsGroupWithParticipationsFields
    }
    standings {
      position
      participantId
      playoffsGroupId
    }
    administratorUserIds
  }
  ${LeagueFields}
  ${LeagueParticipationsFields}
  ${EventFlowWithStagesFields}
  ${DivisionWithParticipationsFields}
  ${PlayoffsGroupWithParticipationsFields}
`;

export default class League extends Event {}
