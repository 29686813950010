




































































































































































import { Component, Vue, Prop, Divider } from "@components/common";
import { ListUsersWithTeamIds } from "@graphql/queries/users";
import { sortBy } from "underscore";

import Team from "@graphql/types/team";
import User from "@graphql/types/user";

@Component({
  components: {
    Divider
  },
  apollo: {
    users: {
      query: ListUsersWithTeamIds,
      variables() { return { filters: this.filters}; },
      result() { this.isUpdating = false; },
      update: ({ users }) => users.map(u => new User(u))
    }
  }
})
export default class TeamMemberManagementForm extends Vue {
  @Prop() team!: Team;
  @Prop({ default: false }) readonly hideMembers;

  private users: User[] = [];

  private addMembersModal = false

  private isUpdating = false;
  private filters = {
    name: ""
  }

  get sortedUsers() {
    return sortBy(this.users, "name");
  }

  get members() {
    return this.team.members?.map(member => new User(member));
  }

  get memberIds() {
    return this.members?.map(member => member.id);
  }

  updateUsers() {
    this.$apollo.queries.users.refetch();
  }

  canRemoveMember(user) {
    return user.id != this.team?.captain?.id;
  }

  canAddMember(player) {
    if (!player.teamIds)
      return true;

    return !player.teamIds.includes(this.team.id);
  }

  async addMember(player) {
    const result = await this.$store.dispatch("teams/addMember", {
      teamId: this.team.id,
      userId: player.id
    });

    if (result.success) {
      this.$notify({text: this.$t("profile.teams.memeber_added").toString()});
      this.$emit("update", result.team);

      player.teamIds.push(result.team.id);
    }
  }

  async removeMember(user) {
    const confirmed = await this.confirmRemoveMember(user);

    if (confirmed) {
      const result = await this.$store.dispatch("teams/removeMember", {
        teamId: this.team.id,
        userId: user.id
      });

      this.$emit("update", result.team);
    }
  }

  confirmRemoveMember(user) {
    return this.$confirm(
      this.$t("team_form.confirm_remove_member", {name: user.name}).toString()
    );
  }

  markUpdating() {
    this.isUpdating = true;
  }

  refresh() {
    this.$apollo.queries.users.refetch();
  }
}
