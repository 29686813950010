import gql from "graphql-tag";
import { MeFields } from "@graphql/types/me";

export const AuthenticationResultFields = gql`
  fragment AuthenticationResultFields on Authentication {
    token
    user {
      ...MeFields
    }
    teamRoles {
      teamId
      role
    }
    isPremiumUser
    premiumUntil
  }
  ${MeFields}
`;

export const AUTHENTICATE = gql`
  query Authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      ...AuthenticationResultFields
    }
  }
  ${AuthenticationResultFields}
`;

export const REAUTHENTICATE = gql`
  query Reauthenticate {
    reauthenticate {
      ...AuthenticationResultFields
    }
  }
  ${AuthenticationResultFields}
`;

export default AUTHENTICATE;
