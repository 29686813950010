





























import { Component, Vue, Prop } from "@components/common";
import Event from "@graphql/types/event";
import Match from "@graphql/types/match";
import MatchResult from "@graphql/types/match_result";

import { GetDivisionsMatches } from "@graphql/queries/matches";
import DivisionMatches from "@components/leagues/division_matches.vue";
import Division from "@graphql/types/division";

import { Week } from "@components/events/week_selector.vue";
import WeekSelector from "@components/events/week_selector.vue";

import { groupBy } from "underscore";
import { DateTime } from "luxon";

import { namespace } from "@/store";
import { ESFQualificationLeagueDivisionsConfiguration } from "@graphql/types/event_flow_stage";
import ListensForOwnMatchUpdates from "@/mixins/listens_for_own_match_updates";

const AuthenticationState = namespace("authentication");
const DIVISION_FLOW_STAGE = "EventStageFlows::Qualification::LeagueDivisions";

interface GroupedMatch extends Match {
  groupedMatchIds: number[];
  groupedResults: MatchResult[];
}

@Component({
  mixins: [ListensForOwnMatchUpdates],
  components: { DivisionMatches, WeekSelector },
  apollo: {
    matches: {
      query: GetDivisionsMatches,
      variables() { return { filters: {divisionIds: this.eventDivisionIds }}; },
      result() { this.activeDivision = this.defaultDivision; this.loading = false; },
    },
  }
})

export default class DivisionsMatches extends Vue {
  @Prop() readonly event!: Event;
  @Prop() readonly selectedDivision: any;

  private matches: Match[] = [];
  private tab = null
  private filterMyMatches = true;
  private loading = true;

  @AuthenticationState.State
  private teamRoles;
  private weekId = null;

  updateSelectedWeek(val) {
    this.weekId = val.id;
  }

  get divisionWeeks(): Week[] {
    const flowStageConf = this.event.eventFlow?.eventFlowStages?.find(efs => efs.flowType === DIVISION_FLOW_STAGE)?.configuration as ESFQualificationLeagueDivisionsConfiguration;

    return flowStageConf?.play_periods?.map(pp => (
      {
        id: pp.position,
        startTime: DateTime.fromISO(pp.start_time),
        endTime: DateTime.fromISO(pp.end_time),
        number: String((Number(pp.position) + 1)),
        name:  this.$t(`common.weekpicker.weekname`, { number: (Number(pp.position) + 1) })
      }
    )) as Week[];
  }

  get currentDivisionWeek() {
    const currentWeek = this.divisionWeeks?.find(w => w.startTime < DateTime.local() && w.endTime > DateTime.local());
    return currentWeek;
  }

  get groupedMatches() {
    const groupedMatches = (Object.values(groupBy(this.matches, "identifier")) as Match[][]).map(
      matches => ({
        ...matches[0],
        groupedMatchIds: matches.map(m => m.id),
        groupedResults: matches.map(m => m.result)
      } as GroupedMatch)
    );

    return groupedMatches;
  }

  get eventDivisionIds() {
    return this.event.divisions?.map(d => d.id) ?? [] as Division[];
  }

  get selectedDivisionId(): number{
    return Number(this.selectedDivision.id);
  }

  get divisionMatches() {
    const matches = this.groupedMatches?.filter(m => !!m.divisionId);

    return groupBy(matches, "divisionId");
  }

  get selectedDivsionMatches() {
    return this.matches;
  }

  get participants() {
    if (this.event.isUserBased)
      return this.event.participatingUsers;
    else
      return this.event.participatingTeams;
  }

  private noDates(dates) {
    return Object.keys(dates).length;
  }

  // just group matches need to be here

  get teamIds () {
    return (this.teamRoles ?? []).map(id => id.teamId);
  }

  get myMatches() {
    return (this.matches ?? []).filter(match =>
      match.participations?.some(p => p.id == this.currentUserId || this.teamIds.includes(p.id ?? 0))
    );
  }

  get hasMatches() {
    return !!this.myMatches.length;
  }
}
