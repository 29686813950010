import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import Game from "@graphql/types/game";
import Platform from "@graphql/types/platform";
import {
  AppProperties as AppPropertiesInterface,
  GetAppProperties
} from "@graphql/queries/app_properties";
import { createApollo } from "@lib/graphql";
import { store } from "@store";

const apollo = createApollo();

@Module({ namespaced: true })
export default class AppProperties extends VuexModule {
  private loaded = false;
  private _appProperties: AppPropertiesInterface = {
    games: [],
    platforms: [],
    settings: {}
  };

  constructor(stuff) {
    super(stuff);

    setInterval(() => {
      store.dispatch("reload");
    }, 86_400_000); // 1 day
  }

  get settings() {
    return this._appProperties.settings;
  }

  get games() {
    return this._appProperties.games.map(g => new Game(g));
  }

  get platforms() {
    return this._appProperties.platforms.map(p => new Platform(p));
  }

  get appProperties() {
    return this._appProperties as AppPropertiesInterface;
  }

  @Action({ rawError: true })
  async reload() {
    this.context.dispatch("reloadAppProperties");
  }

  @Action
  async reloadAppProperties() {
    const { data: result } = await apollo.query({
      query: GetAppProperties,
      fetchPolicy: "no-cache"
    });

    this.context.commit("setAppProperties", result);
  }

  @Mutation
  setAppProperties(appProperties) {
    this._appProperties = appProperties;
    this.loaded = true;
  }

  @Mutation
  clear() {
    this._appProperties = { games: [], platforms: [], settings: {} };
    this.loaded = false;
  }
}
