
























































































































































































































import CardDetailHeader from "@components/tournaments/card_detail_header.vue";
import Event from "@graphql/types/event";
import Match from "@graphql/types/match";
import { Component, Prop, Vue, Divider, FormLabel, Watch } from "@components/common";
import { DateTime } from "luxon";
import { Datetime } from 'vue-datetime';
import { GetMatchWithResult } from "@graphql/queries/match";
import { notEmpty } from "@lib/validations";

import 'vue-datetime/dist/vue-datetime.css';

@Component({
  components: {
    CardDetailHeader,
    Divider,
    FormLabel,
    Datetime
  },
  apollo: {
    match: {
      skip() { return !this.matchId; },
      query: GetMatchWithResult,
      variables() { return {id: this.matchId}; },
      pollInterval: 600_000,
      update: data => new Match(data.match),
      result() { this.initializeForm(); }
    }
  }
})
export default class EditMatchSettings extends Vue {
  @Prop(Object) readonly event!: Event;
  @Prop() readonly matchId!: number;
  @Prop({default:false}) justButton!: boolean;
  @Prop() readonly show!: boolean;
  @Prop() readonly groupedMatchIds?: [];

  private match = new Match;
  private form = this.initializeForm(new Match());

  private reasons = [
    {value: "app-not-working", text: "App lief nicht"},
    {value: "opponent-did-not-respond", text: "Gegner hat nicht auf Einladung reagiert"},
    {value: "noshow", text: "No Show am Match"},
    {value: "not-checked-in", text: "Nicht eingecheckt"},
    {value: "forgot-result", text: "Resultat vergessen einzugeben"},
  ]

  get availableParticipations() {
    const participations = this.event?.participations;

    if (!participations || participations.length == 0)
      return [];

    return participations.map(p => ({
      text: p.name,
      value: p.participationId
    }));
  }

  @Watch("matchId")
  reloadMatch() {
    this.$apollo.queries.match.refetch();
  }

  initializeForm(match = this.match) {
    return this.form = {
      startTime: match.startTime,
      participation1Id: match.participations?.[0]?.participationId ?? null,
      participation2Id: match.participations?.[1]?.participationId ?? null,
      initiatorId: null,
      reason: null,
    };
  }

  get formRef() {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  async scheduleMatch() {
    const match = this.match;
    const form = this.form;

    if (!this.formRef.validate())
      return;

    if (!form.startTime)
      return;

    const groupedMatchIds = this.groupedMatchIds ?? [];
    const ids = Array.from(new Set([match.id, ...groupedMatchIds]));

    const result = await this.$store.dispatch("matches/setAdminMatchOptions", {
      ids, ...form
    });

    if (result.success) {
      result.matches.forEach(match => this.$eventBus.$emit("match:update", new Match(match)));

      this.$notify({type: "success", text: this.$t("matches.admin_setoptions_success").toString()});
    } else if (result.errors) {
      this.$notify({type: "error", text: ""});
    }

    this.close();
  }

  get gameId() {
    return this.event.gameId || -1;
  }

  get gameName() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }

  get game() {
    const game = this.gameById(this.gameId);

    return game?.name ?? "";
  }

  get matchMaxDuration() {
    return this.event.matchDuration ? this.event.matchDuration : 0;
  }

  get minDatetime() {
    return DateTime.local().plus({seconds: this.matchMaxDuration}).toISO();
  }

  get maxDatetime() {
    return DateTime.fromISO(this.event.endTime).minus({seconds: this.matchMaxDuration}).toISO();
  }

  close() {
    this.$emit("close");
  }

  get participants() {
    return [
      ...this.event.participatingUsers ?? [],
      ...this.event.participatingTeams ?? []
    ];
  }

  get participation1() {
    return this.match?.participations?.[0];
  }

  get participation1Avatar() {
    const pId = this.match?.participations?.[0]?.id;

    if (!pId)
      return "";

    return this.participants.find(p => p.id == pId);
  }

  get participation2() {
    return this.match?.participations?.[1];
  }

  get participation2Avatar() {
    const pId = this.match?.participations?.[1]?.id;

    if (!pId)
      return "";

    return this.participants.find(p => p.id == pId);
  }

  private notEmpty = notEmpty;
}
