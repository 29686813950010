






























import { MainHeader } from "@components/common";

export default {
  components: {
    MainHeader
  }
};
