



















































































import { Vue, Component, Prop } from "@/components/common";

import Event from "@graphql/types/event";
import Match from "@graphql/types/match";

import FormattedTime from "@components/common/formatted_time.vue";
import Heading from "@components/v2/Heading.vue";
import TableExpander from "@components/v2/TableExpander.vue";
import EditMatchSettings from "@components/matches/match_settings.vue";

import { DateTime } from "luxon";

@Component({
  components: { FormattedTime, Heading, TableExpander, EditMatchSettings },
})
export default class MatchesOverviewTable extends Vue {
  @Prop() private readonly event!: Event;
  @Prop() private matches!: Match[];
  @Prop() private previewMatches!: Match[];
  @Prop({default: "schedule"}) private readonly translationKey!: string;
  @Prop({default: ""}) private readonly help!: string;
  @Prop({type: Boolean, default: false}) private readonly admin!: boolean;


  private preview = true;

  firstParticipantName(match) {
    return match.participations?.[0]?.name ?? this.$t("match.group_match_no_opponent");
  }

  secondParticipantName(match) {
    return match.participations?.[1]?.name ?? this.$t("match.group_match_no_opponent");
  }

  isStartTimeSameDayAsEndDay(match) {
    const start = DateTime.fromISO(match.startTime);
    const end = DateTime.fromISO(match.endTime);

    return start.ordinal == end.ordinal;
  }

  matchScores(match: Match) {
    const scores = match?.result?.scores;
    const participations = match.participations;

    if (!scores || !participations || participations.length == 0)
      return [];

    return [
      scores[participations[0]?.id ?? -1] ?? 0,
      scores[participations[1]?.id ?? -1] ?? 0
    ];
  }

  get shownMatches() {
    return this.preview && !this.previewMatchesEmpty ? this.previewMatches : this.matches;
  }

  get previewMatchesEmpty() {
    return this.previewMatches.length === 0;
  }

  togglePreview() {
    this.preview = !this.preview;
  }

  matchSchedulingState(match) {
    if (!match.startTimeProposedById)
      return "untouched";
    else if (match.isStartTimeConfirmed)
      return "scheduled";
    else
      return "proposed";
  }
}
