














































import { Page, Prop, Component, Layout, FormLabel } from "@components/common";
import EventCard from "@components/v2/EventCard.vue";
import EventsFilterInput from "@components/events/events_filter_input.vue";

import scrollMonitor from 'scrollmonitor';

const AVAILABLE_STATE_FILTERS = {
  upcoming: "notstarted",
  active: "running",
  past: "finished",
  unfinished: "notfinished"
};

const AVAILABLE_CATEGORY_FILTERS = {
  cups: "CUP",
  leagues: "LEAGUE",
  tournaments: "TOURNAMENT"
};

const AVAILABLE_BASIC_FILTERS = [
  "ids",
  "name",
  "gameIds",
  "platformIds",
  "seasonIds",
  "userIds",
  "teamIds",
  "featured",
  "state",
  "categories",
  "participatingUserId",
];

import { debounce } from "underscore";

@Component({
  components: {
    Layout,
    FormLabel,
    EventCard,
    EventsFilterInput
  }
})
export default class EventsListing extends Page {
  @Prop() private readonly mainFilter!: string;

  private loading = false;

  private events: Event[] = [];
  private filters = {};

  private loadingMore = false;
  private hasMore = true;
  private page = 1;

  updateFilters(filters) {
    this.filters = filters;

    debounce(this.loadData, 1000)();
  }

  async loadData() {
    this.loading = true;
    this.hasMore = true;
    this.page = 1;

    return await Promise.all([
      this.loadEvents(),
    ]).then(() => {
      this.loading = false;
    });
  }

  mounted() {

    this.parseFilters();
    this.loadData()
      .then(this.initializeInfiniteScroll);
  }

  initializeInfiniteScroll() {
    const el = document.getElementById('sensor');
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      if (!(this.loading || this.loadingMore) && this.hasMore) {
        this.page += 1;
        this.loadExtraEvents().finally(() => this.loadingMore = false);
      }
    });
  }

  parseFilters() {
    const query = this.$route.query;
    const params = new URLSearchParams(query as Record<string, string>);
    const entries = [...params.entries()].filter(([k,]) => AVAILABLE_BASIC_FILTERS.includes(k) );

    this.filters = entries.reduce((result, [key, value]) => ({
      ...result, [key]: value
    }), {});
  }

  get mainFilterValue() {
    const filter = this.mainFilter;

    if (AVAILABLE_STATE_FILTERS[filter]) {
      return {filters: {state: AVAILABLE_STATE_FILTERS[filter]}};
    }

    if (AVAILABLE_CATEGORY_FILTERS[filter]) {
      return {category: AVAILABLE_CATEGORY_FILTERS[filter]};
    }

    return {};
  }

  async loadEvents(): Promise<void> {
    if (this.mainFilter == "mine") {
      return this.events = this.$store.state.myEnrolments.myEvents;
    }

    this.events = await this.$store.dispatch("events/search", {
      ...this.mainFilterValue,
      filters: {...this.filters, ...(this.mainFilterValue?.filters ?? {})},
      page: this.page,
      limit: 25,
    });
  }

  async loadExtraEvents() {
    const limit = 25;


    this.loadingMore = true;
    console.log("extra, page: ", this.page);
    const extraEvents = await this.$store.dispatch("events/search", {
      ...this.mainFilterValue,
      filters: {...this.filters, ...(this.mainFilterValue?.filters ?? {})},
      page: this.page,
      limit,
    });

    this.hasMore = extraEvents.length == limit;
    extraEvents.forEach(e => this.events.includes(e) || this.events.push(e));
  }

}
