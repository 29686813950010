import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { apollo } from "@lib/graphql";

import JoinTournament from "@graphql/mutations/join_tournament";
import LeaveTournament from "@graphql/mutations/leave_tournament";

@Module({ namespaced: true })
export default class Tournaments extends VuexModule {
  @Action({ rawError: true })
  async join({ tournamentId, asUser, asTeam = false, teamId = null }) {
    const {
      data: { joinTournament: result }
    } = await apollo.mutate({
      mutation: JoinTournament,
      variables: { tournamentId, asUser, asTeam, teamId }
    });

    return result;
  }

  @Action({ rawError: true })
  async leave({ tournamentId, asTeam = false, teamId = null, id = null }) {
    // Support for same style call as events/leave
    tournamentId = tournamentId ?? id;

    const {
      data: { leaveTournament: result }
    } = await apollo.mutate({
      mutation: LeaveTournament,
      variables: { tournamentId, asTeam, teamId }
    });

    return result;
  }
}
