








































import { GuestPage, Component, MainHeader, Layout, FormLabel } from "@components/common";
import { emailRules } from "@lib/validations";


@Component({components: { MainHeader, Layout, FormLabel }})
export default class PasswordForgot extends GuestPage {
  private isComplete = false;
  private valid = false;
  private emailRules = emailRules;

  private email = ""
  private error = false;


  async submit() {
    if (!this.valid)
      return;

    const result = await this.$store.dispatch("users/requestPasswordReset", {
      email: this.email
    });

    if (result.success) {
      this.isComplete = true;
    } else {
      this.$notify({
        type: "error",
        text: this.$t("password_forgot.email_not_found").toString()
      });
    }
  }
}
