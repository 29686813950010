


















import { Vue, Component, Prop } from "@components/common";
import Header from "@components/layout/header.vue";
import Footer from "@components/layout/footer.vue";

@Component({components: {Header, Footer}})
export default class PrimaryLayout extends Vue {
  @Prop({ default: ''}) readonly routeName!: string
  @Prop({ default: false}) readonly plain!: boolean
  @Prop({ default: ''}) readonly routePath!: string
  @Prop({ default: () => []}) readonly mainTabs!: [string]

  @Prop({ default: null }) readonly returnPath!: string | null;
}
