







































import { Page, Component, Layout } from "@components/common";

import Event from "@graphql/types/event";
import EventCard from "@/components/events/event_card.vue";
import HScroller from "@/components/v2/HScroller.vue";
import EventCategoryHelpers from "@lib/helpers/event_category_helpers";
import Filters from "@components/events/filters.vue";
import { ListFilteredEvents } from "@graphql/queries/events";
import { featuredFilters } from "@/store/filters";
import { chain } from "underscore";

@Component({
  components: { Layout, Filters, EventCard, HScroller },
  apollo: {
    featuredEvents: {
      query: ListFilteredEvents,
      variables() { return {tournamentFilters: this.filters, eventFilters: this.filters}; },
      result() { this.isUpdating = false; this.isUpdatingSearch = false;},
      update: data => data
    },
  }
})

export default class Featured extends Page {
  private isUpdating = true;
  private activeSearch = false;
  private isUpdatingSearch = true;
  private featuredEvents = {tournaments: [], events: []}

  public filters = featuredFilters;

  markUpdating() {
    this.activeSearch = true;
    this.isUpdatingSearch = true;
  }

  eventRouteName(event) {
    return new EventCategoryHelpers(event).routeName;
  }

  get events() {
    return chain(this.featuredEvents.tournaments)
      .union(this.featuredEvents.events)
      .sortBy(event => event.startTime)
      .value();
  }

  get hasFeatured() {
    if (this.activeSearch) return true;
    return this.events.length > 0 ? true : false;
  }

  async refresh() {
    this.$apollo.queries.featuredEvents.refresh();
  }
}

