

















import { Component, Vue, Prop } from "@components/common";

@Component
export default class UserAvatar extends Vue {
  @Prop({ default: '' }) public avatarUrl?: string;
  @Prop({ default: null }) public participationId?: number;
}
